import * as types from './actionTypes';
import * as api from './apiActions';
import * as alerts from './alertActions';
//import * as workflows from './workflowActions';
import axios from 'axios';

export function setActiveTab(activeTab) {
    return { type: types.SET_ACTIVE_TAB, activeTab };
}

// export function setActivePage(activePage) {
//     return { type: types.SET_ACTIVE_PAGE, activePage };
// }

export function showSubmitConfigurationButton(showSubmitConfigurationButton) {
    return { type: types.SHOW_SUBMIT_CONFIGURATION_BUTTON, showSubmitConfigurationButton};
}

export const login = (username, password) => {
    return dispatch => {
        return axios.post('/api/v1/auth/login', { username: username, password: password })
            .then(res => {
                if (res.status !== 200) {
                    dispatch(loginFailed());                                                                   
                } else {                                 
                    let login = res.data.result;
                                        
                    if (res.data.success === true) {
                        window.localStorage.setItem('sxnijSpsandfl', login.token);                        
                    }                    

                    dispatch(loginSuccess(username));                          
                    //dispatch(setUserPermissionLevel(login.isAdmin));                          
                    dispatch(setCompanyAccessLevel(login.access));
                    dispatch(setCompany(login.company));
                    dispatch(setManufacturer(login.manufacturer));
                    dispatch(setCompanyTypeId(login.companyTypeId));
                    dispatch(setUserRole(login.roleName));
                    dispatch(setUserLocations(login.userCompanyLocationIdCSV));
                    dispatch(setUserRoleLevel(login.roleLevel));
                    dispatch(api.getUserConfigurations(username));    
                    dispatch(api.getCompanyProducts(login.company));
                    //dispatch(api.getUserActiveProduct(username, login.company));  
                    //dispatch(workflows.getWorkflows(login.company));                                  
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);
                dispatch(loginFailed()); 
            });
    }
}

export const signupUser = (username, uncommittedUserConfigurations) => {
    return dispatch => {
        return axios.post('/api/v1/auth/signup', { username: username })
            .then(res => {
                if (res.status !== 200) {
                    dispatch(signupFailed());                                                                   
                } else {                                        
                    if (res.data.success === true)                        
                        window.localStorage.setItem('sxnijSpsandfl', res.data.token);

                    if (uncommittedUserConfigurations) dispatch(api.commitUncommittedUserConfigurations(username, uncommittedUserConfigurations));
                    dispatch(signupSuccess(username));
                    //dispatch(setUserPermissionLevel(false)); 
                    dispatch(api.getUserConfigurations(username));                    
                }              
            }).catch(function(error) {
                console.log(error);
                dispatch(signupFailed()); 
            });
    }
}

export const getUserCompanyLocationsCSV = (username)  => {
    return dispatch => {
        return axios.post('/api/v1/auth/getUserCompanyLocationsCSV', { username: username })
        .then(res => {            
            if (res.status !== 200) {
               console.log(res);                                                                
            } else {                            
                console.log(res.data.result);            
                dispatch(setUserLocations(res.data.result[0].userCompanyLocationIdCSV));
            }              
        }).catch(function(error) {
            console.log(error);            
        });
    }
}

export const forgotPassword = (username)  => {
    return dispatch => {
        return axios.post('/api/v1/auth/forgotPassword', { username: username })
        .then(res => {            
            if (res.status !== 200) {
               console.log(res);                                                                
            }              
        }).catch(function(error) {
            console.log(error);           
        });
    }
}

export const emailPasswordResetRequest = (storeNumber, email)  => {
    return dispatch => {
        return axios.post('/api/v1/auth/emailPasswordResetRequest', { storeNumber: storeNumber, email: email })
        .then(res => {            
            if (res.status !== 200) {
               console.log(res);                                                                
            } else {
                dispatch(alerts.showSuccessAlert("Password reset request sent!")); 
            }          
        }).catch(function(error) {
            console.log(error);           
        });
    }
}

export const updatePassword = (requestId, password)  => {
    return dispatch => {
        return axios.post('/api/v1/auth/updatePassword', { requestId: requestId, password: password })
        .then(res => {        
            if (res.status !== 200) {
               console.log(res);                                                                
            } else {                               
                if (res.data.success === false) {
                    dispatch(alerts.showFailureAlert("Password reset link expired.")); 
                    return;
                }

                let login = res.data.result;
                              
                window.localStorage.setItem('sxnijSpsandfl', login.token);                        
                               
                dispatch(loginSuccess(login.username));                          
                //dispatch(setUserPermissionLevel(login.isAdmin));                          
                dispatch(setCompanyAccessLevel(login.access));
                dispatch(setCompany(login.company));
                dispatch(setManufacturer(login.manufacturer));
                dispatch(setUserRole(login.roleName));
                dispatch(setUserLocations(login.userCompanyLocationIdCSV));
                dispatch(setUserRoleLevel(login.roleLevel));
                dispatch(setCompanyTypeId(login.companyTypeId));
                dispatch(api.getUserConfigurations(login.username));    
                dispatch(api.getCompanyProducts(login.company));
                //dispatch(api.getUserActiveProduct(login.username, login.company));                                                            
            }              
        }).catch(function(error) {
            console.log(error);           
        });
    }
}

export function showSignupModal(showSignupModal) {
    return { type: types.SHOW_SIGNUP_MODAL, showSignupModal }
}

export function signOut() {
    return { type: types.SIGN_OUT };
}

export function setUserRole(roleName) {
    return { type: types.SET_USER_ROLE, roleName };
}

export function setUserLocations(userCompanyLocationIdCSV) {
    return { type: types.SET_USER_LOCATIONS, userCompanyLocationIdCSV };
}

export function loginSuccess(username) {
    return { type: types.LOGIN_SUCCESS, username };
}

// export function setUserPermissionLevel(isAdmin) {
//     return { type: types.SET_USER_PERMISSION_LEVEL, isAdmin };
// }

export function setConfiguratorMode(mode) {
    return { type: types.SET_CONFIGURATOR_MODE, mode };
}

export function setCompanyAccessLevel(access) {
    return { type: types.SET_COMPANY_ACCESS_LEVEL, access };
}

export function setUserRoleLevel(roleLevel) {
    return { type: types.SET_USER_ROLE_LEVEL, roleLevel };
}

export function setCompany(company) {
    return { type: types.SET_COMPANY, company };
}

export function setManufacturer(manufacturer) {
    return { type: types.SET_MANUFACTURER, manufacturer };
}

export function setCompanyTypeId(companyTypeId) {
    return { type: types.SET_COMPANY_TYPE_ID, companyTypeId };
}

export function loginFailed() {
    return { type: types.LOGIN_FAILED };
}

export function signupSuccess(username) {
    return { type: types.SIGNUP_SUCCESS, username };
}

export function signupFailed() {
    return { type: types.SIGNUP_FAILED };
}

export function reconfigure(isReconfigure) {
    return { type: types.RECONFIGURE, isReconfigure };
}

export function resetApp() {
    return { type: types.RESET_APP };
}