import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

export default class PaginationControl extends React.Component {
    constructor() {
        super();

        this.state = {            
            pageCount: 1,  
            rowsToFetch: 10,                     
        }
    }

    componentDidMount() {
        this.setState({ pageCount: this.props.pageCount, rowsToFetch: this.props.rowsToFetch });        
    }
    
    render() {        
        var items = [];

        for (var i = 1; i < this.props.pageCount + 1; i++) {
            items.push(i);
        }
                
        return (
        <Pagination aria-label="pagination-wrapper">
            {/* <PaginationItem>
                <PaginationLink first onClick={(e => this.props.onPageChange(0))} />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink previous onClick={(e => this.props.onPageChange(1))} />
            </PaginationItem> */}
            {                 
                items.map(i => {
                    return (
                        <PaginationItem active={i === this.props.currentPage}>
                            <PaginationLink onClick={(e => this.props.onPageChange(i))}>
                                {i}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })
            }                        
            {/* <PaginationItem>
                <PaginationLink next onClick={(e => this.props.onPageChange(this.state.currentPage))} />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink last onClick={(e => this.props.onPageChange(this.state.currentPage))} />
            </PaginationItem> */}
        </Pagination>
        );
    }
}

PaginationControl.propTypes = {   
    onPageChange: PropTypes.func.isRequired,
    pageCount: PropTypes.number.isRequired,
    rowsToFetch: PropTypes.number.isRequired
};