import React from 'react';
import PropTypes from 'prop-types';
import { Label, Row, Col } from 'reactstrap';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import * as constants from '../../modules/constants';

export const LabelStyled = styled(Label)`
    margin-bottom: 0 !important;
`;

export const RowStyled = styled(Row)`
    margin-bottom: .5em;
    margin-left: 0 !important;
    margin-right: 0 !important;       

    @media(max-width:1200px) {
        margin-bottom:.5em;        
    }
`;

export const ColStyled = styled(Col)`
    text-align: right;
`;

export const CancelButtonStyled = styled(Button)`
    &.order-cancel-btn {
        padding-left: 7px !important;
        background-color: ${props => props.theme.buttonColor} !important;
        border-color: ${props => props.theme.buttonColor} !important;
        position: relative;
        top: 5px;
    }
`;

export const ButtonStyled = styled(Button)`
   
    position: relative;
    top: 5px;
    padding: 4% !important
    .btn {
        padding: 4% !important;
    }    
`;

const LineActions = props => {   

    //let color = props.submitButtonColor === '#e2231a' ? '#FFF' : '#000';
    
    if (props.orderId) {
        return ( 
            <React.Fragment>   

                {
                    props.sourceQuoteId ?   
                        <Col xs="12" xl={props.headerRowWidth}><h1>Order {props.orderId} - {props.statusName} - Quote {props.sourceQuoteId}</h1></Col>                                                       
                    : 
                        <Col xs="12" xl={props.headerRowWidth}><h1>Order {props.orderId} - {props.statusName}</h1></Col> 
                }                       
                {
                    props.showInvoiceButton === true &&       
                        <ColStyled xl="1" xs="3">                                     
                            <ButtonStyled                     
                                displayText="Add Invoice"   
                                backgroundColor="lightgray"                      
                                onClick={() => {
                                    props.orderInvoiced()} 
                                }/>                            
                        </ColStyled>   
                }
                <ColStyled xl="1" xs="3">                                     
                    <ButtonStyled                     
                        displayText="Cost Report"  
                        backgroundColor="lightgray"                       
                        onClick={() => {
                            props.generateReport("C", "O", props.orderId)
                        } 
                        }/>                            
                </ColStyled>  
                <ColStyled xl="1" xs="3">                                     
                    <ButtonStyled                     
                        displayText="Retail/Genesis"   
                        backgroundColor="lightgray"                      
                        onClick={() => {
                            props.generateReport("P", "O", props.orderId)} 
                        }/>                            
                </ColStyled>  

                {
                    props.showSaveAsDraftButton === true &&       
                        <ColStyled xl="1" xs="3">                                     
                            <ButtonStyled                     
                                displayText="Save Draft"                         
                                onClick={() => {
                                props.saveAsDraft()}}
                                backgroundColor="lightgray"
                                />                            
                        </ColStyled>   
                }
                <ColStyled xl="1" xs="3">                                     
                    <ButtonStyled                     
                        displayText="Previous" 
                        disabled={props.previousDisabled} 
                        onClick={() => props.previousStage()} 
                        backgroundColor="lightgray" />                            
                </ColStyled>        
                {props.submitDisabled === false &&        
                <ColStyled xl="1" xs="3">                               
                    <ButtonStyled 
                        backgroundColor={props.submitButtonColor} 
                        displayText={props.submitButtonLabel} 
                        disabled={props.submitDisabled} 
                        color={"#FFF"}
                        onClick={(e) => props.submit(e)} />                          
                </ColStyled> 
                }
                <ColStyled xl="1" className={props.showCancelButton ? "d-none d-sm-block" : "invisible"}>                         
                    <CancelButtonStyled className="order-cancel-btn" displayText={props.cancelButtonLabel} onClick={(e) => props.onQuoteOrderCancel(e)} />
                </ColStyled> 
            </React.Fragment> 
            ); 
        } else {
            return ( 
                <React.Fragment>   
    
                     <Col xs="12" xl={props.headerRowWidth}>                      
                        <h1>Quote {props.quoteId} - {props.statusName} {props.quoteConvertedLabel} </h1>
                    </Col>           
                    {
                        props.showInvoiceButton === true &&       
                            <ColStyled xl="1" xs="3">                                     
                                <ButtonStyled                     
                                    displayText="Add Invoice"  
                                    backgroundColor="lightgray"                       
                                    onClick={() => {
                                        props.orderInvoiced()} 
                                    }/>                            
                            </ColStyled>   
                    }
                    {
                        props.showSaveAsDraftButton === true &&       
                            <ColStyled xl="1" xs="3">                                     
                                <ButtonStyled                     
                                    displayText="Save Draft"  
                                    backgroundColor="lightgray"                       
                                    onClick={() => {
                                    props.saveAsDraft()}
                                    }/>                            
                            </ColStyled>   
                    }
                    <ColStyled xl="1" xs="3">                                     
                    <ButtonStyled                     
                        displayText="Cost Report"    
                        backgroundColor="lightgray"                     
                        onClick={() => {
                            props.generateReport("C", "Q", props.quoteId)
                        } 
                        }/>                            
                    </ColStyled>  
                    <ColStyled xl="1" xs="3">                                     
                        <ButtonStyled                     
                            displayText="Retail/Genesis"                         
                            onClick={() => {
                                props.generateReport("P", "Q", props.quoteId)
                            }                               
                            }/>                            
                    </ColStyled> 
                    <ColStyled xl="1" xs="3">                                     
                        <ButtonStyled                     
                            displayText="Previous" 
                            disabled={props.previousDisabled} 
                            onClick={() => props.previousStage()}
                            backgroundColor="lightgray"                             
                            />                            
                    </ColStyled>        
                    {props.submitDisabled === false &&        
                    <ColStyled xl="1" xs="3">                               
                        <ButtonStyled 
                            backgroundColor={props.submitButtonColor} 
                            displayText={props.submitButtonLabel} 
                            disabled={props.submitDisabled} 
                            color={'#FFF'}
                            onClick={(e) => props.submit(e)} />                          
                    </ColStyled> 
                    }
                    <ColStyled xl="1" className={props.showCancelButton ? "d-none d-sm-block" : "invisible"}>                         
                        <CancelButtonStyled className="order-cancel-btn" displayText={props.cancelButtonLabel} onClick={(e) => props.onQuoteOrderCancel(e)} />
                    </ColStyled> 
                </React.Fragment> 
                ); 
        }
};

LineActions.propTypes = {         
    submitDisabled: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    onStageChange: PropTypes.func.isRequired,
    showCancelButton: PropTypes.bool.isRequired,
    onQuoteOrderCancel: PropTypes.func.isRequired,
    cancelButtonLabel: PropTypes.string.isRequired,
    stage: PropTypes.number.isRequired,
    isLocked: PropTypes.bool,
    previousDisabled: PropTypes.bool.isRequired,
    showInvoiceButton: PropTypes.bool,
    showSaveAsDraftButton: PropTypes.bool,
    sourceQuoteId: PropTypes.number,
    headerRowWidth: PropTypes.number,
    orderId: PropTypes.string,
    statusName: PropTypes.string,
    quoteConvertedLabel: PropTypes.string,
    generateReport: PropTypes.func

};

export default LineActions;