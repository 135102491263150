import React from 'react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import * as quoteActions from '../../actions/quoteActions';
import * as orderActions from '../../actions/orderActions';
import * as alertActions from '../../actions/alertActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import $ from 'jquery';
import PropTypes from 'prop-types';
import * as constants from '../../modules/constants';
import InvoiceList from './InvoiceList';
import Button from '../Common/Button/Button';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';

export const FileDropArea = styled.div`
    .wrapper {       
      width: 100%;
      border: dashed 2px lightgray;
      cursor: pointer;
    }

    div {
      padding: 15px;
      text-align: center;
      color: lightgray;
      font-size: 24px !important;
      font-weight: 400;
    }
`;

export const LabelStyled = styled(Label)`
    margin-bottom: 0 !important;
`;

export const ColStyled = styled(Col)`
    padding-left: 5px !important;
    padding-right: 5px !important;
`;

export const RowStyled = styled(Row)`
    width:95%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: .5em !important;
`;

export const FormGroupLeft = styled(FormGroup)`
  button { float: left !important; }
`;

export const FormGroupRight = styled(FormGroup)`
   button { float: right !important; }   
`;

class Invoices extends React.Component {
  constructor(props, context) {
    super(props, context); 
  
      this.onImageDrop = this.onImageDrop.bind(this);  
      this.onClickDownloadFile = this.onClickDownloadFile.bind(this);   
      this.returnToOrder = this.returnToOrder.bind(this);
      this.updateHeader = this.updateHeader.bind(this);
      this.submitInvoice = this.submitInvoice.bind(this);

      this.state = {
        orderId: null,
        onFocusValue: null,
        isLocked: false
      }
    }

    componentDidMount() {      
      this.props.orderActions.getInvoices(this.props.location.orderId, constants.ATTACHMENT_TYPES.Invoices);
      
      this.setState({ orderId: this.props.location.orderId, isLocked: this.props.location.isLocked });
    }

    onClickDownloadFile(path, originalName, mimetype, filename) {
      this.props.quoteOrderCommonActions.downloadFile(path, originalName, mimetype, filename);       
    }
    
    onInputFocus(event) {
      this.setState({onFocusValue: event.target.value});
    }

    updateHeader(column, value) {
      this.props.quoteOrderCommonActions.updateHeaderField(this.props.order.id, column, value, constants.TABLES.Order);
    }
  
    onImageDrop(acceptedFiles) {
      const data = new FormData();
      
      for (let i = 0; i < acceptedFiles.length; i += 1) {


        // const allowedTypes = ["image/png", "application/pdf", "image/jpeg", "application/msword", "image/tiff", "image/bmp"];

        // if (allowedTypes.includes(acceptedFiles[i].type) === false) {           
        //    this.props.alertActions.showFailureAlert("Invalid file type.");
        //    return;
        // }

        data.append('file', acceptedFiles[i]);        
      }

      data.append('company', this.props.location.company);
      data.append('quoteId', null);
      data.append('orderId', this.props.location.orderId);
      data.append('attachmentType', 3);
     
  
      $.ajax({
        url: '/api/v1/quoteOrderCommon/uploadAttachment',
        data,
        processData: false,
        contentType: false,
        method: 'POST',
        dataType: 'json',
        success: (response) => {
          if (response) {            
            this.props.orderActions.getInvoices(this.props.location.orderId, constants.ATTACHMENT_TYPES.Invoices);
            this.props.quoteOrderCommonActions.getAttachments(null, this.props.order.id);            
          }                   
        },
        error: (jqXHR) => {
          const res = jqXHR.responseJSON;
          alert('error: ' + JSON.stringify(res));
        },
      });
    }

    returnToOrder() {
      this.props.history.push({
        pathname: "/Order",        
      });
    }

    submitInvoice() {
      if (this.props.order.attachments.length < 1) {
        this.props.alertActions.showFailureAlert("Please upload the drawing, purchase order, and invoice documents.");
        return;
      } else if (!this.props.order.poNumber) {
        this.props.alertActions.showFailureAlert("Please enter the purchase order number.");
        return;
      } else if (!this.props.order.poCostTotal) {
        this.props.alertActions.showFailureAlert("Please enter the purchase order cost total.");
        return;      
      } else if (!this.props.order.invoiceNumber) {
        this.props.alertActions.showFailureAlert("Please enter an invoice number.");
        return;      
      }

      // mike - order completed
      const promise1 = this.props.quoteOrderCommonActions.updateField(this.props.order.id, 'invoiced', true, constants.TABLES.Order); 
      const promise2 = this.props.quoteOrderCommonActions.updateHeaderField(this.props.order.id, constants.ORDER_SQL_COLUMNS.Status, constants.STATUS_TYPES.Completed, constants.TABLES.Order);
      const promise3 = this.props.orderActions.saveCompletedOrderAttachmentsToDirectory(this.props.order.id, this.props.order.contact[0].lastName);
      //const promise3 = addNew Action(thunk) to download all files to folders for given order
      //this.props.orderActions.NewFunction
      Promise.all([promise1, promise2]).then(() => {
        this.props.history.push({
          pathname: "/Order",        
        });
      });      
    }
  
    render() {     
      return (
        <React.Fragment> 
          <RowStyled>
            <ColStyled>
              <FormGroup>
                <h1>Invoice - Order # {this.props.order.id} </h1>            
                <p>
                    Please upload an POD and any other documents by dragging and dropping the file in the box below.

                    Please verify the PO # and enter the PO Amt before submitting the invoice.                   
                </p>  
              </FormGroup>          
            </ColStyled>
          </RowStyled>
          <RowStyled>            
            <ColStyled>
              <FormGroup>
                    <LabelStyled for="poNumber">PO Number<span className="text-danger">*</span></LabelStyled>
                    <TextInputPrimitive disabled={this.state.isLocked} id="poNumber" name="poNumber" defaultValue={this.props.order.poNumber} onClick={(e) => this.onInputFocus(e)} onBlur={(e) => this.updateHeader(e.target.id, e.target.value)} />                 
              </FormGroup> 
            </ColStyled>
            <ColStyled>
              <FormGroup>
                <LabelStyled for="poCostTotal">PO Cost Total<span className="text-danger">*</span></LabelStyled>
                <TextInputPrimitive disabled={this.state.isLocked} id="poCostTotal" name="poCostTotal" defaultValue={this.props.order.poCostTotal} onClick={(e) => this.onInputFocus(e)} onBlur={(e) => this.updateHeader(e.target.id, e.target.value)} />                 
              </FormGroup> 
            </ColStyled>
            <ColStyled>
              <FormGroup>
                <LabelStyled for="invoiceNumber">Invoice Number<span className="text-danger">*</span></LabelStyled>
                <TextInputPrimitive disabled={this.state.isLocked} id="invoiceNumber" name="invoiceNumber" defaultValue={this.props.order.invoiceNumber} onClick={(e) => this.onInputFocus(e)} onBlur={(e) => this.updateHeader(e.target.id, e.target.value)} />                 
              </FormGroup> 
            </ColStyled>

            
          </RowStyled>         
          <InvoiceList orderId={this.state.orderId} invoices={this.props.order.invoices} onClickDownloadFile={this.onClickDownloadFile}/>
          <FileDropArea>            
              <Dropzone onDrop={acceptedFiles => this.onImageDrop(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                  <section>
                  <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="wrapper"><div>Drag and drop files here or click to select files</div></div>
                  </div>
                  </section>
              )}
              </Dropzone>
          </FileDropArea>

          <RowStyled>
            <ColStyled>
              <FormGroupRight>
                <Button id="invoiceBack" onClick={this.returnToOrder} displayText="Back" />
              </FormGroupRight> 
            </ColStyled>
            <ColStyled>
              <FormGroupLeft>
                <Button id="invoiceSubmit" onClick={this.submitInvoice} displayText="Submit" />
              </FormGroupLeft> 
            </ColStyled>
          </RowStyled>        
          
        </React.Fragment>
      );
    }
  }
 
function mapStateToProps(state, ownProps) {    
    return {
      quote: state.quote,
      order: state.order,
      alert: state.alert,
      quoteOrderCommon: state.quoteOrderCommonActions,
    };
  }
  
function mapDispatchToProps(dispatch) { 
    return {
        quoteActions: bindActionCreators(quoteActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoices));

Invoices.propTypes = {
  
};