import React from 'react';
import Modal from '../Common/CPQ/Modal';
import useModal from '../Common/Modals/useModal';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import Button from '../Common/Button/Button';
import { useState } from 'react';
import {Row, Col } from 'reactstrap';
import styled from 'styled-components';
import validator from '../../modules/validation';
import { FakeXMLHttpRequest } from 'sinon';

export const ButtonStyled = styled(Button)`
    &.btn {
        width: 125px !important;
        position: relative;
        top: 9px;
    }
`;

export const Labelstyled = styled.label`
    display: block;
    color: red;
`;
const AddDesigners = props => {
    const [email, setEmail] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const {isShowing, toggle} = useModal();
    
    return (             
        <Row>
            <Col>
                <ButtonStyled onClick={toggle} displayText="Add Designer" />
            </Col>
            {isShowing === true && 
                <Modal isShowing={isShowing} hide={toggle}>                
                     <React.Fragment>
                            {showWarning === true &&
                                <Labelstyled>Please enter a valid email address</Labelstyled>
                            }
                            <label for="createdByEmail">Designer Email<span className="text-danger">*</span></label>
                            <TextInputPrimitive                                 
                                id="createdByEmail" 
                                name="createdByEmail"                                
                                onClick={(e) => props.onInputFocus(e)} 
                                onBlur={(e) => setEmail(e.target.value)} /> 
                            
                            <Button displayText="Add" onClick={(e) => { 
                                if (validator.isEmailValid(email) === true) {
                                    setShowWarning(false);
                                    props.updateHeader("createdByEmail", email);
                                    toggle();
                                } else {
                                    setShowWarning(true);
                                }
                            }
                             } />                                               
                        </React.Fragment>
                </Modal>   
            } 
        </Row>       
    );     
};

export default AddDesigners;