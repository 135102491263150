import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxPrimitive from '../Common/Primitives/CheckBoxPrimitive';
import styled from 'styled-components';
import { Row, Col, Label } from 'reactstrap';

export const CheckBoxPrimitiveStyled = styled(CheckBoxPrimitive)`
   width: 70% !important;
`;

const LocationConfiguration = props => {
    return (
        <React.Fragment>
            <Row>
            { props.locations.map(location => {
                return (
                    <React.Fragment>
                        <Col xl="1">
                            <Label>{location.label}</Label>                        
                        </Col>
                        <Col xl="1">
                            <CheckBoxPrimitive name={location.id} id={location.id} onClick={(e) => props.onLocationConfigurationChanged(e)} checked={location.checked} />
                        </Col>
                     </React.Fragment>
                );
            })} 
            </Row>
        </React.Fragment>
    );
};

LocationConfiguration.propTypes = {
    locations: PropTypes.array.isRequired,
    onLocationConfigurationChanged: PropTypes.func.isRequired
};

export default LocationConfiguration;