import React from 'react';
import CheckboxPrimitive from '../Primitives/CheckBoxPrimitive';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel/InputLabel';

const CheckBoxWithLabel = React.memo(props => {        
    return (
        <React.Fragment>         
            <InputLabel className="CheckBoxLabel" htmlFor={props.id} caption={props.labelCaption} isRequired={props.isRequired} />
                <CheckboxPrimitive 
                    className="CheckBox"
                    defaultChecked={props.isChecked}                                         
                    id={props.id}
                    name={props.id}                    
                    // value={props.defaultValue}                
                    onClick={(event) => props.onCheckChanged(event)}
                    disabled={props.isEnabled}
                    required={props.isRequired} 
                    autoFocus={props.autoFocus}                    
                    />                                                                        
        </React.Fragment> 
    );      
});

export default CheckBoxWithLabel;

CheckBoxWithLabel.propTypes = {    
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,    
    labelCaption: PropTypes.string.isRequired,                   
    onCheckChanged: PropTypes.func.isRequired,    
    defaultValue: PropTypes.bool,
    isChecked: PropTypes.bool,
    isEnabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    autoFocus: PropTypes.bool
  };

  CheckBoxWithLabel.defaultProps = {
    isChecked: false,
    isEnabled: false,
    isRequired: false,
    defaultValue: false
}