import React from 'react';
import $ from 'jquery';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);

      this.state = { 
        hasError: false, 
        error: '',
        info: '' 
    };
    }
  
    componentDidCatch(error, info) {
      console.log(error);
      console.log(info);
      // Display fallback UI
      this.setState({ hasError: true, error: error.message, info: info.componentStack });
      // You can also log the error to an error reporting service
     // logErrorToMyService(error, info);
     const data = new FormData();
     data.append('error', 'test');
     data.append('info', 'test');
     
     

     $.ajax({
      url: '/api/v1/administration/logError',
      data,
      processData: false,
      contentType: false, 
      method: 'POST',
      dataType: 'json',
      success: (response) => {
        if (response) {

          // alert('success');
        } else {
         // alert('failed');
        }             
      },
      error: (jqXHR) => {
        const res = jqXHR.responseJSON;
        alert('error: ' + JSON.stringify(res));
      },
    });
  
  }

   
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong. {this.state.error} {this.state.info}</h1>;
      }
      return this.props.children;
    }
  }

  export default ErrorBoundary;