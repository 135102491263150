import * as types from '../actions/actionTypes';
import * as constants from '../modules/constants';

export default function quoteReducer(state = [], action) {
    switch(action.type) {    

        case types.CREATE_QUOTE_SUCCESS:
            return {...state, currentQuoteId: action.currentQuoteId, currentAddresses: null, currentLines: null};
            
        // case types.GET_QUOTE_LINES_SUCCESS:
        //     return {...state, currentLines: action.Lines};
        case types.GET_QUOTE_COMPANY_LOCATION_FABRICATOR_SUCCESS:            
            return {...state, fabricatorName: action.fabricatorName[0].name};

        case types.GET_QUOTE_LINES_SUCCESS:            
            return {...state, lines: action.lines};

        case types.GET_QUOTES_SUCCESS:                   
            return {...state, quoteList: action.quotes};

        case types.SET_ACTIVE_QUOTE:
            return {...state, id: action.currentQuoteId};
        
        case types.GET_QUOTE_ADDRESSES_SUCCESS:            
            let billingAddress = action.addresses.filter(address => address.AddressType === constants.ADDRESS_TYPES.Billing);
            let shippingAddress = action.addresses.filter(address => address.AddressType === constants.ADDRESS_TYPES.Shipping);
            return {...state, billingAddress: billingAddress, shippingAddress: shippingAddress};

        case types.GET_QUOTE_CONTACT_SUCCESS:            
            return {...state, contact: action.contact}; 
            
        case types.GET_QUOTE_COMMUNICATIONS_SUCCESS: 
            return {...state, communicationHistory: action.Communications};
        
        case types.GET_QUOTE_ATTACHMENTS_SUCCESS: 
            return {...state, attachments: action.attachments};

        case types.UPDATE_QUOTE_COMPANY_LOCATION_ID:
            return {...state, companyLocationId: action.companyLocationId};

        case types.GET_QUOTE_BY_ID_SUCCESS:            
            return {...state, 
                    id: action.quote['details'][0].id,
                    companyId: action.quote['details'][0].companyId,
                    companyName: action.quote['details'][0].companyName,
                    createdDate: action.quote['details'][0].createdDate,
                    statusTypeId: action.quote['details'][0].statusTypeId,
                    name: action.quote['details'][0].name,
                    statusName: action.quote['details'][0].statusName,  
                    companyLocationId: action.quote['details'][0].companyLocationId,
                    estimatedShipDate: action.quote['details'][0].estimatedShipDate,                  
                    createdByUserId: action.quote['details'][0].createdByUserId,
                    createdByEmail: action.quote['details'][0].createdByEmail,                    
                    createdBy: action.quote['details'][0].createdBy,
                    isLocked: action.quote['details'][0].isLocked,                                        
                    installType: action.quote['details'][0].installType,
                    installerId: action.quote['details'][0].installerId,
                    poNumber: action.quote['details'][0].poNumber,
                    companyLocationStoreNumber: action.quote['details'][0].companyLocationStoreNumber,
                    shipToTypeId: action.quote['details'][0].shipToTypeId,
                    fabricatorName: action.quote['details'][0].fabricatorName,
                    fabricatorPhone: action.quote['details'][0].fabricatorPhone,
                    orderConvertedTo: action.quote['details'][0].orderConvertedTo,
                    lines: action.quote['lines'], 
                    billingAddress: action.quote['billingAddress'], 
                    shippingAddress: action.quote['shippingAddress'],
                    contact: action.quote['contact'],
                    communicationHistory: action.quote['communicationHistory'],
                    attachments: action.quote['attachments']
            };  

        case types.CLEAR_QUOTE:
            return {...state, 
                id: null,
                companyId: null,
                companyName: null,
                createdDate: null,
                statusTypeId: null,
                name: null,
                statusName: null,  
                companyLocationId: null,
                estimatedShipDate: null,                  
                createdByUserId: null,
                createdByEmail: null,                    
                createdBy: null,
                isLocked: null,                                        
                installType: null,
                installerId: null,
                poNumber: null,
                companyLocationStoreNumber: null,
                shipToTypeId: null,
                fabricatorName: null,
                fabricatorPhone: null,
                orderConvertedTo: null,
                lines: [], 
                billingAddress: [], 
                shippingAddress: [],
                contact: [],
                communicationHistory: [],
                attachments: []
        }; 

        case types.DELETE_QUOTE_ATTACHMENT_SUCCESS:     
            let list = state.attachments;
            console.log(list);
            console.log(action.fileId);
            let newList = state.attachments.filter(a => a.id !== action.fileId);
            console.log(newList);      
            return {...state, attachments: newList };
                  
        case types.GET_QUOTE_HEADER_SUCCESS:
            return {...state, 
                statusTypeId: action.header[0].statusTypeId,
                name: action.header[0].name,
                statusName: action.header[0].statusName,  
                companyLocationId: action.header[0].companyLocationId,
                createdByUserId: action.header[0].createdByUserId,
                createdByEmail: action.header[0].createdByEmail,                
                shipToStore: action.header[0].shipToStore,
                installType: action.header[0].installType,
                poNumber: action.header[0].poNumber,
                installerId: action.header[0].installerId,
                companyName: action.header[0].companyName,
                companyId: action.header[0].companyId,
                shipToTypeId: action.header[0].shipToTypeId,                
            };   
            
        case types.UPDATE_PO_NUMBER_REDUX_ONLY:
            return {...state, poNumber: action.poNumber} 
        
        case types.ORDER_CONVERTED_TO:
            return {...state, orderConvertedTo: action.orderId}

        default:
            return state;
    }    
}