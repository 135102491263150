export const HEADER_ID = 'ICE';
export const DEFAULT_SELECT_OPTION = 'Select an option';
export const DROPDOWN = 'dropdown';
export const RADIO_HORIZTONAL = 'radiobuttonhorizontal';
export const NUMERIC_TEXTBOX = 'numerictextbox';
export const TEXTBOX = 'textbox';
export const CHECKBOX = 'checkbox';
export const IMAGE_TEXT_HORIZONTAL = 'imageandtexthorizontal';
export const NUMERIC_CONTROL = 'numericcontrol';
export const TYPEABLE_DROPDOWN = 'typeabledropdown';
export const IMAGE_DROPDOWN = 'imagedropdown';
export const HOME_DELIVERY = 'Home Delivery';
export const DEFAULT_HOMEPAGE = '/Overview';
export const PLACEHOLDER = 'placeholder';

export const ACCESS = {
  ICE: 1,
  Ordering: 2
}
export const CONFIGURATOR_MODES = {
  Default: 0,
  Quote: 1,
  Order: 2
}

export const AUTO_CONTINUE_MODE = {
  StayOnScreenRule: 0,
  StayOnTab: 1,
  MoveTabAndScreenRule: 2
}

// this name and id must have matching values in the WorkFlowEvents table
export const WORKFLOW_EVENTS = {
  onQuoteStatusChange: 1,
  onQuoteSubmit: 2,
  onQuoteCancellationRequest: 3,
  onQuoteCancellationAccept: 4,
  onQuoteCancellationReject: 5
}

// this name and id must have matching values in the WorkFlowActionTypes table
export const WORKFLOW_ACTION_TYPES = {
  sendEmail: 1,
  updateStatus: 2,
  validateRequiredAttachments: 3,
  SendQuoteCancelRequestEmail: 5,
  UpdateStatusAfterQuoteCancelRequest: 6,
  SendQuoteCancelAcceptEmail: 7, 
  UpdateStatusAfterQuoteCancelAccept: 8, 
  SendQuoteCancelRejectEmail: 9, 
  UpdateStatusAfterQuoteCancelReject: 10
}

export const QUOTE_COMMUNICATION_TYPES = {
  user: 1,
  system: 2
}

export const GET_ALL_COMPANIES_PARAMS = {
  excludeInstallers: 0,
  includeInstallers: 1,
  excludeFabricators: 0,
  includeFabricators: 1
}

export const ROLE = {
  Fabricator: 'Fabricator',  
  User: 'User',
  SystemAdmin: 'SystemAdmin'
}

export const ROLE_ID = {
  Fabricator: 1,
  User: 3,
  SystemAdmin: 5
}

export const MESSAGE_RULE_TYPES = {
  Critical: 1,
  Warning: 2,
  Information: 3
}

export const TABLES = {
  Quote: 'Quote',
  Order: 'Order',
  Address: 'Address',
  Contact: 'Contact',
  CompanyLocations: 'CompanyLocations',
  CompanyAddresses: 'CompanyAddresses',
  Company: 'Company'
}

export const ORDER_SQL_COLUMNS = {
  Status: 'statusTypeId',
  EstimatedShipDate: 'estimatedShipDate',
  ActualShipDate: 'actualShipDate',
  CompanyLocationId: 'companyLocationId',
  ShipToStore: 'shipToTypeId',
  IsLocked: 'isLocked',
  InstallType: 'installType',
  VendorNumber: 'vendorNumber',
  InstallerId: 'installerId',
  Invoiced: 'invoiced',
  createdByEmail: 'createdByEmail'
}

export const COMPANY_SQL_COLUMNS = {
  CompanyTypeId: 'companyTypeId'
}

export const COMPANY_LOCATIONS_SQL_COLUMNS = {
  FabricatorId: 'fabricatorId',
  AllowHomeDelivery: 'allowHomeDelivery',
  AllowSOSInstall: 'allowSOSInstall',
  InstallerId: 'installerId',
  FabricatorLocationId: 'fabricatorLocationId',
  InstallerLocationId: 'installerLocationId',
  AdPatchTypeId: 'adPatchTypeId',
  HomeDeliveryCharge: 'homeDeliveryCharge'
}

export const ADDRESS_TYPES = {
  Billing: 17,
  Shipping: 18
}

export const EMAIL_TYPES = {
  QuoteOrder: "QuoteOrder",
  StatusChange: "StatusChange",
  QuoteCancel: "QuoteCancel",
  UserCommunication: "UserCommunication",
  PendingInformation: "PendingInformation"
}

export const ATTACHMENT_TYPES = {
  Drawings: 1,
  PurchaseOrders: 2,
  Invoices: 3,
}

export const INSTALL_TYPES = {
  SOSInstall: "SOS_INSTALL",
  SOS: "SOS"
}

export const COMPANY_TYPES = {
  Store: 1,
  Fabricator: 2,
  Installer: 3
}

export const ACCESS_LEVELS = {
  InterestCapture: 1,
  Quoting: 2
}

export const STATUS_TYPES = {
  //Open: 1,
  Sent: 2,
  Pending: 3, 
  Draft: 4, 
  CancellationRequested: 5, 
  Cancelled: 6, 
  CancellationRejected: 7,
  InProgressAccepted: 8,
  QuoteVerificationRequested: 12,
  QuoteVerified: 13,
  Confirmed: 14,
  Shipped: 15,
  ConvertedToOrder: 16,
  SubmittedForApproval: 17,
  Rejected: 18,
  Completed: 19
}

export const QUOTE_STAGES = {
  Line: 2,
  Details: 1,
 // Customer: 3,
  Attachments: 3,
  Address: 4,
  Submit: 5,
  PoNumber: 6,  
  AdditionalAttachments: 7,
  ConvertToOrder: 8
}

export const STAGES_BUTTON_LABELS = {
  Next: 'Next',
  Submit: 'Submit',
  Convert: 'Convert',
  Confirm: 'Confirm',
  Accept: 'Accept',
  Shipped: 'Shipped',
  Locked: 'Locked'
}

export const SHIP_TO_TYPES = {
  Store: 1,
  Customer: 2,
  Installer: 3  
}

export const VENDOR_NUMBERS = {
  SOS: 26544,
  SOS_INSTALL: 53900  
}

export const REPORT_URLS = {          
  OrderCost: `_HKC_OrderSummary`,
  OrderPrice: `_HKC_OrderSummary`,    
  OrderFax: `_HKC_OrderFax`,
  Home_Delivery_Service_Form: `Home_Delivery_Service_Form`,
 
//The new report is name _HKC_OrderFax
 
//Could we get the links on the Quote and Order Attachments pages updated to point to the new report.  
//We will also have to pass the parameters along with order number, Type (O or Q) and CostPrice (which I think just P would suffice for both links)

}

export const HK_INSTALL_TYPES = [{Value: "SOS", Caption: "SOS"}, {Value: "SOS_INSTALL", Caption: "SOS INSTALL"}];
export const HK_INSTALL_TYPES_SOS_ONLY = [{Value: "SOS", Caption: "SOS"}];

export const GRID_ACTIONS = [ 
  {Value: "quoteCost", Caption: "Quote Cost Summary"}, 
  {Value: "quotePrice", Caption: "Quote Retail Summary"},
  {Value: "downloadAttachments", Caption: "Download Attachments"},
  {Value: "delete", Caption: "Delete"},
  
];

export const ORDER_GRID_ACTIONS = [  
  {Value: "orderCost", Caption: "Order Cost Summary"}, 
  {Value: "orderPrice", Caption: "Order Retail Summary"},  
  {Value: "downloadAttachments", Caption: "Download Attachments"},
  {Value: "delete", Caption: "Delete"},
  
];


//export const GRID_ACTIONS = [{Value: "copy", Caption: "Copy"}, {Value: "delete", Caption: "Delete"}, {Value: "costReport", Caption: "View Cost Report"} ];
//export const GRID_ACTION_OPTIONS = ["copy", "delete", "costReport"];

export const GRID_ACTION_OPTIONS = {
  Copy: "copy",
  Delete: "delete",  
  QuoteCost: "quoteCost",
  QuotePrice: "quotePrice",
  DownloadAttachments: "downloadAttachments",
}

export const ORDER_GRID_ACTION_OPTIONS = {
  Copy: "copy",
  Delete: "delete",  
  OrderCost: "orderCost",
  OrderPrice: "orderPrice", 
  DownloadAttachments: "downloadAttachments",
}

export const rentechTheme = {  
  buttonColor: '#EEEEEE',
  altButtonColor: '#6D6E71',
  callToActionColor: '#e2231a',
  buttonTextWhite:'#FFF',
  activeStageColor: '#13cdfd',
  inactiveStageForeColor:'#000',
  activeStageForeColor:'#FFF',
  completedStageColor: '#EEEEEE',
  incompleteStageColor: '#EEEEEE',
  accentColor: '#ffdd00',
  darkBackground: '#6D6E71',
  lightBackground: '#EEEEEE',
  gridLineHoverBackgroundColor: '#4d4d4d', 
  gridLineHoverOpacity: .7,
  gridLineHoverColor: '#FFF',
  gridRowBackgroundColor: '#f5f5f5', //offwhite
  gridRowAlternateBackgroundColor: '#FFF', //FDB813,
  textLight: '#FFF',
  selectedImage: 'darkgray'

};