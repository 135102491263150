import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const IconImg = styled.img`
    height: ${({height}) => ( !height ? "32px" : height) }
    width: ${({width}) => ( !width ? "32px" : width) }
    width: 32px;
    padding-left: 5px;
    cursor: pointer;
    ${({ isLine }) => isLine && "visibility: hidden !important;"}       
    
    @media (max-width:1200px) {
        height: 20px !important;
        width: 20px !important;
        visibility: visible !important;       
    }
`;

const Icon = props => {
    let source = `/images/svg/${props.iconName}.svg`;
    let style = props.isLine === true ? "icon-line" : "icon-list";
    return (
        <IconImg src={source} height={props.height} width={props.width} alt={props.title} title={props.title} isLine={props.isLine} className={style} onClick={props.click}></IconImg>
    );
};

Icon.propTypes = {
    iconName: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isLine: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string
};

export default Icon;