var _server = "";
var _instance = "";
var _applicationName  = "";
var _headerId = "";
var _ruleset = "";
var _nameSpace = ""; 
var _profile = "";
var _urlScheme = 'http://';
var _port = '';

class WebUI { 

    constructor(server, instance, applicationName, headerId, ruleset, nameSpace, profile) {
        _server = server;
        _instance = instance;
        _applicationName = applicationName;
        _headerId = headerId;
        _ruleset = ruleset;
        _nameSpace = nameSpace;
        _profile = profile;
        _port = window.location.origin;
        
        let url = window.location.href;

        if (url.includes('https://')) _urlScheme = 'https://';
        
    }
    
    GetServer() {
        return _server;
    }

    GetInstance() {
        return _instance;
    }
    
    GetApplicationName() {
        return _applicationName;
    }

     GetHeaderId() {
        return _headerId;
    }

    GetNamespace() {
        return _nameSpace;
    }

    GetRuleset() {
        return _ruleset;
    }

    SetRuleset(rs) {
        _ruleset =  rs;
    }

    GetProfile() {
        return _profile;
    }

    GetRequiredHeaders() {
        return {
            headers: {       
              'Content-Type': _urlScheme === 'https://' ? 'application/x-deflate' : 'application/json',                                                          
            }
          };   
    }    

    GetPrepareForInteractiveConfigurationEndpoint(){        
        return _urlScheme + this.GetServer() + "/" + this.GetInstance() + "/ConfiguratorService/v2/ProductConfigurator.svc/json/prepareforinteractiveconfiguration";
    }
    
    GetPrepareForInteractiveConfigurationRequestBody(configurationId, isReconfigure, sourceConfigurationId, sourceHeaderId, integrationParams = []) {
                 
        
        return {
            "inputParameters": {
                "Application": {"Instance": this.GetInstance(),"Name": this.GetApplicationName()},
                "HeaderDetail": {
                    "HeaderId": this.GetHeaderId(),
                    "DetailId": isReconfigure === true ? sourceConfigurationId : configurationId
                },
                "Part": {"Name": this.GetRuleset(), "Namespace": this.GetNamespace()},
                "Mode": "0",
                "Profile": this.GetProfile(),               
                "IntegrationParameters": integrationParams,
                "RapidOptions": [
                    //{ "VariableName": "", "ValueExpression": "" }, 
                ],                                                                                      
                "VariantKey": "",
                "SourceHeaderDetail": {"DetailId": isReconfigure === true ? sourceConfigurationId : '', "HeaderId": isReconfigure === true ? sourceHeaderId : ''}
        },
        "pageCaption": "asdf",
        "redirectURL": "/"
        }
    }

     GetInitializeConfigurationEndPoint() {
        return _urlScheme + this.GetServer() + "/" + this.GetInstance() + "/ConfiguratorService/v2/ProductConfiguratorUI.svc/json/initializeconfiguration";
    }

     GetInitializeConfigurationRequestBody(configId) {
        return {
            "applicationName": this.GetApplicationName(),
            "instanceName":this.GetInstance(),
            "headerId":this.GetHeaderId(),
            "configurationId": configId
        };
    }

     GetConfigureEndpoint() {
        return _urlScheme + this.GetServer() + "/" + this.GetInstance() + "/ConfiguratorService/v2/ProductConfiguratorUI.svc/json/configure"
    }

     GetConfigureRequestBody(sessionId, id='', value='') {
        if (id === '')
            return {
                "sessionID": sessionId,
                "selections": {}
            };
                           
        return {          
            "sessionID": sessionId,
            "selections": [{
                "ID": id,
                "Value": value
            }]           
        };
    }

    GetFinishInteractiveRequestEndpoint() {
        return _urlScheme + this.GetServer() + "/" + this.GetInstance() + "/ConfiguratorService/v2/ProductConfiguratorUI.svc/json/finalizeconfiguration";
    }
    
    GetFinishInteractiveRequestBody(sessionId) {
        return { "sessionID": sessionId };
    }
}

export default WebUI;