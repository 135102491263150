import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as sessionActions from '../../actions/sessionActions';
import * as quoteActions from '../../actions/quoteActions';
import * as orderActions from '../../actions/orderActions';
import Button from '../Common/Button/Button';
import * as constants from '../../modules/constants';
import styled from 'styled-components';
import Loading from '../Common/Loading/Loading';
import ListFilter from './ListFilter';

export const Wrapper = styled.div`
   padding: 1em;      
   cursor: pointer;
   margin-top: 1em;
   margin-bottom: 1em;
   background-color: ${props => props.theme.buttonColor};
   border-radius: 15px;
   height: 104px;
    width: 95%;

   :hover {
       background-color: ${props => props.theme.gridRowBackgroundColor};       
   }
`;

export const Heading = styled.div`
   font-size: 24px !important;
   text-align: center;   
`;

export const Count = styled.div`
   font-size: 24px !important;
   text-align: center;   
`;

export const NewButtonWrapper = styled.div`
    width: 100%;
`;

export const ActionRequiredLabel = styled.div`
    position: absolute;
    top: 15px;
    left: 38%;
    color: #e2231a;
    font-weight: bold;
`;

export const RowStyled = styled(Row)`
    margin-bottom: 0px !important;
    margin-top: 0px !important;
`;  

export const ButtonStyled = styled(Button)`
    
`;  

export const ContainerStyled = styled(Container)`
   background-image: url(/images/WidePlankedWalnut.jpg);
   min-height: 756px;
`;

export class Overview extends Component {
    constructor(props, context) {
        super(props, context);  

        this.pushHistory = this.pushHistory.bind(this);
        this.onClickNewQuote = this.onClickNewQuote.bind(this);
        this.onClickNewOrder = this.onClickNewOrder.bind(this);
        this.onFilter = this.onFilter.bind(this);

        this.state = {
            loading: true,
            filter: ''
        }        
    }

    componentDidMount() {
        this.props.quoteOrderCommonActions.getQuoteOrderCommonOverview(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName).then(() => {
            this.setState({ loading: false });
        });
    }

    async onClickNewQuote() {
        this.props.quoteActions.clearQuote();
        await this.props.quoteActions.createQuote(this.props.session.username, this.props.session.company);       
         
        this.props.history.push({ pathname: '/Quote' });
    } 

    async onClickNewOrder() {
        this.props.orderActions.clearOrder();
        await this.props.orderActions.createOrder(this.props.session.username, this.props.session.company);       
         
        this.props.history.push({ pathname: '/Order' });
    }

    pushHistory(status, type) {
        if (type === 'Quote') {
            this.props.history.push({
                pathname: `/QuoteDashboard`,
                state: { overviewStatus: this.state.filter ? this.state.filter : status }
            });
        } 

        if (type === 'Order') {
            this.props.history.push({
                pathname: `/OrderDashboard`,
                state: { overviewStatus: this.state.filter ? this.state.filter : status }
            });
        }         
    }

    onFilter(event) {
        this.setState({ loading: true, filter: event.target.value });
        
        this.props.quoteOrderCommonActions.getQuoteOrderCommonOverview(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName, event.target.value).then(() => {
            this.setState({ loading: false });
        });
    }

    render() {          
        if (!this.props.session.company) return <Loading />;
        
        const quotes = this.props.quoteOrderCommon.overview ? this.props.quoteOrderCommon.overview.filter(q => q.type === 'Quote') : [];
        const orders = this.props.quoteOrderCommon.overview ? this.props.quoteOrderCommon.overview.filter(o => o.type === 'Order') : [];

        return (                       
            <ContainerStyled>   
                <RowStyled>
                    <Col><h1>Orders</h1></Col>                                  
                </RowStyled>
                <RowStyled>
                    <Col xl="2">
                        <NewButtonWrapper className="text-left">
                            <ButtonStyled displayText="New Order" onClick={this.onClickNewOrder} backgroundColor="#e2231a" color="#FFF"/>
                        </NewButtonWrapper>
                    </Col>  
                    <Col xl="4"><ListFilter defaultValue={this.state.filter} onFilter={this.onFilter}/></Col>     
                </RowStyled>             
                <RowStyled>
                {
                    orders.map(o => {           
                        return (                               
                            <Col xl="3">                                
                                <Wrapper onClick={() => this.pushHistory(o.name, o.type)}>
                                    <Heading>{o.name}</Heading>
                                    {(o.id === constants.STATUS_TYPES.Pending || o.id === constants.STATUS_TYPES.Rejected) && 
                                        <ActionRequiredLabel className="text-left">Action Required</ActionRequiredLabel>
                                    }
                                    <Count>{o.total}</Count>
                                </Wrapper>                               
                            </Col>                                                                        
                        )
                    })
                }
                </RowStyled>
                <RowStyled>                    
                    <Col><h1>Quotes</h1></Col>                                          
                </RowStyled>
                <RowStyled>
                    <Col>
                        <NewButtonWrapper className="text-left">
                            <ButtonStyled displayText="New Quote" onClick={this.onClickNewQuote} backgroundColor="#e2231a" color="#FFF" />
                        </NewButtonWrapper>  
                    </Col>
                </RowStyled>
                <RowStyled>
                {
                    quotes.map(q => {                      
                        return (                                                            
                            <Col xl="3">                                
                                <Wrapper onClick={() => this.pushHistory(q.name, q.type)}>
                                    <Heading>{q.name}</Heading>
                                    {(q.id === constants.STATUS_TYPES.Pending || q.id === constants.STATUS_TYPES.Rejected) && 
                                        <ActionRequiredLabel className="text-left">Action Required</ActionRequiredLabel>
                                    }
                                    <Count>{q.total}</Count>
                                </Wrapper>                               
                            </Col>                                                            
                        )
                    })
                }
                </RowStyled>
            </ContainerStyled>                                                                                                                                            
        );                          
    }
}

function mapStateToProps(state, ownProps) {    
    return {
        quoteOrderCommon: state.quoteOrderCommon,
        session: state.session,
        quote: state.quote,  
        order: state.order,
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        quoteOrderCommonActions: bindActionCreators( quoteOrderCommonActions, dispatch),
        sessionActions: bindActionCreators( sessionActions, dispatch),
        quoteActions: bindActionCreators(quoteActions, dispatch),  
        orderActions: bindActionCreators(orderActions, dispatch),
    };
} 

export default connect(mapStateToProps, mapDispatchToProps)(Overview);