import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as apiActions from '../../actions/apiActions';
import * as sessionActions from '../../actions/sessionActions';
import Page from '../Page/Page';
import DetailedSummary from '../DetailedSummary/DetailedSummary';
import ProductPreview from '../ProductPreview/ProductPreview';
import styled from 'styled-components';
import Icon from '../QuoteOrderCommon/Icon';

export const NavItemTabsStyled = styled(Nav)`         
        border: solid 5px #EEEEEE;
`;

export const NavItemStyled = styled(NavItem)`
    @media(max-width:1200px) {
        &.nav-item a { 
            padding: 0;
            padding-left: 7px;
        }
    }
`;

export const ColScrollable = styled(Col)`
    @media(min-width: 1201px) { 
        overflow-y: visible;       
        padding: 0px 0px 0px 0px;
    }
`;

export const ContainerStyled = styled(Container)`
    @media(min-width: 1201px) {         
        border-style: solid;
        border-size: 2em;
        border-color: #EEEEEE;
        padding: 0px 0px 0px 0px;
    }
`;

export const ColRightTab = styled(Col)`
    border-left: 2px solid lightgray;
`;

export const ArrowWrapper = styled.div`
    cursor: pointer;      
`;

export const NoMarginRow = styled(Row)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
`;

export const ButtonStyled = styled.button`
    border: 2px solid #e2231a;
    border-radius: 5px;
    background-color: #FFF;
`;

export class TabControl extends Component {
    constructor(props, context) {
        super(props, context);             

        this.props.sessionActions.setActiveTab(0);             

        this.state = {
            detailsOpen: false
        }
    }
  
    toggle(tab) {
        if (this.props.session.activeTab !== tab) this.props.sessionActions.setActiveTab(tab);        
    }

    toggleDetails() {
        let isOpen = this.state.detailsOpen;
        this.setState({detailsOpen: !isOpen });
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    render() { 
        if (!this.props.api.ui) return <React.Fragment></React.Fragment>                            

        let arrowCssClass = this.state.detailsOpen ? "bi bi-arrow-right-square-fill" : "bi bi-arrow-left-square-fill";
        let rightColCss = this.props.api.ui.d.ImageUrl === "" ? "d-none" : "";
        let imageUrl = this.props.api.ui.d.ImageUrl + '?q=' + this.getRandomInt(99999).toString()
        return ( 
            <div className="tab-control"> 

                <Container>
                    <NoMarginRow>    
                        <Col xl="11"></Col>                                                                        
                        <Col xl="1">
                            <ArrowWrapper className="text-right" onClick={() => this.toggleDetails()}>
                            <ButtonStyled   type="button" class="btn btn-demo" data-toggle="modal" data-target="#sidebarDetailSummary">
                            <svg width="2.5em" height="2.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="#e2231a" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                            </svg>
                                {/* <svg width="2.5em" height="2.5em" viewBox="0 0 16 16" class="bi bi-list-task" fill="#13cdfd" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"/>
                                <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z"/>
                                <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"/>
                                </svg> */}
                            </ButtonStyled>
                            
                        </ArrowWrapper>
                    </Col>                                                                            
                    </NoMarginRow>    
                </Container>     
                <Nav tabs>         
                    {this.props.api.ui.d.Pages.map((page, index) =>                                                    
                        <NavItemStyled key={page.Caption}>
                            <NavLink 
                                className={classnames({ active: this.props.session.activeTab === (index) })}
                                onClick={() => { this.toggle(index); } } >{page.Caption}
                            </NavLink>
                        </NavItemStyled>                                                                                         
                    )}


                </Nav>
                
                <ContainerStyled>    
                                   
                    <TabContent activeTab={this.props.session.activeTab}>
                        {this.props.api.ui.d.Pages.map((page, index) =>                     
                            <TabPane key={page.Caption} tabId={index} >  
                               
                                <Row>                                
                                <ColScrollable id="columnScrollable" xs="12" xl={this.props.api.ui.d.ImageUrl !== "" ? 6 : 12}>
                                        <Page page={page} />
                                </ColScrollable>
                                <ColRightTab className={rightColCss}>                                    
                                    <Row>
                                        <Col>
                                            {this.props.api.ui.d.ImageUrl !== "" && <ProductPreview src={imageUrl} /> }
                                        </Col>                                            
                                    </Row>                                                                                                                                   
                                </ColRightTab> 
                                                                                                                                                                                                                            
                                </Row>                              
                            </TabPane>                                                                                       
                        )}   

                        <div class="container demo">
                        <div class="text-center">
                        
                        </div>
                        <div class="modal left fade" id="sidebarDetailSummary" tabindex="" role="dialog" aria-labelledby="sidebarDetailSummaryLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div class="nav flex-sm-column flex-row">
                                            <DetailedSummary detailedSummary={this.props.api.ui.d.Details} />  
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => this.toggleDetails()}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
                    </TabContent>

                               
                </ContainerStyled>                                              
            </div>                                               
        );                          
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      api: state.api,
      session: state.session,
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators( apiActions, dispatch),
        sessionActions: bindActionCreators( sessionActions, dispatch),
    };
} 

export default connect(mapStateToProps, mapDispatchToProps)(TabControl);