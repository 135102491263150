import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as apiActions from '../../../actions/apiActions';
import Button from '../Button/Button';
import * as constants from '../../../modules/constants';

export class ContinueButton extends Component {  
    constructor(props, context) {
        super(props, context);

        this.continueClicked = this.continueClicked.bind(this);
    }

    continueClicked() {        
        if (this.props.api.uncommittedScreenOptions && this.props.api.uncommittedScreenOptions.length > 0)        
            this.props.apiActions.commitUncommittedScreenOptions(this.props.api.sessionId, this.props.api.uncommittedScreenOptions);
        else { 
            // let maxPages = this.props.api.ui.d.Pages.length - 1;
            // let maxScreens = this.props.api.ui.d.Pages[maxPages].Screens.length -1;
            // let maxScreenOptions = this.props.api.ui.d.Pages[maxPages].Screens[maxScreens].ScreenOptions.length - 1;
            // let id = this.props.api.ui.d.Pages[maxPages].Screens[maxScreens].ScreenOptions[maxScreenOptions].ID;
            // let value = this.props.api.ui.d.Pages[maxPages].Screens[maxScreens].ScreenOptions[maxScreenOptions].Value;

            // this.props.apiActions.configure(this.props.api.sessionId, id, value);

            let maxPages = this.props.api.ui.d.Pages.length - 1;                        
            let maxScreenOptions = this.props.api.ui.d.Pages[maxPages].Screens.slice().reverse().find(s => s.ScreenOptions.length > 0 && s.IsVisible === true);

            if (maxScreenOptions && maxScreenOptions.ScreenOptions && maxScreenOptions.ScreenOptions.length > 0) {
                let id = maxScreenOptions.ScreenOptions[0].ID;
                let value = maxScreenOptions.ScreenOptions[0].Value;

                //if (value)

                // $$$ need to check if screen option is set to autocontinue mode move, if so the continue button should not be used to advance the screen
                // rules need to be setup to have each question that is meant to pause as 0 not 2

                // if (this.props.api.lastScreenOptionModified.autoContinueMode === constants.AUTO_CONTINUE_MODE.MoveTabAndScreenRule ) {
                //     return;
                // }

                //maxScreenOptions.ScreenOptions[0].AutoContinueMode
                console.log(maxScreenOptions.ScreenOptions[0]);

                let continueMode = this.props.api.lastScreenOptionModified.autoContinueMode ? this.props.api.lastScreenOptionModified.autoContinueMode : constants.AUTO_CONTINUE_MODE.MoveTabAndScreenRule;
                if (continueMode !== constants.AUTO_CONTINUE_MODE.MoveTabAndScreenRule) {
                    this.props.apiActions.configure(this.props.api.sessionId, id, value);
                    return;
                }

                if (id !== this.props.api.lastScreenOptionModified.id) {
                    if (maxScreenOptions.ScreenOptions[0].autoContinueMode !== constants.AUTO_CONTINUE_MODE.MoveTabAndScreenRule)
                        this.props.apiActions.configure(this.props.api.sessionId, id, value);
                }
            }
        }
    }

    render() {    
        return (
            <React.Fragment>
                <Button 
                    id="continue"                     
                    className="continue" 
                    onMouseUp={this.continueClicked} 
                    value="Continue" 
                    displayText="Continue"   
                    backgroundColor="#e2231a"
                    color="#FFF"                 
                />                  
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      api: state.api
    };
  }
  
    function mapDispatchToProps(dispatch) {
        return {            
            apiActions: bindActionCreators( apiActions, dispatch)
        };
    }    

export default connect(mapStateToProps, mapDispatchToProps)(ContinueButton);