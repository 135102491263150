import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


export const InputLabelStyled = styled.div`
    margin-right: 1em !important; 
    font-weight: bold; 
    display: block; 
    text-align: left;

    @media (max-width:1200px) { 
        width: 95% !important; margin-bottom: .5em;
    }
`;
const InputLabel = React.memo(({caption, isRequired, ...props}) => {         
    return (                        
        <InputLabelStyled className="inputLabel" {...props}> 
            {caption} {isRequired && <span className="text-danger">*</span>}
        </InputLabelStyled> 
    );     
});  

export default InputLabel; 

InputLabel.propTypes = {
    caption: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired
  };