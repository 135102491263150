import * as types from '../actions/actionTypes';

export default function apiReducer(state = [], action) {
    switch(action.type) {    
        
        case types.CLEAR_CONFIGURATOR:
            return {...state, ui: null, isLoading: false};

        case types.PAUSE_EXECUTION:
            return {...state, pauseExecution: action.pauseExecution };
            
        case types.SET_SELECTED_PRODUCT:
            return {...state, selectedProduct: action.product}
    
        case types.SAVE_CONFIGURATION_GUID:
            return {...state, configurationGUID: action.configurationGUID};

        case types.GET_PRODUCTS_SUCCESS:
            return {...state, products: action.products, isLoading: false};

        case types.PREPARE_INTERACTIVE_SUCCESS:
            return {...state, prepareInteractiveSuccess: true};

        case types.INITIALIZE_SUCCESS:
            return {...state, sessionId: action.sessionId, initializeSuccess: true};

        case types.INITIALIZE_FAILURE:
            return {...state, initializeSuccess: false};

        case types.GET_UI_CONFIG_SUCCESS:
            return {...state, ui: action.ui, isLoading: false };
        
        case types.FINISH_CONFIG_SUCCESS:
            return {...state, finishSuccess: false, isLoading: false, initializeSuccess: false, prepareInteractiveSuccess: false };

        case types.CONTINUE_BUTTON_SETUP:
            return {...state, continueButtonValues: action.continueButtonValues};

        case types.GET_CONFIGURATIONS:
            return {...state, userConfigurations: action.userConfigurations};

        case types.GET_PRODUCT_CONFIGURATIONS:
            return {...state, productConfigurations: action.productConfigurations};

        case types.SET_AUTOCONTINUE_MODE:
            return {...state, autoContinueMode: action.mode };

        case types.SHOW_CONTINUE_BUTTON:
            return {...state, showContinueButton: true };

        case types.HIDE_CONTINUE_BUTTON:
            return {...state, showContinueButton: false };

        case types.CLEAR_UNCOMMITTED_USER_CONFIGURATIONS:
            return {...state, uncommittedUserConfigurations: [] };

        case types.STORE_UNCOMMITTED_USER_CONFIGURATIONS:
            return {...state, uncommittedUserConfigurations: [...state.uncommittedUserConfigurations, action.uncommittedUserConfigurations] };
             
        case types.STORE_UNCOMMITTED_SCREEN_OPTION:                       
            return {...state, uncommittedScreenOptions: [...state.uncommittedScreenOptions, action.screenOptionArray] };
        
        case types.REMOVE_UNCOMMITTED_SCREEN_OPTION:
            let screenOptions = [...state.uncommittedScreenOptions.filter(so => so != action.id)];
            return {...state, screenOptions};

        case types.CLEAR_UNCOMMITTED_SCREEN_OPTIONS:
            return {...state, uncommittedScreenOptions: [] };

        case types.GET_COMPANY_PRODUCTS_SUCCESS:
            return {...state, companyProducts: action.products};

        case types.GET_USER_ACTIVE_PRODUCT_SUCCESS:
            return {...state, activeProductId: action.activeProductId};

        case types.SET_ACTIVE_PRODUCT_SUCCESS: 
            return {...state, activeProductId: action.activeProductId};

        case types.RELOAD_CONFIGURATOR:
            return {...state,  showSubmitConfigurationButton: false, uncommittedScreenOptions: [], uncommittedUserConfigurations: [], companyProducts: []}
            
        case types.LAST_SCREEN_OPTION_MODIFIED:
            return {...state, lastScreenOptionModified: action.id};
            
        default:
            return state;
    }
}