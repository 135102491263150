import * as types from '../actions/actionTypes';

export default function quoteOrderCommonReducer(state = [], action) {
    switch(action.type) {                
        case types.GET_USER_COMPANY_LOCATIONS_SUCCESS: 
            return {...state, userCompanyLocations: action.userCompanyLocations};

        case types.GET_USERS_ASSIGNED_TO_LOCATION_SUCCESS:
            return {...state, companyLocationUsers: action.companyLocationUsers};

        case types.GET_COMPANY_LOCATION_INSTALLERS_SUCCESS:
            return {...state, companyLocationInstallers: action.companyLocationInstallers};

        case types.GET_STATUS_TYPES_SUCCESS:
            return {...state, statusTypes: action.statusTypes};   
            
        case types.GET_ALL_ROLES_SUCCESS: 
            return {...state, roles: action.roles};  
            
        case types.CLEAR_USERS_ASSIGNED_TO_LOCATIONS:
            return {...state, companyLocationUsers: null };

        case types.CLEAR_DESIGNERS:
            return {...state, designers: null };

        case types.GET_QUOTE_ORDER_COMMON_OVERVIEW:
            return {...state, overview: action.overview };    
            
        case types.GET_SHIP_TO_TYPES_SUCCESS:
            return {...state, shipToTypes: action.shipToTypes};

        case types.GET_COMPANY_LOCATION_DESIGNERS_SUCCESS:
            return {...state, designers: action.designers};
            
        default:
                return state;
    }
}