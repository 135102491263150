import React from 'react';
import ImageTextHorizontal from '../ImageTextHorizontal/ImageTextHorizontal';
import Modal from './Modal';
import useModal from '../Modals/useModal';
import TypeableSelectCPQ from '../../Common/CPQ/TypeableSelectCPQ';
import PropTypes from 'prop-types';
import Icon from '../../QuoteOrderCommon/Icon';
import styled from 'styled-components';
import {Row, Col } from 'reactstrap';

export const ColStyled = styled(Col)`
    img {
        position: relative;
        top: 25px;
    }
`;

export const RowStyled = styled(Row)`
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
`;

const ImageDropdownCPQ = props => {

    const {isShowing, toggle} = useModal();

    return (     
        <RowStyled>                        
            <Col xl="8" xs="10">
                <TypeableSelectCPQ 
                    placeholder="Click to Select" 
                    autoFocus={props.autoFocus} 
                    screenOption={props.screenOption}                     
                    key={props.caption} 
                    caption={props.caption}
                    onImageClicked={props.onImageClicked}   
                    onChange={props.onChange}
                    value={props.screenOption.Value}
                    width="100% !important" />
               
            </Col>
            <ColStyled xl="4" xs="2">
                <Icon iconName="magnifying-glass" title="magnifying-glass" isLine={false} click={toggle} /> 
            </ColStyled>
                
             
            
            {isShowing === true && 
                <Modal isShowing={isShowing} hide={toggle}>                
                    <ImageTextHorizontal 
                        autoFocus={props.autoFocus} 
                        screenOption={props.screenOption} 
                        key={props.caption} 
                        caption={props.caption} 
                        onImageClicked={props.onImageClicked}
                        toggle={toggle}
                        markAsSelected={props.markAsSelected} />
                </Modal>   
            } 
        </RowStyled>   
    );     
};

export default ImageDropdownCPQ;

ImageDropdownCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    caption: PropTypes.string.isRequired,
    onImageClicked: PropTypes.func.isRequired,
    markAsSelected: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool      
};