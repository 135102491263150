import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
//import * as workflowActions from '../../actions/workflowActions';
import { withRouter } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import jwt from 'jsonwebtoken';
import authentication from '../../modules/authentication';
import { Container } from 'reactstrap';
import * as constants from '../../modules/constants';

export const NavbarTogglerStyled = styled(NavbarToggler)`
    margin-right: 3px;

    @media(max-width:1200px) {
        position: relative;
        top: -5px;
    }
`;

export const NavbarStyled = styled(Navbar)`    
    background-color: #4D4D4D; 
    height: 50px;

    .navbar-collapse {
        z-index: 9999;
        position: relative;
        top: -10px;
    }

    @media(max-width:1200px) {        
        padding:0 !important;        
    }    
`;

export const NavStyled = styled(Nav)`
    @media(min-width:768px) {        
        position: relative;
        top: 10px;         
    }

    @media(max-width:768px) {
        background-color: #4D4D4D;
        text-align: center;
    }
`;

export const Img = styled.img`        
    height: 42px;
    cursor: pointer;

    @media(max-width:1200px) {        
        height: 50px !important;  
        padding: 10px;
        margin-left:.5em;
        position: relative;
        top: -5px;      
    }
`;

export const DropDownMenuStyled = styled(DropdownMenu)`
    background-color: #4D4D4D !important;
`;

export const UserMessage = styled.span`
    color: #FFF;
    padding-left: 10px;
`;

export const DropDownItemStyled = styled(DropdownItem)`
    padding: 0 2em 0 2em !important;
    
    :hover {
        background-color: #4D4D4D !important;
    }
`;

export const ContainerStyled = styled(Container)`
    height:50px;
`;

export const NavbarBrandStyled = styled(NavbarBrand)`
    padding-top: 0px !important;
    padding-bottom: 0px !important;
`;

export const StaticHeaderLink = styled.div`
    cursor: pointer;
`;

class Header extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);   
        this.signOut = this.signOut.bind(this);   
        this.showSignIn = this.showSignIn.bind(this);
        this.requestPasswordReset = this.requestPasswordReset.bind(this);
        this.state = {
          isOpen: false
        };

        let decodedToken = authentication.verifyToken();        

        if (decodedToken) {
            if (this.props.session.username === "" || this.props.session.loggedIn === false) {            
                this.props.sessionActions.loginSuccess(decodedToken.username);    
                // this.props.sessionActions.setUserPermissionLevel(decodedToken.admin);   
                this.props.sessionActions.setCompanyAccessLevel(decodedToken.access);           
                this.props.sessionActions.setCompany(decodedToken.company);
                this.props.sessionActions.setManufacturer(decodedToken.manufacturer);
                this.props.sessionActions.setUserRole(decodedToken.roleName);
                this.props.sessionActions.setUserLocations(decodedToken.userCompanyLocationIdCSV);
                this.props.sessionActions.setUserRoleLevel(decodedToken.roleLevel);
                //this.props.workflowActions.getWorkflows(decodedToken.company);

                if (decodedToken.access === constants.ACCESS.ICE) {
                    this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Default);
                } 
            }
        } else {
            if (!this.props.session.access || this.props.session.access === constants.ACCESS.Ordering) {
                this.showSignIn();
            } 
        } 
    }

    componentDidMount() {
        if (!this.getUsernameFromToken()) {
            this.props.history.push({
                pathname: "/Login"            
            });
        } else {
          this.props.history.push({
            pathname: constants.DEFAULT_HOMEPAGE           
        });
        }
    }

    

    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
    }   

    signOut() {
        authentication.signOut();
        this.props.sessionActions.signOut();
        this.props.sessionActions.resetApp();
        this.props.sessionActions.showSignupModal(true);
        this.props.quoteOrderCommonActions.clearDesigners();
        this.pushHistory('/Login');          
    }

    requestPasswordReset() {
        this.pushHistory('/RequestPasswordReset');    
    }

    openTraining() {
        window.open("https://www.hartson-kennedy.com/lowes/");     
    }

    openContactUs() {
        window.open("https://www.hartson-kennedy.com/lowes/");     
    }

    pushHistory(url) {
        let { history } = this.props;
            history.push({
            pathname: url,
            search: ''
        });
    }

    showSignIn() {
        this.props.sessionActions.showSignupModal(true);
    }
    
    getUsernameFromToken() {
        let token = window.localStorage.getItem('sxnijSpsandfl');
    
        if (token === null) return '';
        
        let decoded = jwt.decode(token);    
    
        return decoded.username;
    }

    render() {                      
        return (                             
            <NavbarStyled dark expand="md">
                <ContainerStyled>
                {/* <NavbarBrand href="/"><Img src="/images/logo.png" alt="renaissancetech" /></NavbarBrand> */}
                <Img src="/images/hartson-kennedy-logo.png" alt="renaissancetech" onClick={() => this.pushHistory('/Overview') } />
                <UserMessage>HKme is Live as of 5/10/21</UserMessage>
                {/* <NavbarBrandStyled href="/"><Img src="/images/logo.png" alt="renaissancetech" /></NavbarBrandStyled> */}
                <NavbarTogglerStyled onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <NavStyled className="ml-auto" navbar>  

                    {/* {this.props.session.access === constants.ACCESS.ICE &&   
                     <React.Fragment>            
                        <NavItem>
                            <NavLink activeClassName="selected" className="nav-link" to="/Configurator">Configurator</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="selected" className="nav-link" to="/MyConfigurations">My Designs</NavLink>
                        </NavItem>
                    </React.Fragment>   
                    } */}
                    {this.props.session.access === constants.ACCESS.Ordering &&    
                        <React.Fragment>
                            {/* <NavItem>
                                <NavLink activeClassName="selected" className="nav-link" to="/QuoteDashboard">Quotes</NavLink>
                            </NavItem> 
                            <NavItem>
                                <NavLink activeClassName="selected" className="nav-link" to="/OrderDashboard">Orders</NavLink>
                            </NavItem>  */}
                            <NavItem>
                                <NavLink activeClassName="selected" className="nav-link" to="/Overview">
                                <svg width="2.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-grid-3x3-gap-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
                                </svg> Dashboard
                                </NavLink>
                            </NavItem>                          
                         
                            <NavItem>
                           
                            <StaticHeaderLink activeClassName="selected" className="nav-link" onClick={this.openContactUs}>
                                <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-telephone" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg>  Contact Us                              
                            </StaticHeaderLink>
                               
                            </NavItem>
                            
                        </React.Fragment>                                                                                             
                    }  
                    
                    { this.props.session.loggedIn === false &&                     
                        <NavItem>
                            <span className="nav-link pointer" onClick={this.showSignIn}>Login/Sign Up</span>
                        </NavItem>
                    }

                { this.props.session.loggedIn === true && 
                    <UncontrolledDropdown nav inNavbar>
                       <DropdownToggle nav caret>
                            {this.props.session.username === '' ? <span>Sign In</span> : <svg width="2.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg> } {this.props.session.username}
                        </DropdownToggle>
                        <DropDownMenuStyled right>    
                            {this.props.session.access === constants.ACCESS.Ordering &&
                            <React.Fragment>
                                <DropDownItemStyled>
                                    <NavItem>
                                        <NavLink activeClassName="selected" className="nav-link" to="/QuoteDashboard">Quotes</NavLink>
                                    </NavItem>
                                </DropDownItemStyled>
                                <DropDownItemStyled>
                                    <NavItem>
                                        <NavLink activeClassName="selected" className="nav-link" to="/OrderDashboard">Orders</NavLink>
                                    </NavItem>
                                </DropDownItemStyled>
                            </React.Fragment>                                              
                            }
                            {
                                (this.props.session.roleName === constants.ROLE.SystemAdmin) && this.props.session.access === constants.ACCESS.Ordering &&
                                <React.Fragment>
                                    {/* <DropDownItemStyled>
                                        <NavItem>
                                            <NavLink activeClassName="selected" className="nav-link" to="/ProductConfigurations">Product Configurations</NavLink>
                                        </NavItem>
                                    </DropDownItemStyled>  */}
                                    <DropDownItemStyled>
                                        <NavItem>
                                            <NavLink activeClassName="selected" className="nav-link" to="/UserList">User Admin</NavLink>
                                        </NavItem>
                                    </DropDownItemStyled>
                                    <DropDownItemStyled>
                                        <NavItem>
                                            <NavLink activeClassName="selected" className="nav-link" to="/CompanyLocationList">Location Admin</NavLink>
                                        </NavItem>
                                    </DropDownItemStyled>
                                    <DropDownItemStyled>
                                        <NavItem>
                                            <NavLink activeClassName="selected" className="nav-link" to="/CompanyList">Company Admin</NavLink>
                                        </NavItem>
                                    </DropDownItemStyled>                                    
                                </React.Fragment>                                    
                            }  
                            <DropDownItemStyled>
                                <NavItem>
                                    <span className="nav-link pointer" onClick={this.openTraining}>Training</span>
                                </NavItem>
                            </DropDownItemStyled>
                            <DropDownItemStyled>
                                <NavItem>
                                    <span className="nav-link pointer" onClick={this.requestPasswordReset}>Request Password Reset</span>
                                </NavItem>
                            </DropDownItemStyled>
                          
                                                        
                            <DropDownItemStyled>
                                <NavItem>
                                    <span className="nav-link pointer" onClick={this.signOut}>Sign Out</span>
                                </NavItem>
                            </DropDownItemStyled>
                        </DropDownMenuStyled>
                    </UncontrolledDropdown> }
                    </NavStyled>
                </Collapse>
                </ContainerStyled>
            </NavbarStyled>            
        );                       
    }
};

function mapStateToProps(state, ownProps) {    
    return {
      alert: state.alert,
      session: state.session,
      api: state.api,      
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {    
      sessionActions: bindActionCreators(sessionActions, dispatch),
      apiActions: bindActionCreators(apiActions, dispatch),   
      quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),    
    };
  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));