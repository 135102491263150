import * as types from '../actions/actionTypes';

export default function validationReducer(state = [], action) {
    switch(action.type) {                
        case types.REQUIRED_SCREEN_OPTIONS:
            return {...state, requiredScreenOptions: [...state.requiredScreenOptions, action.requiredScreenOptions] };     
            
        default:
                return state;
    }
}