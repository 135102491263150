import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`   
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;      
    align-items: center;    
    justify-content: center;
`;

const Loading = React.memo(() => {         
    return (                        
        <Wrapper>
            <img src="/images/loading.svg" alt="Loading" />
        </Wrapper> 
    );     
});  

export default Loading; 