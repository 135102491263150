import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as apiActions from '../../actions/apiActions';
import * as sessionActions from '../../actions/sessionActions';
import { withRouter } from 'react-router-dom';
import { Container, Form, FormGroup } from 'reactstrap';
import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import Button from '../Common/Button/Button';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
    margin-right: 1em;
`;

export const FormGroupStyled = styled(FormGroup)`
   input {width: 100% !important; }
`;

class EditProductConfigurations extends Component {  
    constructor(props, context) {
        super(props, context);    
        
        this.formValueChange = this.formValueChange.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.editCancel = this.editCancel.bind(this);

        this.state = ({
            server: '',
            name: '',
            instance: '',
            application: '',
            headerId: '',
            profile: '',
            ruleset: '',
            namespace: '',
            id: ''
        });     

        let username = this.props.session.username;

        if (username) {
            if (this.props.session.username === "" || this.props.session.loggedIn === false) {                            
                this.props.apiActions.getUserConfigurations(username);  
            }
        }           
    }

    componentDidMount() {        
        this.setState({
            server: this.props.location.state.editProduct.Server,
            name: this.props.location.state.editProduct.Name,
            instance: this.props.location.state.editProduct.ApplicationInstance,
            application: this.props.location.state.editProduct.ApplicationName,
            headerId: this.props.location.state.editProduct.HeaderId,
            profile: this.props.location.state.editProduct.Profile,
            ruleset: this.props.location.state.editProduct.Ruleset,
            namespace: this.props.location.state.editProduct.Namespace,
            id: this.props.location.state.editProduct.ID
        });
    }

    formValueChange(event) {
        switch(event.target.id) {
            case "epServer":
                this.setState({ server: event.target.value });  
                break;
                
            case "epName":
                this.setState({ name: event.target.value });
                break;

            case "epInstance":
                this.setState({ instance: event.target.value });
                break;

            case "epApplication":
                this.setState({ application: event.target.value });  
                break;
                
            case "epHeaderId":
                this.setState({ headerId: event.target.value });
                break; 

            case "epProfile":
                this.setState({ profile: event.target.value });
                break;

            case "epRuleset": 
                this.setState({ ruleset: event.target.value });
                break;

            case "epNamespace":
                this.setState({ namespace: event.target.value });
                break; 
            
            default: 
                break;
        }   
    }

    editProduct() { 
        this.props.apiActions.editProduct(this.state.server, this.state.name, this.state.instance, this.state.application, this.state.headerId, this.state.profile, this.state.ruleset, this.state.namespace, this.props.session.username, this.state.id, this.props.session.company);       
        this.props.history.push({
            pathname: '/ProductConfigurations'
        });   
    }

    editCancel() {
        this.props.history.push({
            pathname: '/ProductConfigurations'
        });
    }

    render() {        
        let server = this.props.location.state.editProduct.Server;
        let name = this.props.location.state.editProduct.Name;
        let instance = this.props.location.state.editProduct.ApplicationInstance;
        let application = this.props.location.state.editProduct.ApplicationName;
        let headerId = this.props.location.state.editProduct.HeaderId;
        let profile = this.props.location.state.editProduct.Profile;
        let ruleset = this.props.location.state.editProduct.Ruleset;
        let namespace = this.props.location.state.editProduct.Namespace;
        
        return (     
        <Container className="add-product-configuration p-3">
            <h1 className="text-center">Edit Product</h1>
            
            <Form>
                <FormGroupStyled>
                    <TextInputWithLabel defaultValue={server} id="epServer" caption="Server" onBlur={(e) => this.formValueChange(e)} />                     
                    <TextInputWithLabel defaultValue={name} id="epName" caption="Name" onBlur={(e) => this.formValueChange(e)} />  
                    <TextInputWithLabel defaultValue={instance} id="epInstance" caption="Instance" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel defaultValue={application} id="epApplication" caption="Application" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel defaultValue={headerId} id="epHeaderId" caption="HeaderId" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel defaultValue={profile} id="epProfile" caption="Profile" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel defaultValue={ruleset} id="epRuleset" caption="Ruleset" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel defaultValue={namespace} id="epNamespace" caption="Namespace" onBlur={(e) => this.formValueChange(e)} /> 
                </FormGroupStyled>
                <div className="text-center">
                    <ButtonStyled onClick={this.editProduct} displayText="Save" />
                    <ButtonStyled onClick={this.editCancel} backgroundColor="#e2231a" displayText="Cancel" />
                </div>
            </Form>
        </Container>           
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      session: state.session,
      api: state.api
    };
  }
  
  function mapDispatchToProps(dispatch) {
      return {
          sessionActions: bindActionCreators(sessionActions, dispatch),
          apiActions: bindActionCreators(apiActions, dispatch)
      };
  }   

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProductConfigurations));