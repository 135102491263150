import * as types from './actionTypes';

export function showSuccessAlert(alertText) {
    return { type: types.SHOW_SUCCESS_ALERT, alertText };
}

export function showFailureAlert(alertText) {
    return { type: types.SHOW_FAILURE_ALERT, alertText };
}

export function hideAlert() {
    return { type: types.HIDE_ALERT };
}