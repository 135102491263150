import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export const ButtonStyled = styled(Button)`    
    &.btn {        
        cursor:pointer !important;        
        background-color: ${props=> props.backgroundColor ? props.backgroundColor + " !important" : props.theme.buttonColor + " !important"};
        border-color: ${props=> props.backgroundColor ? props.backgroundColor + " !important" : props.theme.buttonColor + " !important"};
        color: ${props=> props.color ? props.color + " !important" : "#000 !important"};
        width: 100px !important;
    }

    &.btn:hover {
        opacity: .5 !important;
    }

    &.continue { 
        position: relative; 
        top:-7px;  
    }
    
    &.addProduct {
        margin-bottom:10px;
    }       
`;

const rsButton = React.memo(({displayText, ...props}) => {   
          
    return (           
        <ButtonStyled {...props} > { displayText } </ButtonStyled>        
    );      
});

export default rsButton;

rsButton.propTypes = {
    displayText: PropTypes.string.isRequired,    
    backgoundColor: PropTypes.string,
    color: PropTypes.string
};