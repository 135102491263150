import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import CheckBoxPrimitive from '../Common/Primitives/CheckBoxPrimitive';
import DatePicker from "react-datepicker";
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';

export const DatePickerLabel = styled.label`
    margin-right: 15px;
`;

export const LabelWarning = styled(Label)`
    color: red;
`;

const AcceptOrder = props => {
    return (   
        <Container>     
            <Row>
                <Col xl="2">
                    <FormGroup>
                        <Label for="isAccepted">Accept Order?</Label>
                        <CheckBoxPrimitive disabled={props.acceptOrderReadonly} id="isAccepted" checked={props.accepted} onClick={(e) => props.onOrderAccepted(e.target.checked)} />                                                        
                    </FormGroup>                        
                </Col>
                <Col xl="5">
                    <FormGroup>
                    { 
                        props.accepted ? 
                        <React.Fragment>
                            <DatePickerLabel for="datePicker">Estimated Ship Date</DatePickerLabel>
                            <DatePicker id="datePicker" disabled={props.acceptOrderReadonly} onChange={props.onEstimatedShipDateSet} selected={props.estimatedShipDate} minDate={new  Date()} /> 
                        </React.Fragment>
                        : ''                 
                    }
                    </FormGroup>
                </Col>
                <Col xl="5"> 
                    <FormGroup>
                    { 
                        props.accepted ? 
                        <React.Fragment>
                            <DatePickerLabel for="datePicker">Actual Ship Date</DatePickerLabel>
                            <DatePicker id="datePicker" disabled={props.acceptOrderReadonly} onChange={props.onActualShipDateChanged} selected={props.actualShipDate}  /> 
                        </React.Fragment>
                        : ''                 
                    }
                    </FormGroup>
                </Col>
            </Row>
            {props.showRejectOption === true && 
            <Row>
                <Col xl="2">
                    <FormGroup>
                        <Label for="isRejected">Reject Order?</Label>
                        <CheckBoxPrimitive disabled={props.acceptOrderReadonly} id="isRejected" checked={props.rejected} onClick={(e) => props.onOrderRejected(e.target.checked)} />                                                        
                    </FormGroup>                        
                </Col>
                {props.rejected === true && <Col xl="7">
                    <TextInputPrimitive disabled={props.acceptOrderReadonly} id="rejectComment" placeholder="Enter reason for rejecting cancellation" onChange={(e) => props.onRejectMessageChanged(e)} /> 
                </Col>}
            </Row>
            }

        </Container>
    );
}

AcceptOrder.propTypes = {
    onEstimatedShipDateSet: PropTypes.func.isRequired,
    estimatedShipDate: PropTypes.instanceOf(Date).isRequired,
    accepted: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired,
    showRejectOption: PropTypes.bool.isRequired,
    onOrderAccepted: PropTypes.func.isRequired,
    onOrderRejected: PropTypes.func.isRequired,
    onRejectMessageChanged: PropTypes.func.isRequired,
    actualShipDate: PropTypes.instanceOf(Date).isRequired,
    onActualShipDateChanged: PropTypes.func.isRequired,
    acceptOrderReadonly: PropTypes.bool.isRequired
};

export default AcceptOrder;
