import React from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as orderActions from '../../actions/orderActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import { loadProgressBar } from 'axios-progress-bar';
import { Container, Row, Col } from 'reactstrap';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import ListFilter from '../QuoteOrderCommon/ListFilter';
import Pagination from '../Common/Pagination/Pagination';
import 'axios-progress-bar/dist/nprogress.css';
import Icon from '../QuoteOrderCommon/Icon';

export const HeaderRowStyled = styled(Row)`
    font-weight: bold;
    margin-top: 1em;        
    margin-bottom: 1em;

    @media (max-width:1200px) { 
        display: none !important; 
    }
`;

export const IdColStyled = styled(Col)`
    @media (max-width:1200px) { 
        font-weight: bold; 
        &::before {
            content: "\\0023";
        }
        &::after {
            content: "\\003A";
            padding-left: 30px;
        }
    }
`;

export const StatusColStyled = styled(Col)`
    @media (max-width:1200px) { 
        color: ${props => props.theme.buttonColor} !important;
    }
`;

export const FirstNameColStyled = styled(Col)`
    @media (max-width:1200px) { 
        padding-right: 0px !important;
    }
`;

export const LastNameColStyled = styled(Col)`
    @media (max-width:1200px) { 
        padding-left: 6px !important;
    }
`;

export const CreateDateColStyled = styled(Col)`
    @media (max-width:1200px) {
        color: #7A43B6 !important;
        font-size: 13px !important;
    }
`;

export const BodyRowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;    
    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }
`;

class OrderList extends React.Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();   
        
        this.onClickNewOrder = this.onClickNewOrder.bind(this);
        this.onClickEditOrder = this.onClickEditOrder.bind(this);
        this.onOrderFilter = this.onOrderFilter.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onCopy = this.onCopy.bind(this);
      
        this.state = {
            orderFilter: null,   
            offset: 0,
            rowsToFetch: 10,
            currentPage: 1       
        };        
    }  
    
    componentDidMount() {      
        this.getOrders();                  
    }

    onOrderFilter(event) {
        this.setState({ orderFilter: event.target.value}, () => {
            this.getOrders();   
        });            
    }

    getOrders() {                
        this.props.orderActions.getOrders(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName, this.state.orderFilter, this.state.offset, this.state.rowsToFetch);
    }

    async onClickEditOrder(event, orderId) {
        await this.props.orderActions.setActiveOrder(orderId);
        
        this.props.history.push({ pathname: '/Order' });
    }

    async onClickNewOrder() {
        await this.props.orderActions.createOrder(this.props.session.username, this.props.session.company);       
         
        this.props.history.push({ pathname: '/Order' });
    }   
        
    async onPrint(event, orderId) {          
        event.stopPropagation();
        await this.props.orderActions.setActiveOrder(orderId);

        this.props.history.push({
            pathname: '/QuotePrint'             
        }); 
    }

    onCopy(event, orderId) {        
        event.stopPropagation();
        this.props.quoteOrderCommonActions.copy(null, this.props.session.username, orderId).then(() => {
            this.getOrders();
        });
    }

    onPageChange(currentPage) {               
        let offset = parseInt((currentPage * this.state.rowsToFetch) - this.state.rowsToFetch);
        this.setState({ offset: offset, currentPage: currentPage }, () => this.getOrders());        
    }

    render() {        
        let orders = this.props.order.orderList;      

        if (!orders) { 
            return (
                <Container>
                    <h1>Orders</h1>

                    <div className="text-right">
                        <Button displayText="New" onClick={this.onClickNewOrder} />
                    </div>

                    <ListFilter onFilter={this.onOrderFilter} />

                    <HeaderRowStyled>
                        <Col>Order #</Col>
                        <Col>Description</Col>
                        <Col>Status</Col>
                        <Col>Location</Col>
                        <Col>First Name</Col>
                        <Col>Last Name</Col>                        
                        <Col>Contact Email</Col>
                        <Col>Created Date</Col>                        
                    </HeaderRowStyled>
                </Container>
            );            
        }
                
        let pageCount = 1;
        
        if (this.props.order && this.props.order.orderList) {
            pageCount = this.props.order.orderList.length > 0 ? (this.props.order.orderList[0].totalRows / this.state.rowsToFetch) : 1;
            pageCount = Math.ceil(pageCount);
        }

        return (
            <Container>                                                            
                <h1>Orders</h1>

                <div className="text-left">
                    <Button displayText="New" onClick={this.onClickNewOrder} />
                </div>

                <ListFilter onFilter={this.onOrderFilter} />

                <HeaderRowStyled>
                    <Col xl="1">Order #</Col>
                    <Col xl="1">Description</Col>
                    <Col xl="1">Status</Col>
                    <Col xl="1">Location</Col>
                    {/* <Col xl="1">Branch</Col> */}
                    <Col xl="1">Store Number</Col>
                    <Col xl="1">First Name</Col>
                    <Col xl="1">Last Name</Col>
                    <Col xl="2">Contact Email</Col>
                    <Col xl="1">Created Date</Col>
                    <Col xl="1">Copy</Col>                    
                    {/* <Col xl="1">Print</Col> */}
                </HeaderRowStyled>
                {                                               
                    orders.map(order => {
                        let createdDate = dateFormat(order.createdDate, "m/d/yyyy hh:MM TT", true);
                        
                        return(
                            <BodyRowStyled key={order.id} onClick={(e) => this.onClickEditOrder(e, order.id)}>
                                <IdColStyled xl="1" xs="auto">
                                    {order.id}
                                </IdColStyled>
                                <Col xl="1" xs="auto">
                                    {order.name}
                                </Col>
                                <StatusColStyled xl="1">
                                    {order.statusName}
                                </StatusColStyled>
                                <Col xl="1">
                                    {order.companyLocationName}
                                </Col>                                 
                                {/* <Col xl="1">
                                    {order.branchName}
                                </Col>  */}
                                <Col xl="1">
                                    {order.companyLocationStoreNumber}
                                </Col>                                                                 
                                <FirstNameColStyled xl="1" xs="auto">
                                    {order.firstName}
                                </FirstNameColStyled>
                                <LastNameColStyled xl="1" xs="auto">
                                    {order.lastName}
                                </LastNameColStyled>
                                <Col xl="2" >
                                    {order.email}
                                </Col>
                                <CreateDateColStyled xl="1">
                                    {createdDate}
                                </CreateDateColStyled>
                                <Col xl="1" xs="auto">
                                    <Icon iconName="copy" title="copy" id={order.id} click={(e) => this.onCopy(e, order.id)}></Icon>
                                </Col>
                                {/* <Col xl="1" xs="auto">
                                    <Icon iconName="print" title="print" id={order.id} click={(e) => this.onPrint(e, order.id)}></Icon>
                                </Col> */}
                            </BodyRowStyled>
                        );                        
                    })
                } 
                <Pagination onPageChange={this.onPageChange} pageCount={pageCount} offset={this.state.offset} currentPage={this.state.currentPage} rowsToFetch={this.state.rowsToFetch} />                                                                  
            </Container>               
        );                
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      api: state.api,
      session: state.session,
      order: state.order,
      QuoteOrderCommon: state.QuoteOrderCommon
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch)
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderList));