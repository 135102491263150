import React from 'react';
import styled from 'styled-components';

export const ProductPreviewWrapper = styled.div`
    @media (min-width: 1201px) {            
        text-align: center;   
    }

    @media (max-width: 768px) {         
        border-top: solid 1px lightgray;
    }
`;

export const Img = styled.img`     
    max-width: 550px;
    
    @media(min-width:1201px) {
        position: relative;
        top: -1em;
    }
    
    @media(max-width:1200px) {
        width:99%;
        max-width: 250px;
    }
`;

const ProductPreview = React.memo(props => {        
    return (
        <ProductPreviewWrapper>                     
            <Img src={props.src} alt="product" />
        </ProductPreviewWrapper>
    );      
});

export default ProductPreview;