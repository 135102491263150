import * as types from '../actions/actionTypes';
import * as constants from '../modules/constants';
export default function adminReducer(state = [], action) {
    switch(action.type) {                
        // case types.GET_USERS_SUCCESS:
        //     return {...state, users: action.users };

        // case types.GET_COMPANY_LOCATION_BRANCHES_SUCCESS:
        //     return {...state, companyBranches: action.branches};
                   
        case types.SET_ACTIVE_USER: 
            return {...state, activeUser: action.username};

        case types.GET_ACTIVE_USERS_COMPANY_LOCATIONS_SUCCESS:
            return {...state, activeUserLocations: action.activeUserLocations};

        case types.GET_COMPANY_LOCATIONS_SUCCESS:
            return {...state, companyLocations: action.companyLocations};

        case types.GET_FABRICATOR_LOCATIONS_SUCCESS:
            return {...state, fabricatorLocations: action.fabricatorLocations};            

        case types.GET_INSTALLER_LOCATIONS_SUCCESS:
            return {...state, installerLocations: action.installerLocations};            

        case types.SET_ACTIVE_COMPANY_LOCATION: 
            return {...state, activeCompanyLocation: action.companyLocationId };

        case types.GET_ACTIVE_USER_ROLE:
            return {...state, activeUserRole: action.role[0].id};

        case types.GET_ALL_COMPANIES_SUCCESS:
            return {...state, companyList: action.companies};

        case types.GET_ALL_PRODUCTS_SUCCESS:
            return {...state, productList: action.products};

        case types.GET_ALL_FABRICATORS_SUCCESS:
            return {...state, fabricatorList: action.fabricators};
            
        case types.GET_ALL_INSTALLERS_SUCCESS:
            return {...state, installerList: action.installers};            

        case types.SET_ACTIVE_COMPANY: 
            return {...state, activeCompanyId: action.companyId };

        case types.GET_AD_PATCH_TYPES_SUCCESS:
            return {...state, adPatchTypes: action.adPatchTypes};

        case types.GET_COMPANY_LOCATION_ADDRESSES_SUCCESS:
            let billingAddress = action.addresses.filter(address => address.addressTypeId === constants.ADDRESS_TYPES.Billing);
            let shippingAddress = action.addresses.filter(address => address.addressTypeId === constants.ADDRESS_TYPES.Shipping);
            return {...state, 
                activeCompanyLocationBillingAddress: billingAddress ? billingAddress[0] : null, 
                activeCompanyLocationShippingAddress: shippingAddress ? shippingAddress[0] : null 
            };

        default:
            return state;
    }
}