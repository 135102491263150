import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import Icon from '../QuoteOrderCommon/Icon';
import * as constants from '../../modules/constants';

export const Img = styled.img`
    width:25px;
    height: auto;
`;
export const ColStyled = styled(Col)`
    text-decoration: underline;
    cursor: pointer;
`;

export const DownloadLinkStyled = styled.div`    
    display: inline;    
`;

export const FaxTemplateLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    font-size: 17px;
`;

const InvoiceList = React.memo(props => {   
    return (           
        <Container>            
            {props.invoices && props.invoices.length > 0 &&
            <Row>
            {props.invoices.map(file => {                 
                let uploadDate = dateFormat(file.uploadDate, "m/d/yyyy hh:MM TT", true);                          
                return( 
                    <React.Fragment>
                        <ColStyled xl="4" xs="12" key={file.id} >
                            <DownloadLinkStyled onClick={(e) => props.onClickDownloadFile(file.path, file.originalName, file.mimetype, file.filename)}>
                                <Img src="/images/attachments.png" alt="renaissancetech"  />{file.originalName} - {uploadDate}                                                                                                                          
                            </DownloadLinkStyled>

                            {props.readonly === false && 
                                <DownloadLinkStyled onClick={(e) => props.onDeleteAttachment(file.id)}>
                                    <Icon height="16px" iconName="trash" title="delete" isLine={false}  /> 
                                </DownloadLinkStyled>
                            }
                        </ColStyled>                                                                            
                    </React.Fragment>                                           
                );
            })}
            </Row>
            }
        </Container>
    );
});

export default InvoiceList;

InvoiceList.propTypes = {
    invoices: PropTypes.array.isRequired,
    onClickDownloadFile: PropTypes.func.isRequired,    
    readonly: PropTypes.bool,
    orderId: PropTypes.number
};