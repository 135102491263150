import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

export const CloseModal = styled.div`
    position: fixed;
    right: 30px;
    top: 30px;
    font-size: 20px !important;
    cursor: pointer;
`;

export const ModalBodyStyled = styled(ModalBody)`
    padding: .5em !important;    
`;

export const ModalStyled = styled(Modal)`
    .modal-content {
        background-color:#FFF !important;
        color: #000 !important;
        padding: 1em;
        border-radius:15px !important;
        padding-left:2em;
        padding-right:2em;
    }

    &.modal-dialog {    
        top: 0 !important;        
        border:none;
        width:100% !important;
        height: 100% !important;
        max-width: 100% !important;
    }
`;

const ModalWindow = ({isShowing, hide, ...props}) => {
    if (isShowing === false) return null;

    return(
        <ModalStyled isOpen={isShowing} >               
            <ModalBodyStyled>                                                    
                <CloseModal onClick={hide}>X</CloseModal>                            
                    {props.children}                                                
            </ModalBodyStyled>                
        </ModalStyled>
    );     
}

export default ModalWindow;