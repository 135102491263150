import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel/InputLabel';
import { FormGroup } from 'reactstrap';
import NumericInput from 'react-numeric-input';

const NumericSelectCPQ = React.memo(({screenOption, caption, textInputOnChange, ...props}) => {   

    let min;
    let max ;
    let increment;

    if (screenOption.Ranges &&  screenOption.Ranges.length > 0) {
        min = screenOption.Ranges[0].Min ? screenOption.Ranges[0].Min : 0;
        max = screenOption.Ranges[0].Max ? screenOption.Ranges[0].Max : 1000000;
        increment = screenOption.Ranges[0].Max ? screenOption.Ranges[0].Max : 1;
    }
    
    return (
        <FormGroup className={screenOption.IsVisible === false ? 'd-none' : ''}> 
            
                <InputLabel htmlFor={screenOption.ID} caption={caption} isRequired={true} />                                         
                <NumericInput  
                    min={min} 
                    max={max}
                    step={increment} 
                    precision={4}                
                    id={screenOption.ID} 
                    name={screenOption.ID}                
                    disabled={!screenOption.IsEnabled}                 
                    defaultValue={screenOption.Value}             
                    onBlur={textInputOnChange}                     
                    title={screenOption.Tooltip}   
                    {...props}
                />       
                                  
        </FormGroup>
    );     
});  

export default NumericSelectCPQ;

NumericSelectCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    caption: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool
};