import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

export const DetailSummary = styled.div`
    @media (min-width:1201px) {        
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        padding-top:.5em;       
    }
`;

export const H2 = styled.h2`
    font-size:24px !important;
    text-align:center;
`;

export const ContainerStyled = styled(Container)`
    @media(min-width:1201px) { 
        .selected-values { 
            text-align: right; margin-left: auto; margin-right: auto; 
        } 
    }

    @media(max-width:1200px) {
        .selected-values {
          margin-bottom: 1em;    
          margin-left:1em !important;
          margin-right:1em !important;
        }
      
        .selected-values label { 
            width:100%; 
            text-align: left; 
        }
    }
`;
 
const DetailedSummary = React.memo(props => {     
    if (!props.detailedSummary) return null;

    return(
        <DetailSummary>                                                
            <H2>Configuration Summary</H2>  
            <ContainerStyled>
                <Row>
                    <Col xs="8" xl="8" className="text-left">
                        <label className="font-weight-bold">Selected Option</label>
                    </Col>
                    <Col xs="4" xl="4" className="text-right">
                        <label className="font-weight-bold">Value</label>
                    </Col>
                </Row>                                                                                                                           
            </ContainerStyled>               
            {props.detailedSummary.map(opt =>  
                                        
            { 
                if (!opt.Value) return null;

                let val = opt.Type === 5 ? '$' + opt.Value : opt.Value;
                                
                return (                                
                    <ContainerStyled>
                        <Row>
                            <Col xs="8" xl="8" className="text-left">
                                <label>{opt.Caption}</label>
                            </Col>
                            <Col xs="4" xl="4" className="text-right">
                                <label>{val}</label>
                            </Col>
                        </Row>                                                                                                                           
                    </ContainerStyled>
                );                             
            })}                                                           
        </DetailSummary>
    );            
});

export default DetailedSummary;

DetailedSummary.propTypes = {
    detailedSummary: PropTypes.array.isRequired
};