import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import StateSelect from '../Common/StateSelect/StateSelect';
import CountrySelect from '../Common/CountrySelect/CountrySelect';
import InputLabel from '../Common/InputLabel/InputLabel';
export const LabelStyled = styled(Label)`
    margin-bottom: 0 !important;
`;

export const FormGroupStyled = styled(FormGroup)`
    &.form-group { margin-bottom: .25em; }
`;

export const RowStyled = styled(Row)`
    
    margin-left: 0 !important;
    margin-right: 0 !important;    
    
    @media(max-width:1200px) {
        margin-bottom:.5em;        
    }
`;

export const InputLabelStyled = styled(InputLabel)`
    font-weight: normal !important;
`;

/*
    READ ME

    The input ID must be the same as the column name in sql. This is used when building the update statements in the node service to reduce the amount of code.

*/
const AddressForm = React.memo(props => {             
    return (
        <React.Fragment>
        <Row>
            <h1>{props.title}</h1>
        </Row>
        <Row>                                                                                                          
            <Col xl="2"> 
                <FormGroupStyled>                    
                    <InputLabelStyled htmlFor="Address" caption="Address" isRequired={true} />
                    <TextInputPrimitive disabled={props.readonly} id="Address" name="Address" onClick={(e) => props.onInputFocus(e)} defaultValue={props.address} onBlur={(e) => props.onAddressFieldUpdate(e, props.addressType)} />
                </FormGroupStyled> 
            </Col>
            <Col xl="2">
                <FormGroupStyled>                    
                    <InputLabelStyled htmlFor="Address2" caption="Address 2" isRequired={false} />
                    <TextInputPrimitive disabled={props.readonly} id="Address2" name="Address2" onClick={(e) => props.onInputFocus(e)} defaultValue={props.address2} onBlur={(e) => props.onAddressFieldUpdate(e, props.addressType)}/>
                </FormGroupStyled>
            </Col>
            <Col xl="2"> 
                <FormGroupStyled>
                <InputLabelStyled htmlFor="City" caption="City" isRequired={true} />
                    <TextInputPrimitive disabled={props.readonly} id="City" name="City" onClick={(e) => props.onInputFocus(e)} defaultValue={props.city} onBlur={(e) => props.onAddressFieldUpdate(e, props.addressType)}/>
                </FormGroupStyled>
            </Col>
            <Col xl="2">
                <FormGroupStyled>
                    <InputLabelStyled htmlFor="State" caption="State" isRequired={true} />
                    <StateSelect disabled={props.readonly} id="State" name="State" defaultValue={props.state} onChange={(e) => props.onAddressFieldUpdate(e, props.addressType)} addressType={props.addressType} />
                </FormGroupStyled>
            </Col>
            <Col xl="2">
                <FormGroupStyled>
                <InputLabelStyled disabled={props.readonly} htmlFor="Zip" caption="Zip Code" isRequired={true} />
                    { props.zip 
                    ? <TextInputPrimitive disabled={props.readonly} id="Zip" name="Zip" onClick={(e) => props.onInputFocus(e)} defaultValue={props.zip.toString()} onBlur={(e) => props.onAddressFieldUpdate(e, props.addressType)}/> 
                    : <TextInputPrimitive disabled={props.readonly} id="Zip" name="Zip" onClick={(e) => props.onInputFocus(e)} onBlur={(e) => props.onAddressFieldUpdate(e, props.addressType)}/>
                    }                        
                </FormGroupStyled>
            </Col>            
            {/* <Col xl="2">
                <FormGroupStyled>
                    <LabelStyled for="Country">Country</LabelStyled>
                    <CountrySelect id="Country" name="Country" defaultValue={props.country} onChange={props.onAddressFieldUpdate} addressType={props.addressType} />
                </FormGroupStyled>
            </Col>                                                                 */}
        </Row>
        </React.Fragment>
    );        
});

export default AddressForm;

AddressForm.propTypes = {
    title: PropTypes.string.isRequired,
    addressType: PropTypes.oneOf(['Billing', 'Shipping']).isRequired,   
    onAddressFieldUpdate: PropTypes.func.isRequired,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    address2: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.number,
    readonly: PropTypes.bool
};