
import React from 'react';
import ReactSelect from '../ReactSelect/ReactSelect';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel/InputLabel';
import { FormGroup } from 'reactstrap';

const TypeableSelectCPQ = React.memo(({screenOption, caption, onChange, ...props}) => {   
  
    const options = SelectOptionArray(screenOption.SelectableValues);

    return (
        <div className={screenOption.IsVisible === false ? 'd-none' : ''}>  
            <InputLabel htmlFor={screenOption.ID} caption={caption} isRequired={screenOption.IsRequired} />                                         
            <ReactSelect  
                defaultValue={screenOption.Value}
                id={screenOption.ID} 
                name={screenOption.ID}
                caption={caption}
                disabled={!screenOption.IsEnabled} 
                required={screenOption.IsRequired}                
                options={options}
                onChange={onChange} 
                width={props.width}              
                {...props} 
            />                               
        </div>
    );     
});  

export default TypeableSelectCPQ;

const SelectOptionArray = (arr) => {
    let options = [];       
    
    if (arr) {
        for (var i = 0; i < arr.length; i++) {
            let opt = {
                value: arr[i].Value,
                label: arr[i].Caption
            }      
            options.push(opt);
        }
    }     
    return options;
}

TypeableSelectCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    caption: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool
};