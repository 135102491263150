import React from 'react';
import Button from '../Common/Button/Button';
import greenCheck from './checkmark.png';
import redX from './redX.png';
import PropTypes from 'prop-types';
import { Card, CardBody, CardText, Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import styled from 'styled-components';

export const CardStyled = styled(Card)`
    border: solid 5px #f5f5f5 !important; 
    margin-bottom:30px !important;    
`;

export const CardBodyStyled = styled(CardBody)`
    padding: 0px !important;    
    background-color: #f5f5f5 !important;  
    text-align: center;
`;

export const ListGroupItemStyled = styled(ListGroupItem)`
    text-align:left;
    &.active {
        background-color: ${props => props.theme.buttonColor} !important;
        border-color: ${props => props.theme.buttonColor} !important
    }
`;

export const ButtonStyled = styled(Button)`
    margin-left:10px !important;
    position: relative;
    top: -5px;
`;

export const SpanStyled = styled.span`
    font-weight:bold;
    margin-right:10px;
    display:inline-block;
`;

export const IsActiveStyled = styled.img`
    height: 25px;
    cursor: default;
    position: relative;
    top: -3px;
`;

const ProductConfigurationList = React.memo(props => {       
    return (       
        <Container>
        <Row>
        {props.productConfigurationList.map(item => {                        
            return(                                                                          
                <Col xs="12" xl="4">
                    <CardStyled>                      
                        <CardBodyStyled>  
                            <CardText>
                                <ListGroup key={item.Name}>
                                    {item.ID !== props.activeProductId
                                        ? <ListGroupItemStyled><SpanStyled>Name: </SpanStyled>{item.Name}</ListGroupItemStyled>
                                        : <ListGroupItemStyled data-testid="activeProduct" active><SpanStyled>Name: </SpanStyled>{item.Name}</ListGroupItemStyled>
                                    }
                                    {item.ID !== props.activeProductId
                                        ? <ListGroupItemStyled><SpanStyled>Is Active: </SpanStyled><IsActiveStyled src={redX} alt="not active" /></ListGroupItemStyled> 
                                        : <ListGroupItemStyled><SpanStyled>Is Active: </SpanStyled><IsActiveStyled src={greenCheck} alt="active" /></ListGroupItemStyled> 
                                    }                                   
                                                                        
                                    <ListGroupItemStyled><SpanStyled>Instance: </SpanStyled>{item.ApplicationInstance}</ListGroupItemStyled>
                                    <ListGroupItemStyled><SpanStyled>Application: </SpanStyled>{item.ApplicationName}</ListGroupItemStyled>
                                    <ListGroupItemStyled><SpanStyled>HeaderId: </SpanStyled>{item.HeaderId}</ListGroupItemStyled>
                                    <ListGroupItemStyled><SpanStyled>Profile: </SpanStyled>{item.Profile}</ListGroupItemStyled>
                                    <ListGroupItemStyled><SpanStyled>Ruleset: </SpanStyled>{item.Ruleset}</ListGroupItemStyled>                                
                                    <ListGroupItemStyled><SpanStyled>Namespace: </SpanStyled>{item.Namespace}</ListGroupItemStyled>
                                    <ListGroupItemStyled><SpanStyled>Server: </SpanStyled>{item.Server}</ListGroupItemStyled>                                        
                                </ListGroup>
                            </CardText>                       
                            <ButtonStyled 
                                    id={item.configurationId}                                                                       
                                    type="Button"
                                    onClick={(e) => props.setActiveProduct(item.ID)}
                                    displayText="Activate" 
                            /> 
                            <ButtonStyled                                                                                                          
                                    type="Button"
                                    onClick={(e) => props.editProduct(item)}
                                    displayText="Edit" 
                            />                     
                            <ButtonStyled                                                                     
                                backgroundColor="#e2231a"
                                type="Button"
                                //onClick={(e) => props.onDelete(item.ID)}
                                displayText="Delete" 
                            />
                        </CardBodyStyled>
                    </CardStyled>
                </Col>
                );                    
            })} 
        </Row>
    </Container>         
    );  
});

export default ProductConfigurationList;

ProductConfigurationList.propTypes = {
    productConfigurationList: PropTypes.array.isRequired,
    setActiveProduct: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired      
};