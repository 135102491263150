import React from 'react';
import RadioButtonPrimitive from '../Primitives/RadioButtonPrimitive';
import InputLabel from '../InputLabel/InputLabel';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const RadioButtonPrimitiveStyled = styled(RadioButtonPrimitive)`
    .radioOption {display: inline-block; text-align:left; }
`;

export const RadioButtonGroup = styled.div`
    display: inline-block; 
    width: 100%; 

    @media (min-width: 1201px) {         
        text-align:left;        
    }
`;

const RadioButtonWithLabel = React.memo(props => {              
    return (
        <React.Fragment>                        
            <InputLabel htmlFor={props.id} caption={props.caption} isRequired={props.required} />
            <RadioButtonGroup>
                <RadioButtonPrimitiveStyled 
                    options={props.options} 
                    defaultValue={props.defaultValue} 
                    id={props.id}
                    selectOnChange={props.selectOnChange}
                    disabled={props.disabled}
                    required={props.required}
                    autoFocus={props.autoFocus}                
                />               
            </RadioButtonGroup>                          
        </React.Fragment>
    );      
});

export default RadioButtonWithLabel;

RadioButtonWithLabel.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,    
    defaultValue: PropTypes.string,
    selectOnChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    caption: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    radioButtonWrapperCSS: PropTypes.string,
    autoFocus: PropTypes.bool
  };

  RadioButtonWithLabel.defaultProps = {
    disabled: false,
    required: false    
}
