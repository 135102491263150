module.exports = {
    isEmailValid: function(email) {
        return /\S+@\S+\.\S+/.test(email)
    },

    passwordsMatch: function(password1, password2) {
        return (password1 === password2);       
    }


}