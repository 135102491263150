import React from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteActions from '../../actions/quoteActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import { loadProgressBar } from 'axios-progress-bar';
import { Container, Row, Col } from 'reactstrap';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import ListFilter from '../QuoteOrderCommon/ListFilter';
import Pagination from '../Common/Pagination/Pagination';
import 'axios-progress-bar/dist/nprogress.css';
import Icon from '../QuoteOrderCommon/Icon';

export const HeaderRowStyled = styled(Row)`
    font-weight: bold;
    margin-top: 1em;        
    margin-bottom: 1em;

    @media (max-width:1200px) { 
        display: none !important; 
    }
`;

export const IdColStyled = styled(Col)`
    @media (max-width:1200px) { 
        font-weight: bold; 
        &::before {
            content: "\\0023";
        }
        &::after {
            content: "\\003A";
            padding-left: 30px;
        }
    }
`;

export const StatusColStyled = styled(Col)`
    @media (max-width:1200px) { 
        color: ${props => props.theme.buttonColor} !important;
    }
`;

export const FirstNameColStyled = styled(Col)`
    @media (max-width:1200px) { 
        padding-right: 0px !important;
    }
`;

export const LastNameColStyled = styled(Col)`
    @media (max-width:1200px) { 
        padding-left: 6px !important;
    }
`;

export const CreateDateColStyled = styled(Col)`
    @media (max-width:1200px) {
        color: #7A43B6 !important;
        font-size: 13px !important;
    }
`;

export const BodyRowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;    
    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }
`;

class QuoteList extends React.Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();   
        
        this.onClickNewQuote = this.onClickNewQuote.bind(this);
        this.onClickEditQuote = this.onClickEditQuote.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onCopy = this.onCopy.bind(this);

        this.state = {
            quoteFilter: null, 
            offset: 0,
            rowsToFetch: 10,
            currentPage: 1    
        };        
    }  
    
    componentDidMount() {      
        this.getQuotes();                  
    }

    onFilter(event) {
        this.setState({ quoteFilter: event.target.value}, () => {
            this.getQuotes();   
        });            
    }

    getQuotes() {                
        this.props.quoteActions.getQuotes(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName, this.state.quoteFilter, this.state.offset, this.state.rowsToFetch);
    }

    async onClickEditQuote(event, quoteId) {
        await this.props.quoteActions.setActiveQuote(quoteId);
        
        this.props.history.push({ pathname: '/Quote' });
    }

    async onClickNewQuote() {
        await this.props.quoteActions.createQuote(this.props.session.username, this.props.session.company);       
         
        this.props.history.push({ pathname: '/Quote' });
    }   
    
    async onPrint(event, quoteId) {               
        event.stopPropagation();
        await this.props.quoteActions.setActiveQuote(quoteId);

        this.props.history.push({
            pathname: '/QuotePrint'             
        }); 
    }

    onCopy(event, quoteId) {        
        event.stopPropagation();
        this.props.quoteOrderCommonActions.copy(quoteId, this.props.session.username, null).then(() => {
            this.getQuotes();
        });
    }

    onPageChange(currentPage) {               
        let offset = parseInt((currentPage * this.state.rowsToFetch) - this.state.rowsToFetch);
        this.setState({ offset: offset, currentPage: currentPage }, () => this.getQuotes());        
    }

    render() {        
        
        let pageCount = 1;
        
        if (this.props.quote && this.props.quote.quoteList) {
            pageCount = this.props.quote.quoteList.length > 0 ? (this.props.quote.quoteList[0].totalRows / this.state.rowsToFetch) : 1;
            pageCount = Math.ceil(pageCount);
        }
                
        return (
            <Container>                                                            
                <h1>Quotes</h1>
                                
                <div className="text-left">
                    <Button displayText="New" onClick={this.onClickNewQuote} />
                </div>

                <ListFilter onFilter={this.onFilter} />

                <HeaderRowStyled>
                    <Col xl="1">Quote #</Col>
                    <Col xl="1">Description</Col>
                    <Col xl="1">Status</Col>
                    <Col xl="1">Location</Col>
                    {/* <Col xl="1">Branch</Col> */}
                    <Col xl="1">Store Number</Col>
                    <Col xl="1">First Name</Col>
                    <Col xl="1">Last Name</Col>
                    <Col xl="2">Contact Email</Col>
                    <Col xl="1">Created Date</Col>
                    <Col xl="1">Copy</Col>
                    {/* <Col xl="1">Print</Col> */}
                </HeaderRowStyled>
                {              
                    (this.props.quote.quoteList) &&                                                      
                    this.props.quote.quoteList.map(quote => {
                        let createdDate = dateFormat(quote.createdDate, "m/d/yyyy hh:MM TT", true);
                        
                        return(
                            <BodyRowStyled onClick={(e) => this.onClickEditQuote(e, quote.id)}>
                                <IdColStyled xl="1" xs="auto">
                                    {quote.id}
                                </IdColStyled>
                                <Col xl="1" xs="auto">
                                    {quote.name}
                                </Col>
                                <StatusColStyled xl="1">
                                    {quote.statusName}
                                </StatusColStyled>
                                <Col xl="1">
                                    {quote.companyLocationName}
                                </Col>
                                {/* <Col xl="1">
                                    {quote.branchName}
                                </Col> */}
                                <Col xl="1">
                                    {quote.companyLocationStoreNumber}
                                </Col>                             
                                <FirstNameColStyled xl="1" xs="auto">
                                    {quote.firstName}
                                </FirstNameColStyled>
                                <LastNameColStyled xl="1" xs="auto">
                                    {quote.lastName}
                                </LastNameColStyled>
                                <Col xl="2" >
                                    {quote.email}
                                </Col>
                                <CreateDateColStyled xl="1">
                                    {createdDate}
                                </CreateDateColStyled>
                                <Col xl="1" xs="auto">
                                    <Icon iconName="copy" title="copy" click={(e) => this.onCopy(e, quote.id)}></Icon>
                                </Col>
                                {/* <Col xl="1" xs="auto">
                                    <Icon iconName="print" title="print" click={(e) => this.onPrint(e, quote.id)}></Icon>
                                </Col> */}
                            </BodyRowStyled>
                        );                        
                    })
                }     
                <Pagination onPageChange={this.onPageChange} pageCount={pageCount} offset={this.state.offset} currentPage={this.state.currentPage} rowsToFetch={this.state.rowsToFetch} />                               
            </Container>               
        );                
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      api: state.api,
      session: state.session,
      quote: state.quote,
      quoteOrderCommon: state.quoteOrderCommon
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators( apiActions, dispatch),
        sessionActions: bindActionCreators( sessionActions, dispatch),
        quoteActions: bindActionCreators(quoteActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch)
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuoteList));