import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelectCtrl from 'react-select';

export const ReactSelectStyled = styled(ReactSelectCtrl)`
    background-color:#f3f3f3 !important;

    @media (min-width: 1201px) {        
        width: ${({width}) => ( !width ? "70% !important" : width) }
    }    
`;

const ReactSelect = React.memo(({id, options, onChange, ...props}) => { 
               
    return (                     
        <ReactSelectStyled 
            id={id}            
            options={options} 
            onChange={(e) => onChange(2, e, id)}
            isSearchable={true}
            value={options.filter(option => option.value === props.defaultValue)}
            width={props.width}
             />                                                               
    );       
});

export default ReactSelect;

ReactSelect.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.string
};