import {combineReducers} from 'redux';
import * as types from '../actions/actionTypes';
import api from './apiReducer';
import alert from './alertReducer';
import session from './sessionReducer';
import validation from './validationReducer';
import quote from './quoteReducer';
//import workflows from './workflowReducer';
import order from './orderReducer';
import admin from './adminReducer';
import quoteOrderCommon from './quoteOrderCommon';
import * as constants from '../modules/constants';

const appReducer = combineReducers({
    api,
    alert,
    session,
    validation,
    quote,    
    order,
    quoteOrderCommon,
    admin
});

const rootReducer = (state, action) => {
    if (action.type === types.RESET_APP) {
        state = {...state, 
            api: { 
                showSubmitConfigurationButton: false, 
                uncommittedScreenOptions: [], 
                uncommittedUserConfigurations: [],
                companyProducts: [] 
            },
            quote: {
                quoteList: null
            },
            session: {
                access: 1,
                loggedIn: false, 
                username: '',
                showSignupModal: false,
                configuratorMode: constants.CONFIGURATOR_MODES.default
            },
            validation: {
                requiredScreenOptions: []
            }
        }
    }

    return appReducer(state, action);
}

export default rootReducer;