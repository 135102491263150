import React from 'react';
import PropTypes from 'prop-types';

const SelectOptionsPrimitive = React.memo(({options}) => {
    return (                                    
        options.map((opt) => {
                return (           
                    <option     
                        value={opt.Value}
                        // key={opt.Value}
                    >{opt.Caption}</option>  
                );
        })     
    );   
});

export default SelectOptionsPrimitive;

SelectOptionsPrimitive.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        Value: PropTypes.string.isRequired,
        Caption: PropTypes.string.isRequired,
    })).isRequired
};
