import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import styled from 'styled-components';
import * as constants from '../../modules/constants';
import { detect } from 'detect-browser';

export const EmailLabel = styled.div`
    text-align: center;
    font-size: 20px !important;
    margin-top: 1em;
    margin-bottom: .5em; 
`;

export const PleaseUseLabel = styled.div`
    text-align: center;
    font-size: 20px !important;
    margin-top: 1em;
    margin-bottom: .5em; 
`;

export const NotCompatibleLabel = styled.div`
    text-align: center;
    font-size: 16px !important;
    margin-top: 0em;
    margin-bottom: 0em; 
    font-style: italic;
    font-weight: normal;
`;


export const Link = styled.div`
    cursor: pointer;
    text-decoration: underline;
    display:block;
    text-align: center;
`;

export const CloseModal = styled.div`
    position: fixed;
    right: 15px;
    top: 5px;
    font-size: 20px !important;
    cursor: pointer;
`;

export const TextInputPrimitiveStyled = styled(TextInputPrimitive)`
    padding: 5px;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 1em;
    width: 300px; 
`;

export const Img = styled.img`
    display: block;
    width: 50%;    
    margin-left: auto;
    margin-right: auto;
    margin-bottom:2.5em;
    background-color: white;
`;

export const ButtonStyled = styled(Button)`
    background-color: #f5f5f5 !important;
    color: #4d4d4d !important;
    display: block !important;    
    margin-left: auto;
    margin-right: auto;
    border:none !important;
    height:42px;
    width:120px;
    font-size: 20px !important;
    padding:0;

    :hover {
        background-color: ${props => props.theme.buttonColor} !important;
        color:#FFF !important;
    }
`;

export const FormGroup = styled.div`
    margin-bottom: 0;
`;

export const ModalBodyStyled = styled(ModalBody)`
    padding: .5em !important;    
`;

export const ModalStyled = styled(Modal)`
    .modal-content {
        background-color: ${props => props.theme.altButtonColor} !important;
        color: #FFF !important;
        padding: 1em;
        border-radius:15px !important;
        padding-left:2em;
        padding-right:2em;
    }

    &.modal-dialog {    
        top: 45% !important;
        transform: translateY(-50%)  !important;
        border:none;
        max-width:500px !important;
    }
`;

class SignUpModal extends Component { 
    constructor(props, context) {
        super(props, context);    
        
        this.onClickSignUp = this.onClickSignUp.bind(this); 
        this.passwordOnChange = this.passwordOnChange.bind(this); 
        this.passwordVerifyOnChange = this.passwordVerifyOnChange.bind(this); 
        this.emailOnChange = this.emailOnChange.bind(this);  
        this.memberLogin = this.memberLogin.bind(this);  
        this.memberSignUp = this.memberSignUp.bind(this);  
        this.onClickLogin = this.onClickLogin.bind(this);  
        this.closeModal = this.closeModal.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.requestPasswordReset = this.requestPasswordReset.bind(this);

        this.state = {
            alreadyMember: true,
            username: '',
            password: '',
            passwordVerify: '',
            signupError: '',
            passwordResetSent: false,
            browserSupported: true
        }
    }    

    componentDidMount() {
        let browserSupported = this.isBrowserSupported();

        this.setState({ browserSupported: browserSupported });
    }

    memberSignUp() {     
        this.setState({ alreadyMember: false, signupError: '' });
    }

    memberLogin() {        
        this.setState({ alreadyMember: true, signupError: '' });
    }

    passwordOnChange(event) {
        this.setState({password: event.target.value });
    }

    passwordVerifyOnChange(event) {
        this.setState({passwordVerify: event.target.value });
    }

    emailOnChange(event) {
        this.setState({email: event.target.value });
    }

    closeModal() {
        this.resetLocalState();
        this.props.sessionActions.showSignupModal(false);
    }

    forgotPassword() {
        if (this.state.email) {
            this.props.sessionActions.forgotPassword(this.state.email);
            this.setState({ passwordResetSent: true });
        }
                  
    }

    requestPasswordReset() {        
        this.pushHistory('/RequestPasswordReset');    
    }

    onKeyPressed(event) {        
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          this.onClickLogin();
        }
      }

    pushHistory(url) {
        let { history } = this.props;
            history.push({
            pathname: url,
            search: ''
        });
    }

    resetLocalState() {
        this.setState({
            alreadyMember: true,
            username: '',
            password: '',
            passwordVerify: '',
            signupError: '',
            passwordResetSent: false
        });
    }

    onClickLogin() { 
        try {

            let email = this.state.email;
            let password = this.state.password;      
            
            if (email === '') {
                //this.displayEmailError('Please enter your email');
                return;
            }        

            if (password === '') {            
                //this.displayPasswordError('Please enter your password');
                return;
            } 
                            
            this.props.sessionActions.login(email, password).then(() => {

                if (this.props.session.access === constants.ACCESS.ICE) {
                    this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Default);
                }

                this.props.quoteOrderCommonActions.getQuoteOrderCommonOverview(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName).then(() => {
                    this.props.history.push("/Overview");
                });
                
            });            
        } catch (error) {
            console.log(error);
        }
    }    

    onClickSignUp() { 
        try {
            let email = this.state.email;

            this.clearWarnings();

            if (email === '' || this.validateEmail(email) === false) {
                this.setState({signupError: 'Please enter a valid email'});
                return;
            }         

            if (this.validateEmail(email)) {                             
                this.props.sessionActions.signupUser(email, this.props.api.uncommittedUserConfigurations);            
            }            
        
        } catch (error) {
            console.log(error);
        }
    }  

    clearWarnings() {
        this.setState({ signupError: '' });
    }

    isBrowserSupported() {
        const browser = detect();
        console.log(browser.name);
        switch(browser && browser.name) {
            case 'ie':
                return false;           
            
            default: 
                return true;
        }
    }
        
    validateEmail(email) {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {  
                
        if (this.props.session.showSignupModal === false)
            return <React.Fragment></React.Fragment>;

        if (!this.state.alreadyMember) {
            return ( 
                <ModalStyled isOpen={true} onKeyDown={(e) => this.onKeyPressed(e)} >               
                    <ModalBodyStyled> 
                        <div className="signup-modal">
                            <Img src="images/HKmeLogo.png" alt="RenTech" />
                            {/* <CloseModal onClick={this.closeModal}>X</CloseModal> */}
                            <FormGroup> 
                                <EmailLabel>Login</EmailLabel>                                                                    
                                {this.state.signupError !== '' ? <div className="text-center font-italic">{this.state.signupError}</div> : ''}
                                {this.props.session.signupFailed === true ? <div className="text-center font-italic">Email already in use!</div> : ''}
                                <TextInputPrimitiveStyled type="email" id="emailSignup" placeholder="login" onChange={this.emailOnChange} />                                                                                                                     
                                <ButtonStyled color="primary" onClick={this.onClickSignUp}>Sign Up!</ButtonStyled>
                                <Link onClick={this.requestPasswordReset}>New User/Password Reset</Link>   
                                {this.state.browserSupported === false && 
                                    <React.Fragment>
                                        <PleaseUseLabel>Please use Microsoft Edge or Google Chrome</PleaseUseLabel>
                                        <NotCompatibleLabel>HKME is not compatible with Internet Explorer</NotCompatibleLabel>
                                    </React.Fragment>
                                }
                            </FormGroup>                                                         
                        </div>
                    </ModalBodyStyled>                
                </ModalStyled>                               
            );
        }   

        return ( 
            <ModalStyled isOpen={true} onKeyDown={(e) => this.onKeyPressed(e)}>               
                <ModalBodyStyled>
                    <div className="signup-modal">
                        <Img src="images/HKmeLogo.png" alt="RenTech" />
                        {/* <CloseModal onClick={this.closeModal}>X</CloseModal> */}
                        <FormGroup>  
                            <EmailLabel>Login</EmailLabel>   
                            {this.props.session.loginFailed === true ? <div className="text-center font-italic">Login Failed! Incorrect login/password</div> : ''}
                            <TextInputPrimitiveStyled type="email" id="emailSignup" placeholder="login" onChange={this.emailOnChange}/>                             
                            <TextInputPrimitiveStyled type="password" id="password1" placeholder="password" onChange={this.passwordOnChange}/>                                                        
                            <ButtonStyled color="primary" onClick={this.onClickLogin}>Login</ButtonStyled>
                            <Link onClick={this.requestPasswordReset}>New User/Password Reset</Link>   
                         
                            {this.state.browserSupported === false && 
                                <React.Fragment>
                                    <PleaseUseLabel>Please use Microsoft Edge or Google Chrome</PleaseUseLabel>
                                    <NotCompatibleLabel>HKME is not compatible with Internet Explorer</NotCompatibleLabel>
                                </React.Fragment>
                            }
                        </FormGroup>             
                    </div>
                </ModalBodyStyled>                
            </ModalStyled>                               
            );
        }
    }

    function mapStateToProps(state, ownProps) {    
        return {
            session: state.session,
            api: state.api,
            quoteOrderCommon: state.quoteOrderCommon,
        };
    }
  
    function mapDispatchToProps(dispatch) {
        return {
            sessionActions: bindActionCreators(sessionActions, dispatch),
            apiActions: bindActionCreators(apiActions, dispatch),
            quoteOrderCommonActions: bindActionCreators( quoteOrderCommonActions, dispatch),
        };
    }    

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpModal));