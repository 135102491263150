import React from 'react';
import PropTypes from 'prop-types';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';

const ListFilter = React.memo(props => {  
    let value = props.defaultValue ? props.defaultValue : '';
    return(        
        <TextInputPrimitive defaultValue={value} placeholder="Search" id="filter" name="quoteFilter" onBlur={(e) => props.onFilter(e)} />                         
    );
});

export default ListFilter;

ListFilter.propTypes = {    
    onFilter: PropTypes.func.isRequired,
    defaultValue: PropTypes.string    
};