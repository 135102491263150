import React from 'react';
import SelectOptionsPrimitive from './SelectOptionsPrimitive';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import * as constants from '../../../modules/constants';


const SelectPrimitive = React.memo(({id, onChange, selectableValues, placeHolder, defaultValue, ...props}) => {        
    let options = SelectOptionArray(selectableValues);

    return (
        
            <Input 
                type="select"
                id={id} 
                name={id}
                onChange={onChange}
                defaultValue={defaultValue}
                {...props} >
                <option value={constants.PLACEHOLDER}>{placeHolder}</option>                                                           
                <SelectOptionsPrimitive options={options} />                                                                                                                                                           
            </Input> 
                                                                
    );       
});

const SelectOptionArray = (arr) => {
    let options = [];       
    
    if (arr) {
        for (var i = 0; i < arr.length; i++) {
            let opt = {
              Value: arr[i][Object.keys(arr[i])[0]].toString(),
              Caption: arr[i][Object.keys(arr[i])[1]].toString()
            }
      
            options.push(opt);
        }
    }     
        
    return options;
}

export default SelectPrimitive;

SelectPrimitive.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectableValues: PropTypes.array.isRequired,
    placeHolder: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool
};