import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label } from 'reactstrap';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';

export const RowStyled = styled(Row)`
    margin-bottom: 1em;
`;
export const LabelStyled = styled(Label)`
    color: red;
`;

export const AcceptColStyled = styled(Col)`
    margin-right: 50px !important;
`;

export const RejectButtonStyled = styled(Button)`
&.order-cancel-reject-btn {
    width: 150px !important;
    background-color: #e2231a !important;
    border: none !important;
}
`;

export const AcceptButtonStyled = styled(Button)`
&.order-cancel-accept-btn {
    width: 150px !important;
    background-color: #28a745 !important;
    opacity: 0.8 !important;
    border: none !important;
    margin-right: 50px !important;
}
`;

class OrderManageCancelRequest extends React.Component {   
    constructor(props) {
        super(props);    
        
        this.rejectMessageChanged = this.rejectMessageChanged.bind(this);  
        this.onOrderCancelReject = this.onOrderCancelReject.bind(this);
        
        this.state = {
            rejectMessage: '',
            showEnterCommentWarning: false
        }      
    }

    rejectMessageChanged(event) {     
        this.setState({ rejectMessage: event.target.value });
        if (event.target.value) 
            this.setState({ showEnterCommentWarning: false });
    }

    onOrderCancelReject(comment) {
        if (!this.state.showEnterCommentWarning && comment) {
            this.props.onOrderCancelReject(comment);       
        } else {
            this.setState({ showEnterCommentWarning: true });
        }
    }

    render() {
        return (           
            <Container>
                <RowStyled>
                    <AcceptColStyled  xl="1" className="d-none d-sm-block" >
                        <AcceptButtonStyled className="order-cancel-accept-btn d-block btn" displayText="Accept Cancellation" onClick={(e) => this.props.onOrderCancelAccept(e)} />
                    </AcceptColStyled>
                    <Col xl="1" className="d-none d-sm-block">
                        <RejectButtonStyled className="order-cancel-reject-btn d-block btn" displayText="Reject Cancellation" onClick={() => this.onOrderCancelReject(this.state.rejectMessage)} />
                    </Col>
                </RowStyled>
                <RowStyled>
                    <Col>
                        <TextInputPrimitive id="rejectComment" placeholder="Enter reason for rejecting cancellation" onChange={(e) => this.rejectMessageChanged(e)} /> 
                        {this.state.showEnterCommentWarning === true && <LabelStyled>Must enter comment when rejecting cancellation request</LabelStyled>}
                    </Col>
                </RowStyled>
            </Container>
        );
    }
}

export default OrderManageCancelRequest;

OrderManageCancelRequest.propTypes = {
    onOrderCancelAccept: PropTypes.func.isRequired,
    onOrderCancelReject: PropTypes.func.isRequired
}
