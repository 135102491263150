import axios from 'axios';
import WebUI from '../api/WebUI';
import ErrorHandler from '../modules/errorHandler';
import * as types from './actionTypes';
import * as alerts from './alertActions';
import * as session from './sessionActions';
import * as constants from '../modules/constants';

var _webUi;
var _requiredHeaders;
var _devMode = true;

export const runConfigurator = (product, configurationId, isReconfigure, sourceConfigurationId, sourceHeaderId, integrationParams = []) => {        
    return dispatch => {        
        dispatch(session.showSubmitConfigurationButton(false)); 
        dispatch(setSelectedProduct(product));        
        dispatch(saveConfigurationGUID(isReconfigure === true ? sourceConfigurationId : configurationId));        
        
        return dispatch(prepareForInteractiveConfiguration(configurationId, isReconfigure, sourceConfigurationId, sourceHeaderId, integrationParams ))
        .then(res => {
            //if (!res.data) throw Error(res.statusText);  
                console.log(res);  
            
                dispatch(prepareInteractiveSuccess());

                dispatch(initializeConfiguration(isReconfigure === true ? sourceConfigurationId : configurationId))
                .then(res => {
                    dispatch(getUi(res.data.d))
                .catch(error => {
                    console.log(error);
                });               
            });
        }).catch(error => {
            dispatch(alerts.showFailureAlert("Configurator failed to initalize. Please check your production configuration.")); 
            console.log(error);
           
        });
    }
}

// export const getProducts = (company, username) => {
//     return dispatch => {                 
//         return axios.post('/api/v1/products/companyProducts', {company: company,username: username})
//         .then(res => { 
//             if (res.status !== 200) throw Error(res.statusText);                                                       

//             if (res.data.success === true && res.data.result && res.data.result.length > 0) {                          
//                 dispatch(getProductsSucess(res.data.result[0]));                 
//             } else {                
//                 dispatch(alerts.showFailureAlert("failed to get product: " + res.data.error));                    
//             }                               
//         }).catch(error => {
//             console.log(error);
//         }); 
//     }
// }

export const getCompanyProducts = (company) => {
    return dispatch => {
        return axios.post('/api/v1/products/companyProducts', {company: company})
        .then(res => { 
            if (res.status !== 200) throw Error(res.statusText);                                                       

            if (res.data.success === true) {                
                dispatch(getCompanyProductsSucess(res.data.result));
            } else {                
                dispatch(alerts.showFailureAlert(res.data.error));                    
            }                               
        }).catch(error => {
            console.log(error);
        }); 
    }    
}

export const prepareForInteractiveConfiguration = (configurationId, isReconfigure, sourceConfigurationId, sourceHeaderId, integrationParams = []) => {   
    return dispatch => {         
        let postBody = _webUi.GetPrepareForInteractiveConfigurationRequestBody(configurationId, isReconfigure, sourceConfigurationId, sourceHeaderId, integrationParams);        
        let postUrl = _webUi.GetPrepareForInteractiveConfigurationEndpoint();    
        //window.location.href.includes('https://')
        if (window.location.href.includes('https://') || _devMode === true) {
          //  let body;
           // body = pako.deflate(JSON.stringify(postBody));
            return axios.post('/api/v1/products/prepareForInteractiveConfiguration', {postUrl: postUrl, postBody: postBody})
            .then(res => { 
               // if (res.status !== 200) throw Error(res.statusText);   
                console.log(res.data);                                                                                          
            }).catch(error => {
                console.log(error);
            }); 
        }
        else {           
            return axios.post(postUrl, postBody, _requiredHeaders)
            .catch(error => {
                console.log(error);
            });
        }                                          
    }
}

function stringToUint(string) {
    var string = btoa(unescape(encodeURIComponent(string))),
        charList = string.split(''),
        uintArray = [];
    for (var i = 0; i < charList.length; i++) {
        uintArray.push(charList[i].charCodeAt(0));
    }
    return new Uint8Array(uintArray);
}

export const initializeConfiguration = (configurationId) => {
    return dispatch => {
        let postBody = _webUi.GetInitializeConfigurationRequestBody(configurationId);
        let postUrl = _webUi.GetInitializeConfigurationEndPoint();  
        if (window.location.href.includes('https://') || _devMode === true) {
            return axios.post('/api/v1/products/prepareForInteractiveConfiguration', {postUrl: postUrl, postBody: postBody})
            .then(res => { 
               // if (res.status !== 200) throw Error(res.statusText);   
                console.log(res.data.d); 
                dispatch(initializeSuccess(res.data.d));    
                
                return res;                                                                                         
            }).catch(error => {
                dispatch(alerts.showFailureAlert(error));                    
                console.log(error);
            }); 
        }
        else {
        return axios.post(postUrl, postBody, _requiredHeaders)
            .then(res => { 
                if (res.status !== 200) throw Error(res.statusText);    

                dispatch(initializeSuccess(res.data.d));    
                
                return res;
            })
            .catch(error => {
                dispatch(initializeFailure());               
            });   
        }     
    }
}

export const getUi = (sessionId) => {   
    return dispatch => {
        let postBody = _webUi.GetConfigureRequestBody(sessionId);
        let postUrl = _webUi.GetConfigureEndpoint(); 
       
        if (window.location.href.includes('https://') || _devMode === true) {
            return axios.post('/api/v1/products/prepareForInteractiveConfiguration', {postUrl: postUrl, postBody: postBody})
            .then(res => { 
                if (res.status !== 200) {
                    dispatch(alerts.showFailureAlert(res.statusText));
                    throw Error(res.statusText);                       
                }
               console.log(res);  
               dispatch(getUiConfigSuccess(res.data));                          
               dispatch(session.showSubmitConfigurationButton(res.data.d.IsExecutionComplete));                                           
            }).catch(error => {
                dispatch(alerts.showFailureAlert(error.response.data.result));
                ErrorHandler.logError(error.response.data.result);
            }); 
        }
        else {
            return axios.post(postUrl, postBody, _requiredHeaders) 
            .then(res => { 
               // if (res.status !== 200) throw Error(res.statusText);

                // if (res.data.d.Messages.length > 0) {  
                //     if (res.data.d.Messages[0].Type !== constants.MESSAGE_RULE_TYPES.Critical)    
                //         dispatch(alerts.showSuccessAlert(res.data.d.Messages[0].Value));
                //     else
                //         dispatch(alerts.showFailureAlert(res.data.d.Messages[0].Value));                
                // } 

                // if (res.data.d.Messages.length > 0 && res.data.d.Messages[0].Type !== constants.MESSAGE_RULE_TYPES.Critical) {
                //     dispatch(getUiConfigSuccess(res.data));                          
                //     dispatch(session.showSubmitConfigurationButton(res.data.d.IsExecutionComplete));                
                // } else {
                    dispatch(getUiConfigSuccess(res.data));                          
                    dispatch(session.showSubmitConfigurationButton(res.data.d.IsExecutionComplete));
                //}
                
            })
            .catch(error => {            
                console.log(error);                               
            });   
        }        
    }
}

export const configure = (sessionId, id, value, reloadUi = true) => {
    return dispatch => {       
        if (!value) value = null; 
        let postBody = _webUi.GetConfigureRequestBody(sessionId, id, value);    
        let postUrl = _webUi.GetConfigureEndpoint(); 

        if (window.location.href.includes('https://') || _devMode === true) {
            return axios.post('/api/v1/products/prepareForInteractiveConfiguration', {postUrl: postUrl, postBody: postBody})
            .then(res => { 
                if (res.status !== 200 || res.data.success === false) {
                    dispatch(alerts.showFailureAlert('Error please restart the configuration: ' + res.data.result));
                    dispatch(logError(res.data.result, 'API Actions Configure'));
                    return;
                    //throw Error(res.statusText);                       
                }
                console.log(res.data.d); 
                if (reloadUi === true) {
                    dispatch(getUiConfigSuccess(res.data));
                    dispatch(session.setActiveTab(res.data.d.Pages.length - 1));                                    
                    dispatch(session.showSubmitConfigurationButton(res.data.d.IsExecutionComplete));                
                }                                                        
            }).catch(error => {
                console.log(error);
                dispatch(alerts.showFailureAlert('Server error configuration failed to save.'));
                // if (error.status === 500) {
                //     dispatch(alerts.showFailureAlert('error'));       
                // } else {
                //     dispatch(alerts.showFailureAlert(error));       
                // }
                            
            }); 
        }
        else {
            return axios.post(postUrl, postBody, _requiredHeaders)
            .then(res => { 
                if (res.status !== 200)  {
                    console.log(res);
                    throw Error(res.statusText);
                }

                // if (res.data.d.Messages.length > 0) {    
                //     if (res.data.d.Messages[0].Type !== constants.MESSAGE_RULE_TYPES.Critical)    
                //         dispatch(alerts.showSuccessAlert(res.data.d.Messages[0].Value));
                //     else
                //         dispatch(alerts.showFailureAlert(res.data.d.Messages[0].Value));                            
                // }

                //if (res.data.d.Messages.length > 0 && res.data.d.Messages[0].Type === constants.MESSAGE_RULE_TYPES.Critical) reloadUi = false;

                if (reloadUi === true) {
                    dispatch(getUiConfigSuccess(res.data));
                    dispatch(session.setActiveTab(res.data.d.Pages.length - 1));                                    
                    dispatch(session.showSubmitConfigurationButton(res.data.d.IsExecutionComplete));                
                }
                    
            }).catch(error => {    
                let msg = error.response.data.Message;                       
                dispatch(alerts.showFailureAlert(`Error, please restart the configuration. ${msg} `));
                console.log(error);              
            });   
        }     
    }
}

export const finishInteractiveConfiguration = (sessionId, mode = constants.CONFIGURATOR_MODES.default) => {
    return dispatch => {        
        let postUrl = _webUi.GetFinishInteractiveRequestEndpoint();           
        let postBody = _webUi.GetFinishInteractiveRequestBody(sessionId);
        
        if (window.location.href.includes('https://') || _devMode === true) {
            return axios.post('/api/v1/products/prepareForInteractiveConfiguration', {postUrl: postUrl, postBody: postBody})
            .then(res => { 
                if (res.data.d !== null) {                  
                    dispatch(alerts.showFailureAlert("Configuration failed to save."));              
                } else {                
                    dispatch(finishConfigSuccess(true));   
                    dispatch(session.showSubmitConfigurationButton(false));                                        
                }                                                        
            }).catch(error => {
                console.log(error);
            }); 
        }
        else {
            return axios.post(postUrl, postBody, _requiredHeaders)
            .then(res => { 
                if (res.data.d !== null) {                  
                    dispatch(alerts.showFailureAlert("Configuration failed to save."));              
                } else {                
                    dispatch(finishConfigSuccess(true));   
                    dispatch(session.showSubmitConfigurationButton(false));                                        
                }            
            }).catch(error => {
                console.log(error);
            });     
        }   
    }
}

export const saveConfiguration = (username, headerId, configurationId, imageUrl, productId) => {
    return dispatch => {
        return axios.post('/api/v1/config/saveConfiguration', { username: username, headerId: headerId, configurationId: configurationId, imageUrl: imageUrl, productId: productId })
            .then(res => {                
                if (res.status !== 200) {                   
                    dispatch(alerts.showFailureAlert(res.data.error));
                }                                                                                                      
            }).catch(function(error) {
                console.log(error);              
            });
    }
}

export const deleteConfiguration = (username, configurationId, headerId) => {
    return dispatch => {
        return axios.post('/api/v1/config/deleteConfiguration', { configurationId: configurationId, headerId: headerId, username: username })
            .then(res => {                
                if (res.status !== 200) {
                    dispatch(alerts.showFailureAlert("Failed to delete record"));       
                } else {
                    dispatch(getUserConfigurations(username));
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                
            });
    }
}

// export const deleteProductConfiguration = (id, username) => {
//     return dispatch => {
//         return axios.post('/api/deleteProductConfiguration', { id: id })
//             .then(res => {                
//                 if (res.status !== 200) {
//                     dispatch(alerts.showFailureAlert("Failed to delete record"));       
//                 } else {
//                     dispatch(getProductConfigurations(username));
//                 }                                                                                                       
//             }).catch(function(error) {
//                 console.log(error);                
//             });
//     }
// }

export const getUserConfigurations = (username) => {
    return dispatch => {
        return axios.post('/api/v1/config/getConfigurations', { username: username })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(getUserConfigurationsSuccess(res.data.result));
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                               
            });
    }
}

export const logError = (error, info) => {
    return dispatch => {
        return axios.post('/api/v1/administration/logError', { error: error, info: info })
            .then(res => {                
                console.log(error);                                                                                                 
            }).catch(function(err) {
                console.log(err);                              
            });
    }
}

export const getProductConfigurations = (company) => {
    return dispatch => {
        return axios.post('/api/v1/products/companyProducts', { company: company })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(getProductConfigurationsSuccess(res.data.result));
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                              
            });
    }
}

export const saveProduct = (server, name, instance, application, headerId, profile, ruleset, namespace, username, company) => {
    return dispatch => {
        return axios.post('/api/v1/products/saveProduct', { server: server, name: name, instance: instance, application: application, headerId: headerId, profile, ruleset: ruleset, namespace: namespace, username: username, company: company})
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(getProductConfigurations(username));
                    dispatch(alerts.showSuccessAlert("Product saved successfully!")); 
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                               
            });
    }
}
export const editProduct = (server, name, instance, application, headerId, profile, ruleset, namespace, username, id, company) => {
    return dispatch => {
        return axios.post('/api/v1/products/updateProduct', { server: server, name: name, instance: instance, application: application, headerId: headerId, profile, ruleset: ruleset, namespace: namespace, id: id})
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(getUserActiveProduct(username, company));
                   // dispatch(getProductConfigurations(username));
                    dispatch(alerts.showSuccessAlert("Product updated successfully!")); 
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                              
            });
    }
}

export const setActiveProduct = (username, productId, company) => {
    return dispatch => {
        return axios.post('/api/v1/products/activeProduct', { username: username, productId: productId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(setActiveProductSuccess(productId));
                    dispatch(getProductConfigurations(company));
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                            
            });
    }
}

export const getUserActiveProduct = (username, company) => {
    return dispatch => {
        return axios.post('/api/v1/products/getUserActiveProduct', { username: username, company: company })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {                    
                    dispatch(getUserActiveProductSuccess(res.data.result[0].ID));
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                            
            });
    }
}

export const deleteUserProduct = (username, productId) => {
    return dispatch => {
        return axios.post('/api/v1/products/deleteUserProduct', { username: username, productId: productId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(getProductConfigurations(username));
                }                                                                                                       
            }).catch(function(error) {
                console.log(error);                          
            });
    }
}

export function setSelectedProduct(product) {
    if (!product) return;
            
    _webUi = new WebUI(product.Server, product.ApplicationInstance, product.ApplicationName, product.HeaderId, product.Ruleset, product.Namespace, product.Profile); 
    _requiredHeaders = _webUi.GetRequiredHeaders();

    return { type: types.SET_SELECTED_PRODUCT, product };
}

export const commitUncommittedScreenOptions = (sessionId, uncommittedScreenOptions) => {
    return dispatch => {      
        if (!uncommittedScreenOptions) return;

        uncommittedScreenOptions.forEach(function(i, index, screenOption) {
            let reloadUi = false;

            if (index === uncommittedScreenOptions.length - 1) reloadUi = true;
            
            dispatch(configure(sessionId, screenOption[index].id, screenOption[index].value, reloadUi));            
        }); 

        if (uncommittedScreenOptions.length > 0) dispatch(clearUncommittedScreenOptions());
    }
}

export const commitUncommittedUserConfigurations = (username, uncommittedUserConfigurations) => {
    return dispatch => {      
        if (!uncommittedUserConfigurations) return;

        uncommittedUserConfigurations.forEach(function(i, index, configuration) {            
            dispatch(saveConfiguration(username, configuration[index].headerId, configuration[index].configurationId, configuration[index].imageUrl, configuration[index].productId));            
        }); 

        if (uncommittedUserConfigurations.length > 0) dispatch(clearUncommittedUserConfigurations());        
    }
}

export  function reloadConfigurator() {
    return { type: types.RELOAD_CONFIGURATOR };
}

export function getCompanyProductsSucess(products) {
    return { type: types.GET_COMPANY_PRODUCTS_SUCCESS, products };
}

export function clearUncommittedUserConfigurations() {
    return { type: types.CLEAR_UNCOMMITTED_USER_CONFIGURATIONS };
}

export function storeUncommittedScreenOption(screenOptionArray) {
    return { type: types.STORE_UNCOMMITTED_SCREEN_OPTION, screenOptionArray };
}

export function removeUncommittedScreenOption(id) {
    return { type: types.REMOVE_UNCOMMITTED_SCREEN_OPTION, id };
}

export function clearUncommittedScreenOptions() {
    return { type: types.CLEAR_UNCOMMITTED_SCREEN_OPTIONS };
}

export function saveConfigurationGUID(configurationGUID) {
    return { type: types.SAVE_CONFIGURATION_GUID, configurationGUID};
}

export function prepareInteractiveSuccess() {    
    return { type: types.PREPARE_INTERACTIVE_SUCCESS };
}

export function initializeSuccess(sessionId) {
    return { type: types.INITIALIZE_SUCCESS, sessionId };
}

export function initializeFailure() {
    return { type: types.INITIALIZE_FAILURE };
}

export function finishConfigSuccess(finishSuccess) {
    return { type: types.FINISH_CONFIG_SUCCESS, finishSuccess };
}

export function getUiConfigSuccess(ui) {
    return { type: types.GET_UI_CONFIG_SUCCESS, ui };
}

// export function getProductsSucess(products) {
//     return { type: types.GET_PRODUCTS_SUCCESS, products };
// }

export function createUser(username) {
    return { type: types.CREATE_USER, username };
}

export function continueButtonSetup(continueButtonValues) {
    return { type: types.CONTINUE_BUTTON_SETUP, continueButtonValues};
}

export function getUserConfigurationsSuccess(userConfigurations) {
    return { type: types.GET_CONFIGURATIONS, userConfigurations };
}

export function getProductConfigurationsSuccess(productConfigurations) {
    return { type: types.GET_PRODUCT_CONFIGURATIONS, productConfigurations };
}

export function setAutoContinueMode(mode) {
    return { type: types.SET_AUTOCONTINUE_MODE, mode };
}

export function showContinueButton() {
    return { type: types.SHOW_CONTINUE_BUTTON };
}

export function hideContinueButton() {
    return { type: types.HIDE_CONTINUE_BUTTON };
}

export function storeUncommittedUserConfigurations(uncommittedUserConfigurations) {
    return { type: types.STORE_UNCOMMITTED_USER_CONFIGURATIONS, uncommittedUserConfigurations };
}

export function getUserActiveProductSuccess(activeProductId) {
    return { type: types.GET_USER_ACTIVE_PRODUCT_SUCCESS, activeProductId };
}

export function setActiveProductSuccess(activeProductId) {
    return { type: types.SET_ACTIVE_PRODUCT_SUCCESS, activeProductId };
}

export function pauseRuleExecution(pauseExecution) {
    return { type: types.PAUSE_EXECUTION, pauseExecution };
}

export function clearConfigurator() {
    return { type: types.CLEAR_CONFIGURATOR };
}

export function lastScreenOptionModified(id) {
    return { type: types.LAST_SCREEN_OPTION_MODIFIED, id };
}

