import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScreenOptions from '../ScreenOptions/ScreenOptions';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import ContinueButton from '../Common/CPQ/ContinueButton';

export const H1 = styled.h1`
    padding-top: .5em;
    padding-bottom: .5em;   
`;

export const RowStyled = styled(Row)`
    margin-top: .25em !important;
    margin-bottom: .25em !important;

`;

const Screens = React.memo(props => {         
    return(
        <Container>
            {props.screens.map(screen =>                
                <RowStyled className={screen.IsVisible === false ? 'd-none' : 'Screen'}>                
                    <Col>                        
                        {screen.ShowDescription === true && 
                            <ContinueButton />                           
                            // <div onClick={(e) => props.onContinueClicked(e)} dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(screen.Description)}} />                            
                        }             
                        <ScreenOptions screenOptions={screen.ScreenOptions} />                                                         
                    </Col>                                                              
                </RowStyled>                                 
            )}             
        </Container>
    );            
});

export default Screens;

Screens.propTypes = {
    screens: PropTypes.array.isRequired
};