import React from 'react';
import PropTypes from 'prop-types';
import SelectPrimitive from '../Primitives/SelectPrimitive';
import InputLabel from '../InputLabel/InputLabel';
import styled from 'styled-components';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    background-color:#f3f3f3 !important;

    @media (min-width: 1201px) {
        width: 70% !important;  
    }    
`;

const SelectWithLabel = React.memo(({id, caption, required, ...props}) => {      
    return (
        <React.Fragment>
            <InputLabel htmlFor={id} caption={caption} isRequired={required} />                  

            <SelectPrimitiveStyled 
                id={id}
                required={required}
                {...props} />                                                                                                                                                           
        </React.Fragment>
    );       
});

export default SelectWithLabel;

SelectWithLabel.propTypes = {
    id: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string.isRequired,
    selectableValues: PropTypes.array.isRequired,
    autoFocus: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
};

SelectWithLabel.defaultProps = { 
    required: false    
}
