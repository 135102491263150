import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';

export const PStyled = styled.p`
    font-size: 14px;
`;

export const Img = styled.img`
    width: 200px;
`;

const poweredBy = () => {             
    return (     
        <React.Fragment>
        <PStyled className="poweredBy">Powered By &nbsp;&nbsp;&nbsp;            
        </PStyled>
        
        <p className="poweredBy">
        <a href="https://renaissancetech.com/">
            <Img src="/images/RenTechEmail.png" alt="renaissancetech" />
        </a>
        </p>
        </React.Fragment>       
    );      
};

export default poweredBy;