import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import formatter from '../../modules/formatter';
import * as constants from '../../modules/constants';
import Icon from '../QuoteOrderCommon/Icon';
import Button from '../Common/Button/Button';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';

export const ImgStyled = styled.img`
    max-height: 90px;
    position: absolute;
    top: -30px;
    left: 50px;
   
    @media (max-width: 768px) {       
        max-height: 75px;
        top: 0px;
    }
`;

export const RowTotalStyled = styled(Row)`
   border-top: 1px solid #4f4f4f; 
   margin-bottom: .5em;

`;

export const ColStyled = styled(Col)`
    font-weight: bold;
    padding: 0 !important;
    text-align: center;

    @media(max-width: 1200px) {
        margin-right: 15px;
    }    
`;

export const SummaryLabel = styled.span`
    width: 150px;
    font-weight: bold;
    margin-right: 15px;   
`;

export const RowStyled = styled(Row)`

    margin-top: .25em;   
`;

export const SummaryRowStyled = styled(Row)`
    
    margin-top: 1em;    
    margin-bottom: 1em; 
    border-bottom: 1px solid lightgray;
`;

export const RowLineStyled = styled(Row)`
    
    padding-top: 35px;
    padding-bottom: 35px;

    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }

    :hover button, :hover .icon-line { 
        visibility: visible !important;               
    }
`;


export const TextInputPrimitiveStyled = styled(TextInputPrimitive)`
    width: 50px;
`;

export const Wrapper = styled.div`
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 1% !important;
    padding-right: 1% !important;
`;


const Summary = props => {
    let totalPrice = 0;
    let totalQuantity = 0;
    
    return (
        <Wrapper>
            <Col><h1>Summary</h1></Col>

            <SummaryRowStyled>
                <Col xl="2"><SummaryLabel>Job Name:</SummaryLabel>{props.description}</Col>                
                <Col xl="2"><SummaryLabel>Status:</SummaryLabel>{props.statusName}</Col>                
                {/* <Col xl="2"><SummaryLabel>Company:</SummaryLabel>{props.company}</Col>                 */}
            </SummaryRowStyled>

            <RowStyled>
                <ColStyled xl="1" xs="1">Line #</ColStyled>
                <ColStyled xl="2" className="d-none d-sm-block"></ColStyled>                                
                <ColStyled xl="2" xs="2">Part</ColStyled>
                <ColStyled xl="1" xs="2">Quantity</ColStyled>     
                <ColStyled xl="1" xs="2">Unit Price</ColStyled>           
                <ColStyled xl="1" xs="2">Total Price</ColStyled>                              
            </RowStyled>



            {       
                (props.lines && props.lines.length  > 0) &&                     
               props.lines.map(line => {

               totalPrice = totalPrice + line.price * line.quantity;
               totalQuantity = totalQuantity + line.quantity;
                    return (                  
                        <RowLineStyled key={line.id}>
                            <ColStyled xl="1" xs="1">{line.lineNumber}</ColStyled>
                            { line.imgUrl 
                            ? <ColStyled xl="2" className="hideOnMobile"><ImgStyled src={line.imgUrl} alt="No Preview" /></ColStyled> 
                            : <ColStyled xl="2" className="hideOnMobile"></ColStyled> }
                            <ColStyled xl="2" xs="2">{line.productName}</ColStyled>
                            <ColStyled xl="1" xs="2"><TextInputPrimitiveStyled id={line.id.toString()} disabled={true} type="number" defaultValue={line.quantity.toString()} onBlur={(e) => props.onQuantityChange(e, line.id)} /></ColStyled>                                                        
                            <ColStyled xl="1" xs="2">${formatter.currency(line.price)}</ColStyled>
                            <ColStyled xl="1" xs="2"><div>${formatter.currency(line.price * line.quantity)}</div></ColStyled>                          
                        </RowLineStyled>                                                            
                    );
               })             
           }
            <RowTotalStyled>
                <ColStyled xl="1" xs="3">Total</ColStyled>   
                <ColStyled xl="4" xs="2" className="d-none d-sm-block"></ColStyled>                                        
                <ColStyled xl="1" xs="2">{totalQuantity}</ColStyled>     
                <ColStyled xl="1" xs="2"></ColStyled>           
                <ColStyled xl="1" xs="2">${formatter.currency(totalPrice)}</ColStyled>
           </RowTotalStyled>
        </Wrapper>
    );
};

Summary.propTypes = {
    lines: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,    
    statusName: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired
};

export default Summary;