import React from 'react';
import { Alert } from 'reactstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as alertActions from '../../actions/alertActions';
import styled from 'styled-components';

export const AlertStyled = styled(Alert)`
  button.close {
    height:100%;
    background-color:transparent !important;
    
  }

  button.close span {
    font-size: 35px !important;
    position: relative;
    top: -5px;
  }
  &.alert {
    position: fixed !important;
    width: 100%;
    top: 50px;
    z-index: 999;
    text-align: center;    
  }
`;

export const AlertWrapper = styled.div`
  padding-top:10px;
  padding-bottom:10px;
`;

class Alerts extends React.Component {
  constructor(props) {
    super(props);
   
    this.onDismiss = this.onDismiss.bind(this);    
  }

  onDismiss() {
    this.props.actions.hideAlert();
  }

  render() {
    if (!this.props.alert.showAlert) {
      return(
          <div></div>
      );
  }
    return (
      <AlertStyled color={this.props.alert.alertColor} isOpen={this.props.alert.showAlert} toggle={this.onDismiss}>
        <AlertWrapper>{this.props.alert.alertText}</AlertWrapper>
      </AlertStyled>
    );
  }
} 

function mapStateToProps(state, ownProps) {    
  return {
    alert: state.alert
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(alertActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);