import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as apiActions from '../../actions/apiActions';
import * as sessionActions from '../../actions/sessionActions';
import { withRouter } from 'react-router-dom';
import { Container, Form, FormGroup } from 'reactstrap';
import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import Button from '../Common/Button/Button';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input {width: 100% !important; }
`;

class AddProductConfigurations extends Component {  
    constructor(props, context) {
        super(props, context);    
        
        this.formValueChange = this.formValueChange.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
        this.cancelAdd = this.cancelAdd.bind(this);

        this.state = ({
            server: '',
            name: '',
            instance: '',
            application: '',
            headerId: '',
            profile: '',
            ruleset: '',
            namespace: ''
        });     

        let username = this.props.session.username; 

        if (username) {
            if (this.props.session.username === "" || this.props.session.loggedIn === false)
                this.props.apiActions.getUserConfigurations(username);              
        }           
    }

    formValueChange(event) {
        switch(event.target.id) {
            case "apServer":
                this.setState({ server: event.target.value });  
                break;
                
            case "apName":
                this.setState({ name: event.target.value });
                break;

            case "apInstance":
                this.setState({ instance: event.target.value });
                break;

            case "apApplication":
                this.setState({ application: event.target.value });  
                break;
                
            case "apHeaderId":
                this.setState({ headerId: event.target.value });
                break; 

            case "apProfile":
                this.setState({ profile: event.target.value });
                break;

            case "apRuleset": 
                this.setState({ ruleset: event.target.value });
                break;

            case "apNamespace":
                this.setState({ namespace: event.target.value });
                break; 

            default: 
                break;
        }   
    }

    saveProduct() { 
        this.props.apiActions.saveProduct(this.state.server, this.state.name, this.state.instance, this.state.application, this.state.headerId, this.state.profile, this.state.ruleset, this.state.namespace, this.props.session.username, this.props.session.company);       
        this.props.history.push({
            pathname: '/ProductConfigurations'
        });   
    }

    cancelAdd() {
        this.props.history.push({
            pathname: '/ProductConfigurations'
        }); 
    }

    render() {                
        return (     
        <Container className="add-product-configuration p-3">
            <h1 className="text-center">Add Product</h1>
            <Form>
                <FormGroupStyled>
                    <TextInputWithLabel id="apServer" caption="Server" onBlur={(e) => this.formValueChange(e)} />                     
                    <TextInputWithLabel id="apName" caption="Name" onBlur={(e) => this.formValueChange(e)} />  
                    <TextInputWithLabel id="apInstance" caption="Instance" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel id="apApplication" caption="Application" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel id="apHeaderId" caption="HeaderId" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel id="apProfile" caption="Profile" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel id="apRuleset" caption="Ruleset" onBlur={(e) => this.formValueChange(e)} /> 
                    <TextInputWithLabel id="apNamespace" caption="Namespace" onBlur={(e) => this.formValueChange(e)} /> 
                </FormGroupStyled>
                <div className="text-center">
                    <ButtonStyled onClick={this.saveProduct} displayText="Save" />
                    <ButtonStyled onClick={this.cancelAdd} backgroundColor="#e2231a" displayText="Cancel" />
                </div>
            </Form>
        </Container>           
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      session: state.session,
      api: state.api
    };
  }
  
  function mapDispatchToProps(dispatch) {
      return {
          sessionActions: bindActionCreators(sessionActions, dispatch),
          apiActions: bindActionCreators(apiActions, dispatch)
      };
  }   

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProductConfigurations));