import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as alertActions from '../../actions/alertActions';
import { withRouter } from 'react-router-dom';
import { Container, Jumbotron } from 'reactstrap';
import Button from '../Common/Button/Button';
import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import styled from 'styled-components';
import queryString from 'query-string';

export const ButtonStyled = styled(Button)`
    display: block !important;
`;

export const JumbotronStyled = styled(Jumbotron)`
    background-color: #f5f5f5 !important;
`;

export const TextInputWithLabelStyled = styled(TextInputWithLabel)`
    input { background-color: #FFF !important; }
`;

class RequestPasswordReset extends Component {  

    constructor(props, context) {
        super(props, context);    

        this.submitRequest = this.submitRequest.bind(this);            

        this.state = {
            isLoading: true,
            email: '',  
            storeNumber: ''        
        }
    }

    componentDidMount() {           
        this.props.sessionActions.showSignupModal(false);
        this.setState({ isLoading: false });    
    }

    pushHistory(url) {
        let { history } = this.props;
            history.push({
            pathname: url,
            search: ''
        });
    }

    submitRequest() {        
       if (!this.state.email || !this.state.storeNumber) {
           this.props.alertActions.showFailureAlert("Please enter all fields to submit request");
           return;
       }
       
       this.props.sessionActions.emailPasswordResetRequest(this.state.storeNumber, this.state.email).then(() => {
            this.props.alertActions.showSuccessAlert("Password Reset Request Sent!");
            this.props.sessionActions.showSignupModal(true);
            this.pushHistory("/Login");       
        });
    }   
  
    render() {            
        if (this.state.isLoading) 
            return (<React.Fragment></React.Fragment>);
            
            return (     
            <Container>
                <JumbotronStyled>
                    <h1>Password Reset Request</h1>
                    
                    <TextInputWithLabelStyled id="email" name="email" caption="Email" onBlur={(e) => this.setState({ email: e.target.value })} />                    
                    <TextInputWithLabelStyled id="storeNumber" name="storeNumber" caption="Store Number or Fabricator Name" onBlur={(e) => this.setState({ storeNumber: e.target.value })} />
                    
                    <ButtonStyled displayText="Submit" onClick={this.submitRequest} />
                </JumbotronStyled>          
            </Container>
                
                        
            );
        }
    }

function mapStateToProps(state, ownProps) {    
  return {
    session: state.session,
    api: state.api,
    alert: state.alert
  };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),
        apiActions: bindActionCreators(apiActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
    };
}    

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset));