import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import guid from '../../modules/guid';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as orderActions from '../../actions/orderActions';
//import * as workflowActions from '../../actions/workflowActions';
import * as alertActions from '../../actions/alertActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as adminActions from '../../actions/adminActions';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import { Container, Row, Col } from 'reactstrap';
import * as constants from '../../modules/constants';
import Lines from '../QuoteOrderCommon/Line';
import styled from 'styled-components';
import AddressForm from '../QuoteOrderCommon/AddressForm';
import ContactInfo from '../QuoteOrderCommon/ContactInfo';
import PoNumber from '../QuoteOrderCommon/PoNumber';
import Attachments from '../QuoteOrderCommon/Attachments';
import AttachmentList from '../QuoteOrderCommon/AttachmentList';
import OrderManageCancelRequest from './OrderManageCancelRequest';
import Stages from '../QuoteOrderCommon/Stages';
import "react-datepicker/dist/react-datepicker.css";
import CommunicationList from '../QuoteOrderCommon/CommunicationList';
import HeaderDetails from '../QuoteOrderCommon/HeaderDetails';
import LineActions from '../QuoteOrderCommon/LineActions';
import AcceptOrder from '../Order/AcceptOrder';
import Summary from '../QuoteOrderCommon/Summary';
import * as QuoteOrderCommon from '../../modules/quoteOrderCommon';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loading from '../Common/Loading/Loading';
import AddDesigners from '../QuoteOrderCommon/AddDesigners';

export const ContainerStyled = styled(Container)`    
    @media(min-width:1200px) {
        min-height:440px;
    }
`;

export const DatePickerLabel = styled.label`
    margin-right: 15px;
`;

export const HeaderRowStyled = styled(Row)`
    margin-top: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #EEEEEE;
    height: 70px;

    @media(max-width:1200px) {
        height: 120px;
    }
`;
class Order extends Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();

        this.onConfigureClick = this.onConfigureClick.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.onReconfigure = this.onReconfigure.bind(this);
        this.onLineDelete = this.onLineDelete.bind(this);
        this.onAddressFieldUpdate = this.onAddressFieldUpdate.bind(this);
        this.onContactFieldUpdate = this.onContactFieldUpdate.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onFieldUpdate = this.onFieldUpdate.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.onOrderCancel = this.onOrderCancel.bind(this);
        this.onOrderCancelAccept = this.onOrderCancelAccept.bind(this);
        this.onOrderCancelReject = this.onOrderCancelReject.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onAddCommunicationClick = this.onAddCommunicationClick.bind(this);
        this.onCopyLine = this.onCopyLine.bind(this);
        this.onClickDownloadFile = this.onClickDownloadFile.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.onHeaderFieldUpdate = this.onHeaderFieldUpdate.bind(this);
        this.onEstimatedShipDateSet = this.onEstimatedShipDateSet.bind(this);
        this.onOrderAccepted = this.onOrderAccepted.bind(this);
        this.onOrderRejected = this.onOrderRejected.bind(this);
        this.onRejectMessageChanged = this.onRejectMessageChanged.bind(this);
        this.onCreatedByChanged = this.onCreatedByChanged.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onCommunicationCollapseClicked = this.onCommunicationCollapseClicked.bind(this);
        this.onShipToStoreChanged = this.onShipToStoreChanged.bind(this);
        this.onStageChange = this.onStageChange.bind(this);
        this.onCommunicationPageChange = this.onCommunicationPageChange.bind(this);
        this.onActualShipDateChanged = this.onActualShipDateChanged.bind(this);
        this.previousStage = this.previousStage.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
        this.orderInvoiced = this.orderInvoiced.bind(this);
        this.shipToCustomer = this.shipToCustomer.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.onfaxTemplateOpen = this.onfaxTemplateOpen.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.onAddCountertopClick = this.onAddCountertopClick.bind(this);
        this.onSetToPendingInformationClick = this.onSetToPendingInformationClick.bind(this);
        this.onSetToDraftClick = this.onSetToDraftClick.bind(this);
        this.poNumberChanged = this.poNumberChanged.bind(this);
        this.updateHeader = this.updateHeader.bind(this);
        //this.deleteAttachment = this.deleteAttachment.bind(this);

        this.state = {
            selectedProduct: '',
            disableAddLine: true,
            onFocusValue: '',            
            updatedRequired: false,
            loading: true,
            status: '',
            accepted: false,
            rejected: false,
            showRejectOption: false,  
            rejectMessage: '',
            estimatedShipDate: '',
            actualShipDate: '',
            createdByUserId: '',
            createdByEmail: '',
            companyLocationId: '',            
            communicationCollapsed: false,            
            allowHomeDelivery: false,
            stage: 1,
            submitButtonLabel: constants.STAGES_BUTTON_LABELS.Next,
            submitButtonColor: '#e2231a',
            checkmarkLabel: '',
            requiredStages: 4,
            shipToStoreTotalRequiredStages: 3,
            shipToCustomerTotalRequiredStages: 4,
            isLocked: false,
            communicationOffset: 0,
            communicationRowsToFetch: 5,
            currentCommunicationPage: 1,
            allowSOSInstall: false,   
            allowedShipToTypes: null            
        };        
    }

    componentDidMount() {     
        if (this.props.order.id) { 
            const promise1 = this.props.apiActions.getCompanyProducts(this.props.session.company);     
            const promise2 = this.getOrderById(this.props.order.id);
            const promise3 = this.props.quoteOrderCommonActions.getUserCompanyLocations(this.props.session.userCompanyLocationIdCSV, this.props.session.username)
            const promise4 = this.props.adminActions.getAllCompanies(this.props.session.manufacturer, constants.GET_ALL_COMPANIES_PARAMS.excludeFabricators, constants.GET_ALL_COMPANIES_PARAMS.excludeInstallers);
            const promise5 = this.props.adminActions.getCompanyLocations(this.props.session.company);
            const promise6 = this.props.quoteOrderCommonActions.getShipToTypes(this.props.session.manufacturer, null, this.props.order.id);
            const promise7 = this.props.adminActions.getAllProducts();
            
            Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7]).then(() => {                   
                let shipToStoreTypeId = this.props.order.shipToTypeId;
                let allowHomeDelivery = false;
                let allowSOSInstall = false;
                let allowedShipToTypes;

                if (this.props.admin.companyLocations) {
                    let locations = this.props.admin.companyLocations.filter(l => l.id == this.props.order.companyLocationId);
                            
                    if (locations.length > 0) {
                        let shipToStoreTypes = this.props.quoteOrderCommon.shipToTypes;  
                        
                        if (shipToStoreTypes)
                            allowedShipToTypes = locations[0].allowHomeDelivery !== true ? shipToStoreTypes.filter(x => x.id !== constants.SHIP_TO_TYPES.Customer) : shipToStoreTypes;
                        else 
                            allowedShipToTypes = [];
                            
                        allowHomeDelivery = locations[0].allowHomeDelivery;  
                        allowSOSInstall = locations[0].allowSOSInstall ? locations[0].allowSOSInstall : false;                   
                    }
                    else {
                        allowedShipToTypes = this.props.quoteOrderCommon.shipToTypes;
                    }
                }

                let isLocked = QuoteOrderCommon.isLocked(this.props.session.roleName, this.props.order.statusTypeId);                                                                

                this.setState({ 
                    status: this.props.order.statusTypeId, 
                    createdByUserId: this.props.order.createdByUserId, 
                    createdByEmail: this.props.order.createdByEmail,
                    companyLocationId: this.props.order.companyLocationId,                    
                    allowHomeDelivery: allowHomeDelivery,                    
                    isLocked: isLocked,
                    estimatedShipDate: this.props.order.estimatedShipDate ? QuoteOrderCommon.convertToDate(this.props.order.estimatedShipDate) : null,
                    actualShipDate: this.props.order.actualShipDate ? QuoteOrderCommon.convertToDate(this.props.order.actualShipDate) : null,
                    accepted: this.props.order.estimatedShipDate ? true : false,
                    allowSOSInstall: allowSOSInstall,  
                    allowedShipToTypes: allowedShipToTypes                  
                });
                
                let shipToCustomer = shipToStoreTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;
                this.setRequiredStages(shipToCustomer);
                                       
                if (!this.props.order.companyLocationId) {
                    if (this.props.quoteOrderCommon.userCompanyLocations.length === 1) {
                        this.props.quoteOrderCommonActions.updateField(this.props.order.id, constants.ORDER_SQL_COLUMNS.CompanyLocationId, this.props.quoteOrderCommon.userCompanyLocations[0].id, constants.TABLES.Order).then(() => {                        
                            this.props.quoteOrderCommonActions.getUsersAssignedToLocation(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                            this.props.orderActions.updateCompanyLocation(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                            this.props.quoteOrderCommonActions.getCompanyLocationFabricator(this.props.quoteOrderCommon.userCompanyLocations[0].id, true);
                            this.props.quoteOrderCommonActions.getCompanyLocationDesigners(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                            let locations = this.props.admin.companyLocations.filter(l => l.id == this.props.order.companyLocationId);
                            
                                if (locations.length > 0) {
                                    let shipToStoreTypes = this.props.quoteOrderCommon.shipToTypes;
                                                        
                                    if (shipToStoreTypes)
                                        allowedShipToTypes = locations[0].allowHomeDelivery !== true ? shipToStoreTypes.filter(x => x.id !== constants.SHIP_TO_TYPES.Customer) : shipToStoreTypes;
                                    else 
                                        allowedShipToTypes = [];

                                    this.setState({allowedShipToTypes: allowedShipToTypes});

                                }
                        });                        
                    }
                } else {
                    this.props.quoteOrderCommonActions.getUsersAssignedToLocation(this.props.order.companyLocationId);
                    this.props.quoteOrderCommonActions.getCompanyLocationDesigners(this.props.order.companyLocationId);
                }    
                
                if (this.props.order.companyLocationId)              
                    this.getDefaultInstaller(this.props.order.companyLocationId);                          
                    
                if (this.props.order.statusTypeId === constants.STATUS_TYPES.InProgressAccepted)
                    this.setState({ stage: constants.QUOTE_STAGES.Submit });

                if (this.props.order.statusTypeId === constants.STATUS_TYPES.Pending)
                    this.setState({ stage: constants.QUOTE_STAGES.Attachments });

                if (this.props.order.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval)
                    this.setState({ showRejectOption: true });

                this.setSubmitButtonText(this.state.stage);

                if (this.props.location.previousPage && this.props.location.previousPage === 'Configurator') {
                    this.onStageChange(constants.QUOTE_STAGES.Line);
                }

                this.props.apiActions.clearConfigurator();
                if (!this.props.order.contact || this.props.order.contact.length === 0)
                    this.props.quoteOrderCommonActions.getContact(null, this.props.order.id);

                if (!this.props.order.attachments || this.props.order.contact.length === 0)
                    this.props.quoteOrderCommonActions.getAttachments(null, this.props.order.id, null);
            });              
          
        }
        else {
            this.props.history.push({
                pathname: '/Overview'             
            });   
        }                  
    }  

    setRequiredStages(shipToCustomer) {
        if (this.props.order.statusTypeId === constants.STATUS_TYPES.Confirmed) {
            this.setState({ requiredStages: this.state.confirmedTotalRequiredStages });
        } else {
            this.setState({ requiredStages: shipToCustomer === false ? this.state.shipToStoreTotalRequiredStages : this.state.shipToCustomerTotalRequiredStages });
        }        
    }

    shipToCustomer(shipToStoreTypeId) {
        return shipToStoreTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;
    }

    poNumberChanged(event) {
        this.updateHeader(event.target.id, event.target.value);   
        
        this.props.orderActions.updatePoNumber(event.target.value);
    }

    orderInvoiced() {        
       // this.updateField(constants.ORDER_SQL_COLUMNS.Invoiced, true);       
        let isLocked = QuoteOrderCommon.isLocked(this.props.session.roleName, this.props.order.statusTypeId);
        
        this.props.history.push({
            pathname: '/Invoice',
            orderId: this.props.order.id,  
            company: this.props.session.company,
            isLocked: isLocked          
          });  
    }

    saveAsDraft() {               
         this.props.history.push({
             pathname: '/Overview'        
           });  
     }
    
    onDeleteAttachment(fileId) {      
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.props.quoteOrderCommonActions.deleteAttachment(fileId, null, this.props.order.id);  
                }
              },
              {
                label: 'No',
                onClick: () => ''
              }
            ]
          });        
    }

    // onDeleteAttachment(fileId) {
    //     this.props.quoteOrderCommonActions.deleteAttachment(fileId, null, this.props.order.id);        
    // }

    previousStage() {
        if (this.state.stage === 1) return;

        this.onStageChange(this.state.stage - 1);
    }

    onStageChange(stage) {                
        this.setState({ stage: stage }, this.setSubmitButtonText(stage));
        
        this.setRequiredStages(this.shipToCustomer(this.props.order.shipToTypeId));
        
        if (stage === constants.QUOTE_STAGES.Address) {
            if (!this.props.order.attachments || this.props.order.attachments.length === 0) {
                this.props.alertActions.showFailureAlert("Warning: if no drawing is uploaded one will need to be faxed in. See attachment section for the document to fax");
            }
        }
    }
    
    onCommunicationCollapseClicked() {
        let collapsed = !this.state.communicationCollapsed;
        this.setState({ communicationCollapsed: collapsed });
    }

    onCreatedByChanged(event) {
        let value = event.target.value === constants.PLACEHOLDER ? null : event.target.value;        
        this.setState({ createdByUserId: value });
        this.updateHeader(event.target.id, value);                   
    }

    statusChange(event) {         
        if (event.target.value.toLowerCase() === constants.PLACEHOLDER) return;

        if (event.target.value) {
            let selectedStatus = this.props.quoteOrderCommon.statusTypes.filter(status => status.id.toString() === event.target.value); 
            //this.runStatusChangeWorkflows(selectedStatus[0].name);
            this.updateOrderStatus(selectedStatus[0].id);   
            this.onAddCommunicationClick(`Order status changed to ${selectedStatus[0].name}.`, false);
            //this.createSystemCommunication(`Order status changed to ${selectedStatus[0].name}.`);

            console.log(selectedStatus);
            
            if (selectedStatus[0].id !== constants.STATUS_TYPES.Draft)
                this.sendEmail(constants.EMAIL_TYPES.StatusChange, selectedStatus[0].name);

            if (selectedStatus[0].id === constants.STATUS_TYPES.QuoteVerificationRequested)
                this.runCommunicationUpdates(`Quote verification requested.`);
        }
    }

    // runStatusChangeWorkflows(status) {
    //     if (this.props.workflows.onOrderStatusChange && this.props.workflows.onOrderStatusChange.length > 0 && this.props.quoteOrderCommon.userCompanyLocations.length > 0) {

    //         this.props.workflows.onOrderStatusChange.forEach(workflow => {
    //             switch(workflow.actionTypeId) {

    //                 case constants.WORKFLOW_ACTION_TYPES.sendEmail:                                              
    //                     this.props.workflowActions.emailStatusUpdate(status, this.props.quoteOrderCommon.userCompanyLocations[0].orderSubmitEmailList, workflow.actionSubject, workflow.actionBody);
    //                     break;                    
    
    //                 default:
    //                     console.log('no matching workflow id');
    //             }
    //         });
    //     }
    // }

    // runOrderCancellationRequestWorkflowsrunOrderCancellationRequestWorkflows(order) {
    //     if (this.props.workflows.onOrderCancellationRequest && this.props.workflows.onOrderCancellationRequest.length > 0) {
            
    //         this.props.workflows.onOrderCancellationRequest.forEach(workflow => {
    //             switch(workflow.actionTypeId) {

    //                 case constants.WORKFLOW_ACTION_TYPES.SendOrderCancelRequestEmail:
    //                     this.props.workflowActions.submitCancellationRequest(order, workflow.actionEmail, workflow.actionSubject, workflow.actionBody);
    //                     break;   
                        
    //                 case constants.WORKFLOW_ACTION_TYPES.UpdateStatusAfterOrderCancelRequest: 
    //                     this.updateOrderStatus(workflow.actionNewStatus);
    //                     break;
    
    //                 default:
    //                     console.log('no matching workflow id');
    //             }
    //         });
    //     }
    // }

    runCommunicationUpdates(message) {
        let locations = this.props.quoteOrderCommon.userCompanyLocations.filter(l => l.id === this.props.order.companyLocationId);
        let sendToEmail = "";
                            
        if (locations.length > 0) {
            sendToEmail = this.props.order.installType === constants.INSTALL_TYPES.SOS ? 
                locations[0].orderSubmitAltEmailList :
                locations[0].orderSubmitEmailList;          
        }

        if (!sendToEmail) 
            alertActions.showFailureAlert("Order updated but failed to email. No send-to email configured for the location");
        else
            this.props.quoteOrderCommonActions.emailQuoteOrder(null, this.props.order.lines, this.props.order.billingAddress, this.props.order.shippingAddress, this.props.order.contact, this.props.order.shipToStore, this.props.order.installType, locations[0].fabricatorName, locations[0].installerName, this.props.order.contact, this.props.order.name, sendToEmail, this.props.order.id);                                  
        
        this.onAddCommunicationClick(message, false);
        //this.createSystemCommunication(message);
    }

    onOrderCancel() {
        //this.runOrderCancellationRequestWorkflows(this.props.order);
        this.updateOrderStatus(constants.STATUS_TYPES.CancellationRequested);

        this.runCommunicationUpdates('A request to cancel the order has been submitted.');

        this.props.alertActions.showSuccessAlert(`A cancellation request has been sent for Order #${this.props.order.id}.`);
        this.props.history.push({ pathname: '/OrderList' });   
    }

    runOrderCancellationAcceptWorkflows(order) {
        if (this.props.workflows.onOrderCancellationAccept && this.props.workflows.onOrderCancellationAccept.length > 0) {
            
            this.props.workflows.onOrderCancellationAccept.forEach(workflow => {
                switch(workflow.actionTypeId) {

                    case constants.WORKFLOW_ACTION_TYPES.SendOrderCancelAcceptEmail:
                        this.props.workflowActions.orderCancellationAccept(order, workflow.actionEmail, workflow.actionSubject, workflow.actionBody);
                        break;   
                        
                    case constants.WORKFLOW_ACTION_TYPES.UpdateStatusAfterOrderCancelAccept: 
                        this.updateOrderStatus(workflow.actionNewStatus);
                        break;
    
                    default:
                        console.log('no matching workflow id');
                }
            });
        }
    }

    onOrderCancelAccept() {
        //this.runOrderCancellationAcceptWorkflows(this.props.order);
        this.updateOrderStatus(constants.STATUS_TYPES.Cancelled);
        this.updateHeader(constants.ORDER_SQL_COLUMNS.IsLocked, false);

        this.runCommunicationUpdates(`The cancellation request has been accepted.`);

        this.props.alertActions.showSuccessAlert(`The cancellation request for Order #${this.props.order.id} has been accepted.`);
        this.props.history.push({ pathname: '/OrderList' });   
    }

    runOrderCancellationRejectWorkflows(order) {
        if (this.props.workflows.onOrderCancellationReject && this.props.workflows.onOrderCancellationReject.length > 0) {
            
            this.props.workflows.onOrderCancellationReject.forEach(workflow => {
                switch(workflow.actionTypeId) {

                    case constants.WORKFLOW_ACTION_TYPES.SendOrderCancelRejectEmail:
                        this.props.workflowActions.orderCancellationReject(order, workflow.actionEmail, workflow.actionSubject, workflow.actionBody);
                        break;   
                        
                    case constants.WORKFLOW_ACTION_TYPES.UpdateStatusAfterOrderCancelReject: 
                        this.updateOrderStatus(workflow.actionNewStatus);
                        break;
    
                    default:
                        console.log('no matching workflow id');
                }
            });
        }
    }    

    onOrderCancelReject(comment) {
        //this.runOrderCancellationRejectWorkflows(this.props.order);
        this.updateOrderStatus(constants.STATUS_TYPES.CancellationRejected);

        this.runCommunicationUpdates(`Cancellation rejected: ${comment}`);
        
        this.props.alertActions.showSuccessAlert(`The cancellation request for Order #${this.props.order.id} has been rejected.`);
        this.props.history.push({ pathname: '/OrderList' });   
    }

    sendEmail(emailType, newStatus, comment) {
        let locations = this.props.admin.companyLocations.filter(l => l.id === this.props.order.companyLocationId);
        let sendToEmail = "";
                            
        if (locations.length > 0) {
            sendToEmail = this.props.order.installType === constants.INSTALL_TYPES.SOS ? 
                locations[0].orderSubmitEmailList :
                locations[0].orderSubmitAltEmailList;          
        }

        if (!sendToEmail) {
            alertActions.showFailureAlert("Email failed to send. No email configured for this location");
            return;
        } else {
            let designerEmail = this.getDesignerEmail();
                       
            sendToEmail = sendToEmail + ', ' + designerEmail;

            console.log(sendToEmail);
            if (emailType === constants.EMAIL_TYPES.QuoteOrder)
                this.props.quoteOrderCommonActions.emailQuoteOrder(this.getEmailDTO(locations, sendToEmail, newStatus));                                                       
            else if (emailType === constants.EMAIL_TYPES.StatusChange)
                this.props.quoteOrderCommonActions.emailStatusChange(this.getEmailDTO(locations, sendToEmail, newStatus));
            else if (emailType === constants.EMAIL_TYPES.QuoteCancel) 
                this.props.quoteOrderCommonActions.emailQuoteCancelled(this.props.quote.id, sendToEmail, newStatus, this.props.order.fabricatorPhone);  
            else if (emailType === constants.EMAIL_TYPES.UserCommunication)
                this.props.quoteOrderCommonActions.emailUserCommunication(this.getEmailDTO(locations, sendToEmail, newStatus));   
            else if (emailType === constants.EMAIL_TYPES.PendingInformation)
                this.props.quoteOrderCommonActions.emailPendingInformation(this.getEmailDTO(locations, sendToEmail, newStatus, comment));                                                     
        }
    }

    // sendEmail(emailType, newStatus) {
    //     let sendToEmail = this.props.order.installType === constants.INSTALL_TYPES.SOS ? 
    //         this.props.quoteOrderCommon.userCompanyLocations[0].orderSubmitEmailList :
    //         this.props.quoteOrderCommon.userCompanyLocations[0].orderSubmitAltEmailList;

    //     if (!sendToEmail) {
    //         alertActions.showFailureAlert("Email failed to send. No email configured for this location");
    //         return;
    //     }

    //     let locations = this.props.admin.companyLocations.filter(l => l.id === this.props.order.companyLocationId);

    //     let designerEmail = this.getDesignerEmail();
            
    //     // if (this.props.quoteOrderCommon.companyLocationUsers && this.props.quoteOrderCommon.companyLocationUsers.length > 0) {
    //     //     designerEmail = this.props.quoteOrderCommon.companyLocationUsers.filter(u => u.id === this.props.order.createdByUserId)[0].username;
    //     // }

    //     sendToEmail = sendToEmail + ', ' + designerEmail;

    //     if (emailType === constants.EMAIL_TYPES.QuoteOrder)
    //         this.props.quoteOrderCommonActions.emailQuoteOrder(this.getEmailDTO(locations, sendToEmail, newStatus));                                                       
    //     else if (emailType === constants.EMAIL_TYPES.StatusChange)
    //         this.props.quoteOrderCommonActions.emailStatusChange(this.getEmailDTO(locations, sendToEmail, newStatus));
    //     else if (emailType === constants.EMAIL_TYPES.UserCommunication)
    //         this.props.quoteOrderCommonActions.emailUserCommunication(this.getEmailDTO(locations, sendToEmail, newStatus));
    // }
    
    onfaxTemplateOpen() {
        this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderFax, "C", "O", this.props.order.id);
    }

    getEmailDTO(locations, sendToEmail, newStatus, comment){   
        let locationName = 'n/a';
        let fabricatorPhone = this.props.order.fabricatorPhone;

        if (this.props.order.companyLocationId)
            if (this.props.admin.companyLocations && this.props.admin.companyLocations.length > 0) {
                locationName = this.props.admin.companyLocations.filter(l => l.id === this.props.order.companyLocationId)[0].name;               
            }

        let status = 'n/a'
        if (this.props.order.statusTypeId && this.props.quoteOrderCommon.statusTypes) {
            status = this.props.quoteOrderCommon.statusTypes.filter(s => s.id === this.props.order.statusTypeId)[0].name;
        }

        var emailData = {
            quoteId: null,
            lines: this.props.order.lines,
            billingAddress: this.props.order.billingAddress[0],
            shippingAddress: this.props.order.shippingAddress[0],
            shipToTypeId: this.props.order.shipToTypeId,
            installType: this.props.order.installType,
            fabricator: locations[0].fabricatorName,
            installer: locations[0].installerName,
            contact: this.props.order.contact,
            description: this.props.order.name,
            sendToEmail: sendToEmail,
            orderId: this.props.order.id,
            locationName: locationName,
            firstName: this.props.order.contact[0].firstName,
            lastName: this.props.order.contact[0].lastName,
            newStatus: newStatus,
            designerEmail: this.getDesignerEmail(),
            comment: comment,
            fabricatorPhone: fabricatorPhone,
            isOrder: true
        }

        return emailData;
    }

    async getOrderById(orderId) {
        await this.props.orderActions.getOrderById(orderId);      
        this.setState({ loading: false });
    } 

    getCommunications() {
        this.props.quoteOrderCommonActions.getCommunications(null, this.props.order.id, this.state.communicationOffset, this.state.communicationRowsToFetch);
    }    

    setSubmitButtonText(stage) {
        let defaultLabel = constants.STAGES_BUTTON_LABELS.Next;
        let submitLabel = constants.STAGES_BUTTON_LABELS.Submit;
        
        submitLabel = this.getSubmitButtonLabel(stage);

        this.setState({ 
            submitButtonLabel: stage > this.state.requiredStages ?  submitLabel : defaultLabel,
            submitButtonColor: stage > this.state.requiredStages ? '#e2231a' : '#e2231a',
            checkmarkLabel: QuoteOrderCommon.getCheckmarkLabel(this.state.status)
        });
    }

    getSubmitButtonLabel(stage) {        
        return stage > this.state.requiredStages ? QuoteOrderCommon.getCheckmarkLabel(this.state.status, this.props.session.roleName) : constants.STAGES_BUTTON_LABELS.Submit;                  
    }

    setRequiredStages(shipToCustomer) {
        this.setState({ requiredStages: shipToCustomer === true ?  this.state.shipToCustomerTotalRequiredStages :  this.state.shipToStoreTotalRequiredStages });
    }

    completedRequiredStages() {
        return (this.state.stage > this.stage.requiredStages);
    }

    onShipToStoreChanged(event) {

        if (event.target.value === constants.PLACEHOLDER) return;
      
        this.setRequiredStages(this.shipToCustomer(this.props.order.shipToTypeId));               
        this.onStageChange(constants.QUOTE_STAGES.Details);
        this.updateHeader(constants.ORDER_SQL_COLUMNS.ShipToStore, event.target.value);
        
        if (event.target.value === constants.SHIP_TO_TYPES.Store) 
            this.setShipAddressToLocationShipTo();

        if (event.target.value.toString() === constants.SHIP_TO_TYPES.Customer.toString()) {
            this.addHomeDeliveryCharge();
        } else {
            this.removeHomeDeliveryCharge();
        }
    }

    addHomeDeliveryCharge() {
        let location =  this.props.admin.companyLocations.filter(l => l.id === this.props.order.companyLocationId); 
        let homeDeliveryProduct = this.props.admin.productList.filter(p => p.Name === constants.HOME_DELIVERY);
        let locationHomeDeliveryCharge = location[0].homeDeliveryCharge ? location[0].homeDeliveryCharge : homeDeliveryProduct[0].defaultPrice;

        let newConfigId = guid.GenerateGUID();

        if (homeDeliveryProduct.length > 0)
            this.props.quoteOrderCommonActions.createLine(null, this.props.session.username, locationHomeDeliveryCharge, homeDeliveryProduct[0].ImageUrl, newConfigId, "ICE", homeDeliveryProduct[0].ID, this.props.order.id);
    }

    removeHomeDeliveryCharge() {
        if (!this.props.order.lines || this.props.order.lines.length === 0) return;

        let homeDeliveryProduct = this.props.order.lines.filter(l => l.productName === constants.HOME_DELIVERY);

        if (homeDeliveryProduct.length > 0)
            this.props.quoteOrderCommonActions.deleteLine(homeDeliveryProduct[0].id, null, this.props.order.id);
    }

    setShipAddressToLocationShipTo() {
        if (this.props.order.shippingAddress && this.props.order.companyLocationId && this.props.admin.activeCompanyLocationShippingAddress) {

            let locationShip = this.props.admin.activeCompanyLocationShippingAddress;
            this.updateAddress("address", locationShip.address, "shipping");
            this.updateAddress("address2", locationShip.address2, "shipping");
            this.updateAddress("city", locationShip.city, "shipping");
            this.updateAddress("state", locationShip.state, "shipping");
            this.updateAddress("zip", locationShip.zip, "shipping");               
        }
    } 

    onAddCommunicationClick(comment, sendEmail = true) {
        this.props.quoteOrderCommonActions.createCommunication(null, this.props.session.username, comment, 1, this.props.order.id);

        if (sendEmail === true)
            this.sendEmail(constants.EMAIL_TYPES.UserCommunication, comment);
    }

    onPrint() {        
        this.props.history.push({
            pathname: '/OrderPrint'             
        }); 
    }

    onCopy() {
        this.props.quoteOrderCommonActions.copy(null, this.props.session.username, this.props.order.id).then(() => {
            this.props.history.push({ pathname: '/OrderList' });
        });
    }

    submitOrder() {  
        if (this.state.stage > this.state.requiredStages) {     
            if (this.isLastStage() === true) {
                let poEntered = this.validatePoNumber();      
                
                if (poEntered === false) return;
                
            }      
               
            let communicationText;
            let newStatus;
            if (this.props.order.statusTypeId === constants.STATUS_TYPES.InProgressAccepted) {              

                if (this.isOrderShipDateSet() === false) return;

                communicationText = `Order has been shipped`;
                this.updateOrderStatus(constants.STATUS_TYPES.Shipped);
                newStatus = this.getStatusName(constants.STATUS_TYPES.Shipped);
            }
            else if (this.props.order.statusTypeId === constants.STATUS_TYPES.Pending) {
                if (this.props.order.attachments.length < 1) {                     
                     this.props.alertActions.showFailureAlert('Please attach a drawing & purchase order to update the order');
                     return;
                } else {
                    communicationText = `Order has been submitted for approval`;
                    this.updateOrderStatus(constants.STATUS_TYPES.SubmittedForApproval);
                    newStatus = this.getStatusName(constants.STATUS_TYPES.SubmittedForApproval);
                }
            }
            else if (this.props.order.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval) {              

                if (this.state.rejected === true) {
                    if(this.isOrderRejected() === false) return;

                    communicationText = `Order rejected: ${this.state.rejectMessage}`;
                    this.updateOrderStatus(constants.STATUS_TYPES.Rejected);
                    newStatus = this.getStatusName(constants.STATUS_TYPES.Rejected);
                } else {
                    if (this.isOrderAccepted() === false) return;

                    communicationText = `Order has been accepted`;
                    this.updateOrderStatus(constants.STATUS_TYPES.InProgressAccepted);
                    newStatus = this.getStatusName(constants.STATUS_TYPES.InProgressAccepted);
                }
            }
            else if (this.props.order.statusTypeId === constants.STATUS_TYPES.Confirmed) {
                communicationText = `Order has been submitted for approval`;
                this.updateOrderStatus(constants.STATUS_TYPES.SubmittedForApproval);
                newStatus = this.getStatusName(constants.STATUS_TYPES.SubmittedForApproval);
            }
            else if (!this.props.order.attachments || this.props.order.attachments.length < 1) {
                communicationText = `Order submitted without attachments. Status set to pending`;                                     
                this.updateOrderStatus(constants.STATUS_TYPES.Pending);
                newStatus = this.getStatusName(constants.STATUS_TYPES.Pending);
            }
            else if (this.props.order.statusTypeId === constants.STATUS_TYPES.Draft) {
                communicationText = `Order Submitted by ${this.props.session.username}`;                                     
                this.updateOrderStatus(constants.STATUS_TYPES.SubmittedForApproval);
                newStatus = this.getStatusName(constants.STATUS_TYPES.SubmittedForApproval);
            }
            else if (this.props.order.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval) {
                communicationText = `Order Accepted by ${this.props.session.username}`;                                     
                this.updateOrderStatus(constants.STATUS_TYPES.InProgressAccepted);
                newStatus = this.getStatusName(constants.STATUS_TYPES.InProgressAccepted);
            }
            else if (this.props.order.statusTypeId === constants.STATUS_TYPES.Completed) {
                return;
            }
            // else if (this.props.order.statusTypeId === constants.STATUS_TYPES.Sent) {
            //     communicationText = `Order Confirmed by ${this.props.session.username}`;                                     
            //     this.updateOrderStatus(constants.STATUS_TYPES.Confirmed); 
            //} 
            else {
                communicationText = `Order was submitted by ${this.props.session.username}`;
                this.updateOrderStatus(constants.STATUS_TYPES.SubmittedForApproval);             
                this.updateHeader(constants.ORDER_SQL_COLUMNS.IsLocked, true);   
                newStatus = this.getStatusName(constants.STATUS_TYPES.SubmittedForApproval);                                    
            }

            if (this.state.rejected === true) {
                newStatus = this.getStatusName(constants.STATUS_TYPES.Rejected);
                this.sendEmail(constants.EMAIL_TYPES.StatusChange, newStatus);
            } else {
                this.sendEmail(constants.EMAIL_TYPES.QuoteOrder, newStatus);
            }

            this.onAddCommunicationClick(communicationText, false);
            //this.createSystemCommunication(communicationText);

            this.props.history.push({ pathname: '/OrderDashboard' });

        } else {
                        
            if (this.validateStage() === true)
                this.onStageChange(this.state.stage + 1);            
        }     
    }

    getStatusName(statusId) {
        if (this.props.quoteOrderCommon.statusTypes && this.props.quoteOrderCommon.statusTypes.length > 0) {
            return this.props.quoteOrderCommon.statusTypes.filter(s => s.id === statusId)[0].name;
        }
    }

    isOrderAccepted() {
        if (!this.state.estimatedShipDate) {
            let message = this.props.session.roleName === constants.ROLE.User ? "No estimated ship date has been set yet. Please check back later." : "Please enter an estimated ship date.";
            this.props.alertActions.showFailureAlert(message);
            return false;
        }

        return true;
    }

    isOrderRejected() {
        if(!this.state.rejectMessage) {
            this.props.alertActions.showFailureAlert("Must enter a reason for rejecting order.");
            return false;
        }

        return true;
    }

    isOrderShipDateSet() {
        if (!this.state.actualShipDate) {
            let message = this.props.session.roleName === constants.ROLE.User ? "No actual ship date has been set yet. Please check back later." : "Please enter the actual ship date.";
            this.props.alertActions.showFailureAlert(message);
            return false;
        }

        return true;
    }

    validateStage() {
        let stage = this.state.stage;

        if (stage === constants.QUOTE_STAGES.Line) 
            return true; //return this.validateLineStage();
        else if (stage === constants.QUOTE_STAGES.Details) {
            let validDetails = this.validateDetailsStage();
            let validCustomer = this.validateCustomerStage();

            if (validDetails === false) return validDetails;
            if (validCustomer === false) return validCustomer;

            return true;
        }      
        else if (stage === constants.QUOTE_STAGES.Address)
            return this.validateAddressStage();     
       
        return true;       
    }

    isLastStage() {
        let shipToCustomer = this.props.order.shipToTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;

        if (this.state.stage === constants.QUOTE_STAGES.Submit || (shipToCustomer === false && this.state.stage > this.state.shipToStoreTotalRequiredStages) 
            || (shipToCustomer === true && this.state.stage > this.state.shipToCustomerTotalRequiredStages)) {
                return true;
            } else {
                return false;
            }
    }

    validateLineStage() {
        if (this.props.order.lines.length === 0) {
            this.props.alertActions.showFailureAlert("At least one line must be added to the quote to continue");
            return false;
        } else {
            return true;
        }
    }

    validatePoNumber() {
        if (!this.props.order.poNumber) {
            this.props.alertActions.showFailureAlert("Please enter a purchase order number in the summary section. For Genesis Item Numbers, please use the Retail/Genesis report button.");
            return false;
        } else {
            return true;
        }
    }

    getDesignerEmail() {
        let designer = this.props.quoteOrderCommon.designers.filter(d => d.userId === this.props.order.createdByUserId);
        let email = '';
        
        if (designer && designer.length > 0)
            email = designer[0].email;
        
        return email;
    }

    validateDetailsStage() {
        if (!this.props.order.name) {
            this.props.alertActions.showFailureAlert("Please enter a job name");
            return false;
        }

        if (!this.props.order.companyLocationId || this.props.order.companyLocationId === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please enter a location");
            return false;
        }
        
        // if (!this.props.order.createdByEmail) {
        //     this.props.alertActions.showFailureAlert("Please enter a designer");
        //     return false;
        // }        

        if (!this.props.order.createdByUserId || this.props.order.createdByUserId === constants.PLACEHOLDER || this.props.order.createdByUserId === -1) {
            this.props.alertActions.showFailureAlert("Please select a designer");
            return false;
        }

        if (!this.props.order.installType || this.props.order.installType === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please select an install type");
            return false;
        }

        if (!this.props.order.shipToTypeId || this.props.order.shipToTypeId === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please select a ship to type");
            return false;
        }

        if (this.props.order.installType === constants.INSTALL_TYPES.SOSInstall) {
            if (!this.props.order.installerId) {
                this.props.alertActions.showFailureAlert("An installed is required when install type SOS Install is selected");
                return false;
            }
        }

        return true;
    }

    validateCustomerStage() {
        const contact = this.props.order.contact[0];
        
        if (!contact.firstName || !contact.lastName) {
            this.props.alertActions.showFailureAlert("Please enter a first and last name.");
            return false;
        }
        else if (this.shipToCustomer(this.props.order.shipToTypeId) === true && !contact.phone) {
            this.props.alertActions.showFailureAlert("Phone number is required when ship to customer is selected.");
            return false;
        }
        else 
            return true
    }

    validateAddressStage() {
        if (this.shipToCustomer(this.props.order.shipToTypeId) !== true) return true;

        let shippingAddress = this.props.order.shippingAddress[0];

        if (!shippingAddress) {
            this.props.alertActions.showFailureAlert("No shipping address found");
            return false;
        }

        if (!shippingAddress.Address) {
            this.props.alertActions.showFailureAlert("Please enter a value in the address field");
            return false;
        } 
        
        if (!shippingAddress.City) {
            this.props.alertActions.showFailureAlert("Please enter a value in the City field");
            return false;
        } 

        if (!shippingAddress.State) {
            this.props.alertActions.showFailureAlert("Please enter a value in the State field");
            return false;
        }
        
        // if (!shippingAddress.Country) {
        //     this.props.alertActions.showFailureAlert("Please enter a value in the Country field");
        //     return false;
        // }               

        return true;
    }

    createSystemCommunication(message) {
        this.props.quoteOrderCommonActions.createCommunication(null, this.props.session.username, message, constants.QUOTE_COMMUNICATION_TYPES.system, this.props.order.id);
    }    

    runOrderSubmitWorkflows() {
        let stopWorkflows = false;

        if (this.props.workflows.onOrderSubmit && this.props.workflows.onOrderSubmit.length > 0) {            
            this.props.workflows.onOrderSubmit.forEach(workflow => {

                if (stopWorkflows === true) return;

                switch(workflow.actionTypeId) {

                    case constants.WORKFLOW_ACTION_TYPES.validateRequiredAttachments:
                       
                        if (this.props.order.attachments.length < workflow.actionMinAttachments) {
                            this.onAddCommunicationClick(`The required number of attachments is ${workflow.actionMinAttachments}. The order was not submmitted and the status will be set to pending`, false);                            
                            //this.createSystemCommunication(`The required number of attachments is ${workflow.actionMinAttachments}. The order was not submmitted and the status will be set to pending`);
                            this.props.alertActions.showFailureAlert(`The required number of attachments is ${workflow.actionMinAttachments}. The order was not submmitted and the status will be set to pending`);
                            if (workflow.actionBreakWorkflowOnFail === true) {
                                stopWorkflows = true;
                            } 
                        } else {
                            if (workflow.actionBreakWorkflowOnTrue === true) {
                                stopWorkflows = true;
                            } 
                        }
                        
                        break;                    

                    case constants.WORKFLOW_ACTION_TYPES.updateStatus:
                        this.updateOrderStatus(workflow.actionNewStatus);

                        break;
    
                    default:
                        console.log('no matching workflow id');
                }
            });
        }
    }

    updateOrderStatus(statusTypeId) {     
        this.setState({ status: statusTypeId });
        this.updateHeader(constants.ORDER_SQL_COLUMNS.Status, statusTypeId);
    }
    
    onConfigureClick() {
        let products = this.props.api.companyProducts;

        if (products) {
            let selectedProduct = products.filter(p => p.ID == this.state.selectedProduct)[0];

            if (selectedProduct.isNonConfigurable === true) {
                this.props.quoteOrderCommonActions.createLine(null, this.props.session.username, selectedProduct.defaultPrice, selectedProduct.ImageUrl, "", "", this.state.selectedProduct, this.props.order.id);
                return;
            }
        }
        
        this.props.apiActions.setActiveProduct(this.props.session.username, this.state.selectedProduct, this.props.session.company).then(() => {
            this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Order);
            this.props.history.push({ pathname: '/Configurator' }); 
            
        });                                
    }

    onAddCountertopClick() {
        let products = this.props.admin.productList;
        let productId;
        if (products) {
            // auto load counter top from lowes
            let selectedProduct = products.filter(p => p.ID == 1)[0];                       

            if (!selectedProduct) {
                this.props.alertActions.showFailureAlert("No product found");
                return;
            }

            this.props.apiActions.setSelectedProduct(selectedProduct);    
            
            productId = selectedProduct.ID;           
        }

        this.props.apiActions.setActiveProduct(this.props.session.username, productId, this.props.session.company).then(() => {
            this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Order);        
            this.props.history.push({ pathname: '/Configurator' }); 
        });
    }

    onCommunicationPageChange(currentPage) {
        let communicationOffset = parseInt((currentPage * this.state.communicationRowsToFetch) - this.state.communicationRowsToFetch);
        this.setState({ communicationOffset: communicationOffset, currentCommunicationPage: currentPage }, () => this.getCommunications());   
    }    

    onProductChange(event) {
        this.setState({ selectedProduct: event.target.value });

        this.setState({ disableAddLine: event.target.value === '' ? true : false });
    }

    onQuantityChange(event, id) {    
        if (event.nativeEvent.explicitOriginalTarget &&
            event.nativeEvent.explicitOriginalTarget == event.nativeEvent.originalTarget) {
          return;
        }
            
        this.props.quoteOrderCommonActions.updateLineQuantity(id, event.target.value, null, this.props.order.id);
    }

    onAddressFieldUpdate(event, addressType) {
        if (this.state.onFocusValue === event.target.value) return;

        this.props.quoteOrderCommonActions.updateAddressField(this.props.order.id, addressType, event.target.id, event.target.value, constants.TABLES.Address, constants.TABLES.Order, null, this.props.order.id);
    }

    onContactFieldUpdate(event) {
        if (this.state.onFocusValue === event.target.value) return;

        this.props.quoteOrderCommonActions.updateContactField(this.props.order.id, event.target.id, event.target.value, constants.TABLES.Contact, constants.TABLES.Order);
    }

    onHeaderFieldUpdate(event) {
        if (this.state.onFocusValue === event.target.value || event.target.value === constants.PLACEHOLDER) return;

        this.updateHeader(event.target.id, event.target.value);

        if (event.target.id === constants.ORDER_SQL_COLUMNS.InstallType) {
            if (event.target.value === constants.INSTALL_TYPES.SOS) {
                this.updateHeader(constants.ORDER_SQL_COLUMNS.VendorNumber, constants.VENDOR_NUMBERS.SOS);  
                this.getDefaultInstaller(null);               
            } else {
               this.updateHeader(constants.ORDER_SQL_COLUMNS.VendorNumber, constants.VENDOR_NUMBERS.SOS_INSTALL);

               this.getDefaultInstaller(this.props.order.companyLocationId);
            }
       }        

        if(event.target.id === constants.ORDER_SQL_COLUMNS.InstallType && this.props.order.installerId)
            this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, null);        
    }

    onFieldUpdate(event) {
        if (this.state.onFocusValue === event.target.value) return;

        this.updateField(event.target.id, event.target.value);        
    }

    onCompanyChange(event) {
        if (event.target.value === constants.PLACEHOLDER) return;       
        event.persist();
        const companyId = event.target.value;
        this.props.quoteOrderCommonActions.updateHeaderField(this.props.order.id, event.target.id, event.target.value, constants.TABLES.Order).then(() => {
            let companyName = this.props.admin.companyList.filter(c => c.id == companyId)[0].company;
            this.getCompanyLocations(companyName);
        });              
    }

    getCompanyLocations(companyName) {                  
        this.props.adminActions.getCompanyLocations(companyName).then(() => {
            if (this.props.session.roleName === constants.ROLE.User) {
                const userLocation = this.props.session.userCompanyLocationIdCSV.split(',');
                let defaultLocation = this.props.admin.companyLocations.filter(l => l.id = userLocation[0]);

                this.setState({ companyLocationId: defaultLocation });
            }
        });
    }

    onLocationChange(event) {
        this.setState({ companyLocationId: event.target.value });
        
        let shipToStoreTypes = this.props.quoteOrderCommon.shipToTypes;  
        let allowedShipToTypes;
        let locations = this.props.admin.companyLocations.filter(l => l.id == event.target.value);
        if (shipToStoreTypes)
            allowedShipToTypes = locations[0].allowHomeDelivery !== true ? shipToStoreTypes.filter(x => x.id !== constants.SHIP_TO_TYPES.Customer) : shipToStoreTypes;
        else 
            allowedShipToTypes = [];

        this.setState({ allowedShipToTypes: allowedShipToTypes  });
           

        event.persist();
        
        if (event.target.value !== constants.PLACEHOLDER) {
            this.updateHeader(event.target.id, event.target.value);
            this.updateHeader("createdByEmail", "");
            this.updateHeader("createdByUserId", -1);
            if(this.props.order.installerId)
                this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, null);

            this.props.quoteOrderCommonActions.getUsersAssignedToLocation(event.target.value);     
                
            this.getDefaultInstaller(event.target.value);     
            
            this.props.quoteOrderCommonActions.getCompanyLocationFabricator(event.target.value, true);
            this.props.quoteOrderCommonActions.getCompanyLocationDesigners(event.target.value);
            
        }
    }

    getDefaultInstaller(locationId) {
        this.props.quoteOrderCommonActions.getCompanyLocationInstallers(locationId).then(() => {      
            if (this.props.quoteOrderCommon.companyLocationInstallers && this.props.quoteOrderCommon.companyLocationInstallers.length > 0) {                        
                this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, this.props.quoteOrderCommon.companyLocationInstallers[0].id);     
            }                         
        });
    }

    onInputFocus(event) {
        this.setState({onFocusValue: event.target.value});
    }

    async onReconfigure(configurationId, headerId, id, productId) {  
        let products = this.props.admin.productList;

        if (products) {
            let selectedProduct = products.filter(p => p.ID == productId)[0];

            if (selectedProduct.isNonConfigurable === true) {
                this.props.alertActions.showFailureAlert("Cannot reconfigure non configurable part");
                return;
            }
        }

        await this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Order);

        this.props.history.push({
          pathname: '/Configurator',
          configurationId: configurationId,
          headerId: headerId,
          LineId: id,
          productId: productId
        });   
    }

    onLineDelete(lineId) {        
        this.props.quoteOrderCommonActions.deleteLine(lineId, null, this.props.order.id);
    }    

    onCopyLine(lineId, configurationId, headerId, productId) {
        let newConfigId = guid.GenerateGUID();
        let products = this.props.admin.productList;

        if (products) {
            let lineProduct = products.filter(p => p.ID == productId)[0];
            this.props.quoteOrderCommonActions.CopyLineConfiguration(lineProduct.ApplicationInstance, lineProduct.ApplicationName, "http://hkplt01/PCM/ConfiguratorService/v2/ProductConfigurator.svc", headerId, configurationId, headerId, newConfigId);
        }

        this.props.quoteOrderCommonActions.copyLine(lineId, newConfigId, null, this.props.order.id);        
    }

    onClickDownloadFile(path, originalName, mimetype, filename) {
        this.props.quoteOrderCommonActions.downloadFile(path, originalName, mimetype, filename);       
    } 

    onOrderAccepted(isAccepted) {
        if (isAccepted === false) {
            this.setState({ accepted: isAccepted, estimatedShipDate: null, actualShipDate: null });                
            this.updateField(constants.ORDER_SQL_COLUMNS.EstimatedShipDate, null);  
            this.updateField(constants.ORDER_SQL_COLUMNS.ActualShipDate, null);          
        } else {
            this.setState({ accepted: isAccepted, estimatedShipDate: null, rejected: false });    
        }  
    }

    onOrderRejected(isRejected) {
        if (isRejected === true) {
            //update a field somewhere; send an email at some point 
            this.setState({ 
                rejected: true,
                accepted: false
             });
        } else {
            //update a field somewhere
            this.setState({ 
                rejected: false,
                rejectMessage: ''
            });
        }
    }

    onRejectMessageChanged(event) {     
        this.setState({ rejectMessage: event.target.value });
        if (event.target.value) 
            this.setState({ showEnterCommentWarning: false });
    }

    onEstimatedShipDateSet(date) {        
        if (!date) { 
            this.setState({ estimatedShipDate: '', accepted: false });
            this.updateField(constants.ORDER_SQL_COLUMNS.EstimatedShipDate, null);                                                                  
            return;
        } else {
            this.setState({ estimatedShipDate: date, accepted: true });
            this.updateField(constants.ORDER_SQL_COLUMNS.EstimatedShipDate, date)                                        
        }           
    }

    onActualShipDateChanged(date) {
        if (!date) { 
            this.setState({ actualShipDate: '' });
            this.updateField(constants.ORDER_SQL_COLUMNS.ActualShipDate, null);                                                                  
            return;
        } else {
            this.setState({ actualShipDate: date });
            this.updateField(constants.ORDER_SQL_COLUMNS.ActualShipDate, date)                                        
        }       
    }

    updateHeader(column, value) {
        this.props.quoteOrderCommonActions.updateHeaderField(this.props.order.id, column, value, constants.TABLES.Order);

        if (column === constants.ORDER_SQL_COLUMNS.createdByEmail) {
            this.props.quoteOrderCommonActions.createCompanyLocationDesigner(value, this.props.order.companyLocationId);
        } 
    }

    updateField(column, value) {     
        this.props.quoteOrderCommonActions.updateField(this.props.order.id, column, value, constants.TABLES.Order);            
    }

    generateReport(costOrPrice, quoteOrOrder, id) {
        this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderCost, costOrPrice, quoteOrOrder, id);
    }

    onSetToPendingInformationClick(comment) {
        if (!comment) {
            this.props.alertActions.showFailureAlert("Please enter a comment to set the quote to pending.");
            return;
        }
        this.updateOrderStatus(constants.STATUS_TYPES.Pending);
        this.props.quoteOrderCommonActions.createCommunication(null, this.props.session.username, comment, 1, this.props.order.id);
        this.sendEmail(constants.EMAIL_TYPES.PendingInformation, constants.STATUS_TYPES.Pending, comment);
    }

    onSetToDraftClick(comment) {
        if (!comment) {
            this.props.alertActions.showFailureAlert("Please enter a comment to set the quote to draft.");
            return;
        }

        this.updateOrderStatus(constants.STATUS_TYPES.Draft);
        this.props.quoteOrderCommonActions.createCommunication(null, this.props.session.username, comment, 1, this.props.order.id);
        this.sendEmail(constants.EMAIL_TYPES.StatusChange, constants.STATUS_TYPES.Draft, comment);
    }
    
    render() {

        if (this.state.loading === true || !this.props.quoteOrderCommon.userCompanyLocations) return ( <Loading /> );
        
        let submitDisabled = false; 
        let previousDisabled = false;       
        let shippingAddress = [];
        let contact = null;
        
        if (this.state.stage > this.state.requiredStages) {
            if (this.props.session.roleName === constants.ROLE.User) {
                switch (this.state.status) {
                    case constants.STATUS_TYPES.Sent: 
                        submitDisabled = true;
                        break;
                    case constants.STATUS_TYPES.CancellationRequested:
                        submitDisabled = true;
                        break;
                    case constants.STATUS_TYPES.ConvertedToOrder:
                        submitDisabled = true;
                        break;

                    case constants.STATUS_TYPES.Shipped:
                        submitDisabled = true;
                        break;

                    case constants.STATUS_TYPES.SubmittedForApproval:
                        submitDisabled = true;
                        break;

                    default:
                        submitDisabled = false;
                        break;
                }
            } else {
                switch (this.state.status) {                   
                    case constants.STATUS_TYPES.ConvertedToOrder:
                        submitDisabled = true;
                        break;

                    case constants.STATUS_TYPES.Shipped:
                        submitDisabled = true;
                        break;

                    default:
                        submitDisabled = false;
                        break;
                }
            }
        } 

        let showInvoiceButton = (this.state.status === constants.STATUS_TYPES.Shipped && (this.props.session.roleName === constants.ROLE.SystemAdmin || this.props.session.roleName === constants.ROLE.Fabricator)) === true ? true : false;
        
        if (this.state.stage === 1) previousDisabled = true;
                                         
        if (this.props.order.shippingAddress)
            shippingAddress = this.props.order.shippingAddress[0];

        if (this.props.order.contact)
            contact = this.props.order.contact;                    

        let companyLocationName = ''; // companyLocation[0].name;
        let companyLocationFax = ''; //companyLocation[0].faxNumber; 
        let attachments;

        if (this.props.order.attachments && this.props.order.attachments.length > 0)
            attachments  = this.props.session.roleName === constants.ROLE.User ? this.props.order.attachments.filter(a => a.attachmentTypeId != 3) : this.props.order.attachments;
        
        let communicationPageCount = 1;
        
        if (this.props.order && this.props.order.communicationHistory) {
            communicationPageCount = this.props.order.communicationHistory.length > 0 ? (this.props.order.communicationHistory[0].totalRows / this.state.communicationRowsToFetch) : 1;
            communicationPageCount = Math.ceil(communicationPageCount);
        }      
        
        let fabricatorCompanyLocations = [];
        if (this.props.session.roleName === constants.ROLE.Fabricator && this.props.admin.companyLocations) {
            let userLocations = this.props.session.userCompanyLocationIdCSV.split(',');
            fabricatorCompanyLocations = this.props.admin.companyLocations.filter(loc => userLocations.includes(loc.id.toString()));
        }

        let headerDetailLocations = [];
        if (this.props.session.roleName === constants.ROLE.Fabricator && this.props.admin.companyLocations) {
            let userLocations = this.props.session.userCompanyLocationIdCSV.split(',');
            headerDetailLocations = this.props.admin.companyLocations.filter(loc => userLocations.includes(loc.id.toString()));
        } else if (this.props.session.roleName === constants.ROLE.User && this.props.quoteOrderCommon.userCompanyLocations) {
            headerDetailLocations = this.props.quoteOrderCommon.userCompanyLocations;
        } else if ((this.props.session.roleName === constants.ROLE.CompanyAdmin || this.props.session.roleName === constants.ROLE.SystemAdmin) && this.props.admin.companyLocations) {
            headerDetailLocations = this.props.admin.companyLocations;
        }
      
        let showAddressStage = (this.props.order.shipToTypeId !== constants.SHIP_TO_TYPES.Customer ? false : true);
        let shipToCustomer = this.props.order.shipToTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;
        let acceptOrderReadonly = this.props.session.roleName === constants.ROLE.User ? true : false;

        let headerRowWidth = 8;

        if (this.props.order.statusTypeId === constants.STATUS_TYPES.Draft)
            headerRowWidth = headerRowWidth - 1;

        if (showInvoiceButton === true)
            headerRowWidth = headerRowWidth - 1;

        let statusOptions = this.props.quoteOrderCommon.statusTypes.filter(status => status.roleLevel >= this.props.session.roleLevel && status.allowOnOrder === true); 
        
        let showDraftButton = false;

        if (this.props.order.statusTypeId === constants.STATUS_TYPES.Pending || this.props.order.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval || this.props.order.statusTypeId === constants.STATUS_TYPES.Sent) {
            if (this.props.session.roleName === constants.ROLE.Fabricator || this.props.session.roleName === constants.ROLE.SystemAdmin)
                showDraftButton = true;
        }                          
        
        let showPendingButton = false;

        if (this.props.order.statusTypeId === constants.STATUS_TYPES.Pending || this.props.order.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval || this.props.order.statusTypeId === constants.STATUS_TYPES.Sent) {
            if (this.props.session.roleName === constants.ROLE.Fabricator || this.props.session.roleName === constants.ROLE.SystemAdmin)
                showPendingButton = true;
        }  
        return (
            <React.Fragment>
                <ContainerStyled>
                    <HeaderRowStyled>                                                                           
                        <LineActions                         
                            onPrint={this.onPrint}
                            onCopy={this.onCopy}
                            submit={this.submitOrder}
                            submitDisabled={submitDisabled}
                            showCancelButton={this.props.order.statusTypeId === constants.STATUS_TYPES.InProgressAccepted}
                            onQuoteOrderCancel={this.onOrderCancel}
                            cancelButtonLabel="Cancel Order"
                            onStageChange={this.onStageChange}
                            stage={this.state.stage}
                            submitButtonLabel={this.state.submitButtonLabel}
                            submitButtonColor={this.state.submitButtonColor} 
                            isLocked={this.state.isLocked}  
                            previousStage={this.previousStage}
                            roleName={this.props.session.roleName}
                            status={this.props.order.statusTypeId}
                            previousDisabled={previousDisabled} 
                            showInvoiceButton={showInvoiceButton}
                            orderInvoiced={this.orderInvoiced}
                            showSaveAsDraftButton={(this.props.order.statusTypeId === constants.STATUS_TYPES.Draft) ? true : false}
                            saveAsDraft={this.saveAsDraft}
                            sourceQuoteId={this.props.order.sourceQuoteId}
                            headerRowWidth={headerRowWidth}
                            orderId={this.props.order.id}
                            statusName={this.props.order.statusName}
                            generateReport={this.generateReport}
                        />
                    </HeaderRowStyled>                  
                
                {this.props.order.statusTypeId === constants.STATUS_TYPES.CancellationRequested && 
                    (this.props.session.roleName === constants.ROLE.ManufacturerAdmin || this.props.session.roleName === constants.ROLE.SystemAdmin || this.props.session.roleName === constants.ROLE.Fabricator) &&
                        <OrderManageCancelRequest 
                            onOrderCancelAccept={this.onOrderCancelAccept} 
                            onOrderCancelReject={this.onOrderCancelReject} /> }     

                <Stages 
                    stage={this.state.stage} 
                    onStageChange={this.onStageChange} 
                    showAddressStage={showAddressStage} 
                    allowSubmit={this.state.stage > this.state.requiredStages ? true : false } 
                    isLocked={this.state.isLocked}
                    submitLabel={this.getSubmitButtonLabel(this.state.stage) }
                    checkmarkLabel={this.state.checkmarkLabel} 
                    
                    />

                {this.state.stage === constants.QUOTE_STAGES.Details &&
                <React.Fragment>
                    <HeaderDetails 
                        description={this.props.order.name} 
                        onInputFocus={this.onInputFocus} 
                        onFieldUpdate={this.onFieldUpdate} 
                        statusOptions={statusOptions} 
                        status={this.state.status} 
                        companyLocationId={this.props.order.companyLocationId}
                        createdByOptions={this.props.quoteOrderCommon.companyLocationUsers}
                        createdByUserId={this.props.order.createdByUserId}
                        createdByEmail={this.props.order.createdByEmail}                    
                        statusChange={this.statusChange}
                        onLocationChange={this.onLocationChange}
                        onCreatedByChanged={this.onCreatedByChanged}
                        userCompanyLocations={headerDetailLocations}
                        companyLocationUsers={this.props.quoteOrderCommon.companyLocationUsers} 
                        onShipToStoreChanged={this.onShipToStoreChanged}
                        shipToStoreTypeId={this.props.order.shipToTypeId}
                        allowHomeDelivery={this.state.allowHomeDelivery}
                        installTypes={this.state.allowSOSInstall === true ? constants.HK_INSTALL_TYPES : constants.HK_INSTALL_TYPES_SOS_ONLY}
                        installType={this.props.order.installType}
                        companyLocationInstallers={this.props.quoteOrderCommon.companyLocationInstallers}
                        installerId={this.props.order.installerId}                    
                        onHeaderFieldUpdate = {this.onHeaderFieldUpdate}
                        onProductChange={this.onProductChange}
                        companyProducts={this.props.api.companyProducts}
                        disableAddLine={submitDisabled}
                        onConfigureClick={this.onConfigureClick}
                        stage={this.state.stage}      
                        readonly={this.state.isLocked}
                        roleName={this.props.session.roleName} 
                        companyList={this.props.admin.companyList}
                        onCompanyChange={this.onCompanyChange}
                        companyId={this.props.order.companyId}
                        shipToTypes={this.state.allowedShipToTypes}
                        poNumber={this.props.order.poNumber}
                        isOrder={true}
                        fabricatorName={this.props.order.fabricatorName}
                        designers={this.props.quoteOrderCommon.designers}  
                        updateHeader={this.updateHeader}                       
                        />                
                </React.Fragment>
                
                       
                }                                                            

                {this.state.stage === constants.QUOTE_STAGES.Line &&                            
                    <Lines 
                        orderId={this.props.order.id}  
                        onCopyLine={this.onCopyLine} 
                        onLineDelete={this.onLineDelete} 
                        lines={this.props.order.lines} 
                        onQuantityChange={this.onQuantityChange} 
                        onReconfigure={this.onReconfigure} pageType="Order"                     
                        onProductChange={this.onProductChange}
                        companyProducts={this.props.api.companyProducts}
                        disableAddLine={submitDisabled}
                        onConfigureClick={this.onConfigureClick}
                        stage={this.state.stage} 
                        readonly={this.state.isLocked}
                        onAddCountertopClick={this.onAddCountertopClick} />
                }    

                {this.state.stage === constants.QUOTE_STAGES.Details &&
                    <ContactInfo 
                        onContactFieldUpdate={this.onContactFieldUpdate} 
                        contact={contact} 
                        onInputFocus={this.onInputFocus}
                        readonly={this.state.isLocked}
                        shipToCustomer={this.shipToCustomer(this.props.order.shipToTypeId)} />
                }
               
                {this.state.stage === constants.QUOTE_STAGES.Attachments &&
                    <React.Fragment>
                        <AttachmentList 
                            isOrder={true} 
                            readonly={this.state.isLocked} 
                            faxTemplate={this.onfaxTemplateOpen} 
                            onDeleteAttachment={this.onDeleteAttachment} 
                            attachments={attachments} 
                            onClickDownloadFile={this.onClickDownloadFile} 
                            companyLocationName={companyLocationName} 
                            companyLocationFax={companyLocationFax} 
                            isStatusConfirm={this.props.order.statusTypeId === constants.STATUS_TYPES.Confirmed ? true : false}
                        />
                        <Attachments readonly={this.state.isLocked} company={this.props.session.company} orderId={this.props.order.id} quoteId={null} attachmentType={this.state.status === constants.STATUS_TYPES.Confirmed ? 2 : 1} />
                    </React.Fragment>                    
                } 
      
                {this.state.stage === constants.QUOTE_STAGES.Address && showAddressStage === true && 
                    <AddressForm 
                        title="Shipping Address" 
                        addressType="Shipping"                     
                        onAddressFieldUpdate={this.onAddressFieldUpdate} 
                        onInputFocus={this.onInputFocus} 
                        address={shippingAddress.Address}
                        city={shippingAddress.City}
                        state={shippingAddress.State}
                        address2={shippingAddress.Address2}
                        country={shippingAddress.Country}
                        zip={shippingAddress.Zip} 
                        readonly={this.state.isLocked} />
                    }                                

                {(this.state.stage === constants.QUOTE_STAGES.Submit 
                        || (shipToCustomer === false && this.state.stage > this.state.shipToStoreTotalRequiredStages) 
                        || (shipToCustomer === true && this.state.stage > this.state.shipToCustomerTotalRequiredStages)) 
                && (this.props.order.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval                        
                        || this.props.order.statusTypeId === constants.STATUS_TYPES.InProgressAccepted                        
                        || this.props.order.statusTypeId === constants.STATUS_TYPES.Shipped) 
                && <AcceptOrder 
                    onOrderAccepted={this.onOrderAccepted} 
                    onOrderRejected={this.onOrderRejected}
                    onRejectMessageChanged={this.onRejectMessageChanged}
                    accepted={this.state.accepted}
                    rejected={this.state.rejected} 
                    showRejectOption={this.state.showRejectOption}
                    estimatedShipDate={this.state.estimatedShipDate} 
                    onEstimatedShipDateSet={this.onEstimatedShipDateSet}
                    onActualShipDateChanged={this.onActualShipDateChanged}
                    actualShipDate={this.state.actualShipDate }
                    acceptOrderReadonly={acceptOrderReadonly} />        
                
                }

                {(this.state.stage === constants.QUOTE_STAGES.Submit 
                  || (shipToCustomer === false && this.state.stage > this.state.shipToStoreTotalRequiredStages) 
                  || (shipToCustomer === true && this.state.stage > this.state.shipToCustomerTotalRequiredStages)) &&                
                    <PoNumber id="poNumber" onBlur={this.poNumberChanged} defaultValue={this.props.order.poNumber} />
                }

                {(this.state.stage === constants.QUOTE_STAGES.Submit 
                    || (shipToCustomer === false && this.state.stage > this.state.shipToStoreTotalRequiredStages) 
                    || (shipToCustomer === true && this.state.stage > this.state.shipToCustomerTotalRequiredStages)) &&
                    <Summary 
                        lines={this.props.order.lines}
                        description={this.props.order.name}
                        statusName={this.props.order.statusName} 
                        company=""                             
                    />
                }

              

                </ContainerStyled>
                <Container>
                <CommunicationList 
                    onCommunicationCollapseClicked={this.onCommunicationCollapseClicked} 
                    collapsed={this.state.communicationCollapsed} 
                    communicationHistory={this.props.order.communicationHistory}
                    onCommunicationPageChange={this.onCommunicationPageChange}
                    communicationPageCount = {communicationPageCount}
                    communicationOffset = {this.state.communicationOffset} 
                    currentCommunicationPage = {this.state.currentCommunicationPage} 
                    communicationRowsToFetch = {this.state.communicationRowsToFetch}
                    onCommunicationCollapseClicked={this.onCommunicationCollapseClicked} 
                    collapsed={this.state.communicationCollapsed} 
                    onAddCommunicationClick={this.onAddCommunicationClick}
                    onSetToPendingInformationClick={this.onSetToPendingInformationClick}
                    onSetToDraftClick={this.onSetToDraftClick}
                    showDraftButton={showDraftButton}
                    showPendingButton={showPendingButton}
                    />
                </Container>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      api: state.api,
      session: state.session,
      order: state.order,      
      alert: state.alert,
      quoteOrderCommon: state.quoteOrderCommon,
      admin: state.admin
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators(apiActions, dispatch),
        sessionActions: bindActionCreators(sessionActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),        
        alertActions: bindActionCreators(alertActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),        
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));