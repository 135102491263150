import React from 'react';
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import styled from 'styled-components';

const SelectCPQ = React.memo(({screenOption, caption, onChange, ...props}) => {        
    return (
        <React.Fragment>
            <FormGroup className={screenOption.IsVisible === false ? 'd-none' : ''}>                                         
                <SelectWithLabel  
                    id={screenOption.ID} 
                    name={screenOption.ID}
                    caption={caption}
                    disabled={!screenOption.IsEnabled} 
                    required={screenOption.IsRequired}
                    defaultValue={screenOption.Value}
                    selectableValues={SelectOptionArray(screenOption.SelectableValues)}
                    onChange={(e) => onChange(e, screenOption.AutoContinueMode)} 
                    data-toggle="tooltip" 
                    data-placement="top"
                    title={screenOption.Tooltip}                      
                    {...props}
                />     
                        
            </FormGroup>                
        </React.Fragment>                 
    );     
});  

export default SelectCPQ;

const SelectOptionArray = (arr) => {
    let options = [];       
    
    if (arr) {
        for (var i = 0; i < arr.length; i++) {
            let opt = {
              Value: arr[i].Value,
              Caption: arr[i].Caption
            }
      
            options.push(opt);
        }
    }     
    return options;
}

SelectCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    caption: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool
};