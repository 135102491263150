import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as orderActions from '../../actions/orderActions';
import * as alertActions from '../../actions/alertActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import DashboardGrid from '../QuoteOrderCommon/DashboardGrid';
import ListFilter from '../QuoteOrderCommon/ListFilter';
import { Container, Row, Col } from 'reactstrap';
import Pagination from '../Common/Pagination/Pagination';
import styled from 'styled-components';
import Button from '../Common/Button/Button';
import * as constants from '../../modules/constants';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Loading from '../Common/Loading/Loading';

export const ButtonStyled = styled(Button)`
    color: #FFF !important;
`;

export const H1 = styled.h1`
    margin-top: 1em;
`;

export const ContainerStyled = styled(Container)`
    margin-bottom: 1em;
`;

export const HeaderRowStyled = styled(Row)`
    background-color: ${props => props.theme.lightBackground};
    margin-top: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const GridRow = styled(Row)`
    margin-top: 0px !important;
`;

export const SearchRow = styled(Row)`
    margin-bottom: 0px !important;
    @media (max-width:1200px) { 
        margin-bottom: 1em !important;
    }
`;

class OrderDashboard extends Component {    
    constructor(props, context) {
        super(props, context);
        
        this.onClickNewOrder = this.onClickNewOrder.bind(this);
        this.onClickEditOrder = this.onClickEditOrder.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.stopPropagation = this.stopPropagation.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.sort = this.sort.bind(this);
        
        this.state = {
            statusList: [],
            orderFilter: null,            
            offset: 0,
            rowsToFetch: 10,
            currentPage: 1,
            loading: true,
            sortDirection: null,
            sortByColumn: null,
        }
    }          

    componentDidMount() {       
        if (this.props.location.state && this.props.location.state.overviewStatus) {
            this.setState({ orderFilter: this.props.location.state.overviewStatus }, () => {
                this.getOrders();
            })
        } else {
            this.getOrders();
        }
    }

    async onClickEditOrder(event, orderId) {
        await this.props.orderActions.setActiveOrder(orderId);
        
        this.props.history.push({ pathname: '/Order' });
    }

    async onClickNewOrder() {
        this.props.orderActions.clearOrder();
        await this.props.orderActions.createOrder(this.props.session.username, this.props.session.company);       
         
        this.props.history.push({ pathname: '/Order' });
    }

    onFilter(event) {        
        this.setState({ orderFilter: event.target.value }, () => {
            this.getOrders();   
        });            
    }

    onActionChange(event, id) {
        switch (event.target.value) {
            case constants.GRID_ACTION_OPTIONS.Copy:

                break;

            case constants.GRID_ACTION_OPTIONS.Delete:
                confirmAlert({
                    title: 'Confirm Delete',
                    message: 'Are you sure to delete?',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => {
                            this.props.quoteOrderCommonActions.deleteDraft(null, id).then(() => {
                                this.getOrders();
                            });
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => ''
                      }
                    ]
                  });  
                break;

            case constants.ORDER_GRID_ACTION_OPTIONS.OrderCost:   
                this.setState({ loading: true});
                this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderCost, "C", "O", id).then(() => {
                    this.setState({ loading: false});
                });    
                break;  

            case constants.ORDER_GRID_ACTION_OPTIONS.OrderPrice:  
                this.setState({ loading: true});     
                this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderCost, "P", "O", id).then(() => {
                    this.setState({ loading: false});
                });    
                break;    
                
            case constants.GRID_ACTION_OPTIONS.DownloadAttachments:
                this.getAllAttachments(id);
                break;
        }        
    }

    getAllAttachments(id) {
        this.props.quoteOrderCommonActions.getAttachments(null, id, null).then(() => {
            this.downloadAttachments();
        });
    }

    downloadAttachments() {
        let attachments = this.props.order.attachments;
        if (attachments) {
            attachments.forEach(attachment => {                
                this.props.quoteOrderCommonActions.downloadFile(attachment.path, attachment.originalName, attachment.mimetype);
            });
        } else {
            this.props.alertActions.showFailureAlert("No attachments found"); 
        }
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    sort(sortByColumn) {     
        let direction = this.state.sortDirection === "desc" ? "asc" : "desc";
        this.setState({sortDirection: direction, sortByColumn: sortByColumn}, this.getOrders());                
    }

    getOrders() {                
        this.props.orderActions.getOrders(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName, this.state.orderFilter, this.state.offset, this.state.rowsToFetch, this.state.sortByColumn, this.state.sortDirection).then(() => {
            this.getAllOrderStatuses();
            this.setState({ loading: false });
        });
    }

    getAllOrderStatuses() {
        this.setState({ statusList: [] }, () => {
            let orders = this.props.order.orderList;
            let allStatuses = this.state.statusList;
        
            if (!orders) return;

            orders.forEach(order => {
                if (!allStatuses.includes(order.statusName))
                    allStatuses.push(order.statusName);
            });

            this.setState({ statusList: allStatuses });   
        });     
    }

    onPageChange(currentPage) {               
        let offset = parseInt((currentPage * this.state.rowsToFetch) - this.state.rowsToFetch);
        this.setState({ offset: offset, currentPage: currentPage }, () => this.getOrders());        
    }       

    render() {       
        if (this.state.loading === true) return (<Loading />);         
        let orders = this.props.order.orderList;

        let pageCount = 1;
        
        if (this.props.order && orders) {
            pageCount = orders.length > 0 ? (orders[0].totalRows / this.state.rowsToFetch) : 1;
            pageCount = Math.ceil(pageCount);
        }          
        
        if (!orders) {
            return (          
                <ContainerStyled>
                    <HeaderRowStyled>
                        <Col>
                            <h1>Orders</h1>
                        </Col>
                    </HeaderRowStyled>                              
                    <SearchRow>
                        <Col xl="1" xs="4">                   
                            <div className="text-left">
                                <ButtonStyled displayText="New" onClick={this.onClickNewOrder} backgroundColor={constants.rentechTheme.callToActionColor} color={constants.rentechTheme.textLight}/>
                            </div>
                        </Col>
                        <Col xl="3" xs="8">
                            <ListFilter onFilter={this.onFilter} defaultValue={this.state.orderFilter}/>
                        </Col>
                    </SearchRow>
                </ContainerStyled>
            );   
        }
        return (          
            <ContainerStyled>
                <HeaderRowStyled>
                    <Col>
                        <h1>Orders</h1>
                    </Col>
                </HeaderRowStyled>                              
                <SearchRow>
                    <Col xl="1" xs="4">                   
                        <div className="text-left">
                            <ButtonStyled displayText="New" onClick={this.onClickNewOrder} backgroundColor={constants.rentechTheme.callToActionColor} color={constants.rentechTheme.textLight}/>
                        </div>
                    </Col>
                    <Col xl="3" xs="8">
                        <ListFilter onFilter={this.onFilter} defaultValue={this.state.orderFilter}/>
                    </Col>
                </SearchRow>
                {orders &&
                <GridRow>
                    <Col>                  
                        <DashboardGrid sort={this.sort} onActionChange={this.onActionChange} isAdmin={this.props.session.roleName === constants.ROLE.SystemAdmin ? true : false} stopPropagation={this.stopPropagation} showSourceQuote={true} showInvoicedColumn={true} quotes={orders} onListItemClick={this.onClickEditOrder} />                       
                        <Pagination onPageChange={this.onPageChange} pageCount={pageCount} offset={this.state.offset} currentPage={this.state.currentPage} rowsToFetch={this.state.rowsToFetch} />                               
                    </Col>
                </GridRow>
                }
            </ContainerStyled>
        );
    }    
}

function mapStateToProps(state, ownProps) {    
    return {
        api: state.api,
        session: state.session,
        order: state.order,      
        alert: state.alert,
        quoteOrderCommon: state.quoteOrderCommon,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators(apiActions, dispatch),
        sessionActions: bindActionCreators(sessionActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),        
        alertActions: bindActionCreators(alertActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDashboard));