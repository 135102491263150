import React from 'react';
import styled from 'styled-components';
import { FormGroup, Row, Col } from 'reactstrap';

export const Img = styled.img`
    cursor:pointer;    
    border-radius:15px;
    max-width: 125px !important;   
    border: 5px solid transparent;
    :hover {
        border: 5px ${props => props.theme.selectedImage} solid;
    }
    &.selectedImage {
        border:5px ${props => props.theme.selectedImage} solid !important;
    }
`;

export const Label = styled.label`
    text-align: left;
    font-weight:bold;
    display: block;
`;

export const ImgDesc = styled.div`
    text-align: left;
    max-width: 100px;
`;

export const Ul = styled.ul`
    @media(max-width:1200px) {   
        text-align: center;    
    }
`;

export const RowStyled = styled(Row)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
`;

export const FormGroupStyled = styled(FormGroup)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
`;

const ImageTextHorizontal = props => {   
    
    return (
        <FormGroupStyled className={props.screenOption.IsVisible === false ? 'd-none' : 'image-text-horizontal'}>
            <Label htmlFor={props.screenOption.ID}>{props.caption} {props.screenOption.IsEnabled && <span className="text-danger">*</span> }</Label>
            <RowStyled>
                { props.screenOption.SelectableValues.map(opt => {   
                    return (
                        <Col xl="2" key={opt.Value}>                       
                            <Img  
                                className={opt.Value === props.screenOption.Value ? "selectedImage" : ""}
                                name={props.screenOption.Name} 
                                id={opt.Value} 
                                src={opt.ImageLink} 
                                required={props.screenOption.IsRequired}
                                disabled={!props.screenOption.IsEnabled}
                                alt={opt.Value}
                                tabIndex="99"
                                //onClick={(e) => props.markAsSelected(opt.Value, props.screenOption.Name)}
                                onClick={(e) => props.onImageClicked(opt.Value, props.screenOption.ID, props.screenOption.Name, props.screenOption.AutoContinueMode, props.toggle)} /> 
                            <ImgDesc>{opt.Caption}</ImgDesc>                     
                        </Col>
                    );
                })} 
            </RowStyled>
            {/* <Ul className="list-inline text-left">            
                { props.screenOption.SelectableValues.map(opt => {   
                    return (
                    <LiStyled key={opt.Value} className="list-inline-item mb-2">                       
                        <Img  
                            className={opt.Value === props.screenOption.Value ? "selectedImage" : ""}
                            name={props.screenOption.Name} 
                            id={opt.Value} src={opt.ImageLink} 
                            required={props.screenOption.IsRequired}
                            disabled={!props.screenOption.IsEnabled}
                            alt={opt.Value}
                            onClick={() => props.onImageClicked(opt.Value, props.screenOption.ID, props.screenOption.Name, props.screenOption.AutoContinueMode, props.toggle)} /> 
                        <div className="text-left">{opt.Caption}</div>                     
                    </LiStyled>
                );              
                })}
            </Ul>                                               */}
        </FormGroupStyled>
    );  
};

export default ImageTextHorizontal;