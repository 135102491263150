import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const CheckboxInput = styled.input`    
    height: 1.5em;
    width: 50px;
    position: relative;
    top: 3px;

    @media(max-width:1200px) {        
        width: 25px;        
    }
`;

const CheckBoxPrimitive = React.memo(props => {         
    return (
        <React.Fragment>                                  
            <CheckboxInput type="checkbox" {...props} />                                              
        </React.Fragment>
    );      
});

export default CheckBoxPrimitive;

CheckBoxPrimitive.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool
  };

  CheckBoxPrimitive.defaultProps = {
    disabled: false,
    required: false,
}