
import React from 'react';
import PropTypes from 'prop-types';
import countryList from '../../../modules/countries';

const CountrySelect = React.memo(props => {  
    let states = countryList.getCountries();

    return(
        <select className="form-control" id={props.id} defaultValue={props.defaultValue} onChange={(e) => props.onChange(e, props.addressType)}>
            <option value="--">Select a country</option>
            {states.map(state => {
                return (
                    <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
                );
            })}

        </select>
    );

});

export default CountrySelect;

CountrySelect.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired    
};