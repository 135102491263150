import React from 'react';
import TextInputWithLabel from '../TextInputWithLabel/TextInputWithLabel';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import styled from 'styled-components';

export const TextInputWithLabelStyled = styled(TextInputWithLabel) `
    input { background-color: #f3f3f3; }
`;

const TextInputCPQ = React.memo(props => {   
    return (
        <FormGroup className={props.screenOption.IsVisible === false ? 'd-none' : ''}>                                         
            <TextInputWithLabel                 
                defaultValue={props.screenOption.Value} 
                id={props.screenOption.ID} 
                name={props.screenOption.ID}
                onBlur={(e) => props.textInputOnChange(e, props.screenOption.AutoContinueMode)} 
                disabled={!props.screenOption.IsEnabled} 
                required={props.screenOption.IsRequired} 
                caption={props.caption} 
                autoFocus={props.autoFocus}
            />                               
        </FormGroup>
    );     
});  

export default TextInputCPQ; 

TextInputCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    caption: PropTypes.string.isRequired,
    textInputOnChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool
};