import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TextInputPrimitiveStyled = styled.input`
    padding: 5px;     
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; 
    padding-left: 15px;
    width: 100%;
`;
const TextInputPrimitive = React.memo(props => { 
    
    const handleFocus = (event) => event.target.select();
    
    return (                        
        <TextInputPrimitiveStyled key={props.id} {...props}  onFocus={handleFocus} />        
    );     
});  

export default TextInputPrimitive; 

TextInputPrimitive.propTypes = {
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool
  };

TextInputPrimitive.defaultProps = {
    disabled: false,
    required: false,
    defaultValue: "",
    placeholder: "Please enter a value",
    type: "text"
    
}