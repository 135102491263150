import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import { withRouter } from 'react-router-dom';
import ConfigurationList from './ConfigurationList';

class MyConfigurations extends Component {  

  constructor(props, context) {
    super(props, context);    
    
    this.onReconfigure = this.onReconfigure.bind(this);  
    this.onDelete = this.onDelete.bind(this);
    this.addConfiguration = this.addConfiguration.bind(this);

    let username = this.props.session.username;

    if (this.props.session.username === "" || this.props.session.loggedIn === false) {                    
        this.props.apiActions.getUserConfigurations(username);  
    }               

    this.state = {
       isLoading: true
    }
  }

  componentDidMount() {          

    if(this.props.session.loggedIn === true && this.props.session.username !== "") {
      this.props.apiActions.getUserConfigurations(this.props.session.username).then( () => {
        this.setState({isLoading: false});
      });   
    }
      
  }

  onReconfigure(event, configurationId, headerId) {  
    this.props.history.push({
      pathname: '/Configurator',
      configurationId: configurationId,
      headerId: headerId
    });   
  }

  onDelete(configurationId, headerId) {
    this.props.apiActions.deleteConfiguration(this.props.session.username, configurationId, headerId);
  }

  addConfiguration() {
    this.props.history.push({
      pathname: '/Configurator'
    });
  }

  render() {            
    if (this.state.isLoading || !this.props.api || !this.props.api.userConfigurations) 
        return (
          <div className="p-3">
          <h1 className="text-center">My Designs</h1>         
        </div> 

        );
      
      return (     
        <div className="p-3">
          <h1 className="text-center">My Designs</h1>
          <ConfigurationList addConfiguration={this.addConfiguration} configurationList={this.props.api.userConfigurations} onReconfigure={this.onReconfigure} onDelete={this.onDelete}/>
        </div>           
      );
    }
}

function mapStateToProps(state, ownProps) {    
  return {
    session: state.session,
    api: state.api
  };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),
        apiActions: bindActionCreators(apiActions, dispatch)
    };
}    

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyConfigurations));