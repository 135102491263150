import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Pagination from '../Common/Pagination/Pagination';
import dateFormat from 'dateformat';
import Communication from './Communication';
import PoweredBy from '../Common/PoweredBy/PoweredBy';

export const DeleteButton = styled.h2`
    visibility: hidden;
    font-weight:700;
    font-size: 24px !important;
`;

export const RowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;     
    cursor: pointer;     
    :nth-child(even) {
        background-color: ${props => props.theme.lightBackground};
        margin-top: 0;
        margin-bottom: 0;
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.lightBackground};
        margin-top: 0;
        margin-bottom: 0;
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }   
`;

export const RowFooter = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;   
    background-color: ${props => props.theme.lightBackground};
    margin-top: 0 !important;
    margin-bottom: 0 !important;    
`;

export const Wrapper = styled.div`
    @media(min-width:1200px) {        
        width: 100%;  
    }  
`;

export const LightGrayText = styled.span`
    color: darkgray;
`;

// :hover ${DeleteButton} {
//     visibility: visible;    
// }

/* <Col xl="1">
<DeleteButton>X</DeleteButton>
</Col> */

const CommunicationList = React.memo(props => {       
    if (!props.communicationHistory || props.collapsed === true) return <React.Fragment></React.Fragment>;
    return (           
        <Wrapper>
             <Communication 
                    onCommunicationCollapseClicked={props.onCommunicationCollapseClicked} 
                    collapsed={false} 
                    onAddCommunicationClick={props.onAddCommunicationClick}
                    onSetToPendingInformationClick={props.onSetToPendingInformationClick}
                    showPendingButton={props.showPendingButton}
                    showDraftButton={props.showDraftButton}
                    onSetToDraftClick={props.onSetToDraftClick} />

            {
                props.communicationHistory.length !== 0 &&
                props.communicationHistory.map(c => {
                    let createdDate = dateFormat(c.date, "m/d/yyyy hh:MM TT", true);              
                
                    return(
                        <RowStyled key={c.id}>
                            <Col xl="1" xs="12">
                                <LightGrayText>{createdDate}</LightGrayText>
                            </Col>                                                 
                            <Col xl="11" xs="12">
                                {c.comment} <LightGrayText> - {c.communicationTypeName === 'User' ? c.username : 'System'}</LightGrayText>
                            </Col>                       
                        </RowStyled>
                    );
                })
            }  
            
            <RowFooter>
            <Pagination onPageChange={props.onCommunicationPageChange} pageCount={props.communicationPageCount} offset={props.communicationOffset} currentPage={props.currentCommunicationPage} rowsToFetch={props.communicationRowsToFetch} />                                     
            <PoweredBy />
            </RowFooter>
            
        </Wrapper>       
    );
});

export default CommunicationList;

CommunicationList.propTypes = {
    communicationHistory: PropTypes.array.isRequired,
    collapsed: PropTypes.bool.isRequired,
    onCommunicationCollapseClicked: PropTypes.func.isRequired,
    onCommunicationPageChange: PropTypes.func.isRequired,
    communicationPageCount: PropTypes.number.isRequired,
    communicationOffset: PropTypes.number.isRequired,
    currentCommunicationPage: PropTypes.number.isRequired,
    communicationRowsToFetch: PropTypes.number.isRequired,
    onSetToDraftClick: PropTypes.func,
    showDraftButton: PropTypes.bool
};