// API Actions
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const RESET_APP = 'RESET_APP';
export const SAVE_CONFIGURATION_GUID = 'SAVE_CONFIGURATION_GUID';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const PREPARE_INTERACTIVE_CONFIGURATION = 'PREPARE_INTERACTIVE_CONFIGURATION';
export const PREPARE_INTERACTIVE_SUCCESS = 'PREPARE_INTERACTIVE_SUCCESS';
export const INITIALIZE_CONFIGURATION = 'INITIALIZE_CONFIGURATION';
export const INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS';
export const INITIALIZE_FAILURE = 'INITIALIZE_FAILURE';
export const GET_UI_CONFIG_SUCCESS = 'GET_UI_CONFIG_SUCCESS';
export const FINISH_CONFIG_SUCCESS = 'FINISH_CONFIG_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const LOGIN = 'LOGIN';
export const SAVE_CONFIGURATION = 'SAVE_CONFIGURATION';
export const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS';
export const SET_AUTOCONTINUE_MODE = 'SET_AUTOCONTINUE_MODE';
export const SHOW_CONTINUE_BUTTON = 'SHOW_CONTINUE_BUTTON';
export const HIDE_CONTINUE_BUTTON = 'HIDE_CONTINUE_BUTTON';
export const DELETE_CONFIGURATION = 'DELETE_CONFIGURATION';
export const STORE_UNCOMMITTED_USER_CONFIGURATIONS = 'STORE_UNCOMMITTED_USER_CONFIGURATIONS';
export const CLEAR_UNCOMMITTED_USER_CONFIGURATIONS = 'CLEAR_UNCOMMITTED_USER_CONFIGURATIONS';
export const GET_PRODUCT_CONFIGURATIONS = 'GET_PRODUCT_CONFIGURATIONS';
export const SET_ACTIVE_PRODUCT_SUCCESS = 'SET_ACTIVE_PRODUCT_SUCCESS';
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';
export const DELETE_USER_PRODUCT = 'DELETE_USER_PRODUCT';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const STORE_UNCOMMITTED_SCREEN_OPTION = 'STORE_UNCOMMITTED_SCREEN_OPTION';
export const CLEAR_UNCOMMITTED_SCREEN_OPTIONS = 'CLEAR_UNCOMMITTED_SCREEN_OPTIONS';
export const COMMIT_UNCOMMITTED_SCREEN_OPTIONS = 'COMMIT_UNCOMMITTED_SCREEN_OPTIONS';
export const GET_COMPANY_PRODUCTS_SUCCESS = 'GET_COMPANY_PRODUCTS_SUCCESS';
export const GET_USER_ACTIVE_PRODUCT = 'GET_USER_ACTIVE_PRODUCT';
export const GET_USER_ACTIVE_PRODUCT_SUCCESS = 'GET_USER_ACTIVE_PRODUCT_SUCCESS';
export const RELOAD_CONFIGURATOR = 'RELOAD_CONFIGURATOR';
export const PAUSE_EXECUTION = 'PAUSE_EXECUTION';
export const CLEAR_CONFIGURATOR = 'CLEAR_CONFIGURATOR';
export const REMOVE_UNCOMMITTED_SCREEN_OPTION = 'REMOVE_UNCOMMITTED_SCREEN_OPTION';
export const LAST_SCREEN_OPTION_MODIFIED = 'LAST_SCREEN_OPTION_MODIFIED';

// Alert Actions
export const SHOW_SUCCESS_ALERT = 'SHOW_SUCCESS_ALERT';
export const SHOW_FAILURE_ALERT = 'SHOW_FAILURE_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

// Session Actions
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SHOW_SUBMIT_CONFIGURATION_BUTTON = 'SHOW_SUBMIT_CONFIGURATION_BUTTON';
export const COLLECT_EMAIL_ADDRESS = 'COLLECT_EMAIL_ADDRESS';
export const CONTINUE_BUTTON_SETUP = 'CONTINUE_BUTTON_SETUP';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SHOW_SIGNUP_MODAL = 'SHOW_SIGNUP_MODAL';
export const RECONFIGURE = 'RECONFIGURE';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_USER_PERMISSION_LEVEL = 'SET_USER_PERMISSION_LEVEL';
export const SET_COMPANY_ACCESS_LEVEL = 'SET_COMPANY_ACCESS_LEVEL';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_CONFIGURATOR_MODE = 'SET_CONFIGURATOR_MODE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_LOCATIONS = 'SET_USER_LOCATIONS';
export const SET_USER_ROLE_LEVEL = 'SET_USER_ROLE_LEVEL';
export const SET_MANUFACTURER = 'SET_MANUFACTURER';
export const SET_COMPANY_TYPE_ID = 'SET_COMPANY_TYPE_ID';
// validation Actions
export const REQUIRED_SCREEN_OPTIONS = 'REQUIRED_SCREEN_OPTIONS';

// quote actions
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const CREATE_QUOTE_LINE = 'CREATE_QUOTE_LINE';
export const UPDATE_QUOTE_LINE = 'UPDATE_QUOTE_LINE';
export const DELETE_QUOTE_LINE = 'DELETE_QUOTE_LINE';
export const GET_QUOTE_LINES = 'GET_QUOTE_LINES';
export const GET_QUOTE_LINES_SUCCESS = 'GET_QUOTE_LINES_SUCCESS';
export const UPDATE_QUOTE_LINE_QUANTITY = 'UPDATE_QUOTE_LINE_QUANTITY';
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS';
export const SET_ACTIVE_QUOTE = 'SET_ACTIVE_QUOTE';
export const GET_QUOTE_ADDRESSES = 'GET_QUOTE_ADDRESSES';
export const GET_QUOTE_ADDRESSES_SUCCESS = 'GET_QUOTE_ADDRESSES_SUCCESS';
export const GET_QUOTE_CONTACT = 'GET_QUOTE_CONTACT';
export const GET_QUOTE_CONTACT_SUCCESS = 'GET_QUOTE_CONTACT_SUCCESS';
export const GET_QUOTE_HEADER_SUCCESS = 'GET_QUOTE_HEADER_SUCCESS';
export const GET_STATUS_TYPES = 'GET_STATUS_TYPES';
export const GET_STATUS_TYPES_SUCCESS = 'GET_STATUS_TYPES_SUCCESS';
export const EMAIL_QUOTE = 'EMAIL_QUOTE';
export const COPY_QUOTE = 'COPY_QUOTE';
export const QUOTE_SIGN_OUT = 'QUOTE_SIGN_OUT';
export const CREATE_QUOTE_COMMUNICATION = 'CREATE_QUOTE_COMMUNICATION';
export const GET_QUOTE_COMMUNICATIONS = 'GET_QUOTE_COMMUNICATIONS';
export const GET_QUOTE_COMMUNICATIONS_SUCCESS = 'GET_QUOTE_COMMUNICATIONS_SUCCESS';
export const GET_QUOTE_BY_ID = 'GET_QUOTE_BY_ID';
export const GET_QUOTE_BY_ID_SUCCESS = 'GET_QUOTE_BY_ID_SUCCESS';
export const DOWNLOAD_FILE_RETRIEVED = 'DOWNLOAD_FILE_RETRIEVED';
export const GET_USER_COMPANY_LOCATIONS_SUCCESS = 'GET_USER_COMPANY_LOCATIONS_SUCCESS';
export const GET_USERS_ASSIGNED_TO_LOCATION_SUCCESS = 'GET_USERS_ASSIGNED_TO_LOCATION_SUCCESS';
export const GET_COMPANY_LOCATION_INSTALLERS_SUCCESS = 'GET_COMPANY_LOCATION_INSTALLERS_SUCCESS';
export const CONVERT_QUOTE_TO_ORDER = 'CONVERT_QUOTE_TO_ORDER';
export const UPDATE_PO_NUMBER_REDUX_ONLY = 'UPDATE_PO_NUMBER_REDUX_ONLY';
export const DELETE_QUOTE_ATTACHMENT_SUCCESS = 'DELETE_QUOTE_ATTACHMENT_SUCCESS';
export const GET_QUOTE_ATTACHMENTS_SUCCESS = 'GET_QUOTE_ATTACHMENTS_SUCCESS';
export const UPDATE_QUOTE_COMPANY_LOCATION_ID = 'UPDATE_QUOTE_COMPANY_LOCATION_ID';
export const CLEAR_QUOTE = 'CLEAR_QUOTE';
export const GET_QUOTE_COMPANY_LOCATION_FABRICATOR_SUCCESS = 'GET_QUOTE_COMPANY_LOCATION_FABRICATOR_SUCCESS';
export const ORDER_CONVERTED_TO = 'ORDER_CONVERTED_TO';
//export const GET_QUOTE_LINES_SUCCESS = 'GET_QUOTE_LINES_SUCCESS';

// WORKFLOWS
export const GET_WORKFLOWS = 'GET_WORKFLOWS';
export const GET_WORKFLOWS_SUCCESS = 'GET_WORKFLOWS_SUCCESS';
export const CREATE_CANCELLATION_REQUEST = 'CREATE_CANCELLATION_REQUEST';

// ORDER
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_COMMUNICATIONS_SUCCESS = 'GET_ORDER_COMMUNICATIONS_SUCCESS';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const GET_ORDER_CONTACT_SUCCESS = 'GET_ORDER_CONTACT_SUCCESS';
export const GET_ORDER_HEADER_SUCCESS = 'GET_ORDER_HEADER_SUCCESS';
export const GET_ORDER_ADDRESSES_SUCCESS = 'GET_ORDER_ADDRESSES_SUCCESS';
export const DELETE_ORDER_ATTACHMENT_SUCCESS = 'DELETE_ORDER_ATTACHMENT_SUCCESS';
export const GET_ORDER_ATTACHMENTS_SUCCESS = 'GET_ORDER_ATTACHMENTS_SUCCESS';
export const GET_ORDER_INVOICES_SUCCESS = 'GET_ORDER_INVOICES_SUCCESS';
export const GET_ORDER_LINES_SUCCESS = 'GET_ORDER_LINES_SUCCESS';
export const UPDATE_ORDER_COMPANY_LOCATION_ID = 'UPDATE_ORDER_COMPANY_LOCATION_ID';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const GET_ORDER_COMPANY_LOCATION_FABRICATOR_SUCCESS = 'GET_ORDER_COMPANY_LOCATION_FABRICATOR_SUCCESS';


// QUOTE ORDER COMMON
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const UPDATE_CONTACT_FIELD_SUCCESS ='UPDATE_CONTACT_FIELD_SUCCESS';
export const CLEAR_USERS_ASSIGNED_TO_LOCATIONS = 'CLEAR_USERS_ASSIGNED_TO_LOCATIONS';
export const GET_QUOTE_ORDER_COMMON_OVERVIEW = 'GET_QUOTE_ORDER_COMMON_OVERVIEW';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const GET_SHIP_TO_TYPES_SUCCESS = 'GET_SHIP_TO_TYPES_SUCCESS';
export const GET_ATTACHMENTS = 'GET_ATTACHMENTS';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const GET_COMPANY_LOCATION_DESIGNERS_SUCCESS = 'GET_COMPANY_LOCATION_DESIGNERS_SUCCESS';
export const CLEAR_DESIGNERS = 'CLEAR_DESIGNERS';

// ADMIN
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const GET_ACTIVE_USERS_COMPANY_LOCATIONS_SUCCESS = 'GET_ACTIVE_USERS_COMPANY_LOCATIONS_SUCCESS';
export const GET_ACTIVE_USER_ROLE = 'GET_ACTIVE_USER_ROLE';
export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const GET_COMPANY_LOCATIONS_SUCCESS = 'GET_COMPANY_LOCATIONS_SUCCESS';
export const GET_FABRICATOR_LOCATIONS_SUCCESS = 'GET_FABRICATOR_LOCATIONS_SUCCESS';
export const GET_INSTALLER_LOCATIONS_SUCCESS = 'GET_INSTALLER_LOCATIONS_SUCCESS';
export const SET_ACTIVE_COMPANY_LOCATION = 'SET_ACTIVE_COMPANY_LOCATION';
export const GET_COMPANY_LOCATION_ADDRESSES_SUCCESS = 'GET_COMPANY_LOCATION_ADDRESSES_SUCCESS';
// export const GET_COMPANY_LOCATION_BRANCHES = 'GET_COMPANY_LOCATION_BRANCHES';
// export const GET_COMPANY_LOCATION_BRANCHES_SUCCESS = 'GET_COMPANY_LOCATION_BRANCHES_SUCCESS';
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_FABRICATORS_SUCCESS = 'GET_ALL_FABRICATORS_SUCCESS';
export const GET_ALL_INSTALLERS_SUCCESS = 'GET_ALL_INSTALLERS_SUCCESS';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_AD_PATCH_TYPES = 'GET_AD_PATCH_TYPES';
export const GET_AD_PATCH_TYPES_SUCCESS = 'GET_AD_PATCH_TYPES_SUCCESS';