import React from 'react';
import CheckboxWithLabel from '../CheckBoxWithLabel/CheckBoxWithLabel';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

const CheckBoxCPQ = React.memo(props => {            
    return (
        <FormGroup className={props.screenOption.IsVisible === false ? 'd-none' : ''}>    
            <CheckboxWithLabel 
                id={props.screenOption.ID}
                labelCaption={props.caption}
                isRequired={props.screenOption.IsRequired}
                isChecked={props.screenOption.Value === "True"}
                value={props.screenOption.Value}                
                disabled={!props.screenOption.IsEnabled}                
                onCheckChanged={(e) => props.onCheckChanged(e, props.screenOption.AutoContinueMode)}                
                name={props.screenOption.ID}
                autoFocus={props.autoFocus}
            />                                                            
        </FormGroup>
    );      
});

export default CheckBoxCPQ;

CheckBoxCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    caption: PropTypes.string.isRequired,
    onCheckChanged: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool      
};