import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import Icon from '../QuoteOrderCommon/Icon';
import * as constants from '../../modules/constants';

export const Img = styled.img`
    width:25px;
    height: auto;
`;
export const ColStyled = styled(Col)`
    text-decoration: underline;
    cursor: pointer;
`;

export const DownloadLinkStyled = styled.div`    
    display: inline;    
`;

export const FaxTemplateLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    font-size: 17px;
`;

const QuoteAttachmentList = React.memo(props => {
    let orderMessage = 'drawing and purchase order';
    let quoteMessage = 'drawing';

    let message = props.isOrder === true ? orderMessage : quoteMessage;

    return (           
        <Row>
            <Col>
            <h1>Attachments</h1>            
            <p>
                Please add a drawing or PO if the documents were not uploaded or see the comments below. 
                To fax the {message} to the Fabricator 
                    &nbsp;
                    {/* <FaxTemplateLink onClick={(e) => props.onClickDownloadFile(`uploads\\4dff12de7ef9bb31cba0be396431da9b`, "Allow CORS requests in IIS 8.docx", "application/vnd.open", "4dff12de7ef9bb31cba0be396431da9b")}>
                        Fax Template
                    </FaxTemplateLink> */}
                    <FaxTemplateLink onClick={(e) => props.faxTemplate(e)}>Click Here</FaxTemplateLink>
                    &nbsp;
                 
            </p>            
            {props.attachments && props.attachments.length > 0 &&
            <Row>
            {props.attachments.map(file => {                 
                let uploadDate = dateFormat(file.uploadDate, "m/d/yyyy hh:MM TT", true);                          
                return( 
                    <React.Fragment>
                        <ColStyled xl="4" xs="12" key={file.id} >
                            <DownloadLinkStyled onClick={(e) => props.onClickDownloadFile(file.path, file.originalName, file.mimetype, file.filename)}>
                                <Img src="/images/attachments.png" alt="renaissancetech"  />{file.originalName} - {uploadDate}                                                                                                                          
                            </DownloadLinkStyled>

                            {props.readonly === false && ((file.attachmentTypeId !== 2 && props.isStatusConfirm === true) || props.isStatusConfirm === false)  &&
                                <DownloadLinkStyled onClick={(e) => props.onDeleteAttachment(file.id)}>
                                    <Icon height="16px" iconName="trash" title="delete" isLine={false}  /> 
                                </DownloadLinkStyled>
                            }
                        </ColStyled>                                                                            
                    </React.Fragment>                                           
                );
            })}
            </Row>
            }
            </Col>
        </Row>
        
    );
});

export default QuoteAttachmentList;

QuoteAttachmentList.propTypes = {
    attachments: PropTypes.array.isRequired,
    onClickDownloadFile: PropTypes.func.isRequired,
    companyLocationName: PropTypes.string.isRequired,
    companyLocationFax: PropTypes.string.isRequired,
    readonly: PropTypes.bool,
    faxTemplate: PropTypes.string.isRequired,
    isOrder: PropTypes.bool,
    isStatusConfirm: PropTypes.bool
};