import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from '../reducers';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState) {   
    let enhancer;
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&

    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ 
    trace: true, 
    traceLimit: 25 
}) || compose; 
//const store = createStore( reducer, composeEnhancers(applyMiddleware(sagaMiddleware))

    // const composeEnhancers = composeWithDevTools({          
    //     trace: true, 
    //     traceLimit: 25 
    // }); 

    // if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    //     enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(thunk, reduxImmutableStateInvariant()));
    // } else {
    //     enhancer = compose(
    //         applyMiddleware(thunk, reduxImmutableStateInvariant())          
    //     );
    // }
    return createStore( 
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
    );
}