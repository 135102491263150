import * as types from './actionTypes';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ErrorHandler from '../modules/errorHandler';
import * as alerts from './alertActions';
import * as quoteOrderCommonActions from './quoteOrderCommonActions';

export const createUser = (selectedLocationsArray, roleId, username, password, firstName, lastName, companyId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/createUser', { selectedLocationsArray: selectedLocationsArray, roleId: roleId, username: username, password: password, firstName: firstName, lastName: lastName, companyId: companyId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                alerts.showFailureAlert(res);                
            }  else {      
                console.log(res.data);       
                if (res.data.result.includes("existing") === true){
                    dispatch(alerts.showSuccessAlert(res.data.result));
                } else if (res.data.result === "in use") {
                    dispatch(alerts.showFailureAlert("Username already in use at the given location."));
                }
                else { 
                dispatch(alerts.showSuccessAlert("User successfully created!"));
                }
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const updateUser = (selectedLocationsArray, roleId, username, firstName, lastName, companyId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/updateUser', { selectedLocationsArray: selectedLocationsArray, roleId: roleId, username: username, firstName: firstName, lastName: lastName, companyId: companyId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                alerts.showFailureAlert(res);                
            }  else {                 
                dispatch(alerts.showSuccessAlert("User successfully updated!"));
                dispatch(getActiveUsersCompanyLocations(username));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getCompanyLocations = (company, fabricatorId, installerId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getCompanyLocations', { company: company, fabricatorId: fabricatorId, installerId: installerId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                alerts.showFailureAlert(res);                
            }  else {                 
               dispatch(getCompanyLocationsSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getAdPatchTypes = (company) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getAdPatchTypes', { company: company })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                alerts.showFailureAlert(res);                
            }  else {                 
               dispatch(getAdPatchTypesSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const deleteCompanyLocation = (companyLocationId, company, fabricatorId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/deleteCompanyLocation', { companyLocationId: companyLocationId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                
            }  else {                 
               dispatch(getCompanyLocations(company, fabricatorId));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getFabricatorLocations = (fabricatorId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getFabricatorLocations', { fabricatorId: fabricatorId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                alerts.showFailureAlert(res);                
            }  else {                 
               dispatch(getFabricatorLocationsSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getInstallerLocations = (installerId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getInstallerLocations', { installerId: installerId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                alerts.showFailureAlert(res);                
            }  else {                 
               dispatch(getInstallerLocationsSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const updateUserCompanyLocation = (locationId, locationValue, locationChecked) => {
    return dispatch => {
        return axios.post('/api/v1/administration/updateUserCompanyLocation', { locationId: locationId, locationValue: locationValue, locationChecked })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            }                              
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getCompanyLocationAddresses = (companyLocationId) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getCompanyLocationAddresses', { companyLocationId: companyLocationId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getCompanyLocationAddressesSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const updateCompanyAddress = (id, sqlColumn, value) => {
    return dispatch => {
        return axios.post('/api/v1/administration/updateCompanyAddress', { id: id, sqlColumn: sqlColumn, value: value })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            }                        
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }    
}

export const getActiveUsersCompanyLocations = (username) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getActiveUsersCompanyLocations', { username: username })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getActiveUsersCompanyLocationsSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getActiveUserRole = (username) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getActiveUserRole', { username: username })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getActiveUserRoleSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getAllProducts = () => {
    return dispatch => {
        return axios.post('/api/v1/administration/getAllProducts')
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getAllProductsSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const updateCompanyLocation = (id, column, value) => {
    return dispatch => {
        return axios.post('/api/v1/administration/updateCompanyLocation', { id: id, column: column, value: value })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            }                         
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

// export const updateCompanyBranch = (companyLocationId, branchId) => {
//     return dispatch => {
//         return axios.post('/api/v1/administration/updateCompanyBranch', { companyLocationId: companyLocationId, branchId: branchId })
//         .then(res => {                
//             if (res.status !== 200) {
//                 ErrorHandler.logError(res);
//                 dispatch(alerts.showFailureAlert(res));                
//             }                  
//         }).catch(function(error) {
//             ErrorHandler.logError(error);                               
//         });
//     }
// }

// export const getAllCompanyBranches = (companyLocationId) => {
//     return dispatch => {
//         return axios.post('/api/v1/administration/getAllCompanyBranches', { companyLocationId: companyLocationId })
//         .then(res => {                
//             if (res.status !== 200) {
//                 ErrorHandler.logError(res);                
//                 dispatch(alerts.showFailureAlert(res));                
//             } else {
//                 if (res.data.result.length > 0)
//                     dispatch(getAllCompanyBranchesesSuccess(res.data.result));  
//             }                       
//         }).catch(function(error) {
//             ErrorHandler.logError(error);                               
//         });
//     }
// }

// export const getAllCompanyLocationBranch = (company, companyLocationId) => {
//     return dispatch => {
//         return axios.post('/api/v1/administration/getAllCompanyBranches', { company: company, companyLocationId: companyLocationId })
//         .then(res => {                
//             if (res.status !== 200) {
//                 ErrorHandler.logError(res);                
//                 dispatch(alerts.showFailureAlert(res));                
//             } else {
//                 if (res.data.result.length > 0)
//                     dispatch(getAllCompanyBranchesesSuccess(res.data.result));  
//             }                       
//         }).catch(function(error) {
//             ErrorHandler.logError(error);                               
//         });
//     }
// }

export const createCompanyLocation = (company, username) => {
    return dispatch => {
        return axios.post('/api/v1/administration/createCompanyLocation', { company: company, username: username })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {                
                dispatch(setActiveCompanyLocation(res.data.result[0].companyLocationId));
                dispatch(getCompanyLocationAddresses(res.data.result[0].companyLocationId));
                dispatch(getCompanyLocations(company));
            }                        
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const createCompany = (manufacturer) => {
    return dispatch => {
        return axios.post('/api/v1/administration/createCompany', { manufacturer: manufacturer })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {                
                dispatch(setActiveCompany(res.data.result[0].companyId));
                dispatch(getAllCompanies(manufacturer));
            }                        
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }    
}

export const getAllCompanies = (manufacturer, includeFabricators, includeInstallers) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getAllCompanies', { manufacturer: manufacturer, includeFabricators: includeFabricators, includeInstallers: includeInstallers })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getAllCompaniesSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getAllFabricators = (manufacturer) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getAllFabricators', { manufacturer: manufacturer })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getAllFabricatorsSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }    
}

export const getAllInstallers = (manufacturer) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getAllInstallers', { manufacturer: manufacturer })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(getAllInstallersSuccess(res.data.result));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }   
}

export const updatePasswordAdmin = (username, password) => {
    return dispatch => {
        return axios.post('/api/v1/administration/updatePasswordAdmin', { username: username, password: password })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                dispatch(alerts.showSuccessAlert("Password updated successfully!"));
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }   
}

export const searchByUsername = (username) => {
    return dispatch => {
        return axios.post('/api/v1/administration/getUserByUsername', { username: username })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);
                dispatch(alerts.showFailureAlert(res));                
            } else {
                if (res.data.result && res.data.result.length > 0) { 
                    console.log(res.data.result);
                    dispatch(setActiveUser(res.data.result[0].username));                    
                    dispatch(setActiveCompany(res.data.result[0].companyId));
                    dispatch(getCompanyLocations(res.data.result[0].company));                      
                    dispatch(getActiveUsersCompanyLocations(res.data.result[0].username));
                    dispatch(quoteOrderCommonActions.getUsersAssignedToLocation(res.data.result[0].companyLocationId));
                } else {
                    dispatch(alerts.showFailureAlert(`No user found with username ${username}.`));
                }
            }                           
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }       
}

export function getAllProductsSuccess(products) {
    return { type: types.GET_ALL_PRODUCTS_SUCCESS, products };
}

export function getAllCompaniesSuccess(companies) {
    return { type: types.GET_ALL_COMPANIES_SUCCESS, companies };
}

export function getAllFabricatorsSuccess(fabricators) {
    return { type: types.GET_ALL_FABRICATORS_SUCCESS, fabricators };
}

export function getAllInstallersSuccess(installers) {
    return { type: types.GET_ALL_INSTALLERS_SUCCESS, installers };
}

export function getCompanyLocationAddressesSuccess(addresses) {
    return { type: types.GET_COMPANY_LOCATION_ADDRESSES_SUCCESS, addresses };
}

export function getActiveUsersCompanyLocationsSuccess(activeUserLocations) {
    return { type: types.GET_ACTIVE_USERS_COMPANY_LOCATIONS_SUCCESS, activeUserLocations };
}

export function setActiveUser(username) {
    return { type: types.SET_ACTIVE_USER, username };
}

export function setActiveCompanyLocation(companyLocationId) {
    return { type: types.SET_ACTIVE_COMPANY_LOCATION, companyLocationId };
}

export function getActiveUserRoleSuccess(role) {
    return { type: types.GET_ACTIVE_USER_ROLE, role };
}

export function getCompanyLocationsSuccess(companyLocations) {
    return { type: types.GET_COMPANY_LOCATIONS_SUCCESS, companyLocations };
}

export function getFabricatorLocationsSuccess(fabricatorLocations) {
    return { type: types.GET_FABRICATOR_LOCATIONS_SUCCESS, fabricatorLocations };
}

export function getInstallerLocationsSuccess(installerLocations) {
    return { type: types.GET_INSTALLER_LOCATIONS_SUCCESS, installerLocations };
}

// export function getAllCompanyBranchesesSuccess(branches) {
//     return { type: types.GET_COMPANY_LOCATION_BRANCHES_SUCCESS, branches };
// }

export function setActiveCompany(companyId) {
    return { type: types.SET_ACTIVE_COMPANY, companyId };
}

export function getAdPatchTypesSuccess(adPatchTypes) {
    return { type: types.GET_AD_PATCH_TYPES_SUCCESS, adPatchTypes };
}