import formatter from '../../modules/formatter'; 
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteActions from '../../actions/quoteActions';
import * as constants from '../../modules/constants';

class QuotePrint extends Component {  

    buildLineHTML(Lines) {
        let totalPrice = 0; 
        let totalQuantity = 0;
        let LinesHTML = `
            <tr>            
                <th>Product</th>
                <th>Quantity</th>
                <th>Unit Price</th>                
                <th>Total Price</th>
            </tr>
        `;
    
        Lines.forEach(line => {  
            totalPrice = totalPrice + (line.price * line.quantity);
            let totalLinePrice = line.price * line.quantity;
            totalQuantity = totalQuantity + line.quantity;
            LinesHTML = LinesHTML + `          
            <tr>
                <td>${line.productName}</td>
                <td class="number">${line.quantity}</td>
                <td class="number">${formatter.currency(line.price)}</td>                
                <td class="number">${formatter.currency(totalLinePrice)}</td>
            </tr>                
        `});
    
        LinesHTML = LinesHTML + `
            <tr class="totalRow">
                <td class="bold">Total</td>
                <td class="number">${totalQuantity}</td>
                <td></td>
                <td class="number">${formatter.currency(totalPrice)}</td>
            </tr>
        `;
    
        return LinesHTML;
    }
    
    buildAddressesHTML(Addresses) {
        let AddressesHTML = `
            <tr>        
                <th>Address Type</th>    
                <th>Address</th>
                <th>Address2</th>
                <th>City</th>                
                <th>State</th>
                <th>Country</th>
                <th>Zip</th>
            </tr>
        `;
    
        Addresses.forEach(address => {                 
            let addressType = address.AddressType === constants.ADDRESS_TYPES.Billing ? "Billing" : "Shipping";
                    
            AddressesHTML = AddressesHTML + `          
            <tr>                
                <td>${addressType}</td>
                <td>${address.Address}</td>
                <td>${address.Address2}</td>
                <td>${address.City}</td>                                
                <td>${address.State}</td>  
                <td>${address.Country}</td>  
                <td>${address.Zip}</td>  
            </tr>                
        `});
    
        return AddressesHTML;
    }
    
    buildQuoteEmailBody() {       
        let LinesHTML = this.buildLineHTML(this.props.quote.currentLines);
        let AddressesHTML = this.buildAddressesHTML(this.props.quote.currentAddresses);
        let contactFullName = this.props.quote.currentContact[0].firstName + " " + this.props.quote.currentContact[0].lastName;
        // - ${this.props.quote.quoteName}
        let body = 
        `<!DOCTYPE html>
            <html>
                <head>
                    <style>
                        * { font-family: Calibri; font-size:17px; }
                        p { font-family: Calibri; font-size:17px; }
                        div { font-family: Calibri; font-size:17px; }
                        th, td { padding: 15px; text-align: left; border-bottom: 1px solid #ddd; width: 150px;}                        
                        a { color: blue; }
                        .totalRow td { border-bottom: none; }
                        .number {}                        
                        .bold { font-weight: bold; }
                        .label { font-weight: bold; display: inline-block; margin-bottom: 25px; }  
                        .navbar { display: none; }  
                        body { margin: 1 em; }        
                        .left { text-align: left; }    
                        h1, h2 { margin-bottom: 1em; margin-top: 1em;}                                   
                    </style> 
                </head>
                <body>
                    <div class="left"><img src="http://www.hartson-kennedy.com/wp-content/uploads/2018/07/hartson-kennedy-logo-600x81.png" alt="HK" /></div>
                    <h1>Quote ID: ${this.props.quote.currentQuoteId}</h1>                                        
                    <div><span class="label">Prepared For: </span><span>${contactFullName}</span></div>
                        
                    <h1>Addresses</h1>
                    <table>${AddressesHTML}</table>

                    <h1>Quote Lines</h1>
                    <table>${LinesHTML}</table>
                </body>
            </html>
        `;
    
        return body;
    }

    render() {    

        if (!this.props.quote || !this.props.quote.currentContact) 
            return <React.Fragment></React.Fragment>;

        return (
            <div dangerouslySetInnerHTML={{__html: this.buildQuoteEmailBody()}} />
        );
    }
}


function mapStateToProps(state, ownProps) {    
    return {
      api: state.api,
      session: state.session,
      quote: state.quote
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators( apiActions, dispatch),
        sessionActions: bindActionCreators( sessionActions, dispatch),
        quoteActions: bindActionCreators(quoteActions, dispatch)
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuotePrint));