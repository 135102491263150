import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as adminActions from '../../actions/adminActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as alertActions from '../../actions/alertActions';
import { withRouter } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Row, Col } from 'reactstrap';

import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import CheckBoxWithLabel from '../Common/CheckBoxWithLabel/CheckBoxWithLabel';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import * as constants from '../../modules/constants';
import { loadProgressBar } from 'axios-progress-bar';
import Address from '../QuoteOrderCommon/AddressForm';
import 'axios-progress-bar/dist/nprogress.css';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    margin-bottom: 1.5em;
`;

export const LabelStyled = styled(Label)`
    font-weight: bold;    
`;

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input {width: 100% !important; }
`;

export const PasswordResetButton = styled(Button)`
    &#passwordReset {
        width: 160px !important;   
    }
`;

export const RowStyled = styled(Row)`
margin-bottom: 25px;
`

export const CheckBoxWithLabelStyled = styled(CheckBoxWithLabel)`
    margin-bottom: 15px;
`;

export const ContainerStyled = styled(Container)`
    padding-left: 1% !important;
    padding-right: 1% !important;
`;

class ModifyCompany extends Component {  
    constructor(props, context) {
        super(props, context);   
        
        loadProgressBar();
        
        this.formValueChange = this.formValueChange.bind(this);      
        this.cancel = this.cancel.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onAddressFieldUpdate = this.onAddressFieldUpdate.bind(this);
        this.updateField = this.updateField.bind(this);
        this.getSelectableValuesFromConstants = this.getCompanyTypeValues.bind(this);

        this.state = ({
            id: '',       
            name: '',
            companyTypeId: '',
            billingId: '',
            billingAddress: '',
            billingAddress2: '',
            billingCity: '',
            billingState: '',
            billingZip: '',
            billingCountry: '',
            shippingId: '',
            shippingAddress: '',
            shippingAddress2: '',
            shippingCity: '',
            shippingState: '',
            shippingZip: '',
            shippingCountry: '',
            manufacturer: '',
            editMode: false,
            loading: true,
            onFocusValue: ''
        });                
    }

    componentDidMount() {       
        if (this.props.admin.activeCompanyId === null) {
            this.setState({ loading: false });
            return;
        }

        this.props.adminActions.getAllCompanies(this.props.session.manufacturer).then(() => {
            let companies = this.props.admin.companyList;
            if (!companies) return;
    
            let activeCompany = companies.filter(company => company.id === this.props.admin.activeCompanyId);
            if (activeCompany.length > 0 ) {
                this.setState({ 
                    id: activeCompany[0].id,
                    name: activeCompany[0].company, 
                    companyTypeId: activeCompany[0].companyTypeId,
                    billingId: activeCompany[0].billingId,
                    billingAddress: activeCompany[0].billingAddress,
                    billingAddress2: activeCompany[0].billingAddress2,
                    billingCity: activeCompany[0].billingCity,
                    billingState: activeCompany[0].billingState,
                    billingZip: activeCompany[0].billingZip,
                    billingCountry: activeCompany[0].billingCountry,
                    shippingId: activeCompany[0].shippingId,
                    shippingAddress: activeCompany[0].shippingAddress,
                    shippingAddress2: activeCompany[0].shippingAddress2,
                    shippingCity: activeCompany[0].shippingCity,
                    shippingState: activeCompany[0].shippingState,
                    shippingZip: activeCompany[0].shippingZip,
                    shippingCountry: activeCompany[0].shippingCountry,
                    manufacturer: this.props.session.manufacturer,
                    loading: false, editMode: true
                });
            }    
        });           
    }
    
    formValueChange(event) {   
        let value = event.target.value;        
        this.updateField(this.props.admin.activeCompanyId, event.target.id, value, constants.TABLES.Company);

        if (event.target.id === constants.COMPANY_SQL_COLUMNS.CompanyTypeId) {
            this.setState({ companyTypeId: value })            
        }
    }   
 
    cancel() {
        this.props.history.push({ pathname: '/CompanyList' });
    }

    validate() {
        if (!this.state.name) {
            this.props.alertActions.showFailureAlert("Please enter a company name");
            return false;
        }

       return true;
    }     
    
    onInputFocus(event) {        
        this.setState({onFocusValue: event.target.value});
    }

    updateField(id, column, value, table) {
        this.props.quoteOrderCommonActions.updateField(id, column, value, table).then(() => {
            this.props.adminActions.getAllCompanies(this.props.session.manufacturer);
        });
    }

    onAddressFieldUpdate(event, addressType) {
        if (this.state.onFocusValue === event.target.value) return;        

        this.updateAddress(event.target.id, event.target.value, addressType); 
        if (event.target.id === 'State') {
            if (addressType === constants.ADDRESS_TYPES.Billing)
                this.setState({ billingState: event.target.value });
            else 
                this.setState({ shippingState: event.target.value });
        } 
    }

    updateAddress(column, value, type) {        
        let id = type === constants.ADDRESS_TYPES.Billing ? this.state.billingId : this.state.shippingId;

        this.props.adminActions.updateCompanyAddress(id, column, value);
    }

    getCompanyTypeValues(companyTypes) {
        let types = [];
        for (var i = 0; i < Object.keys(companyTypes).length; i++) {
            let opt = {
              Value: companyTypes[Object.keys(companyTypes)[i]].toString(),
              Caption: Object.keys(companyTypes)[i].toString()
            }
      
            types.push(opt);
        }   
        return types;     
    }

    render() {               
        if (!this.state.loading && this.state.editMode && !this.props.admin.companyList) return <React.Fragment />;
        
        let companyTypes = this.getCompanyTypeValues(constants.COMPANY_TYPES);
                                  
        return (     
        <ContainerStyled className="add-company p-3">            
            <h1 className="text-center">Company</h1>
            <Form id="company">
                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="company" caption="Name" defaultValue={this.state.name} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>

                <LabelStyled for="companyTypeId">Type</LabelStyled>
                <SelectPrimitiveStyled id="companyTypeId" value={this.state.companyTypeId} selectableValues={companyTypes} placeHolder="Select Company Type" onChange={(e) => this.formValueChange(e)} required={true} />                

                <RowStyled>
                    <Col>
                        <Address 
                            onInputFocus={this.onInputFocus} 
                            addressType={constants.ADDRESS_TYPES.Billing} 
                            title='Billing Address' 
                            onAddressFieldUpdate={this.onAddressFieldUpdate} 
                            state={this.state.billingState}
                            address={this.state.billingAddress}
                            city={this.state.billingCity}                            
                            address2={this.state.billingAddress2}
                            country={this.state.billingCountry}
                            zip={this.state.billingZip} 
                        />  
                    </Col>
                </RowStyled>
                <RowStyled>
                    <Col>
                        <Address 
                            onInputFocus={this.onInputFocus} 
                            addressType={constants.ADDRESS_TYPES.Shipping} 
                            title='Shipping Address' 
                            onAddressFieldUpdate={this.onAddressFieldUpdate} 
                            state={this.state.shippingState}
                            address={this.state.shippingAddress}
                            city={this.state.shippingCity}                            
                            address2={this.state.shippingAddress2}
                            country={this.state.shippingCountry}
                            zip={this.state.shippingZip} 
                        /> 
                    </Col>
                </RowStyled>

                <div className="text-center">                    
                    <ButtonStyled onClick={this.cancel} backgroundColor="#e2231a" displayText="Back" />
                </div>
            </Form>
        </ContainerStyled>           
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      session: state.session,      
      quoteOrderCommon: state.quoteOrderCommon,
      alert: state.alert,
      admin: state.admin
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),          
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
    };
}   

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModifyCompany));