
import * as constants from './constants';
import moment from 'moment';

export function isLocked(role, statusId) {             
    if (role === constants.ROLE.User) {
        if (statusId === constants.STATUS_TYPES.InProgressAccepted || statusId === constants.STATUS_TYPES.Sent || statusId === constants.STATUS_TYPES.ConvertedToOrder || statusId === constants.STATUS_TYPES.Shipped || statusId === constants.STATUS_TYPES.Completed || statusId === constants.STATUS_TYPES.SubmittedForApproval) {
            return true;
        }
    }
    else if (role === constants.ROLE.Fabricator) {
        if (statusId === constants.STATUS_TYPES.Completed || statusId === constants.STATUS_TYPES.ConvertedToOrder) {
            return true;
        } else {
            return false;
        }
    }
    else { 
        return false;
    }
}

export function convertToDate(dateString) {
    if (dateString)
        return moment(dateString).toDate();
}

export function getCheckmarkLabel(status, roleName) {
    let submitLabel;
    
    if (status === constants.STATUS_TYPES.InProgressAccepted) {

        submitLabel = roleName === constants.ROLE.User ? constants.STAGES_BUTTON_LABELS.Locked : constants.STAGES_BUTTON_LABELS.Shipped;
    } else if (status === constants.STATUS_TYPES.SubmittedForApproval) {
        submitLabel = constants.STAGES_BUTTON_LABELS.Accept;    
    } else if (status === constants.STATUS_TYPES.Sent) {
        submitLabel = constants.STAGES_BUTTON_LABELS.Confirm;        
    }
    else {
        submitLabel = constants.STAGES_BUTTON_LABELS.Submit;
    }

    return submitLabel;
}

export function getCompanyType(companyList, companyName) {
    if (!companyList || !companyName) return;

    let company = companyList.filter(x => x.name === companyName);

    if (company.length > 0) {
        let c = company[0];
        
        if (c.fabricatorId)
            return constants.COMPANY_TYPES.Fabricator;

        if (c.installerId)
            return constants.COMPANY_TYPES.Installer;
    } 
}

export function isEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}