import * as types from '../actions/actionTypes';
import * as constants from '../modules/constants';

export default function orderReducer(state = [], action) {
    switch(action.type) {                
        case types.GET_ORDER_BY_ID_SUCCESS:            
            return {...state, 
                    id: action.order['details'][0].id,
                    companyId: action.order['details'][0].companyId,
                    companyName: action.order['details'][0].companyName,
                    createdDate: action.order['details'][0].createdDate,
                    statusTypeId: action.order['details'][0].statusTypeId,
                    name: action.order['details'][0].name,
                    statusName: action.order['details'][0].statusName,  
                    companyLocationId: action.order['details'][0].companyLocationId,
                    estimatedShipDate: action.order['details'][0].estimatedShipDate,                  
                    actualShipDate: action.order['details'][0].actualShipDate, 
                    createdByUserId: action.order['details'][0].createdByUserId,
                    createdByEmail: action.order['details'][0].createdByEmail,                    
                    createdBy: action.order['details'][0].createdBy,
                    isLocked: action.order['details'][0].isLocked,                    
                    sourceQuoteId: action.order['details'][0].sourceQuoteId,
                    shipToStore: action.order['details'][0].shipToStore,
                    installType: action.order['details'][0].installType,
                    installerId: action.order['details'][0].installerId,
                    poNumber: action.order['details'][0].poNumber,
                    poCostTotal: action.order['details'][0].poCostTotal,
                    invoiceNumber: action.order['details'][0].invoiceNumber,
                    companyLocationStoreNumber: action.order['details'][0].companyLocationStoreNumber,
                    shipToTypeId: action.order['details'][0].shipToTypeId,
                    fabricatorName: action.order['details'][0].fabricatorName,
                    fabricatorPhone: action.order['details'][0].fabricatorPhone,
                    lines: action.order['lines'], 
                    billingAddress: action.order['billingAddress'],  
                    shippingAddress: action.order['shippingAddress'],
                    contact: action.order['contact'],
                    communicationHistory: action.order['communicationHistory'],
                    attachments: action.order['attachments']
            };

            case types.CLEAR_ORDER:
                return {...state, 
                    id: null,
                    companyId: null,
                    companyName: null,
                    createdDate: null,
                    statusTypeId: null,
                    name: null,
                    statusName: null,  
                    companyLocationId: null,
                    estimatedShipDate: null,                  
                    createdByUserId: null,
                    createdByEmail: null,                    
                    createdBy: null,
                    isLocked: null, 
                    sourceQuoteId: null,                                       
                    installType: null,
                    installerId: null,
                    poNumber: null,
                    companyLocationStoreNumber: null,
                    shipToTypeId: null,
                    fabricatorName: null,
                    fabricatorPhone: null,
                    orderConvertedTo: null,
                    poNumber: null,
                    poCostTotal: null,
                    lines: [], 
                    billingAddress: [], 
                    shippingAddress: [],
                    contact: [],
                    communicationHistory: [],
                    attachments: []
            }; 

        case types.GET_ORDER_COMPANY_LOCATION_FABRICATOR_SUCCESS:
            return {...state, fabricatorName: action.fabricatorName[0].name};

        case types.GET_ORDERS_SUCCESS:
            return {...state, orderList: action.orders };   
            
        case types.SET_ACTIVE_ORDER:
            return {...state, id: action.currentOrderId};
            
        case types.GET_ORDER_COMMUNICATIONS_SUCCESS: 
            return {...state, communicationHistory: action.Communications};

        case types.GET_ORDER_ATTACHMENTS_SUCCESS: 
            return {...state, attachments: action.attachments};

        case types.DELETE_ORDER_ATTACHMENT_SUCCESS:            
            return {...state, attachments: state.attachments.filter(a => a.id === action.fileId) };

        case types.GET_ORDER_CONTACT_SUCCESS:
            return {...state, contact: action.contact};

        case types.GET_ORDER_ADDRESSES_SUCCESS:            
            let billingAddress = action.addresses.filter(address => address.AddressType === constants.ADDRESS_TYPES.Billing);
            let shippingAddress = action.addresses.filter(address => address.AddressType === constants.ADDRESS_TYPES.Shipping);
            return {...state, billingAddress: billingAddress, shippingAddress: shippingAddress};

        case types.GET_ORDER_INVOICES_SUCCESS:            
            return {...state, invoices: action.invoices};

        case types.GET_ORDER_LINES_SUCCESS:            
            return {...state, lines: action.lines};

        case types.UPDATE_ORDER_COMPANY_LOCATION_ID:
            return {...state, companyLocationId: action.companyLocationId};

        case types.GET_ORDER_HEADER_SUCCESS:
            return {...state,
                statusTypeId: action.header[0].statusTypeId,
                name: action.header[0].name,
                statusName: action.header[0].statusName,  
                companyLocationId: action.header[0].companyLocationId,
                createdByUserId: action.header[0].createdByUserId,
                createdByEmail: action.header[0].createdByEmail,                
                shipToStore: action.header[0].shipToStore,
                installType: action.header[0].installType,
                poNumber: action.header[0].poNumber,
                poCostTotal: action.header[0].poCostTotal,
                installerId: action.header[0].installerId,
                companyName: action.header[0].companyName,
                companyId: action.header[0].companyId,
                shipToTypeId: action.header[0].shipToTypeId,
                invoiceNumber:  action.header[0].invoiceNumber,
            };
            
        default:
                return state;
    }
}