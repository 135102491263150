import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Input } from 'reactstrap';
import Button from '../Common/Button/Button';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import formatter from '../../modules/formatter';
import * as constants from '../../modules/constants';
import Icon from '../QuoteOrderCommon/Icon';

export const ImgStyled = styled.img`
    max-height: 90px;
    position: absolute;
    top: -30px;
    left: 100px;
    transform: scale(3);
    clip: rect(25px, 100px, 65px, 0px);

    @media (max-width: 768px) {       
        max-height: 75px;
        top: 0px;
    }
`;

export const RowStyled = styled(Row)`
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0;    
`;

export const RowLineStyled = styled(Row)`
    margin-left: 0 !important;
    margin-right: 0 !important;    
    padding-top: 35px;
    padding-bottom: 35px;

    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }

    :hover button, :hover .icon-line { 
        visibility: visible !important;               
    }
`;

export const RowTotalStyled = styled(Row)`
   border-top: 1px solid #4f4f4f; 
   margin-bottom: .5em;
   margin-left: 0 !important;
   margin-right: 0 !important;   
   padding-left: 1% !important;
   padding-right: 1% !important;
`;

export const ColStyled = styled(Col)`
    font-weight: bold;        
    text-align: center;
    display: inline-block;

    @media(max-width: 1200px) {
        margin-right: 15px;
    }    
`;

export const ColAddLine = styled(Col)`
    display: inline-block;
`;

export const ButtonStyled = styled(Button)`    
    visibility: hidden !important;
    
    @media(max-width:1200px) {        
        visibility: visible !important;       
        margin-top: .5em; 
    }
`;

export const TextInputPrimitiveStyled = styled(TextInputPrimitive)`
    width: 50px;
`;

export const H1Wrapper = styled.span`
    display: block;
    width: 100%;
    text-align: center;
`;
export const H1 = styled.h1`
    margin-bottom: 0;
    text-align: center;
`;

export const AddLineGuide = styled.div`
    font-style: italic !important;
    margin-bottom: .5em;
`;

export const LinePlaceHolder = styled.div`    
    border: dashed 2px lightgray;
    padding: 15px;
    text-align: center;
    color: lightgray;
    font-size: 24px !important;
    font-weight: 400;
    margin-top:.25em;
    cursor: pointer;
    margin-left: 1%;
    margin-right: 1%;

`;

export const AddNewButtonStyled = styled(Button)`
    margin-left: 1em;
`;

export const AddCountertopButtonStyled = styled(Button)`
    
    &.btn {
        width: 340px !important;
    }
`;

export const Wrapper = styled.div`
    margin-bottom: 1em;
`;

const Line = React.memo(props => {  
    
    console.log('line: ' + props.readonly);
    if (!props.lines || props.lines.length === 0)
        return (
            <Wrapper>
                <RowStyled><H1Wrapper><H1>Lines</H1></H1Wrapper></RowStyled> 
                {props.readonly != true &&  
                    <React.Fragment>
                        <RowStyled><p>For Postform tops, please use the "Click here to start" button. For Custom Countertops, please use the next button to advance to the Attachments step. For additional information on Postform vs Custom, <a href="https://www.hartson-kennedy.com/wp-content/uploads/2021/03/Postform-v.-Custom-1-pager.pdf" target="_blank">click here.</a></p></RowStyled>
                        <RowStyled>
                            <ColAddLine xl="1" xs="1">                                                                               
                                <AddCountertopButtonStyled disabled={props.readonly} id="addCountertop" className="d-block" displayText="Click here to start or to add another countertop" disabled={props.disableAddLine} onClick={() => props.onAddCountertopClick()} />                            
                            </ColAddLine> 
                        </RowStyled> 
                 </React.Fragment>                              
                }
                {props.readonly != true &&
                    <LinePlaceHolder  onClick={() => props.onAddCountertopClick()}>Click here to start or to add another countertop</LinePlaceHolder>
                }
            </Wrapper>
        );
    
    let totalPrice = 0;
    let totalQuantity = 0;

    return (                   
        <React.Fragment>                       
            {props.stage === constants.QUOTE_STAGES.Line &&  props.readonly !== true &&  
                 <Wrapper>
                 <RowStyled><H1Wrapper><H1>Lines</H1></H1Wrapper></RowStyled> 
                 {props.readonly != true &&  
                    <React.Fragment>
                        <RowStyled><p>For Postform tops, please use the "Click here to start" button. For Custom Countertops, please use the next button to advance to the Attachments step. For additional information on Postform vs Custom, <a href="https://www.hartson-kennedy.com/wp-content/uploads/2021/03/Postform-v.-Custom-1-pager.pdf" target="_blank">click here.</a></p></RowStyled>
                        <RowStyled>
                        <ColAddLine xl="1" xs="1">                                                                                 
                            <AddCountertopButtonStyled disabled={props.readonly} id="addCountertop" className="d-block" displayText="Click here to start or to add another countertop" disabled={props.disableAddLine} onClick={() => props.onAddCountertopClick()} />                        
                        </ColAddLine> 
                        </RowStyled> 
                    </React.Fragment> 
                 }                                                        
            </Wrapper>
            }

            <RowStyled>
                <ColStyled xl="1" xs="1">Line #</ColStyled>
                <ColStyled xl="2" className="d-none d-sm-block"></ColStyled>                                
                <ColStyled xl="2" xs="2">Part</ColStyled>
                <ColStyled xl="1" xs="2">Quantity</ColStyled>     
                <ColStyled xl="1" xs="2">Unit Price</ColStyled>           
                <ColStyled xl="1" xs="2">Total Price</ColStyled>
                { props.readonly !== true && 
                    <React.Fragment>
                        <ColStyled xl="1" xs="3"></ColStyled>
                        <ColStyled xl="1" xs="3"></ColStyled>                
                        <ColStyled xl="1" xs="3"></ColStyled>  
                    </React.Fragment>
                }                
            </RowStyled>
           {                              
               props.lines.map(line => {

               totalPrice = totalPrice + (line.productId !== 1 ? line.totalCost : line.price * line.quantity);
               totalQuantity = totalQuantity + line.quantity;
               let price = line.productId !== 1 ? line.totalCost : line.price;
                    return (                  
                        <RowLineStyled key={line.id}>
                            <ColStyled xl="1" xs="1">{line.lineNumber}</ColStyled>
                            { line.imgUrl 
                            ? <ColStyled xl="2" className="hideOnMobile"><ImgStyled src={line.imgUrl} alt="No Preview" /></ColStyled> 
                            : <ColStyled xl="2" className="hideOnMobile"></ColStyled> }
                            <ColStyled xl="2" xs="2">{line.productName}</ColStyled>
                            <ColStyled xl="1" xs="2"><TextInputPrimitiveStyled id={line.id.toString()} disabled={props.readonly} type="number" defaultValue={line.quantity.toString()} onBlur={(e) => props.onQuantityChange(e, line.id)} /></ColStyled>                                                        
                            <ColStyled xl="1" xs="2">${formatter.currency(price)}</ColStyled>
                            <ColStyled xl="1" xs="2"><div>${formatter.currency(price * line.quantity)}</div></ColStyled>
                            { props.readonly !== true && 
                                <React.Fragment>
                                    <ColStyled xl="1" xs="3">
                                        <Icon iconName="pencil" title="modify" isLine={true} click={() => props.onReconfigure(line.configurationId, line.headerId, line.id, line.productId)}></Icon>
                                    </ColStyled>
                                    {/* <ColStyled xl="1" xs="3">
                                        <Icon iconName="copy" title="copy" isLine={true} click={() => props.onCopyLine(line.id, line.configurationId, line.headerId, line.productId)} ></Icon>
                                    </ColStyled> */}
                                    <ColStyled xl="1" xs="3">
                                        <Icon iconName="trash" title="delete" isLine={true} click={() => props.onLineDelete(line.id)} ></Icon>
                                    </ColStyled>
                                </React.Fragment>
                            }
                        </RowLineStyled>                                                            
                    );
               })             
           }
           <RowTotalStyled>
                <ColStyled xl="1" xs="3">Total</ColStyled>   
                <ColStyled xl="4" xs="2" className="d-none d-sm-block"></ColStyled>                                        
                <ColStyled xl="1" xs="2">{totalQuantity}</ColStyled>     
                <ColStyled xl="1" xs="2"></ColStyled>           
                <ColStyled xl="1" xs="2">${formatter.currency(totalPrice)}</ColStyled>
           </RowTotalStyled>
        </React.Fragment>       
    );
});

export default Line;

Line.propTypes = {
  lines: PropTypes.array.isRequired,
  pageType: PropTypes.string.isRequired,
  onReconfigure: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onLineDelete: PropTypes.func.isRequired,
  onConfigureClick: PropTypes.func.isRequired,
  disableAddLine: PropTypes.bool.isRequired,
  onProductChange: PropTypes.func.isRequired,
  stage: PropTypes.number.isRequired,
  companyProducts: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onAddCountertopClick: PropTypes.func.isRequired,
};