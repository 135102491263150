import React from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as adminActions from '../../actions/adminActions';
import * as alertActions from '../../actions/alertActions';
import { loadProgressBar } from 'axios-progress-bar';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import * as constants from '../../modules/constants';
import 'axios-progress-bar/dist/nprogress.css';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    margin-bottom: 10px;
`;

export const LabelStyled = styled(Label)`
    font-weight: bold;    
`;

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input { width: 100% !important; }
`;

export const HeaderRowStyled = styled(Row)`
    font-weight: bold;
    margin-top: 1em;        
    margin-bottom: 1em;
`;

export const BodyRowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    cursor: pointer;    
    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }
`;

export const ContainerStyled = styled(Container)`
    padding-left: 1% !important;
    padding-right: 1% !important;
`;

class CompanyList extends React.Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();   
        
        this.onClickNewCompany = this.onClickNewCompany.bind(this);
        this.onClickEditCompany = this.onClickEditCompany.bind(this);  
    }  
    
    componentDidMount() {            
        this.props.adminActions.getAllCompanies(this.props.session.manufacturer).then(() => {
           this.setState({ loading: false });
        });                          
    }
    
    onClickEditCompany(companyId) {             
        this.props.adminActions.setActiveCompany(companyId);
        this.props.history.push({ pathname: '/ModifyCompany' });               
    }

    onClickNewCompany() {    
        this.props.adminActions.createCompany(this.props.session.manufacturer).then(() => {
            this.props.history.push({ pathname: '/ModifyCompany' });
        });                   
    }   

    render() {        
        let companies = this.props.admin.companyList;
                
        return (
            <ContainerStyled>        
                <h1>Companies</h1>

                <div className="text-right">
                    <Button displayText="New" onClick={this.onClickNewCompany} />
                </div>

                <HeaderRowStyled>
                    <Col xl="2">Company</Col>                                  
                </HeaderRowStyled>

                {   companies &&                                       
                    companies.map(company => {                                               
                        return(                            
                            <BodyRowStyled onClick={(e) => this.onClickEditCompany(company.id)}>
                                <Col xl="2" >
                                    {company.company}
                                </Col>                                
                            </BodyRowStyled>                                                                                         
                        );                        
                    })
                }                                    
            </ContainerStyled>               
        );                
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      quoteOrderCommon: state.quoteOrderCommon,
      session: state.session,
      admin: state.admin
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {        
        sessionActions: bindActionCreators(sessionActions, dispatch), 
        adminActions: bindActionCreators(adminActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch)               
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyList));