import * as types from '../actions/actionTypes';

export default function sessionReducer(state = [], action) {
    switch(action.type) {                
        case types.SET_ACTIVE_TAB:
            return {...state, activeTab: action.activeTab };

        case types.SIGN_OUT:
            return {...state, loggedIn: false, username: '', company: '' };  
            
        case types.SHOW_SUBMIT_CONFIGURATION_BUTTON:
            return {...state, showSubmitConfigurationButton: action.showSubmitConfigurationButton };

        case types.SET_COMPANY_ACCESS_LEVEL:             
            return {...state, access: action.access};

        case types.SET_COMPANY:             
            return {...state, company: action.company};

        case types.SET_MANUFACTURER:             
            return {...state, manufacturer: action.manufacturer};

        case types.SET_COMPANY_TYPE_ID:
            return {...state, companyTypeId: action.companyTypeId};
            
        case types.SET_CONFIGURATOR_MODE:
            return {...state, configuratorMode: action.mode};
        
        // case types.SET_USER_PERMISSION_LEVEL: 
        //     return {...state, isAdmin: action.isAdmin};

        case types.LOGIN_SUCCESS:            
            return {...state, loggedIn: true, username: action.username, loginFailed: false, showSignupModal: false};

        case types.LOGIN_FAILED:
            return {...state, loginFailed: true}

        case types.SIGNUP_SUCCESS:
            return {...state, loggedIn: true, username: action.username, loginFailed: false, signupFailed: false, showSignupModal: false};

        case types.SIGNUP_FAILED:
            return {...state, signupFailed: true};

        case types.SHOW_SIGNUP_MODAL:
            return {...state, showSignupModal: action.showSignupModal};

        case types.RECONFIGURE:
            return {...state, isReconfigure: action.isReconfigure};

        case types.SET_USER_ROLE:
            return {...state, roleName: action.roleName };

        case types.SET_USER_ROLE_LEVEL: 
            return {...state, roleLevel: action.roleLevel };

        case types.SET_USER_LOCATIONS: 
            return {...state, userCompanyLocationIdCSV: action.userCompanyLocationIdCSV };

        default:
            return state;
    }
}