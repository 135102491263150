import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import * as constants from '../../modules/constants';

export const CircleBg = styled.div`    
    display: table-cell;
    width: 500px;
    height: 25px;
    margin-right: 75px;
    color: #FFF;    
    text-align: center;
    vertical-align: middle;
    font-size: 24px !important;

    @media(max-width:1200px) {
        display: block;
        width: 25px;
        height: 25px;
        font-size 16px !important;
    }
    
    &.active {
        background-color: ${props => props.theme.buttonColor};        
    }

    &.completed {
        background-color: #e4e4e4;
        cursor: pointer;
    }

    &.incomplete {
        background-color: ${props => props.theme.altButtonColor};
    }    
`;

export const CircleBgSubmit = styled.div`    
    display: table-cell;
    width: 500px;
    height: 25px;
    margin-right: 75px;
    color: #FFF;    
    text-align: center;
    vertical-align: middle;
    font-size: 24px !important;

    @media(max-width:1200px) {
        display: block;
        width: 25px;
        height: 25px;
        font-size 16px !important;
    }
    
    &.active {
        background-color: ${props => props.theme.altButtonColor};        
    }

    &.completed {
        background-color: #e4e4e4;
        cursor: pointer;
    }

    &.incomplete {
        background-color: ${props => props.theme.altButtonColor};
    }    
`;

export const Wrapper = styled.div`        
    margin-top: 2em;
    margin-bottom: 1em; 
    
    @media(max-width:1200px) {
        margin-top: .75em;
        margin-bottom: 0;
    }
`;

export const FigcaptionStyled = styled.figcaption`    
    display: inline-block;
    position: relative;    
    font-size: 17px !important;
    font-weight: 700;
    color: ${props => props.theme.buttonColor};
    margin-top: 8px;

    &#poStageCaption {
        position: relative;
        left: -13px;
    } 

    &#attachmentStageCaption {
        position: relative;
        left: -15px;
    }   
    
    @media(max-width:1200px) {
        display: table-cell;

        &#poStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }

        &#attachmentStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }
    }
    
    &.active {
        color: ${props => props.theme.buttonColor};        
    }

    &.completed {
        color: #e4e4e4;
        cursor: pointer;
    }

    &.incomplete {
        color: ${props => props.theme.altButtonColor};
    }    
`;

export const FigcaptionSubmitStyled = styled.figcaption`    
    display: inline-block;
    position: relative;    
    font-size: 17px !important;
    font-weight: 700;
    color: ${props => props.theme.buttonColor};
    margin-top: 8px;

    &#submitStageCaption { 
        position: relative;
        left: -25px;        
    }

    @media(max-width:1200px) {
        display: table-cell;

        &#submitStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }
    }

    &.active {
        color: ${props => props.theme.altButtonColor};        
    }

    &.completed {
        color: #e4e4e4;
        cursor: pointer;
    }

    &.incomplete {
        color: ${props => props.theme.altButtonColor};
    }    
`;

export const RowStyled = styled(Row)`
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;

    @media(max-width:1200px) {
        width: 95%;
    }
`;

const StagesConfirmed = props => {
    const stage = props.stage;
    const active = "active";
    const completed = "completed";
    const incomplete = "incomplete";

    return (
        <Wrapper>            
            <RowStyled>
                <Col xl="2" xs="2">
                    <figure>
                        <CircleBg onClick={() => stage === constants.QUOTE_STAGES.PoNumber || stage > constants.QUOTE_STAGES.PoNumber ? props.onStageChange(constants.QUOTE_STAGES.PoNumber) : null} className={ stage === constants.QUOTE_STAGES.PoNumber ? active : stage > 1 ? completed : incomplete} >
                            {constants.QUOTE_STAGES.PoNumber}
                        </CircleBg>
                        <FigcaptionStyled id="poStageCaption" className={[ stage === constants.QUOTE_STAGES.PoNumber ? active : stage > constants.QUOTE_STAGES.PoNumber ? completed : incomplete, "d-none d-sm-block"]} >PO Number</FigcaptionStyled>
                    </figure>
                </Col>               
                <Col xl="2" xs="2">
                    <figure>
                        <CircleBg onClick={() => stage === constants.QUOTE_STAGES.AdditionalAttachments || stage > constants.QUOTE_STAGES.AdditionalAttachments ? props.onStageChange(constants.QUOTE_STAGES.AdditionalAttachments) : null} className={ stage === constants.QUOTE_STAGES.AdditionalAttachments ? active : stage > constants.QUOTE_STAGES.AdditionalAttachments ? completed : incomplete} >
                            {constants.QUOTE_STAGES.AdditionalAttachments}
                        </CircleBg>
                        <FigcaptionStyled id="attachmentStageCaption" className={[ stage === constants.QUOTE_STAGES.AdditionalAttachments ? active : stage > constants.QUOTE_STAGES.AdditionalAttachments ? completed : incomplete, "d-none d-sm-block"]}>Additional Attachments</FigcaptionStyled>
                    </figure>
                </Col>                                             
                <Col xl="2" xs="2">
                    <figure>
                        <CircleBgSubmit className={ props.allowSubmit === true ? active : incomplete} >
                           &#10004;&#xFE0E;
                        </CircleBgSubmit>
                        <FigcaptionSubmitStyled id="submitStageCaption" className={[ props.allowSubmit === true ? active : incomplete , "d-none d-sm-block"]}>Convert To Order</FigcaptionSubmitStyled>
                    </figure>
                </Col>                
            </RowStyled>
        </Wrapper>
    );
};

StagesConfirmed.propTypes = {
    onStageChange: PropTypes.func.isRequired,    
    allowSubmit: PropTypes.bool.isRequired
};

export default StagesConfirmed;