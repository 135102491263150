import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { loadProgressBar } from 'axios-progress-bar';
import {HotKeys} from 'react-hotkeys';
import TabControl from '../TabControl/TabControl';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteActions from '../../actions/quoteActions';
import * as orderActions from '../../actions/orderActions';
import * as alertActions from '../../actions/alertActions';
import * as adminActions from '../../actions/adminActions';
import ConfiguratorPriceBar from './ConfiguratorPriceBar';
import guid from '../../modules/guid';
import ErrorHandler from '../../modules/errorHandler';
import * as keymaps from '../../modules/shortcuts';
import 'axios-progress-bar/dist/nprogress.css';
import * as constants from '../../modules/constants';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
export const MessageRule = styled.h1`
    background-color: red;
    color: white;
    display: block;
    text-align: center;    
`;

class Configurator extends Component {  
    constructor(props, context) {
        super(props, context);  
        
        this.onSubmitConfiguration = this.onSubmitConfiguration.bind(this);           
        this.onCancel = this.onCancel.bind(this);        

        loadProgressBar();  
        
        this.state = {
            isLoading: true 
        }
    }

    componentDidMount() {   
        //this.props.sessionActions.resetApp();     
        if (!this.props.quote.id && !this.props.order.id) this.props.history.push("/Overview");              
                  
        this.props.apiActions.reloadConfigurator();
        this.props.adminActions.getAdPatchTypes(this.props.session.company).then(() => {
            this.loadConfigurator();
        });                          
    }

    // isValidInput() {
    //     let isValidInput = true;
                    
    //     let selects = Array.prototype.slice.call(document.getElementsByTagName("Select"));
    //     let inputs = Array.prototype.slice.call(document.getElementsByTagName("input"));
       
    //     console.log(selects);
    //     console.log(inputs);
    //     this.props.validation.requiredScreenOptions.map(screenOption => {
    //         let requiredControl;                      

    //         if (screenOption.DisplayType.toLowerCase() === constants.DROPDOWN) {
    //             requiredControl = selects.filter(i => i.id === screenOption.ID);
    //         } else {
    //             requiredControl = inputs.filter(i => i.id === screenOption.ID);
    //         }

    //         if (requiredControl.length > 0) {
    //             if (!requiredControl[0].value || requiredControl[0].value === constants.DEFAULT_SELECT_OPTION) {
    //                 this.props.alertActions.showFailureAlert("Please enter a value for the following field: " + screenOption.Caption);
    //                 return;
    //             }
    //         }                                   
    //     });
        

    //     return isValidInput;
    // }

    getContinueHotkeyHandler() {
        return {
            'continue': (event) => this.continueHotkeyPressed(),
            'finish': (event) => this.onSubmitConfiguration(),
            'moveTab': (event) => this.moveTab()  
          };
    }

    moveTab() {
        let activeTab = this.props.session.activeTab;
        if (activeTab + 1 < this.props.api.ui.d.Pages.length)
            this.props.sessionActions.setActiveTab(activeTab + 1);
    }

    continueHotkeyPressed() {
        this.props.apiActions.commitUncommittedScreenOptions(this.props.api.sessionId, this.props.api.uncommittedScreenOptions);
    }

    onCancel() {
        confirmAlert({
            title: 'Confirm Cancel',
            message: 'Are you sure to cancel?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.props.apiActions.clearConfigurator();

                    if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Quote) {                                                       
                        this.returnToQuote();
                    } else if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order) {
                        this.returnToOrder();
                    } else {
                        this.props.history.push("/QuoteDashboard"); 
                    }
                }
              },
              {
                label: 'No',
                onClick: () => ''
              }
            ]
          });

       
    }

    onSubmitConfiguration() {        
        this.props.apiActions.finishInteractiveConfiguration(this.props.api.sessionId).then(() => {
            if (!this.props.session.loggedIn) {
                this.props.apiActions.storeUncommittedUserConfigurations(this.getUncommittedUserConfigurations()); 
                this.reloadApp();
                return;
            }

            if (this.props.session.isReconfigure && this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Default) {
                this.props.history.push("/MyConfigurations");  
                return;
            }            

            if (this.props.session.isReconfigure && (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Quote || this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order)) {               
                let LineId = this.props.location.LineId === undefined ? '' : this.props.location.LineId;                
                this.updateLine(LineId);
                return;                
            }

            // if (this.props.session.isReconfigure && this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order) {               
            //     let LineId = this.props.location.LineId === undefined ? '' : this.props.location.LineId;                
            //     this.updateLine(LineId);
            //     return;                
            // }

            if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Quote) {                               
                this.createLine(this.props.quote.id, null);  
                return;                                 
            } else if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order) {
                this.createLine(null, this.props.order.id);  
            }
            else {
                this.props.apiActions.saveConfiguration(this.props.session.username, this.props.api.selectedProduct.HeaderId, this.props.api.configurationGUID, this.props.api.ui.d.ImageUrl, this.props.api.selectedProduct.ID);                                   
                this.moveToMyConfigurationPage();
            }           
        });                 
    }

    updateLine(LineId) {
        this.props.quoteActions.updateLine(this.props.api.ui.d.ConfiguredPrice, this.props.api.ui.d.ImageUrl, LineId);     
        
        if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order)
            this.returnToOrder();
        else
            this.returnToQuote();             
    }

    createLine(quoteId, orderId) {       
        this.props.quoteActions.createLine(quoteId, this.props.session.username, this.props.api.ui.d.ConfiguredPrice, this.props.api.ui.d.ImageUrl, this.props.api.configurationGUID, constants.HEADER_ID, this.props.api.selectedProduct.ID, orderId).then(() => {
            confirmAlert({
                title: 'More items to add?',
                message: 'Do you have any more items to add?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        this.reloadApp();
                    }
                  },
                  {
                    label: 'No',
                    onClick: () =>  {
                            if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order)
                                this.returnToOrder();
                            else
                                this.returnToQuote(); 
                        } 
                    } 
                ]
              }); 
        });                    
    }

    returnToQuote() {
        this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Default);
        this.props.history.push({
            pathname: "/Quote",
            previousPage: "Configurator"
        });
    }

    returnToOrder() {
        this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Default);
        this.props.history.push({
            pathname: "/Order",
            previousPage: "Configurator"
        });
    }

    getUncommittedUserConfigurations() {
        var uncommittedConfiguration = {                    
            headerId: this.props.api.selectedProduct.HeaderId,
            configurationId: this.props.api.configurationGUID,
            imageUrl: this.props.api.ui.d.ImageUrl,
            productId: this.props.api.selectedProduct.ID                    
        };       

        return uncommittedConfiguration;
    }

    moveToMyConfigurationPage() {        
        this.props.history.push("/MyConfigurations"); 
    }
    
    reloadApp() {
        //this.props.sessionActions.resetApp(); 
        this.setState({ isLoading: true });
        this.props.apiActions.reloadConfigurator();
        this.loadConfigurator();  
    }

    runConfigurator(integrationParams) {   
        try {     
            let product = this.props.api.selectedProduct;

            if (!product) {
                this.props.alertActions.showFailureAlert("No product returned or product does not exist.");
                this.returnToQuote();
                return;
            }
            let configurationId = guid.GenerateGUID();
            let sourceConfigurationId = this.props.location.configurationId === undefined ? '' : this.props.location.configurationId;
            let sourceHeaderId = this.props.location.headerId === undefined ? '' : this.props.location.headerId;
            let isReconfigure = sourceConfigurationId === '' || sourceHeaderId === '' ? false : true;
            
            this.props.sessionActions.reconfigure(isReconfigure);    
            
           
             
            this.props.apiActions.runConfigurator(product, configurationId, isReconfigure, sourceConfigurationId, sourceHeaderId, integrationParams).then(() => {
                this.setState({ isLoading: false });
                
                // if (this.props.api.ui && this.props.api.ui.d.Messages.length > 0) {
                //     this.props.alertActions.showFailureAlert(this.props.api.ui.d.Messages[0].Value);
                // }

                // if (this.props.api.ui && this.props.api.ui.d.Messages.length > 0) {
                //     this.props.alertActions.showFailureAlert(this.props.api.ui.d.Messages[0].Value);
                // }
            });    
            
            
           // const isConfirmed = alert("Are you sure you want to remove this burrito?");

          

           
        } catch(error) {
            ErrorHandler.logError(error);           
        }                      
    }
   
    loadConfigurator() {    
        var integrationParams;
        var lineNumber;

        const promise1 = this.props.apiActions.getCompanyProducts(this.props.session.company);
        //const promise2 = this.props.apiActions.getUserActiveProduct(this.props.session.username, this.props.session.company);
        

        Promise.all([promise1]).then(() => {
            if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Quote || this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order) {
                                
                if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Order) {                                                                               
                    let activeCompanyLocation = this.props.admin.companyLocations.filter(c => c.id == this.props.order.companyLocationId)[0];
                    let adPatchName = this.props.admin.adPatchTypes.filter(p => p.id == activeCompanyLocation.adPatchTypeId)[0].name;
                    if (!this.props.order.lines || (this.props.order.lines && this.props.order.lines.length === 0)) {
                        lineNumber = 1;
                    } else {                        
                        lineNumber = this.props.order.lines.length + 1;
                    } 
                    integrationParams = [
                        { "IsNull": false, "Name":"LineNumber", "SimpleValue":lineNumber, "Type":1 },
                        { "IsNull": false, "Name":"QuoteId", "SimpleValue":this.props.order.id.toString(), "Type":0 },
                        { "IsNull": false, "Name":"IsOrder", "SimpleValue":true, "Type":2 },
                        { "IsNull": false, "Name":"adPatch", "SimpleValue":adPatchName, "Type":0 },
                    ]; 
                } else if (this.props.session.configuratorMode === constants.CONFIGURATOR_MODES.Quote){
                    let activeCompanyLocation = this.props.admin.companyLocations.filter(c => c.id == this.props.quote.companyLocationId)[0];
                    let adPatchName = this.props.admin.adPatchTypes.filter(p => p.id == activeCompanyLocation.adPatchTypeId)[0].name;
                    if (!this.props.order.lines || (this.props.quote.lines && this.props.quote.lines.length === 0)) {
                        lineNumber = 1;
                    } else {
                        lineNumber = this.props.quote.lines.length + 1;
                    }
                    integrationParams = [
                        { "IsNull": false, "Name":"LineNumber", "SimpleValue":lineNumber, "Type":1 },
                        { "IsNull": false, "Name":"QuoteId", "SimpleValue":this.props.quote.id.toString(), "Type":0 },
                        { "IsNull": false, "Name":"IsOrder", "SimpleValue":false, "Type":2 },
                        { "IsNull": false, "Name":"adPatch", "SimpleValue":adPatchName, "Type":0 },
                    ];
                } else {
                    integrationParams = [];
                }
                               
                let productId = this.props.location.productId === undefined ? '' : this.props.location.productId;  

                if (productId) {
                    let products = this.props.admin.productList;
                    let productFilter;
                        
                    if (!products) {
                        this.props.alertActions.showFailureAlert("No product returned or product does not exist.");
                        this.returnToQuote();
                        return;
                    }
                    productFilter = products.filter(p => p.ID === productId); 
                    
                    if (!productFilter || productFilter.length === 0) {
                        this.props.alertActions.showFailureAlert("No product returned or product does not exist.");
                        this.returnToQuote();
                        return;
                    }

                    this.props.apiActions.setSelectedProduct(productFilter[0]);    
                    
                    return;
                }
            } 
            
            let products = this.props.admin.productList;
            let productFilter;

            if (!products || products.length === 0) return;

            if (this.props.api.activeProductId) {
                productFilter = products.filter(p => p.ID == this.props.api.activeProductId);
            } else {           
                if (this.props.api.companyProducts)         
                    productFilter = this.props.api.companyProducts.filter(p => p.DefaultProduct === true);                                                     
            }           
            
            if (!productFilter || !productFilter[0]) {
                //this.props.alertActions.showFailureAlert("No product returned or product does not exist.");
                console.log("No product returned or product does not exist.");
                return;
            }


            this.props.apiActions.setSelectedProduct(productFilter[0]);
            
        }).then(() => {
            if (!this.props.api.selectedProduct) {
                //this.props.alertActions.showFailureAlert("configurator failed to initialize, please reload the page.")
                return;
            }
            
            this.runConfigurator(integrationParams);
        });                          
    }    

    render() {
        
        if (this.state.isLoading === true || !this.props.api || !this.props.api.ui) {
            return ( 
                <React.Fragment>
                    {/* {  this.props.api.ui && this.props.api.ui.d && this.props.api.ui.d.Messages && this.props.api.ui.d.Messages.length > 0 && <MessageRule message={this.props.api.ui.d.Messages[0]} /> }                                  */}
                </React.Fragment>
            );
        }
        let message = '';
        if (this.props.api.ui && this.props.api.ui.d.Messages.length > 0) {

            this.props.api.ui.d.Messages.map(m => { 
                message += m.Value;
            });            
        }
                    
        return (   
            <HotKeys keyMap={keymaps.configurator}>    
                <HotKeys handlers={this.getContinueHotkeyHandler()}>     
                <ConfiguratorPriceBar                                                
                    showSubmitConfigurationButton={this.props.session.showSubmitConfigurationButton} 
                    onSubmitConfiguration={this.onSubmitConfiguration} 
                    price={this.props.api.ui.d.ConfiguredPrice}
                    onCancel={this.onCancel}                                                      
                />                        
                <MessageRule>{message}</MessageRule>
                <TabControl />                                                                                                                 
                   
                </HotKeys>                          
            </HotKeys> 
        );
    } 
  }

function mapStateToProps(state, ownProps) {    
    return {
        session: state.session,
        api: state.api,
        quote: state.quote,
        alert: state.alert,
        order: state.order,
        admin: state.admin,                   
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),
        apiActions: bindActionCreators(apiActions, dispatch),
        quoteActions: bindActionCreators(quoteActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    };
}    

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Configurator));