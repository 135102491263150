import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteActions from '../../actions/quoteActions';
import * as alertActions from '../../actions/alertActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import QuoteDashboardGrid from '../QuoteOrderCommon/DashboardGrid';
import ListFilter from '../QuoteOrderCommon/ListFilter';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { loadProgressBar } from 'axios-progress-bar';
import Pagination from '../Common/Pagination/Pagination';
import Button from '../Common/Button/Button';
import 'axios-progress-bar/dist/nprogress.css';
import * as constants from '../../modules/constants';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Loading from '../Common/Loading/Loading';

export const H1 = styled.h1`
    margin-top: 1em;
`;

export const ContainerStyled = styled(Container)`
    margin-bottom: 1em;
`;

export const HeaderRowStyled = styled(Row)`
    background-color: ${props => props.theme.lightBackground};
    margin-top: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const GridRow = styled(Row)`
    margin-top: 0px !important;
`;

export const SearchRow = styled(Row)`
    margin-bottom: 0px !important;
    @media (max-width:1200px) { 
        margin-bottom: 1em !important;
    }
`;

export const ButtonStyled = styled(Button)`
    color: #FFF !important;
`;

class QuoteDashboard extends Component {    
    constructor(props, context) {
        super(props, context);

        loadProgressBar();
        
        this.onClickEditQuote = this.onClickEditQuote.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onClickNewQuote = this.onClickNewQuote.bind(this);
        this.stopPropagation = this.stopPropagation.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.sort = this.sort.bind(this);

        this.state = {
            statusList: [],
            quoteFilter: null,
            offset: 0,
            rowsToFetch: 20,
            currentPage: 1,
            loading: true,
            sortDirection: null,
            sortByColumn: null,
        }
    }          

    componentDidMount() {   
        if (this.props.location.state && this.props.location.state.overviewStatus) {
            this.setState({ quoteFilter: this.props.location.state.overviewStatus }, () => {
                this.getQuotes();
            })
        } else {
            this.getQuotes(); 
        }                  
    }

    async onClickEditQuote(event, quoteId) {
        await this.props.quoteActions.setActiveQuote(quoteId);
        
        this.props.history.push({ pathname: '/Quote' });
    }

    async onClickNewQuote() {
        this.props.quoteActions.clearQuote();
        await this.props.quoteActions.createQuote(this.props.session.username, this.props.session.company);       
        
        this.props.history.push({ pathname: '/Quote' });
    } 

    sort(sortByColumn) {     
        let direction = this.state.sortDirection === "desc" ? "asc" : "desc";
        this.setState({sortDirection: direction, sortByColumn: sortByColumn}, this.getQuotes());                
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    onFilter(event) {        
        this.setState({ quoteFilter: event.target.value }, () => {
            this.getQuotes();   
        });            
    }

    onActionChange(event, id) {
        switch (event.target.value) {
            case constants.GRID_ACTION_OPTIONS.Copy:

                break;

            case constants.GRID_ACTION_OPTIONS.Delete:
                    confirmAlert({
                        title: 'Confirm Delete',
                        message: 'Are you sure to delete?',
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                                this.props.quoteOrderCommonActions.deleteDraft(id, null).then(() => {
                                    this.getQuotes();
                                });
                            }
                          },
                          {
                            label: 'No',
                            onClick: () => ''
                          }
                        ]
                      });  
                    break;

            case constants.GRID_ACTION_OPTIONS.QuoteCost:
                this.setState({ loading: true});
                this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderCost, "C", "Q", id).then(() => {
                    this.setState({ loading: false});
                });                             
                break;

            case constants.GRID_ACTION_OPTIONS.QuotePrice:
                this.setState({ loading: true});
                this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderCost, "P", "Q", id).then(() => {
                    this.setState({ loading: false});
                });    
                break;    
                
            case constants.GRID_ACTION_OPTIONS.DownloadAttachments:
                this.getAllAttachments(id);
                break;
        }
    }

    getAllAttachments(id) {
        this.props.quoteOrderCommonActions.getAttachments(id, null, null).then(() => {
            this.downloadAttachments();
        });
    }

    downloadAttachments() {
        let attachments = this.props.quote.attachments;
        if (attachments) {
            attachments.forEach(attachment => {
                console.log(`download ${attachment.originalName}, ${attachment.path}, ${attachment.mimetype}`);
                this.props.quoteOrderCommonActions.downloadFile(attachment.path, attachment.originalName, attachment.mimetype);
            });
        } else {
            this.props.alertActions.showFailureAlert("No attachments found"); 
        }
    }

    getQuotes() {                
        this.props.quoteActions.getQuotes(this.props.session.company, this.props.session.userCompanyLocationIdCSV, this.props.session.roleName, this.state.quoteFilter, this.state.offset, this.state.rowsToFetch, this.state.sortByColumn, this.state.sortDirection).then(() => {
            this.getAllQuoteStatuses();
            this.setState({ loading: false });
        });
    }
    
    getAllQuoteStatuses() {    
        this.setState({ statusList: [] }, () => {
            let quotes = this.props.quote.quoteList;        
            let allStatuses = this.state.statusList;
          
            if (!quotes) return;
    
            quotes.forEach(quote => {
                if (!allStatuses.includes(quote.statusName))
                    allStatuses.push(quote.statusName);
            });
    
            this.setState({ statusList: allStatuses });   
        });               
    }

    onPageChange(currentPage) {               
        let offset = parseInt((currentPage * this.state.rowsToFetch) - this.state.rowsToFetch);
        this.setState({ offset: offset, currentPage: currentPage }, () => this.getQuotes());        
    }    

    render() {       
        if (this.state.loading === true) return (<Loading />);

        let quotes = this.props.quote.quoteList;

        let pageCount = 1;
        
        if (this.props.quote && quotes) {
            pageCount = quotes.length > 0 ? (quotes[0].totalRows / this.state.rowsToFetch) : 1;
            pageCount = Math.ceil(pageCount);
        }               
        
        return (          
            <ContainerStyled>
                 <HeaderRowStyled>
                    <Col><h1>Quotes</h1></Col>                                  
                </HeaderRowStyled>                                                          
                <SearchRow>
                <Col xl="1" xs="4">                          
                    <div className="text-left">
                        <Button displayText="New" onClick={this.onClickNewQuote} backgroundColor={constants.rentechTheme.callToActionColor} color={constants.rentechTheme.textLight}/>
                    </div>
                </Col>
                <Col xl="3" xs="8">
                    <ListFilter onFilter={this.onFilter} defaultValue={this.state.quoteFilter}/>
                </Col>
                </SearchRow>
                {quotes && 
                <GridRow>
                    <Col>                                                                                                            
                        <QuoteDashboardGrid sort={this.sort} onActionChange={this.onActionChange} stopPropagation={this.stopPropagation} quotes={quotes} onListItemClick={this.onClickEditQuote} isAdmin={this.props.session.roleName === constants.ROLE.SystemAdmin ? true : false} />                                                                                                              
                        <Pagination onPageChange={this.onPageChange} pageCount={pageCount} offset={this.state.offset} currentPage={this.state.currentPage} rowsToFetch={this.state.rowsToFetch} /> 
                    </Col>                              
                </GridRow>
                }       
                {/* {quotes && 
                <GridRow>
                    <Col>
                        {                   
                            this.state.statusList.map(status => {                        
                                let quotesByStatus = quotes.filter(quote => quote.statusName === status);
                                
                                return (
                                    <div key={status}>
                                        <H1>{status}</H1>
                                        <QuoteDashboardGrid onActionChange={this.onActionChange} stopPropagation={this.stopPropagation} status={status} quotes={quotesByStatus} onListItemClick={this.onClickEditQuote} isAdmin={this.props.session.roleName === constants.ROLE.SystemAdmin ? true : false} />
                                    </div>
                                );
                            })
                        }               
                        <Pagination onPageChange={this.onPageChange} pageCount={pageCount} offset={this.state.offset} currentPage={this.state.currentPage} rowsToFetch={this.state.rowsToFetch} /> 
                    </Col>                              
                </GridRow>
                }                 */}
            </ContainerStyled>
        );
    }    
}

function mapStateToProps(state, ownProps) {    
    return {
        api: state.api,
        session: state.session,
        quote: state.quote,      
        alert: state.alert,
        quoteOrderCommon: state.quoteOrderCommon,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators(apiActions, dispatch),
        sessionActions: bindActionCreators(sessionActions, dispatch),
        quoteActions: bindActionCreators(quoteActions, dispatch),        
        alertActions: bindActionCreators(alertActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuoteDashboard));