import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import TypeableSelectCPQ from '../Common/CPQ/TypeableSelectCPQ';
import SelectCPQ from '../Common/CPQ/SelectCPQ';
import RadioButtonsCPQ from '../Common/CPQ/RadioButtonsCPQ';
import TextInputCPQ from '../Common/CPQ/TextInputCPQ';
import CheckBoxCPQ from '../Common/CPQ/CheckBoxCPQ';
import ImageTextHorizontal from '../Common/ImageTextHorizontal/ImageTextHorizontal';
import NumericSelect from '../Common/CPQ/NumericSelect';
import ImageDropdown from '../Common/CPQ/ImageDropdown';
import * as constants from '../../modules/constants';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as validationActions from '../../actions/validationActions';
import styled from 'styled-components';

export const InfoLink = styled.a`
  position: relative;
  top: -5px;
  font-style: italic; 
  cursor: pointer;
  color: black; 
`;

export class ScreenOption extends Component {
   
    constructor(props, context) {
        super(props, context);

        this.selectOnChange = this.selectOnChange.bind(this);        
        this.textInputOnChange = this.textInputOnChange.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onImageClicked = this.onImageClicked.bind(this); 
        this.typeableSelectOnChange = this.typeableSelectOnChange.bind(this);  
        this.markAsSelected = this.markAsSelected.bind(this);

        this.state = {
          configureInProgress: false
        }
    }    

    selectOnChange(event, autoContinueMode, value, id) {     
      
      if (id !== null && value != null)
        this.ScreenOptionUpdated(autoContinueMode, value, id);
      else        
        this.ScreenOptionUpdated(autoContinueMode, event.target.value, event.target.id);
    }

    typeableSelectOnChange(autoContinueMode, e, id) {         
      this.ScreenOptionUpdated(autoContinueMode, e.value, id);
    }

    textInputOnChange(event, autoContinueMode) {
      this.props.apiActions.pauseRuleExecution(true);      
      let val;

      if (isNaN(event.target.value)) {
        val = event.target.value;       
      }
      else {
        val = parseFloat(event.target.value);
      }             
      this.ScreenOptionUpdated(autoContinueMode, val, event.target.id);            
    }

    onCheckChanged(event, autoContinueMode) {      
      this.ScreenOptionUpdated(autoContinueMode, event.target.checked, event.target.id);                   
    }

    markAsSelected(value, imageGroup) {
      var images = document.getElementsByName(imageGroup);

      for (var i = 0; i < images.length; i++) {
          images[i].classList.remove("selectedImage");            
      }

      document.getElementById(value).classList.add("selectedImage");
    }

    onImageClicked(value, ID, imageGroup, autoContinueMode, toggle) {
      if (toggle)
        toggle();
      var images = document.getElementsByName(imageGroup);

      for (var i = 0; i < images.length; i++) {
          images[i].classList.remove("selectedImage");            
      }

      document.getElementById(value).classList.add("selectedImage");
                
      this.ScreenOptionUpdated(autoContinueMode, value, ID);                
    } 

    ScreenOptionUpdated(autoContinueMode, value, id) {        
      this.props.apiActions.lastScreenOptionModified({id: id, autoContinueMode: autoContinueMode });        
      this.props.apiActions.setAutoContinueMode(autoContinueMode);

      if (autoContinueMode === constants.AUTO_CONTINUE_MODE.StayOnScreenRule)
        this.storeUncommittedScreenOption(id, value, autoContinueMode);                 
      else {                   
        if (this.props.api.uncommittedScreenOptions.length > 0) {          
          this.props.apiActions.commitUncommittedScreenOptions(this.props.api.sessionId, this.props.api.uncommittedScreenOptions);
        } else {
          this.setState({ configureInProgress: true });
       
          this.props.apiActions.configure(this.props.api.sessionId, id, value);
        }             
                              
      }
    }

    storeUncommittedScreenOption(id, value, autoContinueMode) {
      this.props.apiActions.pauseRuleExecution(false);

      // if (this.props.api.uncommittedScreenOptions && this.props.api.uncommittedScreenOptions.length > 0) {
      //    let screenOptionthis.props.api.uncommittedScreenOptions.filter(so => so.ID !== id)
      // }
      this.props.apiActions.removeUncommittedScreenOption(id);
      this.props.apiActions.storeUncommittedScreenOption({ "id": id, "value": value, "autoContinueMode": autoContinueMode});
    }

    // storeRequiredScreenOptionsForValidation() {      
    //   this.props.screenOptions.map(option => {          
    //     if (option.IsRequired === true && option.IsVisible === true)
    //       this.props.validationActions.storeRequiredScreenOptions(option);
    //   });              
    // }

    render() {
      if (!this.props.api.ui) return <React.Fragment />;   
      
      // if (this.props.api.ui.d.Messages.length > 0) {
      //     return(
      //       <React.Fragment><h1>messaes</h1></React.Fragment>
      //     )
      // }
            
      const screenOptions = this.props.screenOptions;
      return (     
          <React.Fragment>             
            { screenOptions.map(opt => {  
                if (!opt) return null;  
                
                let type = opt.DisplayType.toLowerCase();                      
                let caption = opt.Caption.replace(/<\/?[^>]+(>|$)/g, "");   
                let autoFocus = this.props.api.ui.d.OptionToFocus === opt.ID ? true : false;
                
                switch (type) {
                  case constants.DROPDOWN:                        
                    return (
                      <React.Fragment>                                                
                        <SelectCPQ autoFocus={autoFocus} screenOption={opt} caption={caption} onChange={(e) => this.selectOnChange(e, opt.AutoContinueMode)} placeHolder={constants.DEFAULT_SELECT_OPTION}/>
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>
                    );
                      
                  case constants.RADIO_HORIZTONAL:
                    return (
                      <React.Fragment>
                        <RadioButtonsCPQ autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} selectOnChange={this.selectOnChange} />
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>
                    );   
                      
                  case constants.NUMERIC_TEXTBOX:
                    return (
                      <React.Fragment>
                        <TextInputCPQ autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} textInputOnChange={this.textInputOnChange} type="number" />    
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }   
                        {opt.Description && <span>{opt.Description}</span> }                 
                      </React.Fragment>
                      );
                        
                  case constants.TEXTBOX:
                    return (
                      <React.Fragment>
                        <TextInputCPQ autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} textInputOnChange={this.textInputOnChange} /> 
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> } 
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>           
                      );           
                  case constants.CHECKBOX:
                    return (
                      <React.Fragment>
                        <CheckBoxCPQ autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} onCheckChanged={this.onCheckChanged} />
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>                      
                      );

                  case constants.IMAGE_TEXT_HORIZONTAL: 
                    return (
                      <React.Fragment>
                        <ImageTextHorizontal markAsSelected={this.markAsSelected} autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} onImageClicked={this.onImageClicked} />
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>
                      );                        

                  case constants.TYPEABLE_DROPDOWN: 
                    return (
                      <React.Fragment>
                          <TypeableSelectCPQ autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} onChange={this.typeableSelectOnChange} placeHolder={constants.DEFAULT_SELECT_OPTION}/>
                          {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                          {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>
                      );

                  case constants.NUMERIC_CONTROL:
                    return (
                      <React.Fragment>                        
                        <NumericSelect autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} textInputOnChange={this.textInputOnChange} />
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment>
                      );

                  case constants.IMAGE_DROPDOWN:
                    return (  
                      <React.Fragment>                   
                        <ImageDropdown markAsSelected={this.markAsSelected} autoFocus={autoFocus} screenOption={opt} key={caption} caption={caption} onChange={this.typeableSelectOnChange} onImageClicked={this.onImageClicked} />                      
                        {opt.InformationLink && <InfoLink href={opt.InformationLink} target="_blank">see more info</InfoLink> }
                        {opt.Description && <span>{opt.Description}</span> }
                      </React.Fragment> 
                    );
                      
                  default:
                    return null;
                }                                
            })}    

          
          </React.Fragment> 
        );
      } 
    }
  
    function mapStateToProps(state, ownProps) {    
      return {
        session: state.session,
        api: state.api,
        validation: state.validation
      };
    }
    
    function mapDispatchToProps(dispatch) {
        return {
            sessionActions: bindActionCreators(sessionActions, dispatch),
            apiActions: bindActionCreators(apiActions, dispatch),
            validationActions: bindActionCreators(validationActions, dispatch)
        };
    }    
  
  export default connect(mapStateToProps, mapDispatchToProps)(ScreenOption);