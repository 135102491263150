import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Header from  '../Header/Header';
import Configurator from '../Configurator/Configurator';
import Alerts from '../Alerts/Alerts';
import MyConfigurations from '../MyConfigurations/MyConfigurations';
import ProductConfigurations from '../ProductConfigurations/ProductConfigurations';
import AddProduct from '../ProductConfigurations/AddProductConfiguration';
import SignUpModal from '../SignUpModal/SignUpModal';
import EditProduct from '../ProductConfigurations/EditProductConfigurations';
import QuoteList from '../Quote/QuoteList';
import OrderList from '../Order/OrderList';
import Quote from '../Quote/Quote';
import Order from '../Order/Order';
import ResetPassword from '../SignUpModal/ResetPassword';
import RequestPasswordReset from '../SignUpModal/RequestPasswordReset';
import QuoteDashboard from '../Quote/QuoteDashboard';
import OrderDashboard from '../Order/OrderDashboard';
import UserList from '../Administration/UserList';
import CompanyLocationList from '../Administration/CompanyLocationList';
import ModifyCompanyLocation from '../Administration/ModifyCompanyLocation';
import CompanyList from '../Administration/CompanyList';
import ModifyCompany from '../Administration/ModifyCompany';
import AddUser from '../Administration/ModifyUser';
import QuotePrint from '../Quote/QuotePrint';
import QuoteOrderCommonOverview from '../QuoteOrderCommon/Overview';
import Invoice from '../Order/Invoice';
import {ThemeProvider} from 'styled-components';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteActions from '../../actions/quoteActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as alertActions from '../../actions/alertActions';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import * as constants from '../../modules/constants';
import LoggedOut from '../Common/LoggedOut/LoggedOut';


// const rentechTheme = {  
//   buttonColor: '#EEEEEE',
//   altButtonColor: '#6D6E71',
//   buttonTextWhite:'#FFF',
//   activeStageColor: '#13cdfd',
//   inactiveStageForeColor:'#000',
//   activeStageForeColor:'#FFF',
//   completedStageColor: '#EEEEEE',
//   incompleteStageColor: '#EEEEEE',
//   accentColor: '#ffdd00',
//   darkBackground: '#6D6E71',
//   lightBackground: '#EEEEEE',
//   gridLineHoverBackgroundColor: '#4d4d4d', 
//   gridLineHoverOpacity: .7,
//   gridLineHoverColor: '#FFF',
//   gridRowBackgroundColor: '#f5f5f5', //offwhite
//   gridRowAlternateBackgroundColor: '#FFF' //FDB813

// };




class App extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {      
      theme: constants.rentechTheme,    
      hasError: false  
    };

    //this.props.quoteActions.getQuotes(this.props.session.company, this.props.session.userCompanyLocationIdCSV);

    this.getStatusTypes();
  }  

  async getStatusTypes() {    
    await this.props.quoteOrderCommonActions.getStatusTypes();                         
  }  

  render() {   
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    
    return (      
          
      <ThemeProvider theme={this.state.theme}>
        <div className="App"> 
          <ErrorBoundary>
            <Header />             
            <Alerts />              
            <SignUpModal />  
            <Switch>
              <Route exact path="/" component={Configurator}/>
              <Route path="/Configurator" component={Configurator}/> 
              <Route path="/MyConfigurations" component={MyConfigurations} />
              {/* <Route path="/ProductConfigurations" component={ProductConfigurations} /> */}
              <Route path="/AddProduct" component={AddProduct} />
              <Route path="/EditProduct" component={EditProduct} />
              <Route path="/QuoteList" component={QuoteList} />
              <Route path="/OrderList" component={OrderList} />
              <Route path="/Quote" component={Quote} />
              <Route path="/Order" component={Order} />
              <Route path="/QuotePrint" component={QuotePrint} />
              <Route path="/ResetPassword" component={ResetPassword} />
              <Route path="/QuoteDashboard" component={QuoteDashboard} />            
              <Route path="/UserList" component={UserList} />
              <Route path="/AddUser" component={AddUser} />
              <Route path="/CompanyLocationList" component={CompanyLocationList} />
              <Route path="/ModifyCompanyLocation" component={ModifyCompanyLocation} />
              <Route path="/CompanyList" component={CompanyList} />
              <Route path="/ModifyCompany" component={ModifyCompany} />
              <Route path="/OrderDashboard" component={OrderDashboard} />
              <Route path="/Overview" component={QuoteOrderCommonOverview} />
              <Route path="/Invoice" component={Invoice} />
              <Route path="/RequestPasswordReset" component={RequestPasswordReset} />
              <Route path="/Login" component={LoggedOut} />
            </Switch>
                       
            {/* <p className="poweredBy">Powered By &nbsp;&nbsp;&nbsp;<a href="https://renaissancetech.com/"><img src="/images/RenTechEmail.png" alt="renaissancetech" /></a></p> */}
           
          </ErrorBoundary>                      
        </div>   
      </ThemeProvider>         
    );
  }
}

function mapStateToProps(state, ownProps) {    
  return {
    api: state.api,
    session: state.session,
    quote: state.quote,    
    alert: state.alert,
    quoteOrderCommon: state.quoteOrderCommon
  };
}

function mapDispatchToProps(dispatch) {
  return {
      apiActions: bindActionCreators(apiActions, dispatch),
      sessionActions: bindActionCreators(sessionActions, dispatch),
      quoteActions: bindActionCreators(quoteActions, dispatch),      
      alertActions: bindActionCreators(alertActions, dispatch),
      quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
  };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));