var jwt = require('jsonwebtoken');

module.exports = {
    
    verifyToken: function() {
        let token = window.localStorage.getItem('sxnijSpsandfl');

        if (token === null) return;
        
        let decoded = jwt.decode(token);    

        var dateNow = new Date();        
        
        if (!decoded) return null;        

        if (decoded.exp > dateNow.getTime() / 1000)             
            return decoded;               
        else 
            return null;    
    },

    getUsernameFromToken: function() {
        let token = window.localStorage.getItem('sxnijSpsandfl');

        if (token === null) return '';
        
        let decoded = jwt.decode(token);   
        
        if (!decoded) return null;

        return decoded.username;
    },

    signOut: function() {
        localStorage.removeItem('sxnijSpsandfl');
    }
    
}