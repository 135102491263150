import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import {Provider} from 'react-redux';
import * as constants from './modules/constants';


const initialState = {
    session: { 
        loggedIn: false, 
        username: '',
        showSignupModal: false,
        configuratorMode: constants.CONFIGURATOR_MODES.default
    },
    api: { 
        showSubmitConfigurationButton: false, 
        uncommittedScreenOptions: [], 
        uncommittedUserConfigurations: [],
        companyProducts: [] 
    },
    validation: {
        requiredScreenOptions: []
    }    
};

const store = configureStore(initialState);

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();