import * as types from './actionTypes';
import axios from 'axios';
import ErrorHandler from '../modules/errorHandler';
import * as alerts from './alertActions';

export const createQuote = (username, company) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/createQuote', { username: username})
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                     
                    dispatch(createQuoteSuccess(res.data.result[0].id));
                    dispatch(setActiveQuote(res.data.result[0].id));
                    dispatch(getQuotes(company));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const createLine = (quoteId, username, price, imgUrl, configId, headerId, productId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/createLine', { quoteId: quoteId, username: username, price: price, imgUrl: imgUrl, configId: configId, headerId: headerId, productId: productId, orderId: orderId })
            .then(res => {                
                if (res.status !== 200) { 
                    ErrorHandler.logError(res);                
                }  else {                 
                    console.log(res.data.result);
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const updateLine = (price, imgUrl, LineId) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/updateLine', { price: price, imgUrl: imgUrl, LineId: LineId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getQuotes = (company, userCompanyLocationIdCSV, roleName, quoteFilter = null, offset = null, rowsToFetch = null, sortByColumn = null, sortDirection = null) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/getQuotes', { company: company, userCompanyLocationIdCSV: userCompanyLocationIdCSV, roleName: roleName, quoteFilter: quoteFilter, offset: offset, rowsToFetch: rowsToFetch, sortByColumn: sortByColumn, sortDirection: sortDirection  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                  
                    dispatch(getQuotesSuccess(res.data.result));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const convertQuoteToOrder = (quoteId, username) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/convertQuoteToOrder', { quoteId: quoteId, username })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);  
                    dispatch(alerts.showFailureAlert(`Quote failed to convert to an order`));              
                } else {
                    console.log(res.data.result[0].orderId);
                    dispatch(orderConvertedTo(res.data.result[0].orderId));
                    dispatch(alerts.showSuccessAlert(`Quote: ${quoteId} successfully converted to an order`));
                }                              
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getQuoteById = (quoteId, communicationOffset, communicationRowsToFetch) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/getQuoteById', {quoteId: quoteId, communicationOffset: communicationOffset, communicationRowsToFetch: communicationRowsToFetch})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {               
                // create quote comm success                                                                                
                dispatch(getQuoteByIdSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export function getQuoteByIdSuccess(quote) {
    return { type: types.GET_QUOTE_BY_ID_SUCCESS, quote };
}

export function getQuotesSuccess(quotes) {
    return { type: types.GET_QUOTES_SUCCESS, quotes };
}

export function createQuoteSuccess(currentQuoteId) {
    return { type: types.CREATE_QUOTE_SUCCESS, currentQuoteId };
}

export function setActiveQuote(currentQuoteId) {
    return { type: types.SET_ACTIVE_QUOTE, currentQuoteId };
}

export function getContactSuccess(contact) {
    return { type: types.GET_QUOTE_CONTACT_SUCCESS, contact };
}

export function getHeaderSuccess(header) {
    return { type: types.GET_QUOTE_HEADER_SUCCESS, header };
}

export function getAddressesSuccess(addresses) {
    return { type: types.GET_QUOTE_ADDRESSES_SUCCESS, addresses };
}

export function updatePoNumber(poNumber) {
    return { type: types.UPDATE_PO_NUMBER_REDUX_ONLY, poNumber };
}

export function deleteQuoteAttachmentSuccess(fileId) {
    return { type: types.DELETE_QUOTE_ATTACHMENT_SUCCESS, fileId };
}

export function getLinesSuccess(lines) {
    return { type: types.GET_QUOTE_LINES_SUCCESS, lines };
}

export function updateCompanyLocation(companyLocationId) {
    return { type: types.UPDATE_QUOTE_COMPANY_LOCATION_ID, companyLocationId };
}

export function getQuoteCompanyLocationFabricatorSuccess(fabricatorName) {
    return { type: types.GET_QUOTE_COMPANY_LOCATION_FABRICATOR_SUCCESS, fabricatorName };
}

export function orderConvertedTo(orderId) {
    return {type: types.ORDER_CONVERTED_TO, orderId}
}

export function clearQuote() {
    return { type: types.CLEAR_QUOTE };
}