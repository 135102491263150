import React from 'react';
import RadioButtonWithLabel from '../RadioButtonWithLabel/RadioButtonWithLabel';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

const RadioButtonsCPQ = React.memo(props => {                   
    return (
        <FormGroup className={props.screenOption.IsVisible === false ? 'd-none' : ''}>  
            <RadioButtonWithLabel 
                options={props.screenOption.SelectableValues} 
                defaultValue={props.screenOption.Value} 
                id={props.screenOption.ID}
                selectOnChange={(e) => props.selectOnChange(e, props.screenOption.AutoContinueMode)}
                disabled={!props.screenOption.IsEnabled}
                required={props.screenOption.IsRequired}
                caption={props.screenOption.Caption}                
                name={props.screenOption.ID}
                autoFocus={props.autoFocus}
            />   
        </FormGroup>       
    );      
});

export default RadioButtonsCPQ;

RadioButtonsCPQ.propTypes = {
    screenOption: PropTypes.object.isRequired,
    caption: PropTypes.string.isRequired,
    selectOnChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool  
};