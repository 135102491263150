import React from 'react';
import Button from '../Common/Button/Button';
import ContinueButton from '../Common/CPQ/ContinueButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import formatter from '../../modules/formatter';

export const ContainerStyled = styled.div`
    padding: 1em;    
    background-color: #f5f5f5;
    height: 54px;
    margin-bottom:1em;

    button {
        margin-left: 5em;
        float:right;
    }
    
    @media(min-width:1201px) {                                               
        text-align:left; 
                        
    }
`;

export const H1 = styled.h1`
    font-size:24px !important;
    font-weight: 500 !important;
    text-align: center; 
    float:left;    
`;

const ConfiguratorPriceBar = React.memo(props => {               
    return (
        <ContainerStyled>           
                                                                 
            {(props.showSubmitConfigurationButton === true) ?
            <Button                 
                disabled={false} 
                className="continue" 
                type="Button" 
                onMouseUp={props.onSubmitConfiguration} 
                displayText="Finish" 
                backgroundColor="#e2231a"
                color="#FFF"
            />   
            : <ContinueButton className="continue"/> }  

             <Button                 
                disabled={false} 
                className="continue" 
                type="Button" 
                onMouseUp={props.onCancel} 
                displayText="Cancel"                
            />            
                                   
            <H1 className="total-price">Total Price:  ${formatter.currency(props.price)}</H1>                                                                
        </ContainerStyled>
    );  
});

export default ConfiguratorPriceBar;

ConfiguratorPriceBar.propTypes = {
    showSubmitConfigurationButton: PropTypes.bool,
    onSubmitConfiguration: PropTypes.func.isRequired,
    price: PropTypes.number.isRequired  
};