import React from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as sessionActions from '../../actions/sessionActions';
import * as adminActions from '../../actions/adminActions';
import * as alertActions from '../../actions/alertActions';
import { loadProgressBar } from 'axios-progress-bar';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import * as constants from '../../modules/constants';
import 'axios-progress-bar/dist/nprogress.css';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    margin-bottom: 10px;
`;

export const LabelStyled = styled(Label)`
    font-weight: bold;    
`;

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input {width: 100% !important; }
`;

export const HeaderRowStyled = styled(Row)`
    font-weight: bold;
    margin-top: 1em;        
    margin-bottom: 1em;
`;

export const TextInputPrimitiveStyled = styled(TextInputPrimitive)`
    width: 300px;
`;

export const BodyRowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;    
    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }
`;

export const ContainerStyled = styled(Container)`
    padding-left: 1% !important;
    padding-right: 1% !important;
`;

class UserList extends React.Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();   
        
        this.onClickNewUser = this.onClickNewUser.bind(this);
        this.onClickEditUser = this.onClickEditUser.bind(this);
        this.onClickSearchUser = this.onClickSearchUser.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.userSearchChanged = this.userSearchChanged.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);

        this.state = {   
            companyLocationId: null,  
            companyId: null,
            activeUser: null,
            loading: true,
            userSearchText: '',
            roleId: null
        };        
    }  
    
    componentDidMount() {   
        this.props.adminActions.getAllCompanies(this.props.session.manufacturer).then(() => {
            if (!this.props.quoteOrderCommon.roles) {
                this.props.quoteOrderCommonActions.getRoles(this.props.session.roleName).then( () => {
                    this.setState({ loading: false }) 
                });
            } else {
                this.setState({ loading: false }) 
            }             
        });                                                                        
    }

    onCompanyChange(event) {
        const companyId = event.target.value;

        if (companyId === constants.PLACEHOLDER) return;

        this.setState({ companyId: companyId }, () => {

            if(this.props.admin.companyList) {
                let companyName = this.props.admin.companyList.filter(c => c.id == companyId)[0].company;
                this.props.adminActions.getCompanyLocations(companyName);
                this.props.quoteOrderCommonActions.clearUsersAssignedToLocation();
                this.props.adminActions.setActiveCompany(this.state.companyId);   
            } else {
                this.props.alertActions.showFailureAlert("no company found");
            }      
        });
    }

    onLocationChange(event) {
        this.setState({ companyLocationId: event.target.value }, this.getUsers(event.target.value, this.state.roleId));              
    }

    onRoleChange(event) {
        this.setState({ roleId: event.target.value }, this.getUsers(this.state.companyLocationId, event.target.value));              
    }

    userSearchChanged(event) {        
        this.setState({ userSearchText: event.target.value });
    }

    onClickSearchUser() {
        this.props.adminActions.searchByUsername(this.state.userSearchText).then(() => {
            if (this.props.admin.activeUser === this.state.userSearchText) {
                this.props.history.push({ pathname: '/AddUser' });                
            } 
        });
    }

    onClickEditUser(event, userId, username) {             
        this.setState({ activeUser: userId }, this.editUser(username));                
    }

    editUser(username) {
        this.props.adminActions.setActiveUser(username, this.state.companyId, this.state.companyLocationId);
        this.props.history.push({ pathname: '/AddUser' });     
    }

    onClickNewUser() {     
        this.props.adminActions.setActiveUser(null);           
        this.props.history.push({ pathname: '/AddUser' });
    }   

    getUsers(companyLocationId, roleId) {
        if (companyLocationId !== constants.PLACEHOLDER)
            this.props.quoteOrderCommonActions.getUsersAssignedToLocation(companyLocationId, roleId);
    }

    render() {   
        
        if (this.state.loading === true) return <React.Fragment />;
        let users = !this.state.companyLocationId ? null : this.props.quoteOrderCommon.companyLocationUsers;

        if (!users) { 
            return (
                <ContainerStyled>
                    <h1>Users</h1>
                    
                    <Row>
                        <Col xl="2">
                            <TextInputPrimitiveStyled id="userSearch" placeholder="Search by Username" onChange={(e) => this.userSearchChanged(e)} />
                        </Col>
                        <Col xl="2">
                            <Button 
                                displayText="Search" onClick={this.onClickSearchUser} />
                        </Col>  
                        <Col xl="6"></Col>  
                        <Col xl="2">
                            <div className="text-right">
                                <Button displayText="New" onClick={this.onClickNewUser} />
                            </div>
                        </Col>                             
                    </Row>

                    <LabelStyled for="companyList">Company</LabelStyled>
                    <SelectPrimitiveStyled id="companyList" value={this.state.companyId} selectableValues={this.props.admin.companyList} placeHolder="Select a Company" onChange={(e) => this.onCompanyChange(e)} autoFocus={false} required={true} />                

                    <LabelStyled for="companyLocationId">Location</LabelStyled>
                    <SelectPrimitiveStyled id="companyLocationId" value={this.state.companyLocationId} selectableValues={this.props.admin.companyLocations} placeHolder="Select a Location" onChange={(e) => this.onLocationChange(e)} autoFocus={false} required={true} />                   

                    <LabelStyled for="roleId">Role</LabelStyled>
                <SelectPrimitiveStyled id="roleId" value={this.state.roleId} selectableValues={this.props.quoteOrderCommon.roles} placeHolder="Select a Role" onChange={(e) => this.onRoleChange(e)} autoFocus={false} required={true} />                
                    <HeaderRowStyled>
                        <Col xl="2">Username</Col>                
                        <Col xl="2">First Name</Col>
                        <Col xl="2">Last Name</Col>                        
                    </HeaderRowStyled>
                </ContainerStyled>
            );            
        }
                
        return (
            <ContainerStyled>                                                            
                <h1>Users</h1>

                <div className="text-right">
                    <Button displayText="New" onClick={this.onClickNewUser} />
                </div>

                <LabelStyled for="companyList">Company</LabelStyled>
                <SelectPrimitiveStyled id="companyList" value={this.state.companyId} selectableValues={this.props.admin.companyList} placeHolder="Select a Company" onChange={(e) => this.onCompanyChange(e)} autoFocus={false} required={true} />                

                <LabelStyled for="companyLocationId">Location</LabelStyled>
                <SelectPrimitiveStyled id="companyLocationId" value={this.state.companyLocationId} selectableValues={this.props.admin.companyLocations} placeHolder="Select a Location" onChange={(e) => this.onLocationChange(e)} autoFocus={false} required={true} />                

                <LabelStyled for="roleId">Role</LabelStyled>
                <SelectPrimitiveStyled id="roleId" value={this.state.roleId} selectableValues={this.props.quoteOrderCommon.roles} placeHolder="Select a Role" onChange={(e) => this.onRoleChange(e)} autoFocus={false} required={true} />                
                
                <HeaderRowStyled>
                    <Col xl="2">Username</Col>                
                    <Col xl="2">First Name</Col>
                    <Col xl="2">Last Name</Col>                      
                </HeaderRowStyled>
                {                                               
                    users.map(user => {                                               
                        return(
                            <BodyRowStyled key={user.id} onClick={(e) => this.onClickEditUser(e, user.id, user.username)}>
                                <Col xl="2" >
                                    {user.username}
                                </Col>
                                <Col xl="2">
                                    {user.firstName}
                                </Col>
                                <Col xl="2">
                                    {user.lastName}
                                </Col>                                
                                {/* <Col>
                                    <Button displayText="Modify" onClick={(e) => this.onClickEditUser(e, quote.id)} />
                                </Col> */}
                            </BodyRowStyled>
                        );                        
                    })
                }                                    
            </ContainerStyled>               
        );                
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      quoteOrderCommon: state.quoteOrderCommon,
      session: state.session,
      admin: state.admin
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),      
        sessionActions: bindActionCreators(sessionActions, dispatch), 
        alertActions: bindActionCreators(alertActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch)               
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));