import React from 'react';
import InputLabel from '../InputLabel/InputLabel';
import PropTypes from 'prop-types';
import TextInputPrimitive from '../Primitives/TextInputPrimitive';
import styled from 'styled-components';

export const TextInputPrimitiveStyled = styled(TextInputPrimitive)`
    width: 100%;       

    @media (min-width: 1201px) { width: 70%;}

    @media (max-width: 1200px) { width: 95%;}
`;

export const InputLabelStyled = styled(InputLabel)`
    margin-right: 10px;
`;

const TextInputWithLabel = React.memo(({id, caption, required, ...props}) => {             
    return (
        <React.Fragment>
            <InputLabelStyled htmlFor={id} caption={caption} isRequired={required} />
                             
            <TextInputPrimitiveStyled
                key={id}                                
                id={id} 
                name={id}              
                required={required}    
                {...props}
            />               
        </React.Fragment>
    );     
});  

export default TextInputWithLabel; 

TextInputWithLabel.propTypes = {
    caption: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,    
    onBlur: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,   
    disabled: PropTypes.bool,     
    required: PropTypes.bool,
    autoFocus: PropTypes.bool        
  };
