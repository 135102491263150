import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import { withRouter } from 'react-router-dom';
import ProductConfigurationList from './ProductConfigurationList';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import { Container } from 'reactstrap';

export const AddButtonWrapper = styled.div`
  text-align: right;
  display: inline-block;
  width: 100%;
`;

export const H1 = styled.h1`
  text-align: center;
`;

class ProductConfigurations extends Component {  

  constructor(props, context) {
    super(props, context);    
    
    this.setActiveProduct = this.setActiveProduct.bind(this);  
    this.onDelete = this.onDelete.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);

    // let username = this.props.session.username;

    // if (username) {
    //   if (this.props.session.username === "" || this.props.session.loggedIn === false) {            
    //      this.props.apiActions.getProductConfigurations(this.props.session.company);   
    //   }              
    // }
  }
  
  componentDidMount() {          
    if(this.props.session.loggedIn === true)
      this.props.apiActions.getProductConfigurations(this.props.session.company); 
      
    //this.props.apiActions.getUserActiveProduct(this.props.session.username, this.props.session.company);
  }

  setActiveProduct(productId) {     
    this.props.apiActions.setActiveProduct(this.props.session.username, productId, this.props.session.company);      
  }

  editProduct(editProduct) {     
    this.props.history.push({
      pathname: '/EditProduct',
      state: { editProduct: editProduct }
    }); 
  }

  onDelete(productId) {
    this.props.apiActions.deleteUserProduct(this.props.session.username, productId);
  }

  addProduct(){
    this.props.history.push({
      pathname: '/AddProduct'
    });  
  }

  render() {            
    if (!this.props.api.productConfigurations) 
      return ( <React.Fragment></React.Fragment> );
          
    return (     
      <Container className="product-configurations p-3 container">
        <H1>Products</H1>
        <AddButtonWrapper>
          <Button                                                 
            className="addProduct"
            type="Button"
            onClick={this.addProduct}
            displayText="New"             
          />
        </AddButtonWrapper>
        <ProductConfigurationList activeProductId={this.props.api.activeProductId} editProduct={this.editProduct} productConfigurationList={this.props.api.productConfigurations} setActiveProduct={this.setActiveProduct} onDelete={this.onDelete}/>
      </Container>           
    );
  } 
}

function mapStateToProps(state, ownProps) {    
  return {
    session: state.session,
    api: state.api
  };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),
        apiActions: bindActionCreators(apiActions, dispatch)
    };
}    

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductConfigurations));