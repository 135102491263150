import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';

const PoNumber = props => {
    return (        
        <Row>               
            <Col>
                <label htmlFor={props.id}>PO Number</label>
                <TextInputPrimitive disabled={props.readonly} id={props.id} onBlur={e => props.onBlur(e)} defaultValue={props.defaultValue} />
            </Col>
        </Row>        
    );
};

PoNumber.propTypes = {
    onBlur: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired
};

export default PoNumber;