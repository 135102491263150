import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import InputLabel from '../Common/InputLabel/InputLabel';

export const HeaderRowStyled = styled(Row)`   
    margin-left: 0 !important;
    margin-right: 0 !important;    

    @media(max-width:1200px) {
        margin-bottom:.5em;        
    }
`;


export const RowStyled = styled(Row)`
    margin-bottom: 1em;
    margin-left: 0 !important;
    margin-right: 0 !important;    

    @media(max-width:1200px) {
        margin-bottom:.5em;        
    }
`;

export const ColStyled = styled(Col)`
    @media(max-width:1200px) {
        margin-bottom:.5em;        
    }
`;

export const ContactRowStyled = styled(Row)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    position: relative;
    top: -15px;
`;
export const InputLabelStyled = styled(InputLabel)`
    font-weight: normal !important;
`;

/*
    READ ME

    The input ID must be the same as the column name in sql. This is used when building the update statements in the node service to reduce the amount of code.

*/

const ContactInfo = React.memo(props => {  
    if (!props.contact) {
        return <React.Fragment></React.Fragment>
    }

    let contactInfo = props.contact;

     
    return (
        <React.Fragment>            
            {contactInfo.map(contact => {
                return (
                    <div key={contact.id}>
                        <ContactRowStyled>       
                            {/* <Col xl="2">
                                <FormGroup>
                                    <InputLabelStyled htmlFor="email" caption="Email" isRequired={true} />
                                    <TextInputPrimitive id="email" name="email" disabled={props.readonly} onClick={(e) => props.onInputFocus(e)} defaultValue={contact.email} onBlur={(e) => props.onContactFieldUpdate(e) }/>
                                </FormGroup>
                            </Col>                  */}
                            <Col xl="3">
                                <FormGroup>
                                    <InputLabelStyled htmlFor="firstName" caption="Consumer First Name" isRequired={true} />
                                    <TextInputPrimitive id="firstName" name="firstName" disabled={props.readonly} onClick={(e) => props.onInputFocus(e)} defaultValue={contact.firstName} onBlur={(e) => props.onContactFieldUpdate(e) }/>
                                </FormGroup>
                            </Col>   
                            <Col xl="3">
                                <FormGroup>
                                <InputLabelStyled htmlFor="lastName" caption="Consumer Last Name" isRequired={true} />
                                    <TextInputPrimitive id="lastName" name="lastName" disabled={props.readonly} onClick={(e) => props.onInputFocus(e)} defaultValue={contact.lastName} onBlur={(e) => props.onContactFieldUpdate(e) }/>
                                </FormGroup>
                            </Col> 

                            {props.shipToCustomer === true && 
                            <Col xl="3">
                                <FormGroup>
                                    <InputLabelStyled htmlFor="phone" caption="Phone" isRequired={true} />
                                    <TextInputPrimitive id="phone" name="phone" disabled={props.readonly} onClick={(e) => props.onInputFocus(e)} defaultValue={contact.phone} onBlur={(e) => props.onContactFieldUpdate(e) }/>
                                </FormGroup> 
                            </Col>  
                            }

                            {/* <Col xl="2">
                                <FormGroup>
                                    <InputLabelStyled htmlFor="altPhone" caption="Alt Phone" isRequired={false} />
                                    <TextInputPrimitive id="altPhone" name="altPhone" disabled={props.readonly} onClick={(e) => props.onInputFocus(e)} defaultValue={contact.altPhone} onBlur={(e) => props.onContactFieldUpdate(e) }/>
                                </FormGroup> 
                            </Col>  */}
                        </ContactRowStyled>                           
                    </div>
                );
            })}
                                             
        </React.Fragment>
    );
});

export default ContactInfo;

ContactInfo.propTypes = {
    contact: PropTypes.array.isRequired,
    readonly: PropTypes.bool,
    shipToCustomer: PropTypes.bool
};