import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import CheckBoxPrimitive from '../Common/Primitives/CheckBoxPrimitive';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import * as constants from '../../modules/constants';
import AddDesigners from '../QuoteOrderCommon/AddDesigners';
export const LabelStyled = styled(Label)`
    margin-bottom: 0 !important;
`;

export const DatePickerLabel = styled.label`
    margin-right: 15px;
`;

export const CheckBoxPrimitiveStyled = styled(CheckBoxPrimitive)`
    display: block;
`;

export const ColStyled = styled(Col)`
    padding-left: 5px !important;
    padding-right: 5px !important;
`;

const HeaderDetails = props => {    
    
    return (
        <React.Fragment>
        <Row><Col><h1>Details</h1></Col></Row>
        <Row> 
            <ColStyled xs="12" xl="3">                    
                <FormGroup>
                    <LabelStyled for="name">Job Name<span className="text-danger">*</span></LabelStyled>
                    <TextInputPrimitive disabled={props.readonly} id="name" name="name" defaultValue={props.description} onClick={(e) => props.onInputFocus(e)} onBlur={(e) => props.onHeaderFieldUpdate(e)} />
                </FormGroup>                
            </ColStyled>                   
            <ColStyled xs="12" xl="3">
                <FormGroup>
                    <LabelStyled for="status">Status<span className="text-danger">*</span></LabelStyled>
                    <SelectPrimitive disabled={props.roleName !== constants.ROLE.SystemAdmin ? true : props.readonly} id="status" value={props.status} selectableValues={props.statusOptions} placeHolder="Select a Status" onChange={(e) => props.statusChange(e)} autoFocus={false} required={false} />
                </FormGroup>                        
            </ColStyled>   
            {props.roleName === constants.ROLE.SystemAdmin && 
                <ColStyled xs="12" xl="3">
                    <FormGroup>
                        <LabelStyled for="company">Company<span className="text-danger">*</span></LabelStyled>
                        <SelectPrimitive disabled={props.readonly} id="companyId" value={props.companyId} selectableValues={props.companyList} placeHolder="Select Company" onChange={(e) => props.onCompanyChange(e)} autoFocus={false} required={false} />
                    </FormGroup>                        
                </ColStyled> 
            }
            <ColStyled xs="12" xl="3">
                <FormGroup>
                    <LabelStyled for="companyLocationId">Location<span className="text-danger">*</span></LabelStyled>
                    <SelectPrimitive disabled={props.readonly} id="companyLocationId" value={props.companyLocationId} selectableValues={props.userCompanyLocations} placeHolder="Select a Location" onChange={(e) => props.onLocationChange(e)} autoFocus={false} required={false} />
                </FormGroup>                        
            </ColStyled>  
            <ColStyled xs="12" xl="3">
                <FormGroup>                                    
                    <LabelStyled for="fabricatorName">Fabricator Name<span className="text-danger"></span></LabelStyled>
                    <TextInputPrimitive disabled={true} id="fabricatorName" name="fabricatorName" defaultValue={props.fabricatorName} onClick={(e) => props.onInputFocus(e)} onBlur={(e) => props.onHeaderFieldUpdate(e)} />                    
                </FormGroup>                        
            </ColStyled> 
              
            <ColStyled xs="12" xl="2">
                <FormGroup>
                   
                    {/* {props.designers && props.designers.length > 0 && */}
                        <React.Fragment>
                            <LabelStyled for="createdByUserId">Designer<span className="text-danger">*</span></LabelStyled>
                            <SelectPrimitive id="createdByUserId" disabled={props.readonly} htmlFor="createdByUserId"  value={props.createdByUserId} onChange={(e) => props.onCreatedByChanged(e)} placeHolder="Select a Designer" selectableValues={props.designers} />   
                        </React.Fragment>
                    {/* }

                    {!props.designers &&
                        <React.Fragment>
                            <LabelStyled for="createdByEmail">Designer Email<span className="text-danger">*</span></LabelStyled>
                            <TextInputPrimitive disabled={props.readonly} id="createdByEmail" name="createdByEmail" defaultValue={props.createdByEmail} onClick={(e) => props.onInputFocus(e)} onBlur={(e) => props.onHeaderFieldUpdate(e)} />                    
                        </React.Fragment>
                    } */}
                </FormGroup>                        
            </ColStyled> 
            <ColStyled xs="12" xl="1">
                <FormGroup>                   
                    <AddDesigners onInputFocus={props.onInputFocus} updateHeader={props.updateHeader}  />
                </FormGroup>                        
            </ColStyled> 

            
            <ColStyled xs="12" xl="2">
                <FormGroup>
                    <LabelStyled for="installType">Order Type<span className="text-danger">*</span></LabelStyled>

                    {props.companyLocationUsers && <SelectPrimitive id="installType" value={props.installType} disabled={true} htmlFor="installType" onChange={(e) => props.onHeaderFieldUpdate(e)} placeHolder="Select an Install Type" selectableValues={props.installTypes} />  }                     
                </FormGroup>                        
            </ColStyled> 
            {props.installType === constants.INSTALL_TYPES.SOSInstall &&
            <ColStyled xs="12" xl="2">
                <FormGroup>
                    <LabelStyled for="installerId">Installer</LabelStyled>

                    {props.companyLocationInstallers && <SelectPrimitive id="installerId" value={props.installerId ? props.installerId : ""} disabled={props.readonly || props.roleName === constants.ROLE.User} htmlFor="installerId" onChange={(e) => props.onHeaderFieldUpdate(e)} placeHolder="Select" selectableValues={props.companyLocationInstallers} />  } 
                    
                </FormGroup>                        
            </ColStyled> 
            }
          
            <ColStyled xs="12" xl="2">
                <FormGroup>
                    <LabelStyled for="shipToStore">Ship To<span className="text-danger">*</span></LabelStyled>

                    <SelectPrimitive id="shipToStore" value={props.shipToStoreTypeId} disabled={props.readonly} htmlFor="shipToStore" onChange={(e) => props.onShipToStoreChanged(e)} placeHolder="Select Ship To" selectableValues={props.shipToTypes} />
                    {/* <CheckBoxPrimitiveStyled id="shipToStore" disabled={props.readonly} checked={props.shipToStore} onClick={(e) => props.onShipToStoreChanged(e.target.checked)} />                       */}
                </FormGroup>                        
            </ColStyled> 

            {/* { props.isOrder === true && 
            <ColStyled xs="12" xl="2">
                <FormGroup>
                    <LabelStyled for="poNumber">PO Number<span className="text-danger">*</span></LabelStyled>
                    <TextInputPrimitive disabled={props.readonly} id="poNumber" name="poNumber" defaultValue={props.poNumber} onClick={(e) => props.onInputFocus(e)} onBlur={(e) => props.onHeaderFieldUpdate(e)} />                 
                </FormGroup>                        
            </ColStyled>  
            }                           */}
        </Row>
        
        </React.Fragment>
    );
};

HeaderDetails.propTypes = {
    description: PropTypes.string.isRequired,
    onInputFocus: PropTypes.func.isRequired,
    statusOptions: PropTypes.array.isRequired,
    status: PropTypes.number,
    companyLocationId: PropTypes.number,
    createdByOptions: PropTypes.array.isRequired,
    createdByUserId: PropTypes.number,
    onHeaderFieldUpdate: PropTypes.func.isRequired,
    onLocationChange: PropTypes.func.isRequired,
    statusChange: PropTypes.func.isRequired,
    userCompanyLocations: PropTypes.array.isRequired,
    companyLocationUsers: PropTypes.array.isRequired,
    shipToStoreTypeId: PropTypes.bool.isRequired,
    onShipToStoreChanged: PropTypes.func.isRequired,
    onCreatedByChanged: PropTypes.func.isRequired,
    installerId: PropTypes.number,
    companyLocationInstallers: PropTypes.array.isRequired,
    installType: PropTypes.string.isRequired, 
    installTypes: PropTypes.array.isRequired,
    readonly: PropTypes.bool,
    roleName: PropTypes.string.isRequired,
    onCompanyChange: PropTypes.func.isRequired,
    shipToTypes: PropTypes.array.isRequired,
    poNumber: PropTypes.string.isRequired,
    isOrder: PropTypes.bool,
    designers: PropTypes.array
};

export default HeaderDetails;