import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as adminActions from '../../actions/adminActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as alertActions from '../../actions/alertActions';
import { withRouter } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import CheckBoxPrimitive from '../Common/Primitives/CheckBoxPrimitive';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import * as constants from '../../modules/constants';
import * as validation from '../../modules/validation';
import LocationConfiguration from './LocationConfiguration';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import Loading from '../Common/Loading/Loading';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    margin-bottom: 10px;
`;

export const LabelStyled = styled(Label)`
    font-weight: bold;    
`;

export const RowStyled = styled(Row)`
    margin-bottom: 1em;
`;

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input {width: 100% !important; }
`;

export const PasswordResetButton = styled(Button)`
    &.btn {                
        width: 160px !important;
        margin-top: .5em;
    }
`;

export const PasswordWrapper = styled.div`
    padding: .5em;
    border: 2px solid lightgray;
    margin-top: 1em;
`;

export const ContainerStyled = styled(Container)`
    padding-left: 1% !important;
    padding-right: 1% !important;
`;

class Users extends Component {  
    constructor(props, context) {
        super(props, context); 
        
        loadProgressBar();
        
        this.formValueChange = this.formValueChange.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onFabricatorChange = this.onFabricatorChange.bind(this);
        this.updatePassword = this.updatePassword.bind(this);

        this.state = ({
            id: '',
            username: '',
            password: '',
            reenterPassword: '',           
            role: '',
            selectedLocationsArray: [],
            firstName: '',
            lastName: '',
            editMode: false,
            loading: true,
            companyId: null,
            fabricatorId: null,
            hideFabricatorDropdown: false 
        });              
    }

    componentDidMount() { 
        let promises = [];

        const promise1 = this.props.quoteOrderCommonActions.getUserCompanyLocations(this.props.session.userCompanyLocationIdCSV, this.props.session.username);
        const promise2 = this.props.quoteOrderCommonActions.getRoles(this.props.session.roleName);
        const promise3 = this.props.adminActions.getActiveUsersCompanyLocations(this.props.admin.activeUser);
        const promise4 = this.props.adminActions.getActiveUserRole(this.props.admin.activeUser);
        const promise5 = this.props.adminActions.getAllFabricators(this.props.session.manufacturer);
        // if (!this.props.quoteOrderCommon.userCompanyLocations) 
             promises.push(promise1);

        // if (!this.props.quoteOrderCommon.roles)
             promises.push(promise2);

        // if (!this.props.admin.activeUserLocations)
             promises.push(promise3);

        // if (!this.props.admin.activeUserRole) 
             promises.push(promise4);

        // if (!this.props.admin.fabricatorList)
             promises.push(promise5);

        // if (promises.length === 0)  {
        //     this.setState({ loading: false });
        //     return;
        // }
        Promise.all(promises).then(() => {
            if (this.props.admin && this.props.admin.activeUser) {
                
                let user = this.props.quoteOrderCommon.companyLocationUsers.filter(u => u.username === this.props.admin.activeUser);

                if (user.length > 0) {
                    console.log('if');
                    this.setState({ 
                        id: user[0].id,
                        username: user[0].username,
                        firstName: user[0].firstName,
                        lastName: user[0].lastName,
                        editMode: true,
                        loading: false                        
                    });
                   
                    let initialLocations = [];
                    
                    this.props.admin.activeUserLocations.forEach(l => {
                        initialLocations.push(l.companyLocationId);
                    });

                    this.setState({ 
                        role: this.props.admin.activeUserRole, 
                        loading: false, 
                        selectedLocationsArray: initialLocations,
                        companyId: this.props.admin.activeCompanyId,
                        loading: false                                                  
                    });                                           
                }             
            }  else {
                console.log('else');
                this.setState({ loading: false });
                if (this.props.admin) {
                    this.props.adminActions.getCompanyLocationsSuccess(null);
                }
            }    
            
            

            // if (this.props.admin.companyList) {
            //     let activeCompany = this.props.admin.companyList.filter(c => c.id.toString() === this.props.admin.activeCompanyId);

            //     if (activeCompany.length > 0) {
            //         this.setState({
            //             hideFabricatorDropdown: activeCompany[0].companyTypeId === constants.COMPANY_TYPES.Store ? false : true,
            //             loading: false
            //         });
            //     }
            // }
        });
        
    }

    updatePassword() {
        if (!this.state.username) { 
            this.props.alertActions.showFailureAlert("Please enter a username to reset password.");
            return;
        }
        if (!this.state.password) {
            this.props.alertActions.showFailureAlert("Please enter a password to reset password.");
            return;
        } 

        if (this.state.password != this.state.reenterPassword) {
            this.props.alertActions.showFailureAlert("Passwords do now match.");
            return;
        }

        this.props.adminActions.updatePasswordAdmin(this.state.username, this.state.password);
    }

    onCompanyChange(event) {
        if (event.target.value === constants.PLACEHOLDER) {
            this.setState({ companyId: null });
            return;
        }

        const companyId = event.target.value;

        this.setState({ companyId: companyId }, () => {
            this.props.adminActions.setActiveCompany(companyId);

            let company = this.props.admin.companyList.filter(c => c.id == companyId)[0];
            let companyName = company.company;
            let companyTypeId = company.companyTypeId;
            
            // this.setState({
            //     hideFabricatorDropdown: companyTypeId === constants.COMPANY_TYPES.Store ? false : true
            // });
            
            this.props.adminActions.getCompanyLocations(companyName, this.state.fabricatorId);            
        });

        if (!this.props.admin.activeUser) {
            //default new users to role based on selected company type
            let companyTypeId = this.props.admin.companyList.filter(c => c.id == companyId)[0].companyTypeId;
            switch(companyTypeId) {
                case constants.COMPANY_TYPES.Fabricator:
                    this.setState({ role: constants.ROLE_ID.Fabricator });
                    break;              
                default:
                    this.setState({ role: constants.ROLE_ID.User });
                    break;
            }
        }
    }

    formValueChange(event) {
        switch(event.target.id) {                
            case "username":
                this.setState({ username: event.target.value });
                break;

            case "password":
                this.setState({ password: event.target.value });
                break;           
                
            case "role":
                this.setState({ role: event.target.value });
                break;             

            case "reenterPassword":
                this.setState({ reenterPassword: event.target.value });
                break; 
                
            case "firstName":
                this.setState({ firstName: event.target.value });
                break; 

            case "lastName":
                this.setState({ lastName: event.target.value });
                break; 
            default: 
                break;
        }   
    }   

    save() {        
        if (this.validate() !== true) {
           return;
        }        

        if (this.state.editMode === true) {
            this.props.adminActions.updateUser(this.state.selectedLocationsArray, this.state.role, this.state.username, this.state.firstName, this.state.lastName, this.state.companyId).then(() => {
                this.props.history.push("/UserList");
            });
        } else {
            this.props.adminActions.createUser(this.state.selectedLocationsArray, this.state.role, this.state.username, this.state.password, this.state.firstName, this.state.lastName, this.state.companyId).then(() => {
                this.props.history.push("/UserList");
            });
        }         
    }

    cancel() {
        this.props.history.push({ pathname: '/UserList' });
    }

    validate() {

        if (!this.state.companyId) {
            this.props.alertActions.showFailureAlert("Please select a company");
            return false;
        }

        if (!this.state.selectedLocationsArray) {
            this.props.alertActions.showFailureAlert("Please select a location");
            return false;
        }

        if (!this.state.role === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please select a role");
            return false;
        } 

        if (!this.state.username) { 
            this.props.alertActions.showFailureAlert("Please enter a username");
            return false;
        }

       if (this.state.editMode === false && (!this.state.password || !this.state.reenterPassword || validation.passwordsMatch(this.state.password, this.state.reenterPassword)) === false) {
            this.props.alertActions.showFailureAlert("Password is blank or passwords do not match.");
            return false;
        }            

        if (!this.state.firstName) {
            this.props.alertActions.showFailureAlert("Please enter a first name");
            return false;
        }
        
        if (!this.state.lastName) {
            this.props.alertActions.showFailureAlert("Please enter a last name");
            return false;
        }

       return true;
    }

    onSelectAllChange(event) {
        let locations = this.state.selectedLocationsArray;

        if (event.target.checked) {
            //add all locations in props.admin.companyLocations to this.state.selectedLocationArray
            if (this.props.admin.companyLocations && this.props.admin.companyLocations.length > 0) {
                this.props.admin.companyLocations.forEach(cl => {
                    if (!locations.includes(cl.id)) {
                        locations.push(cl.id);
                    }
                });
            }
            
            this.setState({ selectedLocationsArray: locations });  
        } else {
            //remove all locations in props.admin.companyLocations from this.state.selectedLocationArray
            let companyLocations = this.props.admin.companyLocations.map(l => l.id);
            let newLocations = locations.filter(l => !companyLocations.includes(l));
            this.setState({ selectedLocationsArray: newLocations });
        }
    }

    onFabricatorChange(event) {
        let fabricatorId;
        if (event.target.value === constants.PLACEHOLDER) {
            fabricatorId = 0;
        } else {
            fabricatorId = event.target.value;
        }
        
        const companyId = this.state.companyId; 
        if (!companyId)
            return;

        let companyName = this.props.admin.companyList.filter(c => c.id == companyId)[0].company;

        this.setState({ fabricatorId: fabricatorId }, () => {
            this.props.adminActions.getCompanyLocations(companyName, fabricatorId);
        })
    }    

    onLocationChange(event) {    
        let locations = this.state.selectedLocationsArray;
        let newId = parseInt(event.target.id);

        if (event.target.checked) {
            if (locations.includes(newId)) 
                return;
            else 
                locations.push(newId);
        } else {           
            let xlocations = locations.filter(l => l != newId);
            this.setState({ selectedLocationsArray: xlocations }); 
            return;            
        }
        
        this.setState({ selectedLocationsArray: locations });  
    }

    onRoleChange(event) {                     
        this.setState({ role: event.target.value });       
    }

    resetPassword() {
        this.props.sessionActions.forgotPassword(this.state.username);
    }

    formatLocationsForCheckboxSelect(arr) {      
        let options = [];         
        //if (this.props.admin.activeUserLocations)
                                    
        if (arr) {
            for (var i = 0; i < arr.length; i++) {    
                let checked = this.checkIfUserAssignedToLocation(arr[i][Object.keys(arr[i])[0]].toString());
                let opt = {
                    id: arr[i][Object.keys(arr[i])[0]],
                    label: arr[i][Object.keys(arr[i])[1]].toString(),
                    checked: checked
                }
          
                options.push(opt);
            }
        } 
        
        return options;
    }    

    checkIfUserAssignedToLocation(locationId) {
        if (this.state.editMode === true && !this.props.admin.activeUserLocations) return false;
        
        let isMatch = this.state.selectedLocationsArray.includes(parseInt(locationId));
        return isMatch;        
    }    

    render() {   
        if (this.state.loading === true) return(
            <Loading />
        );
       
        let locations = this.formatLocationsForCheckboxSelect(this.props.admin.companyLocations);

        return (     
        <ContainerStyled className="add-user p-3">            
            <h1 className="text-center">User</h1>
            <Form id="createUser">
                <FormGroupStyled>  
                    <LabelStyled for="companyId">Company</LabelStyled>
                    <SelectPrimitiveStyled id="companyId" value={this.state.companyId} selectableValues={this.props.admin.companyList} placeHolder="Select a Company" onChange={(e) => this.onCompanyChange(e)} autoFocus={false} required={true} />                

                    {/* {this.state.hideFabricatorDropdown === false &&
                    <FormGroupStyled> 
                        <LabelStyled for="fabricatorId">Fabricator</LabelStyled>
                        <SelectPrimitiveStyled id="fabricatorId" value={this.state.fabricatorId} selectableValues={this.props.admin.fabricatorList} placeHolder="Select a Fabricator" onChange={(e) => this.onFabricatorChange(e)} autoFocus={false} required={false} />                
                    </FormGroupStyled>
                    } */}

                    <LabelStyled for="companyLocationId">Locations</LabelStyled>    
                    <RowStyled>
                        <Col xl="1">
                            <Label for="selectAll">Select All</Label>
                        </Col>
                        <Col xl="1">
                            <CheckBoxPrimitive id="selectAll" onChange={(e) => this.onSelectAllChange(e)} required={false} autoFocus={false} />                                                                                           
                        </Col>
                    </RowStyled>
                                
                    <LocationConfiguration locations={locations} onLocationConfigurationChanged={this.onLocationChange} />
                              
                    <LabelStyled for="role">Role</LabelStyled>
                    <SelectPrimitiveStyled id="role" disabled={this.props.session.roleName && this.props.session.roleName === constants.ROLE.User} value={this.state.role} selectableValues={this.props.quoteOrderCommon.roles} placeHolder="Select a Role" onChange={(e) => this.onRoleChange(e)} autoFocus={false} required={true} />                
                    <TextInputWithLabel id="username" value={this.state.username} caption="Email Address (Username)" onChange={(e) => this.formValueChange(e)} required={true} />                       
                    <TextInputWithLabel id="firstName" value={this.state.firstName} caption="First Name" onChange={(e) => this.formValueChange(e)} required={true} />                     
                    <TextInputWithLabel id="lastName" value={this.state.lastName} caption="Last Name" onChange={(e) => this.formValueChange(e)} required={true} /> 

                    { 
                        this.state.editMode === false ? 
                        <React.Fragment>
                            <TextInputWithLabel id="password" caption="Password" onChange={(e) => this.formValueChange(e)} required={true} />                     
                            <TextInputWithLabel id="reenterPassword" caption="Re-enter Password" onChange={(e) => this.formValueChange(e)} required={true} />     
                        </React.Fragment> 

                        : 
                        <PasswordWrapper>                          
                            <TextInputWithLabel id="password" caption="Password" onChange={(e) => this.formValueChange(e)} required={true} />                     
                            <TextInputWithLabel id="reenterPassword" caption="Re-enter Password" onChange={(e) => this.formValueChange(e)} required={true} />     
                            <PasswordResetButton onClick={this.updatePassword} displayText="Update Password" />
                        </PasswordWrapper> 
                    }                                                      
                </FormGroupStyled>
                <div className="text-center">
                    <ButtonStyled onClick={this.save} displayText="Save" />
                    <ButtonStyled onClick={this.cancel} backgroundColor="e2231a" displayText="Cancel" />
                </div>
            </Form>
        </ContainerStyled>           
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      session: state.session,      
      quoteOrderCommon: state.quoteOrderCommon,
      alert: state.alert,
      admin: state.admin
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),          
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
    };
}   

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));