import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const H1 = styled.h1`
    cursor: pointer;
`;

const AccordionHeader = props => {
    let arrowDirection = props.collapsed === true ? "arrow right" : "arrow down";

    return (
        <H1 onClick={props.onClick}>{props.caption}<i className={arrowDirection} /></H1>
    );
};

AccordionHeader.propTypes = {
    onClick: PropTypes.func.isRequired,
    caption: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired
};

export default AccordionHeader;