import * as types from './actionTypes';
import axios from 'axios';
import ErrorHandler from '../modules/errorHandler';
import * as alerts from './alertActions';
import * as quoteActions from './quoteActions';
import * as orderActions from './orderActions';
import * as constants from '../modules/constants';

export const getRoles = (roleName)  => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getRoles', {roleName: roleName})
        .then(res => {            
            if (res.status !== 200) {
               console.log(res);                                                                
            } else {                                        
               dispatch(getRolesSuccess(res.data.result));              
            }              
        }).catch(function(error) {
            console.log(error);           
        });
    }
}

export const createCommunication = (quoteId, username, comment, communicationTypeId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/createCommunication', {quoteId: quoteId, username: username, comment: comment, communicationTypeId: communicationTypeId, orderId: orderId})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {               
                dispatch(getCommunications(quoteId, orderId));                                                                
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getQuoteOrderCommonOverview = (company, userCompanyLocationIdCSV, roleName, filter = null) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getQuoteOrderCommonOverview', { company: company, userCompanyLocationIdCSV: userCompanyLocationIdCSV, roleName: roleName, filter: filter })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                  
                   dispatch(getQuoteOrderCommonOverviewSuccess(res.data.result));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getCommunications = (quoteId, orderId, offset = null, rowsToFetch = null) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getCommunications', {quoteId: quoteId, orderId: orderId, offset: offset, rowsToFetch: rowsToFetch })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {        
                
                if (orderId)
                    dispatch(getOrderCommunicationsSuccess(res.data.result));
                else 
                    dispatch(getQuoteCommunicationsSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const generateReport = (report, costPrice, type, orderNo) => {
    return dispatch => {
      
        var urlScheme = 'http://';
        var domain = window.location.hostname;

        
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json', 
                "Access-Control-Allow-Origin": "*",                
            }
          };

        let url = window.location.href;

        if (url.includes('https://')) urlScheme = 'https://';

        // if (url.includes('-pilot')) 
        //     domain = 'hkme.hartson-kennedy.com';
        // else
        //     domain = '10.128.100.45';

        return axios.post(urlScheme + domain + '/ReportGenerator/api/Reports', 
        {
            "ReportPath": "/InterestCapture/" + report,
            "Params": [ 
                {
                "Name": "CostPrice",
                "Value": costPrice
                },
                {
                "Name": "Type",
                "Value": type
                }, 
                {
                "Name": "OrderNo",
                "Value": orderNo
                }
            ]
        }, axiosConfig)
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {        
                let url = urlScheme + domain + '/ReportGenerator/Home/index?reportId=' + res.data;
                
                window.open(url, "_blank");                             
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const generateHomeDeliveryReport = (report, orderId) => {
    return dispatch => {
        var urlScheme = 'http://';
        var domain = window.location.hostname;

        let url = window.location.href;

        if (url.includes('https://')) urlScheme = 'https://';

        if (url.includes('-pilot')) domain = 'hkme-pilot.hartson-kennedy.com';        
        if (url.includes('localhost')) domain = 'hkme-pilot.hartson-kennedy.com'; 

        
        return axios.post(urlScheme + domain + '/ReportGenerator/api/Reports', 
        {
            "ReportPath": "/InterestCapture/" + report,
            "Params": [                
                {
                "Name": "orderId",
                "Value": orderId
                }
            ]
        })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {        
                let url = urlScheme + domain + '/ReportGenerator/Home/index?reportId=' + res.data;
                
                window.open(url, "_blank");                             
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getAttachments = (quoteId, orderId, attachmentTypeId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getAttachments', {quoteId: quoteId, orderId: orderId, attachmentTypeId: attachmentTypeId })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {        
                
                if (orderId)
                    dispatch(getOrderAttachmentsSuccess(res.data.result));
                else 
                    dispatch(getQuoteAttachmentsSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}


export const downloadFile = (path, originalName, mimetype) => {     
    return dispatch => {                
        return axios.post('/api/v1/quoteOrderCommon/downloadFile', { path: path, mimetype: mimetype })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);  
                dispatch(alerts.showFailureAlert("File failed to download."));              
            } 
            else {
                console.log(res);                    
                

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    let linkSource = dataURItoBlob(res.data);
                  
                    navigator.msSaveOrOpenBlob(linkSource, originalName);
                   
                } else {
                    let linkSource = res.data;
                    const downloadLink = document.createElement("a");
                    const fileName = originalName;                

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();  
                    downloadLink.remove();
                }                   
                
                
            }         
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

function dataURItoBlob(dataURI, callback) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab]);
    return bb;
}

export const getStatusTypes = () => {
    return dispatch => {
        return axios.post('/api/v1/quotes/getStatusTypes')
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                   
                    dispatch(getStatusTypesSuccess(res.data.result));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getUserCompanyLocations = (userCompanyLocationIdCSV, username, company) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getUserCompanyLocations', { userCompanyLocationIdCSV: userCompanyLocationIdCSV, username: username, company: company  })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                
            }  else {                                                                  
                dispatch(getUserCompanyLocationsSuccess(res.data.result));                
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const getUsersAssignedToLocation = (companyLocationId, roleId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getUsersAssignedToLocation', { companyLocationId: companyLocationId, roleId: roleId  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                  
                    dispatch(getUsersAssignedToLocationSuccess(res.data.result));                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const createCompanyLocationDesigner = (email, companyLocationId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/createCompanyLocationDesigner', { email: email, companyLocationId: companyLocationId  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {      
                    console.log(res.data.result);                                                            
                    dispatch(getCompanyLocationDesignersSuccess(res.data.result));                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getCompanyLocationDesigners = (companyLocationId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getCompanyLocationDesigners', { companyLocationId: companyLocationId  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {      
                    console.log(res.data.result);                                                            
                    dispatch(getCompanyLocationDesignersSuccess(res.data.result));                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getShipToTypes = (manufacturerId, quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getShipToTypes', { manufacturerId: manufacturerId  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {        
                    if (orderId) {
                        dispatch(getHeader(null, orderId));
                    } else {
                        dispatch(getHeader(quoteId, null));
                    }                                                          
                    dispatch(getShipToTypesSuccess(res.data.result));                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getCompanyLocationInstallers = (companyLocationId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getCompanyLocationInstallers', { companyLocationId: companyLocationId  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                  
                    dispatch(getCompanyLocationInstallersSuccess(res.data.result));                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getCompanyLocationFabricator = (companyLocationId, isOrder = false) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getCompanyLocationFabricator', { companyLocationId: companyLocationId  })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {          
                    if (isOrder === true)                                                        
                        dispatch(orderActions.getOrderCompanyLocationFabricatorSuccess(res.data.result));                
                    else
                        dispatch(quoteActions.getQuoteCompanyLocationFabricatorSuccess(res.data.result));                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const updateAddressField = (id, addressType, sqlColumn, value, table, type, quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/updateAddressField', { id: id, addressType: addressType, sqlColumn: sqlColumn, value: value, table: table, type: type })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    dispatch(getAddresses(quoteId, orderId));                 
                }                               
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const updateLineQuantity = (LineId, quantity, quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/updateLineQuantity', { LineId: LineId, quantity: quantity })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else { 
                    
                    if (orderId)
                        dispatch(orderActions.getOrderById(orderId));
                    else
                        dispatch(quoteActions.getQuoteById(quoteId));                                    
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const updateContactField = (id, sqlColumn, value, table, type) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/updateContact', { id: id, sqlColumn: sqlColumn, value: value, table: table, type: type })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                
            } else {
                if (type === constants.TABLES.Order) {
                    dispatch(getContact(null, id));
                } else {
                    dispatch(getContact(id, null));
                }                    
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const updateHeaderField = (id, sqlColumn, value, table) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/updateHeader', { id: id, sqlColumn: sqlColumn, value: value, table: table })
            .then(res => {
                if (res.status !== 200) {
                    ErrorHandler.logError(res);
                } else {
                    if (table === constants.TABLES.Order) {
                        dispatch(getHeader(null, id));
                    } else {
                        dispatch(getHeader(id, null));
                    }
                }
            }).catch(function(error) {
                ErrorHandler.logError(error);
            });
    }
}

export const updateField = (id, sqlColumn, value, table) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/updateField', { id: id, sqlColumn: sqlColumn, value: value, table: table })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                
            }                               
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const copy = (quoteId, username, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/copy', {quoteId: quoteId, username: username, orderId: orderId})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {                       
                if (orderId) 
                    dispatch(createQuoteSuccess(res.data.result[0].orderId));
                else                                                           
                    dispatch(createQuoteSuccess(res.data.result[0].quoteId));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const emailQuoteCancelled = (quoteId, sendToEmail, newStatus, fabricatorPhone) => {   
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/emailQuoteCancel', {quoteId: quoteId, sendToEmail: sendToEmail, newStatus: newStatus, fabricatorPhone: fabricatorPhone})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);          
                dispatch(alerts.showFailureAlert("Quote cancel email failed to send. Please validate the entered email address and try again."));      
            }                               
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const emailUserCommunication = (emailData) => {   
    console.log(emailData.orderId); 
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/emailUserCommunication', { emailData })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                          
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const emailPendingInformation = (emailData) => {   
    console.log(emailData.orderId); 
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/emailPendingInformation', { emailData })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                          
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const emailStatusChange = (emailData) => {    
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/emailStatusChange', { emailData })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);          
                dispatch(alerts.showFailureAlert("The quote failed to send. Please validate the entered email address and try again."));      
            }  else {                                                                  
                // display alert of email success?
                //dispatch(alerts.showSuccessAlert(`${quoteId ? `Quote` : `Order`} emailed successfully!`)); 
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const emailQuoteOrder = (emailData) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/emailQuoteOrder', { emailData })
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);          
                dispatch(alerts.showFailureAlert("The quote failed to send. Please validate the entered email address and try again."));      
            }  else {                                                                                  
                if (emailData.orderId) {
                    dispatch(alerts.showSuccessAlert(`Order # ${emailData.orderId} emailed successfully!`)); 
                } else {
                    dispatch(alerts.showSuccessAlert(`Quote # ${emailData.quoteId} emailed successfully!`)); 
                }
                
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const copyLine = (LineId, newConfigId, quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/copyLine', {LineId: LineId, newConfigId: newConfigId})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {    
                if (orderId)
                    dispatch(orderActions.getOrderById(orderId));
                else
                    dispatch(quoteActions.getQuoteById(quoteId));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

export const CopyLineConfiguration = (instanceName, applicationName, serviceUrl, headerId, detailId, newHeaderId, newDetailId) => {
    return dispatch => {
        return axios.post('http://localhost/RenTechLaunchConfig/api/Configurator/CopyConfiguration', {instanceName: instanceName, applicationName: applicationName, serviceUrl: serviceUrl, headerId: headerId, detailId: detailId, newHeaderId: newHeaderId, newDetailId: newDetailId})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }                             
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }    
}

export const deleteLine = (LineId, quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/deleteLine', { LineId: LineId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                
                    if (orderId)
                        dispatch(orderActions.getOrderById(orderId));
                    else
                        dispatch(quoteActions.getQuoteById(quoteId));                                        
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const deleteDraft = (quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/deleteDraft', { quoteId: quoteId, orderId: orderId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const deleteAttachment = (fileId, quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/deleteAttachment', { fileId: fileId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                } else {
                    if (orderId)
                        dispatch(orderActions.deleteOrderAttachmentSuccess(fileId));
                    else
                        dispatch(quoteActions.deleteQuoteAttachmentSuccess(fileId));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getContact = (quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getContact', { quoteId, orderId })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                
                    if (orderId)
                        dispatch(orderActions.getContactSuccess(res.data.result));
                    else
                        dispatch(quoteActions.getContactSuccess(res.data.result));                                        
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const createLine = (quoteId, username, price, imgUrl, configId, headerId, productId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quotes/createLine', { quoteId: quoteId, username: username, price: price, imgUrl: imgUrl, configId: configId, headerId: headerId, productId: productId, orderId: orderId })
            .then(res => {                
                if (res.status !== 200) { 
                    ErrorHandler.logError(res);                
                }  else {                                    
                    dispatch(getLines(quoteId, orderId));
                   
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getHeader = (quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getHeader', { quoteId, orderId })
            .then(res => {
                if (res.status !== 200) {
                    ErrorHandler.logError(res);
                } else {                    
                    if (orderId)
                        dispatch(orderActions.getHeaderSuccess(res.data.result));
                    else                        
                        dispatch(quoteActions.getHeaderSuccess(res.data.result));
                }
            }).catch(function(error) {
                ErrorHandler.logError(error);
            })
    }
}

export const getLines = (quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getLines', { quoteId, orderId })
            .then(res => {
                if (res.status !== 200) {
                    ErrorHandler.logError(res);
                } else {                    
                    if (orderId)
                        dispatch(orderActions.getLinesSuccess(res.data.result));
                    else                        
                        dispatch(quoteActions.getLinesSuccess(res.data.result));
                }
            }).catch(function(error) {
                ErrorHandler.logError(error);
            })
    }
}

export const getAddresses = (quoteId, orderId) => {
    return dispatch => {
        return axios.post('/api/v1/quoteOrderCommon/getAddresses', { quoteId, orderId })
            .then(res => {
                if (res.status !== 200) {
                    ErrorHandler.logError(res);
                } else {                    
                    if (orderId)
                        dispatch(orderActions.getAddressesSuccess(res.data.result));
                    else
                        dispatch(quoteActions.getAddressesSuccess(res.data.result));
                }
            }).catch(function(error) {
                ErrorHandler.logError(error);
            });
    }
}

export function updateContactFieldSuccess(contactData) {
    return { type: types.UPDATE_CONTACT_FIELD_SUCCESS, contactData };
}

export function getRolesSuccess(roles) {
    return { type: types.GET_ALL_ROLES_SUCCESS, roles };
}

export function getQuoteCommunicationsSuccess(Communications) {
    return { type: types.GET_QUOTE_COMMUNICATIONS_SUCCESS, Communications };
}

export function getOrderCommunicationsSuccess(Communications) {
    return { type: types.GET_ORDER_COMMUNICATIONS_SUCCESS, Communications };
}

export function getQuoteAttachmentsSuccess(attachments) {
    return { type: types.GET_QUOTE_ATTACHMENTS_SUCCESS, attachments };
}

export function getOrderAttachmentsSuccess(attachments) {
    return { type: types.GET_ORDER_ATTACHMENTS_SUCCESS, attachments };
}

export function getStatusTypesSuccess(statusTypes) {
    return { type: types.GET_STATUS_TYPES_SUCCESS, statusTypes };
}

export function getUserCompanyLocationsSuccess(userCompanyLocations) {
    return { type: types.GET_USER_COMPANY_LOCATIONS_SUCCESS, userCompanyLocations};
}

export function getUsersAssignedToLocationSuccess(companyLocationUsers) {
    return { type: types.GET_USERS_ASSIGNED_TO_LOCATION_SUCCESS, companyLocationUsers};
}

export function getCompanyLocationInstallersSuccess(companyLocationInstallers) {
    return { type: types.GET_COMPANY_LOCATION_INSTALLERS_SUCCESS, companyLocationInstallers };
}

export function clearUsersAssignedToLocation() {
    return { type: types.CLEAR_USERS_ASSIGNED_TO_LOCATIONS };
}

export function clearDesigners() {
    return { type: types.CLEAR_DESIGNERS };
}

export function createQuoteSuccess(currentQuoteId) {
    return { type: types.CREATE_QUOTE_SUCCESS, currentQuoteId };
}

export function getQuoteOrderCommonOverviewSuccess(overview) {
    return { type: types.GET_QUOTE_ORDER_COMMON_OVERVIEW, overview };
}

export function getShipToTypesSuccess(shipToTypes) {
    return { type: types.GET_SHIP_TO_TYPES_SUCCESS, shipToTypes };
}

export function getCompanyLocationDesignersSuccess(designers) {
    return { type: types.GET_COMPANY_LOCATION_DESIGNERS_SUCCESS, designers };
}