import * as types from '../actions/actionTypes';

export default function alertReducer(state = [], action) {
    switch(action.type) {                
        case types.SHOW_SUCCESS_ALERT:
            return {...state, showAlert: true, alertText: action.alertText, alertColor: "success" };

        case types.HIDE_ALERT:
            return {...state, showAlert: false};

        case types.SHOW_FAILURE_ALERT:
            return {...state, showAlert: true, alertText: action.alertText, alertColor: "danger" };

        default:
            return state;
    }
}