import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import * as constants from '../../modules/constants';

export const CircleBg = styled.div`    
    display: table-cell;
    width: 500px;
    height: 25px;
    margin-right: 75px;
    color: ${props => props.theme.inactiveStageForeColor};    
    text-align: center;
    vertical-align: middle;
    font-size: 24px !important;    

    @media(max-width:1200px) {
        display: block;
        width: 100%;
        height: 25px;
        font-size 16px !important;
    }
    
    &.active {
        background-color: ${props => props.theme.activeStageColor};            
        color: ${props => props.theme.activeStageForeColor};
    }
    
    &.completed {
        background-color: ${props => props.theme.completedStageColor};
        cursor: pointer;
    }

    &.incomplete {
        background-color: ${props => props.theme.incompleteStageColor};
    }    
`;

export const CircleBgSubmit = styled.div`    
    display: table-cell;
    width: 500px;
    height: 25px;
    margin-right: 75px;
    color: ${props => props.theme.inactiveStageForeColor};
    text-align: center;
    vertical-align: middle;
    font-size: 24px !important;    

    @media(max-width:1200px) {
        display: block;
        width: 100%;
        height: 25px;
        font-size 16px !important;
    }
    
    &.active {
        background-color: ${props => props.theme.activeStageColor};        
        color: ${props => props.theme.activeStageForeColor};
    }

    &.completed {
        background-color: ${props => props.theme.completedStageColor};
        cursor: pointer;
    }

    &.incomplete {
        background-color: ${props => props.theme.incompleteStageColor};
    }    
`;

// margin-top: 2em;
// margin-bottom: 1em;

export const Wrapper = styled.div`               
    @media(max-width:1200px) {
        margin-top: .75em;
        margin-bottom: 0;
    }
`;

export const FigcaptionStyled = styled.figcaption`    
    display: inline-block;
    position: relative;    
    font-size: 17px !important;
    font-weight: 700;
    color: ${props => props.theme.inactiveStageForeColor};
    margin-top: 8px;

    &#lineStageCaption {
        position: relative;
        left: 7px;
    } 
    
    &#customerStageCaption {
        position: relative;
        left: -4px;
    }

    &#attachmentStageCaption {
        position: relative;
        left: -15px;
    }

    &#addressStageCaption {
        position: relative;
        left: -2px;
    }   

    &#detailsStageCaption {
        position: relative;
        left: 2px;
    }

    @media(max-width:1200px) {
        display: table-cell;

        &#lineStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }

        &#customerStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }

        &#attachmentStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }

        &#addressStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }

        &#detailsStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }
    }
            
    &.active {
        color: ${props => props.theme.activeStageColor};        
    }

    &.completed {
        color: ${props => props.theme.completedStageColor};
        cursor: pointer;
    }

    &.incomplete {
        color: ${props => props.theme.incompleteStageColor};
    }    
`;

export const FigcaptionSubmitStyled = styled.figcaption`    
    display: inline-block;
    position: relative;    
    font-size: 17px !important;
    font-weight: 700;
    color: ${props => props.theme.altButtonColor};
    margin-top: 8px;

    @media(max-width:1200px) {
        display: table-cell;

        &#submitStageCaption { 
            position: relative;
            left: 20px;
            top: 15px;
        }
    }

    &.active {
        color: ${props => props.theme.activeStageColor};        
    }

    &.completed {
        color: ${props => props.theme.completedStageColor};
        cursor: pointer;
    }

    &.incomplete {
        color: ${props => props.theme.incompleteStageColor};
    }    
`;

export const RowStyled = styled(Row)`    
    @media(max-width:1200px) {
        width: 95%;
    }
`;

export const FigureStyled = styled.figure`
    margin: 0;
`;

const Stages = props => {
    const stage = props.stage;
    const completed = "completed";
    const active = "active";
    const incomplete = "incomplete";

    return (
       <Wrapper>              
            <RowStyled id="stageContainer" className="h-100 justify-content-center align-items-center">
                <Col xl="2" xs="2">
                        <FigureStyled>
                        <CircleBg 
                            onClick={() => stage === constants.QUOTE_STAGES.Details || stage > constants.QUOTE_STAGES.Details || props.isLocked === true
                                ? props.onStageChange(constants.QUOTE_STAGES.Details) 
                                : null} 
                            className={ props.isLocked === true 
                                ? completed 
                                : stage === constants.QUOTE_STAGES.Details 
                                    ? active 
                                    : stage > constants.QUOTE_STAGES.Details 
                                        ? completed 
                                        : incomplete} >
                            Details
                        </CircleBg>
                        {/* <FigcaptionStyled id="detailsStageCaption" className={[ props.isLocked === true ? completed : stage === constants.QUOTE_STAGES.Details ? active : stage > constants.QUOTE_STAGES.Details ? completed : incomplete, "d-none d-sm-block"]}>Details</FigcaptionStyled> */}
                        </FigureStyled>
                    </Col>
                <Col xl="2" xs="2">
                    <FigureStyled>
                        <CircleBg 
                            onClick={() => stage === constants.QUOTE_STAGES.Line || stage > constants.QUOTE_STAGES.Line 
                                ? props.onStageChange(constants.QUOTE_STAGES.Line) 
                                : null} 
                            className={props.isLocked === true ? completed : stage === constants.QUOTE_STAGES.Line ? active : stage > 1 ? completed : incomplete} >
                            Lines
                        </CircleBg>
                        {/* <FigcaptionStyled id="lineStageCaption" className={[ props.isLocked === true ? completed : stage === constants.QUOTE_STAGES.Line ? active : stage > constants.QUOTE_STAGES.Line ? completed : incomplete, "d-none d-sm-block"]} >Lines</FigcaptionStyled> */}
                    </FigureStyled>
                </Col>
               
                {/* <Col xl="2" xs="2">
                    <FigureStyled>
                    <CircleBg 
                        onClick={() => stage === constants.QUOTE_STAGES.Customer || stage > constants.QUOTE_STAGES.Customer || props.isLocked === true
                            ? props.onStageChange(constants.QUOTE_STAGES.Customer) 
                            : null} 
                        className={ props.isLocked === true 
                            ? completed 
                            : stage === constants.QUOTE_STAGES.Customer 
                                ? active 
                                : stage > constants.QUOTE_STAGES.Customer 
                                    ? completed 
                                    : incomplete} >
                        Customer
                    </CircleBg>                  
                    </FigureStyled>
                </Col> */}
                <Col xl="2" xs="2">
                    <FigureStyled>
                        <CircleBg 
                            onClick={() => stage === constants.QUOTE_STAGES.Attachments || stage > constants.QUOTE_STAGES.Attachments || props.isLocked === true
                                ? props.onStageChange(constants.QUOTE_STAGES.Attachments) 
                                : null} 
                            className={ 
                                props.isLocked === true 
                                ? completed 
                                : stage === constants.QUOTE_STAGES.Attachments 
                                    ? active 
                                    : stage > constants.QUOTE_STAGES.Attachments 
                                        ? completed 
                                        : incomplete} >
                            Attachments
                        </CircleBg>
                        {/* <FigcaptionStyled id="attachmentStageCaption" className={[ props.isLocked === true ? completed : stage === constants.QUOTE_STAGES.Attachments ? active : stage > constants.QUOTE_STAGES.Attachments ? completed : incomplete, "d-none d-sm-block"]}>Attachments</FigcaptionStyled> */}
                    </FigureStyled>
                </Col>                
                {props.showAddressStage === true &&                     
                <Col xl="2" xs="2">                  
                    <FigureStyled>
                        <CircleBg 
                            onClick={() => stage === constants.QUOTE_STAGES.Address || stage > constants.QUOTE_STAGES.Address || props.isLocked === true
                                ? props.onStageChange(constants.QUOTE_STAGES.Address) 
                                : null} 
                            className={ 
                                props.isLocked === true 
                                ? completed
                                : stage === constants.QUOTE_STAGES.Address 
                                    ? active 
                                    : stage > constants.QUOTE_STAGES.Address 
                                        ? completed 
                                        : incomplete} >
                            Address
                        </CircleBg>
                        {/* <FigcaptionStyled id="addressStageCaption" className={[ props.isLocked === true ? completed : stage === constants.QUOTE_STAGES.Address ? active : stage > constants.QUOTE_STAGES.Address ? completed : incomplete, "d-none d-sm-block"]}>Address</FigcaptionStyled> */}
                    </FigureStyled>                    
                </Col>}                
                <Col xl="2" xs="2">
                    <FigureStyled>
                        <CircleBgSubmit 
                            className={ props.isLocked === true ? completed : props.allowSubmit === true ? active : incomplete} >
                          {props.isLocked === true 
                                    ? "Locked" 
                                    : "Summary"}
                        </CircleBgSubmit>
                        {/* <FigcaptionSubmitStyled 
                            id="submitStageCaption" 
                            className={
                                [ props.isLocked === true 
                                    ? "completed" 
                                    : props.allowSubmit === true 
                                        ? active 
                                        : incomplete , "d-none d-sm-block"]
                            }>
                                {props.isLocked === true 
                                    ? "Locked" 
                                    : props.checkmarkLabel}
                        </FigcaptionSubmitStyled> */}
                    </FigureStyled>
                </Col>                
            </RowStyled>
        </Wrapper>
    );
};

Stages.propTypes = {
    onStageChange: PropTypes.func.isRequired,
    showAddressStage: PropTypes.bool.isRequired,
    allowSubmit: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool
};

export default Stages;