import * as types from './actionTypes';
import axios from 'axios';
import ErrorHandler from '../modules/errorHandler';
//import * as alerts from './alertActions';

export const createOrder = (username, company) => {
    return dispatch => {
        return axios.post('/api/v1/orders/createOrder', { username: username})
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                 
                    dispatch(createOrderSuccess(res.data.result[0].id));
                    dispatch(setActiveOrder(res.data.result[0].id));
                    dispatch(getOrders(company));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const saveCompletedOrderAttachmentsToDirectory = (orderId, contactLastName) => {
    return dispatch => {
        return axios.post('/api/v1/orders/saveCompletedOrderAttachmentsToDirectory', { orderId: orderId, contactLastName: contactLastName})
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }                                 
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getOrders = (company, userCompanyLocationIdCSV, roleName, filter = null, offset = null, rowsToFetch = null, sortByColumn = null, sortDirection = null) => {    
    return dispatch => {
        return axios.post('/api/v1/orders/getOrders', { company: company, userCompanyLocationIdCSV: userCompanyLocationIdCSV, roleName: roleName, filter: filter, offset: offset, rowsToFetch: rowsToFetch, sortByColumn: sortByColumn, sortDirection: sortDirection })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                  
                    dispatch(getOrdersSuccess(res.data.result));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getInvoices = (orderId, attachmentType) => {    
    return dispatch => {
        return axios.post('/api/v1/orders/getInvoices', { orderId: orderId, attachmentType: attachmentType })
            .then(res => {                
                if (res.status !== 200) {
                    ErrorHandler.logError(res);                
                }  else {                                                                  
                    dispatch(getInvoicesSuccess(res.data.result));
                }                                
            }).catch(function(error) {
                ErrorHandler.logError(error);                               
            });
    }
}

export const getOrderById = (orderId, communicationOffset, communicationRowsToFetch) => {
    return dispatch => {
        return axios.post('/api/v1/orders/getOrderById', {orderId: orderId, communicationOffset: communicationOffset, communicationRowsToFetch: communicationRowsToFetch})
        .then(res => {                
            if (res.status !== 200) {
                ErrorHandler.logError(res);                              
            }  else {                                                                                                             
                dispatch(getOrderByIdSuccess(res.data.result));
            }                                
        }).catch(function(error) {
            ErrorHandler.logError(error);                               
        });
    }
}

// mike new thunk function to trigger file download, pass orderId

export function updatePoNumber(poNumber) {
    return { type: types.UPDATE_PO_NUMBER_REDUX_ONLY, poNumber };
}

export function getOrderByIdSuccess(order) {
    return { type: types.GET_ORDER_BY_ID_SUCCESS, order };
}

export function setActiveOrder(currentOrderId) {
    return { type: types.SET_ACTIVE_ORDER, currentOrderId };
}

export function getOrdersSuccess(orders) {
    return { type: types.GET_ORDERS_SUCCESS, orders };
}

export function createOrderSuccess(orderId) {
    return { type: types.CREATE_ORDER_SUCCESS, orderId };
}

export function getContactSuccess(contact) {
    return { type: types.GET_ORDER_CONTACT_SUCCESS, contact };
}

export function getAddressesSuccess(addresses) {
    return { type: types.GET_ORDER_ADDRESSES_SUCCESS, addresses };
}

export function getHeaderSuccess(header) {
    return { type: types.GET_ORDER_HEADER_SUCCESS, header };
}

export function deleteOrderAttachmentSuccess(fileId) {
    return { type: types.DELETE_ORDER_ATTACHMENT_SUCCESS, fileId };
}

export function getInvoicesSuccess(invoices) {
    return { type: types.GET_ORDER_INVOICES_SUCCESS, invoices };
}

export function getLinesSuccess(lines) {
    return { type: types.GET_ORDER_LINES_SUCCESS, lines };
}

export function updateCompanyLocation(companyLocationId) {
    return { type: types.UPDATE_ORDER_COMPANY_LOCATION_ID, companyLocationId };
}

export function getOrderCompanyLocationFabricatorSuccess(fabricatorName) {
    return { type: types.GET_ORDER_COMPANY_LOCATION_FABRICATOR_SUCCESS, fabricatorName };
}

export function clearOrder() {
    return { type: types.CLEAR_ORDER };
}