import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const RadioOption = styled.div`
    display: inline-block; 
    text-align:left;
    margin: 5px;

    @media (max-width: 1200px) { 
       text-align:center;       
       margin:0px;
    }
`;

export const RadioInput = styled.input`
    margin: 5px;
    height: 1.5em;
    width: 50px;
    position: relative;
    top: 3px;
`;

const RadioButtonPrimitive = React.memo(props => {              
    return (
        <div >                   
            {props.options.map(opt => 
                {   
                    let caption = opt.Caption.replace(/<\/?[^>]+(>|$)/g, "");
                    return(      
                        <RadioOption key={opt.Value} >                      
                            <RadioInput 
                                checked={props.defaultValue.toLowerCase() === opt.Value.toLowerCase()} 
                                type="radio" 
                                key={caption} 
                                id={props.id} 
                                name={props.id} 
                                value={opt.Value} 
                                onChange={props.selectOnChange}
                                disabled={props.disabled}
                                required={props.required}
                                autoFocus={props.autoFocus}
                                />                                                            
                            <label>{caption}</label>                                                 
                        </RadioOption>
                    );
                }
            )}                                       
        </div>
    );      
});

export default RadioButtonPrimitive;

RadioButtonPrimitive.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
    autoFocus: PropTypes.bool    
  };

  RadioButtonPrimitive.defaultProps = {
    disabled: false,
    required: false,
    defaultValue: ""        
}