import React from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as adminActions from '../../actions/adminActions';
import * as alertActions from '../../actions/alertActions';
import { loadProgressBar } from 'axios-progress-bar';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import * as constants from '../../modules/constants';
import 'axios-progress-bar/dist/nprogress.css';
import Icon from '../QuoteOrderCommon/Icon';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    margin-bottom: 10px;
`;

export const LabelStyled = styled(Label)`
    font-weight: bold;    
`;

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input { width: 100% !important; }
`;

export const HeaderRowStyled = styled(Row)`
    font-weight: bold;
    margin-top: 1em;        
    margin-bottom: 1em;
`;

export const BodyRowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    cursor: pointer;    
    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }
`;

export const ContainerStyled = styled(Container)`
    padding-left: 1% !important;
    padding-right: 1% !important;
`;

class CompanyLocationList extends React.Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();   
        
        this.onClickNewCompanyLocation = this.onClickNewCompanyLocation.bind(this);
        this.onClickEditCompanyLocation = this.onClickEditCompanyLocation.bind(this);  
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);

        this.state = {
            companyId: null
        }
    }  
    
    componentDidMount() {            
        this.props.adminActions.getAllCompanies(this.props.session.manufacturer).then(() => {
           this.setState({ loading: false });
        });                          
    }

    onCompanyChange(event) {
        if (event.target.value === constants.PLACEHOLDER) return; 

        const companyId = event.target.value;

        this.setState({ companyId: companyId }, () => {
            let companyName = this.props.admin.companyList.filter(c => c.id == companyId)[0].company;

            this.props.adminActions.getCompanyLocations(companyName);   
            this.props.adminActions.setActiveCompany(this.state.companyId);         
        });
    }
    
    onClickEditCompanyLocation(locationId) {             
        this.props.adminActions.setActiveCompanyLocation(locationId);
        this.props.history.push({ pathname: '/ModifyCompanyLocation' });               
    }

    onClickNewCompanyLocation() {    
        if (!this.state.companyId || this.state.companyId === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please select a company");
            return;
        }

        const activeCompany = this.props.admin.companyList.filter(c => c.id == this.state.companyId)[0].company;
        
        this.props.adminActions.createCompanyLocation(activeCompany, this.props.session.username).then(() => {
            this.props.history.push({ pathname: '/ModifyCompanyLocation' });
        });                   
    }   

    stopPropagation(event) {
        event.stopPropagation();
    }

    onDeleteClick(event, locationId) 
    {
        this.stopPropagation(event);

        confirmAlert({
            title: 'Confirm delete',
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.props.adminActions.deleteCompanyLocation(locationId, this.getActiveCompany());
                }
              },
              {
                label: 'No',
                onClick: () => ''
              }
            ]
          });                 
    }

    getActiveCompany() {
        if (this.props.admin.companyList && this.props.admin.companyList.length > 0)
            return this.props.admin.companyList.filter(c => c.id == this.state.companyId)[0].company;
        
        return null;        
    }

    render() {        
        let locations = this.props.admin.companyLocations;
                
        return (
            <ContainerStyled>        
                <h1>Locations</h1>

                <div className="text-right">
                    <Button displayText="New" onClick={this.onClickNewCompanyLocation} />
                </div>

                <LabelStyled for="companyList">Company</LabelStyled>
                <SelectPrimitiveStyled id="companyList" value={this.state.companyId} selectableValues={this.props.admin.companyList} placeHolder="Select a Company" onChange={(e) => this.onCompanyChange(e)} autoFocus={false} required={true} />                                

                <HeaderRowStyled>
                    <Col xl="5">Location</Col>                                  
                    <Col xl="5">Fabricator</Col>   
                </HeaderRowStyled>


                {   locations &&                                       
                    locations.map(location => {                                                                   
                        return(                            
                            <BodyRowStyled onClick={(e) => this.onClickEditCompanyLocation(location.id)}>
                                <Col xl="5" >
                                    {location.name}
                                </Col> 
                                <Col xl="5" >
                                    {location.fabricatorName}
                                </Col> 
                                   
                                <Col xl="1">
                                    <Icon iconName="trash" title="delete" isLine={false} click={e => this.onDeleteClick(e, location.id)} ></Icon>
                                </Col>                           
                            </BodyRowStyled>                                                                                         
                        );                        
                    })
                }                                    
            </ContainerStyled>               
        );                
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      quoteOrderCommon: state.quoteOrderCommon,
      session: state.session,
      admin: state.admin
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {        
        sessionActions: bindActionCreators(sessionActions, dispatch), 
        adminActions: bindActionCreators(adminActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch)               
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyLocationList));