import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Button from '../Common/Button/Button';
import TextInputPrimitive from '../Common/Primitives/TextInputPrimitive';
import AccordionHeader from '../Common/Accordion/AccordionHeader';

export const JournalWrapper = styled.div`
    margin-top: 2em;
    margin-bottom: 0em;
    display: inline-block;
    width: 100%;
    background-color:${props => props.theme.lightBackground};    
`;

export const ColStyled = styled(Col)`
    margin-right: 1em;
`;

export const ButtonWide = styled(Button)`
    &.btn {
        width: 200px !important;
    }
`;

class Communications extends React.Component {   
    constructor(props) {
        super(props);    
        
        this.journalTextChanged = this.journalTextChanged.bind(this);  
        
        this.state = {
            journalText: ''
        }      
    }

    journalTextChanged(event) {        
        this.setState({ journalText: event.target.value });
    }

    clearTextInput() {        
        document.getElementById('newComment').value = '';
    }

    render() {
        console.log(this.props.showPendingButton);
        return (           
            <JournalWrapper>
                <Row>
                    <Col>
                        {/* <AccordionHeader onClick={this.props.onCommunicationCollapseClicked} caption="Activity" collapsed={this.props.collapsed} /> */}
                        <h1>Activity</h1>                        
                    </Col>
                </Row>    

                { this.props.collapsed === false &&            
                <Row>               
                    <ColStyled xl="4" xs="8">
                        <TextInputPrimitive id="newComment" placeholder="Enter a comment and press Add button" onChange={(e) => this.journalTextChanged(e)} />
                    </ColStyled>
                    <Col xs="2" xl="1">
                        <Button 
                            displayText="Add" 
                            backgroundColor="#FFF"                            
                            onClick={(e) =>  {
                                this.clearTextInput();
                                this.props.onAddCommunicationClick(this.state.journalText) }} />
                    </Col>   
                    { this.props.showDraftButton === true && 
                    <Col xs="2" xl="1">
                        <Button 
                            displayText="Set to Draft" 
                            backgroundColor="#FFF"                            
                            onClick={(e) =>  {
                                this.clearTextInput();
                                this.props.onSetToDraftClick(this.state.journalText) }} />
                    </Col>
                    } 
                    { this.props.showPendingButton === true && 
                    <Col xs="2" xl="1">
                        <ButtonWide 
                            displayText="Set to Pending Information" 
                            backgroundColor="#FFF"                            
                            onClick={(e) =>  {
                                this.clearTextInput();
                                this.props.onSetToPendingInformationClick(this.state.journalText) }} />
                    </Col>
                    } 
                                 
                </Row>       
                }         
            </JournalWrapper>       
        );
    }
}

export default Communications;

Communications.propTypes = {    
    onAddCommunicationClick: PropTypes.func.isRequired,
    collapsed: PropTypes.bool.isRequired,
    onCommunicationCollapseClicked: PropTypes.func.isRequired,
    onSetToPendingInformationClick: PropTypes.func.isRequired,
    showPendingButton: PropTypes.bool,
    onSetToDraftClick: PropTypes.func,
    showDraftButton: PropTypes.bool

};