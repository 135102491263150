import React from 'react';
import Screens from '../Screens/Screens';

const Page = React.memo(props => {      
    return(
        <React.Fragment>                        
            <Screens screens={props.page.Screens} detailedSummary={props.detailedSummary} />                               
        </React.Fragment>          
    );          
});

export default Page;