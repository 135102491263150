import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import guid from '../../modules/guid';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as quoteActions from '../../actions/quoteActions';
import * as adminActions from '../../actions/adminActions';
//import * as workflowActions from '../../actions/workflowActions';
import * as alertActions from '../../actions/alertActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import { Container, Row, Col } from 'reactstrap';
import * as constants from '../../modules/constants';
import Lines from '../QuoteOrderCommon/Line';
import styled from 'styled-components';
import AddressForm from '../QuoteOrderCommon/AddressForm';
import ContactInfo from '../QuoteOrderCommon/ContactInfo';
import Attachments from '../QuoteOrderCommon/Attachments';
import AttachmentList from '../QuoteOrderCommon/AttachmentList';
import CommunicationList from '../QuoteOrderCommon/CommunicationList';
import HeaderDetails from '../QuoteOrderCommon/HeaderDetails';
import LineActions from '../QuoteOrderCommon/LineActions';
import Stages from '../QuoteOrderCommon/Stages';
import StagesConfirmed from '../QuoteOrderCommon/StagesConfirmed';
import Summary from '../QuoteOrderCommon/Summary';
import PoNumber from '../QuoteOrderCommon/PoNumber';
import * as QuoteOrderCommon from '../../modules/quoteOrderCommon';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loading from '../Common/Loading/Loading';

export const DatePickerLabel = styled.label`
    margin-right: 15px;
`;

export const HeaderRowStyled = styled(Row)`
    margin-top: .5em;
    margin-bottom: 2em;
    height: 70px;
`;

export const statusLabel = styled.div`
    font-size: 20px !important;
`;

export const ContainerStyled = styled(Container)`    
    @media(min-width:1200px) {
        min-height:440px;
    }
`;

class Quote extends Component {  
    constructor(props, context) {
        super(props, context);  

        loadProgressBar();

        this.onConfigureClick = this.onConfigureClick.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.onReconfigure = this.onReconfigure.bind(this);
        this.onLineDelete = this.onLineDelete.bind(this);
        this.onAddressFieldUpdate = this.onAddressFieldUpdate.bind(this);
        this.onContactFieldUpdate = this.onContactFieldUpdate.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onFieldUpdate = this.onFieldUpdate.bind(this);
        this.submitQuote = this.submitQuote.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.onQuoteCancel = this.onQuoteCancel.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onAddCommunicationClick = this.onAddCommunicationClick.bind(this);
        this.onCopyLine = this.onCopyLine.bind(this);
        this.onClickDownloadFile = this.onClickDownloadFile.bind(this);
        this.statusChange = this.statusChange.bind(this);                        
        this.onCreatedByChanged = this.onCreatedByChanged.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onCommunicationCollapseClicked = this.onCommunicationCollapseClicked.bind(this);
        this.onHeaderFieldUpdate = this.onHeaderFieldUpdate.bind(this);
        this.onShipToStoreChanged = this.onShipToStoreChanged.bind(this);
        this.onStageChange = this.onStageChange.bind(this);
        this.poNumberChanged = this.poNumberChanged.bind(this);
        this.onCommunicationPageChange = this.onCommunicationPageChange.bind(this);
        this.previousStage = this.previousStage.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onDeleteAttachment = this.onDeleteAttachment.bind(this);  
        this.shipToCustomer = this.shipToCustomer.bind(this);    
        this.saveAsDraft = this.saveAsDraft.bind(this);  
        this.onfaxTemplateOpen = this.onfaxTemplateOpen.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.onAddCountertopClick = this.onAddCountertopClick.bind(this);
        this.onSetToPendingInformationClick = this.onSetToPendingInformationClick.bind(this);
        this.onSetToDraftClick = this.onSetToDraftClick.bind(this);
        
        this.updateHeader = this.updateHeader.bind(this);

        this.state = {
            selectedProduct: '',
            disableAddLine: true,
            onFocusValue: '',            
            updatedRequired: false,
            loading: true,
            status: null,
            accepted: false,
            shipDate: '',
            createdByUserId: null,
            createdByEmail: null,
            companyLocationId: null,
            communicationCollapsed: false,            
            allowHomeDelivery: false,
            stage: 1,
            submitButtonLabel: constants.STAGES_BUTTON_LABELS.Next,
            submitButtonColor: constants.rentechTheme.callToActionColor,
            checkmarkLabel: '',
            requiredStages: 3,
            shipToStoreTotalRequiredStages: 3,
            shipToCustomerTotalRequiredStages: 4,
            confirmedTotalRequiredStages: 7,
            isLocked: false,
            communicationOffset: 0,
            communicationRowsToFetch: 5,
            currentCommunicationPage: 1,
            allowSOSInstall: false,
            allowedShipToTypes: null,
            showAddDesignerModal: false
        };        
    }

    componentDidMount() {                                                        
        if (this.props.quote.id) { 
            const promise1 = this.props.apiActions.getCompanyProducts(this.props.session.company);     
            const promise2 = this.getQuoteById(this.props.quote.id);
            const promise3 = this.props.quoteOrderCommonActions.getUserCompanyLocations(this.props.session.userCompanyLocationIdCSV, this.props.session.username, this.props.session.company);
            const promise4 = this.props.adminActions.getAllCompanies(this.props.session.manufacturer, constants.GET_ALL_COMPANIES_PARAMS.excludeFabricators, constants.GET_ALL_COMPANIES_PARAMS.excludeInstallers);
            const promise5 = this.props.adminActions.getCompanyLocations(this.props.session.company);
            const promise6 = this.props.quoteOrderCommonActions.getShipToTypes(this.props.session.manufacturer, this.props.quote.id);
            const promise7 = this.props.adminActions.getAllProducts();            

            Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7]).then(() => { 
                let shipToStoreTypeId = this.props.quote.shipToTypeId;
                let allowHomeDelivery = false;
                let allowSOSInstall = false;
                let allowedShipToTypes;

                
                if (this.props.admin.companyLocations) {
                    let locations = this.props.admin.companyLocations.filter(l => l.id == this.props.quote.companyLocationId);
                            
                    if (locations.length > 0) {
                        let shipToStoreTypes = this.props.quoteOrderCommon.shipToTypes;
                                             
                        if (shipToStoreTypes)
                            allowedShipToTypes = locations[0].allowHomeDelivery !== true ? shipToStoreTypes.filter(x => x.id !== constants.SHIP_TO_TYPES.Customer) : shipToStoreTypes;
                        else 
                            allowedShipToTypes = [];

                        allowHomeDelivery = locations[0].allowHomeDelivery;    
                        allowSOSInstall = locations[0].allowSOSInstall ? locations[0].allowSOSInstall : false;                
                    } else {
                        allowedShipToTypes = this.props.quoteOrderCommon.shipToTypes;
                    }
                }
                                
                this.setState({ 
                    status: this.props.quote.statusTypeId  ? this.props.quote.statusTypeId : constants.STATUS_TYPES.Draft, 
                    createdByUserId: this.props.quote.createdByUserId, 
                    createdByEmail: this.props.quote.createdByEmail, 
                    companyLocationId: this.props.quote.companyLocationId,
                    //shipToStoreTypeId: shipToStoreTypeId,
                    allowHomeDelivery: allowHomeDelivery,
                    stage:  this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed ? constants.QUOTE_STAGES.PoNumber : constants.QUOTE_STAGES.Details,
                    isLocked: QuoteOrderCommon.isLocked(this.props.session.roleName, this.props.quote.statusTypeId),
                    allowSOSInstall: allowSOSInstall,
                    allowedShipToTypes: allowedShipToTypes               
                }); 
                                    
                let shipToCustomer = shipToStoreTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;
                this.setRequiredStages(shipToCustomer);

                if (!this.props.quote.companyLocationId) {
                    if (this.props.quoteOrderCommon.userCompanyLocations.length === 1) {
                        this.props.quoteOrderCommonActions.updateField(this.props.quote.id, constants.ORDER_SQL_COLUMNS.CompanyLocationId, this.props.quoteOrderCommon.userCompanyLocations[0].id, constants.TABLES.Quote).then(() => {                        
                            this.props.quoteOrderCommonActions.getUsersAssignedToLocation(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                            this.props.quoteActions.updateCompanyLocation(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                                this.props.quoteOrderCommonActions.getCompanyLocationDesigners(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                                this.props.quoteOrderCommonActions.getCompanyLocationFabricator(this.props.quoteOrderCommon.userCompanyLocations[0].id);
                                let locations = this.props.admin.companyLocations.filter(l => l.id == this.props.quote.companyLocationId);
                            
                                if (locations.length > 0) {
                                    let shipToStoreTypes = this.props.quoteOrderCommon.shipToTypes;
                                                        
                                    if (shipToStoreTypes)
                                        allowedShipToTypes = locations[0].allowHomeDelivery !== true ? shipToStoreTypes.filter(x => x.id !== constants.SHIP_TO_TYPES.Customer) : shipToStoreTypes;
                                    else 
                                        allowedShipToTypes = [];

                                    this.setState({allowedShipToTypes: allowedShipToTypes});

                                }
                            });                                             
                    }
                } else {
                    this.props.quoteOrderCommonActions.getUsersAssignedToLocation(this.props.quote.companyLocationId);
                }    

                if (this.props.quote.companyLocationId) {                    
                    if (this.props.quote.installType === constants.INSTALL_TYPES.SOSInstall) {
                        this.getDefaultInstaller(this.props.quote.companyLocationId);  
                    }

                    this.props.adminActions.getCompanyLocationAddresses(this.props.quote.companyLocationId);
                    this.props.quoteOrderCommonActions.getCompanyLocationDesigners(this.props.quote.companyLocationId);
                }       

                if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Pending)
                    this.setState({ stage: constants.QUOTE_STAGES.Attachments });

                this.setSubmitButtonText();

                if (this.props.location.previousPage && this.props.location.previousPage === 'Configurator') {
                    this.onStageChange(constants.QUOTE_STAGES.Line);
                }     
                
                this.props.apiActions.clearConfigurator();

                if (!this.props.quote.contact || this.props.quote.contact.length === 0)
                    this.props.quoteOrderCommonActions.getContact(this.props.quote.id, null);

                if (!this.props.quote.attachments || this.props.quote.attachments.length === 0)
                    this.props.quoteOrderCommonActions.getAttachments(this.props.quote.id, null, null);
            });        
        }
        else {
            this.props.history.push({ pathname: '/Overview' });   
        }        
    }

    shipToCustomer(shipToStoreTypeId) {
        return shipToStoreTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;
    }

    onfaxTemplateOpen() {
        this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderFax, "C", "Q", this.props.quote.id);
    }
    
    onDeleteAttachment(fileId) {      
        confirmAlert({
            title: 'Confirm delete',
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.props.quoteOrderCommonActions.deleteAttachment(fileId, this.props.quote.id);  
                }
              },
              {
                label: 'No',
                onClick: () => ''
              }
            ]
          });        
    }

    previousStage(event) {
        if (this.state.stage === 1 || (this.state.status === constants.STATUS_TYPES.Confirmed && this.state.stage === 6)) return;

        this.onStageChange(this.state.stage - 1);
    }

    saveAsDraft() {               
        this.props.history.push({
            pathname: '/Overview'        
          });  
    }

    poNumberChanged(event) {
        this.updateHeader(event.target.id, event.target.value);   
        
        this.props.quoteActions.updatePoNumber(event.target.value);
    }

    onStageChange(stage) {                
        this.setState({ stage: stage }, this.setSubmitButtonText(stage)); 
                
        this.setRequiredStages(this.shipToCustomer(this.props.quote.shipToTypeId));

        if (stage === constants.QUOTE_STAGES.Address) {
            if (!this.props.quote.attachments || this.props.quote.attachments.length === 0) {
                this.props.alertActions.showFailureAlert("Warning: if no drawing is uploaded one will need to be faxed in. See attachment section for the document to fax");
            }
        }

    }

    setSubmitButtonText(stage) {
        let defaultLabel = constants.STAGES_BUTTON_LABELS.Next;
        let submitLabel = constants.STAGES_BUTTON_LABELS.Submit;
        
        submitLabel = this.getSubmitButtonLabel(stage);

        this.setState({ 
            submitButtonLabel: stage > this.state.requiredStages ?  submitLabel : defaultLabel,
            //submitButtonColor: stage > this.state.requiredStages ? '#e2231a' : '#00AA0B',
            checkmarkLabel: QuoteOrderCommon.getCheckmarkLabel(this.state.status)
        });
    }

    getSubmitButtonLabel(stage) {        
        return stage > this.state.requiredStages ? QuoteOrderCommon.getCheckmarkLabel(this.state.status) : constants.STAGES_BUTTON_LABELS.Submit;                  
    }

    setRequiredStages(shipToCustomer) {
        if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed) {
            this.setState({ requiredStages: this.state.confirmedTotalRequiredStages });
        } else {
            this.setState({ requiredStages: shipToCustomer === true ?  this.state.shipToCustomerTotalRequiredStages :  this.state.shipToStoreTotalRequiredStages });
        }        
    }

    onShipToStoreChanged(event) {
        if (event.target.value === constants.PLACEHOLDER) return;       

        this.setRequiredStages(this.shipToCustomer(this.props.quote.shipToTypeId));               
        this.onStageChange(constants.QUOTE_STAGES.Details);
        this.updateHeader(constants.ORDER_SQL_COLUMNS.ShipToStore, event.target.value);

        if (event.target.value === constants.SHIP_TO_TYPES.Store) 
            this.setShipAddressToLocationShipTo();

        if (event.target.value.toString() === constants.SHIP_TO_TYPES.Customer.toString()) {
            this.addHomeDeliveryCharge();
        } else {
            this.removeHomeDeliveryCharge();
        }
    }

    addHomeDeliveryCharge() {
        let location =  this.props.admin.companyLocations.filter(l => l.id === this.props.quote.companyLocationId); 
        let homeDeliveryProduct = this.props.admin.productList.filter(p => p.Name === constants.HOME_DELIVERY);
        let locationHomeDeliveryCharge = location[0].homeDeliveryCharge ? location[0].homeDeliveryCharge : homeDeliveryProduct[0].defaultPrice;
        
        let newConfigId = guid.GenerateGUID();

        if (homeDeliveryProduct.length > 0)
            this.props.quoteOrderCommonActions.createLine(this.props.quote.id, this.props.session.username, locationHomeDeliveryCharge, homeDeliveryProduct[0].ImageUrl, newConfigId, "ICE", homeDeliveryProduct[0].ID, null);
    }

    removeHomeDeliveryCharge() {
        if (!this.props.quote.lines || this.props.quote.lines.length === 0) return;

        let homeDeliveryProduct = this.props.quote.lines.filter(l => l.productName === constants.HOME_DELIVERY);

        if (homeDeliveryProduct.length > 0)
            this.props.quoteOrderCommonActions.deleteLine(homeDeliveryProduct[0].id, this.props.quote.id, null);
    }

    setShipAddressToLocationShipTo() {
        if (this.props.quote.shippingAddress && this.props.quote.companyLocationId && this.props.admin.activeCompanyLocationShippingAddress) {

            let locationShip = this.props.admin.activeCompanyLocationShippingAddress;
            this.updateAddress("address", locationShip.address, "shipping");
            this.updateAddress("address2", locationShip.address2, "shipping");
            this.updateAddress("city", locationShip.city, "shipping");
            this.updateAddress("state", locationShip.state, "shipping");
            this.updateAddress("zip", locationShip.zip, "shipping");               
        }
    }    

    onCommunicationCollapseClicked() {
        let collapsed = !this.state.communicationCollapsed;
        this.setState({ communicationCollapsed: collapsed });
    }

    onCreatedByChanged(event) {
        let value = event.target.value === constants.PLACEHOLDER ? null : event.target.value;
        this.setState({ createdByUserId: value });

        if (value)  {
            this.updateHeader(event.target.id, value);           
        }
    }  

    statusChange(event) {         
        if (event.target.value === constants.PLACEHOLDER) return;
       
        if (event.target.value) {
            let selectedStatus = this.props.quoteOrderCommon.statusTypes.filter(status => status.id.toString() === event.target.value); 
            //this.runStatusChangeWorkflows(selectedStatus[0].name);

            this.updateQuoteStatus(selectedStatus[0].id);
            this.onAddCommunicationClick(`Quote status changed to ${selectedStatus[0].name}.`, false);
            //this.createSystemCommunication(`Quote status changed to ${selectedStatus[0].name}.`);

            if (selectedStatus[0].id === constants.STATUS_TYPES.Confirmed) {
                this.setState({ stage: constants.QUOTE_STAGES.PoNumber }, this.setState({ requiredStages: this.state.confirmedTotalRequiredStages }));                                
            }
    
            if (selectedStatus[0].id !== constants.STATUS_TYPES.Draft)
                this.sendEmail(constants.EMAIL_TYPES.StatusChange, selectedStatus[0].name);
        }
    }

    // runStatusChangeWorkflows(status) {
    //     if (this.props.workflows.onQuoteStatusChange && this.props.workflows.onQuoteStatusChange.length > 0 && this.props.quoteOrderCommon.userCompanyLocations.length > 0) {

    //         this.props.workflows.onQuoteStatusChange.forEach(workflow => {
    //             switch(workflow.actionTypeId) {

    //                 case constants.WORKFLOW_ACTION_TYPES.sendEmail:                                              
    //                     this.props.workflowActions.emailStatusUpdate(status, this.props.quoteOrderCommon.userCompanyLocations[0].quoteSubmitEmailList, workflow.actionSubject, workflow.actionBody);
    //                     break;                    
    
    //                 default:
    //                     console.log('no matching workflow id');
    //             }
    //         });
    //     }
    // }

    async getQuoteById(quoteId) {
        await this.props.quoteActions.getQuoteById(quoteId);      
        this.setState({ loading: false });
    }

    getCommunications() {
        this.props.quoteOrderCommonActions.getCommunications(this.props.quote.id, null, this.state.communicationOffset, this.state.communicationRowsToFetch);        
    }

    onAddCommunicationClick(comment, sendEmail = true) {
        this.props.quoteOrderCommonActions.createCommunication(this.props.quote.id, this.props.session.username, comment, 1, null);
        
        if (sendEmail === true)
            this.sendEmail(constants.EMAIL_TYPES.UserCommunication, comment);
    }

    onSetToPendingInformationClick(comment) {
        if (!comment) {
            this.props.alertActions.showFailureAlert("Please enter a comment to set the quote to pending.");
            return;
        }
        this.updateQuoteStatus(constants.STATUS_TYPES.Pending);
        this.props.quoteOrderCommonActions.createCommunication(this.props.quote.id, this.props.session.username, comment, 1, null);
        this.sendEmail(constants.EMAIL_TYPES.PendingInformation, constants.STATUS_TYPES.Pending, comment);
    }

    onSetToDraftClick(comment) {
        if (!comment) {
            this.props.alertActions.showFailureAlert("Please enter a comment to set the quote to draft.");
            return;
        }
        this.updateQuoteStatus(constants.STATUS_TYPES.Draft);
        this.props.quoteOrderCommonActions.createCommunication(this.props.quote.id, this.props.session.username, comment, 1, null);
        this.sendEmail(constants.EMAIL_TYPES.StatusChange, constants.STATUS_TYPES.Draft, comment);
    }
    
    onQuoteCancel() {
        this.updateQuoteStatus(constants.STATUS_TYPES.Cancelled);

        this.onAddCommunicationClick(`The quote has been cancelled.`, false);
        //this.createSystemCommunication(`The quote has been cancelled.`);

        this.sendEmail(constants.EMAIL_TYPES.QuoteCancel);

        this.props.alertActions.showSuccessAlert(`Quote #${this.props.quote.id} has been cancelled.`);
        this.props.history.push({ pathname: '/QuoteList' });   
    }   

    onCopyLine(LineId, configurationId, headerId, productId) {
        let newConfigId = guid.GenerateGUID();
        let products = this.props.admin.productList;

        if (products) {
            // eslint-disable-next-line
            let lineProduct = products.filter(p => p.ID == productId)[0];
            this.props.quoteOrderCommonActions.CopyLineConfiguration(lineProduct.ApplicationInstance, lineProduct.ApplicationName, "http://hkplt01/PCM/ConfiguratorService/v2/ProductConfigurator.svc", headerId, configurationId, headerId, newConfigId);
        }

        this.props.quoteOrderCommonActions.copyLine(LineId, newConfigId, this.props.quote.id, null);
    }

    onAddCountertopClick() {
        let products = this.props.admin.productList;
        let productId;
        if (products) {
            // auto load counter top from lowes
            let selectedProduct = products.filter(p => p.ID == 1)[0];                       

            if (!selectedProduct) {
                this.props.alertActions.showFailureAlert("No product found");
                return;
            }

            this.props.apiActions.setSelectedProduct(selectedProduct);    
            
            productId = selectedProduct.ID;           
        }

        this.props.apiActions.setActiveProduct(this.props.session.username, productId, this.props.session.company).then(() => {
            this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Quote);        
            this.props.history.push({ pathname: '/Configurator' }); 
        });
    }

    onPrint() {        
        this.props.history.push({
            pathname: '/QuotePrint'             
        }); 
    }

    onCopy() {
        this.props.quoteOrderCommonActions.copy(this.props.quote.id, this.props.session.username, null).then(() => {
            this.props.history.push({ pathname: '/QuoteList' });
        });
    }

   

    getDesignerEmail() {
        let designer = this.props.quoteOrderCommon.designers.filter(d => d.userId === this.props.quote.createdByUserId);
        let email = '';
        
        if (designer && designer.length > 0)
            email = designer[0].email;
        
        return email;
    }

    sendEmail(emailType, newStatus, comment) {
        let locations = this.props.admin.companyLocations.filter(l => l.id === this.props.quote.companyLocationId);
        let sendToEmail = "";
                            
        if (locations.length > 0) {
            sendToEmail = this.props.quote.installType === constants.INSTALL_TYPES.SOS ? 
                locations[0].orderSubmitEmailList :
                locations[0].orderSubmitAltEmailList;          
        }

        if (!sendToEmail) {
            alertActions.showFailureAlert("Email failed to send. No email configured for this location");
            return;
        } else {
            let designerEmail = this.getDesignerEmail();
            
            // if (this.props.quoteOrderCommon.companyLocationUsers && this.props.quoteOrderCommon.companyLocationUsers.length > 0) {
            //     designerEmail = this.props.quoteOrderCommon.companyLocationUsers.filter(u => u.id === this.props.quote.createdByUserId)[0].username;
            // }
            
            sendToEmail = sendToEmail + ', ' + designerEmail;

            console.log(sendToEmail);
            if (emailType === constants.EMAIL_TYPES.QuoteOrder)
                this.props.quoteOrderCommonActions.emailQuoteOrder(this.getEmailDTO(locations, sendToEmail, newStatus));                                                       
            else if (emailType === constants.EMAIL_TYPES.StatusChange)
                this.props.quoteOrderCommonActions.emailStatusChange(this.getEmailDTO(locations, sendToEmail, newStatus));
            else if (emailType === constants.EMAIL_TYPES.QuoteCancel) 
                this.props.quoteOrderCommonActions.emailQuoteCancelled(this.props.quote.id, sendToEmail, newStatus, this.props.quote.fabricatorPhone);  
            else if (emailType === constants.EMAIL_TYPES.UserCommunication)
                this.props.quoteOrderCommonActions.emailUserCommunication(this.getEmailDTO(locations, sendToEmail, newStatus)); 
            else if (emailType === constants.EMAIL_TYPES.PendingInformation)
                this.props.quoteOrderCommonActions.emailPendingInformation(this.getEmailDTO(locations, sendToEmail, newStatus, comment));                                                       
        }
    }

    getEmailDTO(locations, sendToEmail, newStatus, comment){     
        let locationName = 'n/a';
        let fabricatorPhone = this.props.quote.fabricatorPhone;

        if (this.props.quote.companyLocationId)
            if (this.props.admin.companyLocations && this.props.admin.companyLocations.length > 0) {
                locationName = this.props.admin.companyLocations.filter(l => l.id === this.props.quote.companyLocationId)[0].name;                
            }

        let status = 'n/a'
        if (this.props.quote.statusTypeId && this.props.quoteOrderCommon.statusTypes) {
            status = this.props.quoteOrderCommon.statusTypes.filter(s => s.id === this.props.quote.statusTypeId)[0].name;
        }
        
        var emailData = {
            quoteId: this.props.quote.id,
            lines: this.props.quote.lines,
            billingAddress: this.props.quote.billingAddress[0],
            shippingAddress: this.props.quote.shippingAddress[0],
            shipToTypeId: this.props.quote.shipToTypeId,
            installType: this.props.quote.installType,
            fabricator: locations[0].fabricatorName,
            installer: this.props.quote.installType === constants.INSTALL_TYPES.SOS ? '' :  locations[0].installerName,
            contact: this.props.quote.contact,
            description: this.props.quote.name,
            sendToEmail: sendToEmail,
            orderId: this.props.quote.orderConvertedTo,
            locationName: locationName,
            firstName: this.props.quote.contact[0].firstName,
            lastName: this.props.quote.contact[0].lastName,
            status: status,
            newStatus: newStatus,
            oldStatus: status,
            designerEmail: this.getDesignerEmail(), 
            comment: comment,
            fabricatorPhone: fabricatorPhone,
            isOrder: false           
        }

        return emailData;
    }

    getStatusName(statusId) {
        if (this.props.quoteOrderCommon.statusTypes && this.props.quoteOrderCommon.statusTypes.length > 0) {
            return this.props.quoteOrderCommon.statusTypes.filter(s => s.id === statusId)[0].name;
        }
    }

    submitQuote() {        
        if (this.state.stage > this.state.requiredStages) {  
            if (this.state.isLocked === true) {
                this.props.alertActions.showFailureAlert('Quote is locked, no action can be taken.');
                return;
            }

            let communicationText;
            let newStatus;

            if (this.state.stage === constants.QUOTE_STAGES.ConvertToOrder) {
                communicationText = `The quote was converted to an order by ${this.props.session.username}`;
                
                this.props.quoteActions.convertQuoteToOrder(this.props.quote.id, this.props.session.username).then(() => {
                    newStatus = this.getStatusName(constants.STATUS_TYPES.SubmittedForApproval);
                    this.updateQuoteStatus(constants.STATUS_TYPES.ConvertedToOrder);     
    
                    this.sendEmail(constants.EMAIL_TYPES.QuoteOrder, newStatus);
                    this.onAddCommunicationClick(communicationText, false);
                    ///this.createSystemCommunication(communicationText); 
                    
                    this.props.history.push({ pathname: '/OrderDashboard' });                    
                }); 
                return;
                
            }
            else if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Pending) {
                if (!this.props.quote.attachments || this.props.quote.attachments.length === 0) {                     
                     this.props.alertActions.showFailureAlert('Please attach a drawing to update the quote');
                     return;
                } else {
                    communicationText = `Quote has been submitted for approval`;
                    this.updateQuoteStatus(constants.STATUS_TYPES.Sent);                    

                    newStatus = this.getStatusName(constants.STATUS_TYPES.Sent);

                   this.sendEmail(constants.EMAIL_TYPES.QuoteOrder, newStatus);
                   this.onAddCommunicationClick(communicationText, false);
                   //this.createSystemCommunication(communicationText);
                   return;                   
                }
            }             
            else {
                let newStatus = this.props.quoteOrderCommon.statusTypes.filter(s => s.id === constants.STATUS_TYPES.Sent);
                if (!this.props.quote.attachments || this.props.quote.attachments.length === 0) {
                    communicationText = `Quote submitted without attachments. Status set to pending.`;                                     
                    this.updateQuoteStatus(constants.STATUS_TYPES.Pending);
                    newStatus = this.getStatusName(constants.STATUS_TYPES.Pending);
                }
                else if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Sent) {
                    communicationText = `Quote confirmed by ${this.props.session.username}`;                                     
                    this.updateQuoteStatus(constants.STATUS_TYPES.Confirmed); 
                    newStatus = this.getStatusName(constants.STATUS_TYPES.Confirmed);
                } else {
                    communicationText = `The quote was submitted by ${this.props.session.username}`;
                    this.updateQuoteStatus(constants.STATUS_TYPES.Sent);             
                    this.updateHeader(constants.ORDER_SQL_COLUMNS.IsLocked, true); 
                    newStatus = this.getStatusName(constants.STATUS_TYPES.Sent);                                      
                }
                
                this.sendEmail(constants.EMAIL_TYPES.QuoteOrder, newStatus);
                this.onAddCommunicationClick(communicationText, false);
               // this.createSystemCommunication(communicationText);
            }                              

            this.props.history.push({ pathname: '/QuoteDashboard' });

        } else {
                       
            if (this.validateStage() === true)
                this.onStageChange(this.state.stage + 1);            
        }               
    }

    validateStage() {
        let stage = this.state.stage;

        if (stage === constants.QUOTE_STAGES.Line) 
            return true; //return this.validateLineStage();
        else if (stage === constants.QUOTE_STAGES.Details) {
            let validDetails = this.validateDetailsStage();
            let validCustomer = this.validateCustomerStage();

            if (validDetails === false) return validDetails;
            if (validCustomer === false) return validCustomer;

            return true;
        }                
            
        else if (stage === constants.QUOTE_STAGES.Address)
            return this.validateAddressStage();
        else if (stage === constants.QUOTE_STAGES.PoNumber)
            return this.validatePO();        
        
        return true;        
    }

    validatePO(){
        if (!this.props.quote.poNumber) {
            this.props.alertActions.showFailureAlert("Please enter a PO number");
            return false;
        }
        return true;
    }

    validateAddressStage() {
        if (this.shipToCustomer(this.props.quote.shipToTypeId) !== true) return true;

        let shippingAddress = this.props.quote.shippingAddress[0];

        if (!shippingAddress) {
            this.props.alertActions.showFailureAlert("No shipping address found");
            return false;
        }

        if (!shippingAddress.Address) {
            this.props.alertActions.showFailureAlert("Please enter a value in the address field");
            return false;
        } 
        
        if (!shippingAddress.City) {
            this.props.alertActions.showFailureAlert("Please enter a value in the City field");
            return false;
        } 

        if (!shippingAddress.State) {
            this.props.alertActions.showFailureAlert("Please enter a value in the State field");
            return false;
        }
        
        // if (!shippingAddress.Country) {
        //     this.props.alertActions.showFailureAlert("Please enter a value in the Country field");
        //     return false;
        // }               

        return true;
    }

    validateDetailsStage() {
        if (!this.props.quote.name) {
            this.props.alertActions.showFailureAlert("Please enter a job name");
            return false;
        }

        // if (!this.props.quote.createdByEmail) {
        //     this.props.alertActions.showFailureAlert("Please enter a designer");
        //     return false;
        // }       

        if (!this.props.quote.companyLocationId || this.props.quote.companyLocationId === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please enter a location");
            return false;
        }

        if (!this.props.quote.createdByUserId || this.props.quote.createdByUserId === constants.PLACEHOLDER || this.props.quote.createdByUserId === -1) {
            this.props.alertActions.showFailureAlert("Please select a designer");
            return false;
        }

        

        if (!this.props.quote.installType || this.props.quote.installType === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please select an install type");
            return false;
        }

        if (this.props.quote.installType === constants.INSTALL_TYPES.SOSInstall) {
            if (!this.props.quote.installerId) {
                this.props.alertActions.showFailureAlert("An installer is required when install type SOS Install is selected");
                return false;
            }
        }

        if (!this.props.quote.shipToTypeId || this.props.quote.shipToTypeId === constants.PLACEHOLDER) {
            this.props.alertActions.showFailureAlert("Please select a ship to");
            return false;
        }

        return true;
    }

    validateLineStage() {
        if (this.props.quote.lines.length === 0) {
            this.props.alertActions.showFailureAlert("At least one line must be added to the quote to continue");
            return false;
        } else {
            return true;
        }
    }

    validateCustomerStage() {
        const contact = this.props.quote.contact[0];
        
        if (!contact.firstName || !contact.lastName ) {
            this.props.alertActions.showFailureAlert("Please enter a first and last name.");
            return false;
        }
        else if (this.shipToCustomer(this.props.quote.shipToTypeId) === true && !contact.phone) {
            this.props.alertActions.showFailureAlert("Phone number is required when ship to customer is selected.");
            return false;
        }
        else
            return true
    }

    createSystemCommunication(message) {
        this.props.quoteOrderCommonActions.createCommunication(this.props.quote.id, this.props.session.username, message, constants.QUOTE_COMMUNICATION_TYPES.system, null);
    }

    // runQuoteSubmitWorkflows() {
    //     let stopWorkflows = false;

    //     if (this.props.workflows.onQuoteSubmit && this.props.workflows.onQuoteSubmit.length > 0) {            
    //         this.props.workflows.onQuoteSubmit.forEach(workflow => {

    //             if (stopWorkflows === true) return;

    //             switch(workflow.actionTypeId) {

    //                 case constants.WORKFLOW_ACTION_TYPES.validateRequiredAttachments:
    //                     if (this.props.quote.attachments.length < workflow.actionMinAttachments) {
    //                         this.createSystemCommunication(`The required number of attachments is ${workflow.actionMinAttachments}. The quote was not submmitted and the status will be set to pending`);
    //                         this.props.alertActions.showFailureAlert(`The required number of attachments is ${workflow.actionMinAttachments}. Please fax drawing.`);
    //                         if (workflow.actionBreakWorkflowOnFail === true) {
    //                             stopWorkflows = true;
    //                         } 
    //                     } else {
    //                         if (workflow.actionBreakWorkflowOnTrue === true) {
    //                             stopWorkflows = true;
    //                         } 
    //                     }
                        
    //                     break;                    

    //                 case constants.WORKFLOW_ACTION_TYPES.updateStatus:
    //                     this.updateQuoteStatus(workflow.actionNewStatus);

    //                     break;
    
    //                 default:
    //                     console.log('no matching workflow id');
    //             }
    //         });
    //     }
    // }

    updateQuoteStatus(statusTypeId) {                
        this.setState({ status: statusTypeId });
        this.updateHeader(constants.ORDER_SQL_COLUMNS.Status, statusTypeId);        
        this.updateHeader(constants.ORDER_SQL_COLUMNS.IsLocked, (statusTypeId === constants.STATUS_TYPES.Sent || constants.STATUS_TYPES.ConvertedToOrder));             
    }
    
    onConfigureClick() {
        let products = this.props.admin.productList;

        if (products) {
            let selectedProduct = products.filter(p => p.ID == this.state.selectedProduct)[0];
            
            if (selectedProduct.isNonConfigurable === true) {
                this.props.quoteOrderCommonActions.createLine(this.props.quote.id, this.props.session.username, selectedProduct.defaultPrice, selectedProduct.ImageUrl, "", "", this.state.selectedProduct, null);
                return;
            }
        }

        this.props.apiActions.setActiveProduct(this.props.session.username, this.state.selectedProduct, this.props.session.company).then(() => {
            this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Quote);        
            this.props.history.push({ pathname: '/Configurator' }); 
        });
        
    }

    onProductChange(event) {
        this.setState({ selectedProduct: event.target.value, disableAddLine: event.target.value === '' ? true : false });
    }

    onQuantityChange(event, id) {  
        if (event.nativeEvent.explicitOriginalTarget &&
            event.nativeEvent.explicitOriginalTarget == event.nativeEvent.originalTarget) {
          return;
        }

        this.props.quoteOrderCommonActions.updateLineQuantity(id, event.target.value, this.props.quote.id, null);
    }

    onAddressFieldUpdate(event, addressType) {
        if (this.state.onFocusValue === event.target.value) return;

        this.updateAddress(event.target.id, event.target.value, addressType);
    }    

    onContactFieldUpdate(event) {
        if (this.state.onFocusValue === event.target.value) return;

        this.updateContact(event.target.id, event.target.value);
    }

    onHeaderFieldUpdate(event) {
        if (this.state.onFocusValue === event.target.value || event.target.value === constants.PLACEHOLDER) return;
        
        this.updateHeader(event.target.id, event.target.value);

        if (event.target.id === constants.ORDER_SQL_COLUMNS.InstallType) {
             if (event.target.value === constants.INSTALL_TYPES.SOS) {
                 this.updateHeader(constants.ORDER_SQL_COLUMNS.VendorNumber, constants.VENDOR_NUMBERS.SOS);  
                 this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, null);            
             } else {
                this.updateHeader(constants.ORDER_SQL_COLUMNS.VendorNumber, constants.VENDOR_NUMBERS.SOS_INSTALL);

                this.getDefaultInstaller(this.props.quote.companyLocationId);
             }
        } 

        // if (event.target.id === constants.ORDER_SQL_COLUMNS.InstallType && this.props.quote.installerId) {
        //     this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, null);                        
        // }
    }

    onFieldUpdate(event) {
        if (this.state.onFocusValue === event.target.value) return;

        this.updateField(event.target.id, event.target.value);
    }

    onCompanyChange(event) {
        if (event.target.value === constants.PLACEHOLDER) return;       
        event.persist();
        const companyId = event.target.value;
        this.props.quoteOrderCommonActions.updateHeaderField(this.props.quote.id, event.target.id, parseInt(event.target.value), constants.TABLES.Quote).then(() => {
            let companyName = this.props.admin.companyList.filter(c => c.id == companyId)[0].company;
            this.getCompanyLocations(companyName);
        });              
    }

    getCompanyLocations(companyName) {                  
        this.props.adminActions.getCompanyLocations(companyName).then(() => {
            if (this.props.session.roleName === constants.ROLE.User) {
                const userLocation = this.props.session.userCompanyLocationIdCSV.split(',');
                let defaultLocation = this.props.admin.companyLocations.filter(l => l.id = userLocation[0]);

                this.setState({ companyLocationId: defaultLocation });
            }
        });
    }

    getCurrentLocation(locationId) {
        let location = this.props.admin.companyLocations.filter(l => l.id == locationId);

        if (location && location.length > 0)
            return location[0];     
    }

    onLocationChange(event) {
        this.setState({ companyLocationId: event.target.value });
       
        let shipToStoreTypes = this.props.quoteOrderCommon.shipToTypes;  
        let allowedShipToTypes;
        let locations = this.props.admin.companyLocations.filter(l => l.id == event.target.value);
        if (shipToStoreTypes)
            allowedShipToTypes = locations[0].allowHomeDelivery !== true ? shipToStoreTypes.filter(x => x.id !== constants.SHIP_TO_TYPES.Customer) : shipToStoreTypes;
        else 
            allowedShipToTypes = [];

        this.setState({ allowedShipToTypes: allowedShipToTypes  });
         
        event.persist();
        
        if (event.target.value !== constants.PLACEHOLDER) {
            this.updateHeader(event.target.id, event.target.value);
            this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, null);
            this.updateHeader("createdByEmail", "");
            this.updateHeader("createdByUserId", -1);
            
            this.props.quoteOrderCommonActions.getUsersAssignedToLocation(event.target.value);
            this.props.adminActions.getCompanyLocationAddresses(event.target.value);
            this.props.quoteOrderCommonActions.getCompanyLocationFabricator(event.target.value);
            this.props.quoteOrderCommonActions.getCompanyLocationDesigners(event.target.value);
            
            //this.getDefaultInstaller(event.target.value);  
            this.props.quoteOrderCommonActions.getCompanyLocationInstallers(event.target.value).then(() => {  
                if (this.props.session.roleName === constants.ROLE.User && this.props.quoteOrderCommon.companyLocationInstallers) {
                    this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, this.props.quoteOrderCommon.companyLocationInstallers[0].id);
                }                    
            });            
        }
    }

    onInputFocus(event) {
        this.setState({onFocusValue: event.target.value});
    }

    async onReconfigure(configurationId, headerId, id, productId) {  

        let products = this.props.admin.productList;

        if (products) {
            let selectedProduct = products.filter(p => p.ID == productId)[0];

            if (selectedProduct.isNonConfigurable === true) {
                this.props.alertActions.showFailureAlert("Cannot reconfigure non configurable part");
                return;
            }
        }

        await this.props.sessionActions.setConfiguratorMode(constants.CONFIGURATOR_MODES.Quote);

        this.props.history.push({
          pathname: '/Configurator',
          configurationId: configurationId,
          headerId: headerId,
          LineId: id,
          productId: productId
        });   
    }

    getDefaultInstaller(locationId) {
        this.props.quoteOrderCommonActions.getCompanyLocationInstallers(locationId).then(() => {      
            if (this.props.session.roleName === constants.ROLE.User && !this.props.quote.installerId 
                && this.props.quoteOrderCommon.companyLocationInstallers && this.props.quoteOrderCommon.companyLocationInstallers.length > 0) {                        
                this.updateHeader(constants.ORDER_SQL_COLUMNS.InstallerId, this.props.quoteOrderCommon.companyLocationInstallers[0].id);     
            }                         
        });
    }

    onCommunicationPageChange(currentPage) {
        let communicationOffset = parseInt((currentPage * this.state.communicationRowsToFetch) - this.state.communicationRowsToFetch);
        this.setState({ communicationOffset: communicationOffset, currentCommunicationPage: currentPage }, () => this.getCommunications());   
    }

    onLineDelete(LineId) {        
        this.props.quoteOrderCommonActions.deleteLine(LineId, this.props.quote.id, null);
    }    
  
    onClickDownloadFile(path, originalName, mimetype, filename) {
        if (path && originalName && mimetype && filename)
            this.props.quoteOrderCommonActions.downloadFile(path, originalName, mimetype, filename);       
    }    

    updateAddress(column, value, addressType) {
        this.props.quoteOrderCommonActions.updateAddressField(this.props.quote.id, addressType, column, value, constants.TABLES.Address, constants.TABLES.Quote, this.props.quote.id, null);
    }
    
    updateContact(column, value) {
        this.props.quoteOrderCommonActions.updateContactField(this.props.quote.id, column, value, constants.TABLES.Contact, constants.TABLES.Quote);
    }

    updateHeader(column, value) {             
        this.props.quoteOrderCommonActions.updateHeaderField(this.props.quote.id, column, value, constants.TABLES.Quote);

        if (column === constants.ORDER_SQL_COLUMNS.createdByEmail) {
            this.props.quoteOrderCommonActions.createCompanyLocationDesigner(value, this.props.quote.companyLocationId);
        }  
    }

    updateField(column, value) {        
        this.props.quoteOrderCommonActions.updateField(this.props.quote.id, column, value, constants.TABLES.Quote);
    }

    generateReport(costOrPrice, quoteOrOrder, id) {
        this.props.quoteOrderCommonActions.generateReport(constants.REPORT_URLS.OrderCost, costOrPrice, quoteOrOrder, id);
    }
    
    render() {

        if (this.state.loading === true || !this.props.quoteOrderCommon.userCompanyLocations) return ( <Loading /> );
        
        let submitDisabled = false;       
        let previousDisabled = false;
        let shippingAddress = [];
        let contact = null;
        
       // let preventSubmitStatuses = [constants.STATUS_TYPES.Sent, constants.STATUS_TYPES.Draft];
        
     //   submitDisabled = this.props.quote.statusTypeId && preventSubmitStatuses.includes(this.props.quote.statusTypeId) && this.state.submitButtonLabel === 'Submit';
        if (this.state.stage > this.state.requiredStages) {
            if (this.props.session.roleName === constants.ROLE.User) {
                switch (this.state.status) {
                    case constants.STATUS_TYPES.Sent: 
                        submitDisabled = true;
                        break;
                    case constants.STATUS_TYPES.CancellationRequested:
                        submitDisabled = true;
                        break;
                    case constants.STATUS_TYPES.ConvertedToOrder:
                        submitDisabled = true;
                        break;

                    case constants.STATUS_TYPES.Shipped:
                        submitDisabled = true;
                        break;

                    default:
                        submitDisabled = false;
                        break;
                }
            }
            else {
                switch (this.state.status) {                   
                    case constants.STATUS_TYPES.ConvertedToOrder:
                        submitDisabled = true;
                        break;

                    case constants.STATUS_TYPES.Shipped:
                        submitDisabled = true;
                        break;

                    default:
                        submitDisabled = false;
                        break;
                }
            }
        }

        if (this.state.stage === 1) previousDisabled = true;
        

        if (this.props.quote.shippingAddress)
            shippingAddress = this.props.quote.shippingAddress[0];

        if (this.props.quote.contact)
            contact = this.props.quote.contact;
                    
        //let companyLocation = [];
        let companyLocationName = '';
        let companyLocationFax = '';

        // if (this.props.admin.companyLocations) {
        //     companyLocation = this.props.admin.companyLocations.filter(c => c.id == this.props.quote.companyLocationId);
        //     companyLocationName= companyLocation[0].name;
        //     companyLocationFax = companyLocation[0].faxNumber;
        // }
      
        //   const locationList = this.props.admin.companyLocations;        
        // if (this.props.admin.companyLocations && companyLocationId) {            
        //     companyLocationName = locationList.filter(l => l.id == this.state.companyLocationId)[0].companyLocationName;
        //     companyLocationFax = locationList.filter(l => l.id == this.state.companyLocationId)[0].faxNumber;
        // }

        // if (!companyLocationId && this.props.quoteOrderCommon.userCompanyLocations.length === 1) {
        //     companyLocationId = this.props.quoteOrderCommon.userCompanyLocations[0].id;            
        // }

        // let location = this.props.quoteOrderCommon.userCompanyLocations.filter(l => l.id = this.props.quote.companyLocationId);

        // let allowHomeDelivery = location[0].allowHomeDelivery;
                        
        let attachments = this.props.quote.attachments;

        let communicationPageCount = 1;
        
        if (this.props.quote && this.props.quote.communicationHistory) {
            communicationPageCount = this.props.quote.communicationHistory.length > 0 ? (this.props.quote.communicationHistory[0].totalRows / this.state.communicationRowsToFetch) : 1;
            communicationPageCount = Math.ceil(communicationPageCount);
        }        
        let statusOptions = this.props.quoteOrderCommon.statusTypes.filter(status => status.roleLevel >= this.props.session.roleLevel && status.allowOnQuote === true); 

        let headerDetailLocations = [];
        // if (this.props.session.roleName === constants.ROLE.Fabricator && this.props.admin.companyLocations) {
        //     let userLocations = this.props.session.userCompanyLocationIdCSV.split(',');
        //     headerDetailLocations = this.props.admin.companyLocations.filter(loc => userLocations.includes(loc.id.toString()));
        if (this.props.session.roleName === constants.ROLE.Fabricator && this.props.admin.companyLocations) {
            let userLocations = this.props.session.userCompanyLocationIdCSV.split(',');
            headerDetailLocations = this.props.quoteOrderCommon.userCompanyLocations.filter(loc => userLocations.includes(loc.id.toString()));
        } else if (this.props.session.roleName === constants.ROLE.User && this.props.quoteOrderCommon.userCompanyLocations) {
            headerDetailLocations = this.props.quoteOrderCommon.userCompanyLocations;
        } else if ((this.props.session.roleName === constants.ROLE.CompanyAdmin || this.props.session.roleName === constants.ROLE.SystemAdmin) && this.props.admin.companyLocations) {
            headerDetailLocations = this.props.admin.companyLocations;
        }

        let headerRowWidth = 8;

        if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Draft)
            headerRowWidth = headerRowWidth - 1;

        let showAddressStage = (this.props.quote.shipToTypeId !== constants.SHIP_TO_TYPES.Customer ? false : true);
        let orderLabel = this.props.quote.statusTypeId === constants.STATUS_TYPES.ConvertedToOrder ? ' - Order #: ' + this.props.quote.orderConvertedTo : '';
        let shipToCustomer = this.props.quote.shipToTypeId === constants.SHIP_TO_TYPES.Customer ? true : false;

        let showDraftButton = false;

        if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed || this.props.quote.statusTypeId === constants.STATUS_TYPES.Pending || this.props.quote.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval || this.props.quote.statusTypeId === constants.STATUS_TYPES.Sent) {
            if (this.props.session.roleName === constants.ROLE.Fabricator || this.props.session.roleName === constants.ROLE.SystemAdmin)
                showDraftButton = true;
        }                          
        
        let showPendingButton = false;

        if (this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed || this.props.quote.statusTypeId === constants.STATUS_TYPES.Pending || this.props.quote.statusTypeId === constants.STATUS_TYPES.SubmittedForApproval || this.props.quote.statusTypeId === constants.STATUS_TYPES.Sent) {
            if (this.props.session.roleName === constants.ROLE.Fabricator || this.props.session.roleName === constants.ROLE.SystemAdmin)
                showPendingButton = true;
        }           
        
        return (
            <React.Fragment>
            <ContainerStyled>
                <HeaderRowStyled>                
                    {/* <Col xs="6" xl={headerRowWidth}>                      
                        <h1>Quote #: {this.props.quote.id} - {this.props.quote.statusName} {orderLabel} </h1>
                    </Col>                     */}
                      
                    <LineActions                    
                        onPrint={this.onPrint}
                        onCopy={this.onCopy}
                        submit={this.submitQuote}
                        submitDisabled={submitDisabled}
                        onStageChange={this.onStageChange}
                        stage={this.state.stage}
                        submitButtonLabel={this.state.submitButtonLabel}
                        submitButtonColor={this.state.submitButtonColor}
                        showCancelButton={this.props.quote.statusTypeId === constants.STATUS_TYPES.Sent}
                        onQuoteOrderCancel={this.onQuoteCancel}
                        cancelButtonLabel="Cancel Quote"
                        isLocked={this.state.isLocked} 
                        previousStage={this.previousStage}
                        roleName={this.props.session.roleName}
                        status={this.props.quote.statusTypeId}
                        previousDisabled={previousDisabled}
                        showSaveAsDraftButton={(this.props.quote.statusTypeId === constants.STATUS_TYPES.Draft || this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed) ? true : false}
                        saveAsDraft={this.saveAsDraft}  
                        headerRowWidth={headerRowWidth}
                        quoteId={this.props.quote.id}
                        statusName={this.props.quote.statusName}       
                        quoteConvertedLabel={orderLabel}  
                        generateReport={this.generateReport}               
                    />                                                    
                </HeaderRowStyled>

                {this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed 
                ?   
                    <React.Fragment>                       
                        <StagesConfirmed 
                            stage={this.state.stage} 
                            onStageChange={this.onStageChange}                         
                            allowSubmit={this.state.stage > this.state.requiredStages ? true : false } />   
                            
                        
                    </React.Fragment>   
                        
                : 
                    <Stages 
                        stage={this.state.stage} 
                        onStageChange={this.onStageChange} 
                        showAddressStage={showAddressStage} 
                        allowSubmit={this.state.stage > this.state.requiredStages ? true : false }
                        isLocked={this.state.isLocked}
                        submitLabel={this.getSubmitButtonLabel(this.state.stage) }
                        checkmarkLabel={this.state.checkmarkLabel} />
                }               

                {this.state.stage === constants.QUOTE_STAGES.Details &&       
                    <React.Fragment>
                        <HeaderDetails 
                            description={this.props.quote.name} 
                            onInputFocus={this.onInputFocus} 
                            onFieldUpdate={this.onFieldUpdate} 
                            statusOptions={statusOptions} 
                            status={this.state.status} 
                            companyLocationId={this.props.quote.companyLocationId} 
                            createdByOptions={this.props.quoteOrderCommon.companyLocationUsers}
                            createdByUserId={this.props.quote.createdByUserId}
                            createdByEmail={this.props.quote.createdByEmail}
                            statusChange={this.statusChange}
                            onLocationChange={this.onLocationChange}
                            onCreatedByChanged={this.onCreatedByChanged}
                            userCompanyLocations={headerDetailLocations}
                            companyLocationUsers={this.props.quoteOrderCommon.companyLocationUsers}     
                            onShipToStoreChanged={this.onShipToStoreChanged}
                            shipToStoreTypeId={this.props.quote.shipToTypeId}
                            allowHomeDelivery={this.state.allowHomeDelivery}
                            installTypes={this.state.allowSOSInstall === true ? constants.HK_INSTALL_TYPES : constants.HK_INSTALL_TYPES_SOS_ONLY}
                            installType={this.props.quote.installType}
                            companyLocationInstallers={this.props.quoteOrderCommon.companyLocationInstallers}
                            installerId={this.props.quote.installerId}
                            onHeaderFieldUpdate = {this.onHeaderFieldUpdate}
                            readonly={this.state.isLocked}
                            roleName={this.props.session.roleName}
                            companyList={this.props.admin.companyList}
                            onCompanyChange={this.onCompanyChange}
                            companyId={this.props.quote.companyId}
                            shipToTypes={this.state.allowedShipToTypes} 
                            fabricatorName={this.props.quote.fabricatorName}
                            designers={this.props.quoteOrderCommon.designers}
                            updateHeader={this.updateHeader} 
                        />    

                     
                    </React.Fragment>                             
                }
                
                {this.state.stage === constants.QUOTE_STAGES.Line &&                          
                    <Lines 
                        quoteId={this.props.quote.id} 
                        onCopyLine={this.onCopyLine} 
                        onLineDelete={this.onLineDelete} 
                        lines={this.props.quote.lines} 
                        onQuantityChange={this.onQuantityChange} 
                        onReconfigure={this.onReconfigure} pageType="Quote"                     
                        onProductChange={this.onProductChange}
                        companyProducts={this.props.api.companyProducts}
                        disableAddLine={submitDisabled}
                        onConfigureClick={this.onConfigureClick}
                        stage={this.state.stage} 
                        readonly={this.state.isLocked}
                        onAddCountertopClick={this.onAddCountertopClick} />
                }   

                {this.state.stage === constants.QUOTE_STAGES.Details &&      
                    <ContactInfo onContactFieldUpdate={this.onContactFieldUpdate}  shipToCustomer={this.shipToCustomer(this.props.quote.shipToTypeId)} contact={contact} onInputFocus={this.onInputFocus} readonly={this.state.isLocked} />
                }
                
                {(this.state.stage === constants.QUOTE_STAGES.Attachments || this.state.stage === constants.QUOTE_STAGES.AdditionalAttachments) &&     
                    <React.Fragment>
                        <AttachmentList 
                            faxTemplate={this.onfaxTemplateOpen} 
                            readonly={this.state.isLocked} 
                            onDeleteAttachment={this.onDeleteAttachment} 
                            attachments={attachments} 
                            onClickDownloadFile={this.onClickDownloadFile} 
                            companyLocationName={companyLocationName} 
                            companyLocationFax={companyLocationFax} 
                            isStatusConfirm={this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed ? true : false}
                        />
                        <Attachments readonly={this.state.isLocked} company={this.props.session.company} quoteId={this.props.quote.id} orderId={null} attachmentType={this.state.status === constants.STATUS_TYPES.Confirmed ? 2 : 1} />
                    </React.Fragment>              
                }  

                {showAddressStage === true &&     
                    this.state.stage === constants.QUOTE_STAGES.Address &&                     
                    <AddressForm 
                        title="Shipping Address" 
                        addressType="Shipping"                     
                        onAddressFieldUpdate={this.onAddressFieldUpdate} 
                        onInputFocus={this.onInputFocus} 
                        address={shippingAddress.Address}
                        city={shippingAddress.City}
                        state={shippingAddress.State}
                        address2={shippingAddress.Address2}
                        country={shippingAddress.Country}
                        zip={shippingAddress.Zip}
                        readonly={this.state.isLocked} />
                }      

                {this.state.stage === constants.QUOTE_STAGES.PoNumber && 
                    <PoNumber id="poNumber" onBlur={this.poNumberChanged} defaultValue={this.props.quote.poNumber} />
                }

                {/* {(this.props.quote.statusTypeId === constants.STATUS_TYPES.Confirmed || this.state.stage !== constants.QUOTE_STAGES.Submit) &&
                    <Summary lines={this.props.quote.lines} description={this.props.quote.name} statusName={this.props.quote.statusName} company={this.props.quote.companyName}/>
                } */}

                {(this.state.stage === constants.QUOTE_STAGES.Submit 
                                                        || (shipToCustomer === false && this.state.stage > this.state.shipToStoreTotalRequiredStages) 
                                                        || (shipToCustomer === true && this.state.stage > this.state.shipToCustomerTotalRequiredStages)) &&
                                                        <Summary 
                                                            lines={this.props.quote.lines}
                                                            description={this.props.quote.name}
                                                            statusName={this.props.quote.statusName} 
                                                            company=""                             
                                                        />
                }

                {
                    (this.state.stage === constants.QUOTE_STAGES.ConvertToOrder && this.props.session.roleName === constants.ROLE.SystemAdmin) ?
                    <Lines 
                    quoteId={this.props.quote.id} 
                    onCopyLine={this.onCopyLine} 
                    onLineDelete={this.onLineDelete} 
                    lines={this.props.quote.lines} 
                    onQuantityChange={this.onQuantityChange} 
                    onReconfigure={this.onReconfigure} pageType="Quote"                     
                    onProductChange={this.onProductChange}
                    companyProducts={this.props.api.companyProducts}
                    disableAddLine={submitDisabled}
                    onConfigureClick={this.onConfigureClick}
                    stage={this.state.stage} 
                    readonly={this.state.isLocked}
                    onAddCountertopClick={this.onAddCountertopClick} />
                    :
                    <React.Fragment />
                }
            </ContainerStyled>
            <ContainerStyled>
                {/* <Communication 
                    onCommunicationCollapseClicked={this.onCommunicationCollapseClicked} 
                    collapsed={this.state.communicationCollapsed} 
                    onAddCommunicationClick={this.onAddCommunicationClick} /> */}

                <CommunicationList 
                    onCommunicationCollapseClicked={this.onCommunicationCollapseClicked} 
                    collapsed={this.state.communicationCollapsed} 
                    communicationHistory={this.props.quote.communicationHistory}
                    onCommunicationPageChange={this.onCommunicationPageChange}
                    communicationPageCount = {communicationPageCount}
                    communicationOffset = {this.state.communicationOffset} 
                    currentCommunicationPage = {this.state.currentCommunicationPage} 
                    communicationRowsToFetch = {this.state.communicationRowsToFetch}
                    onAddCommunicationClick={this.onAddCommunicationClick}
                    onSetToPendingInformationClick={this.onSetToPendingInformationClick}
                    onSetToDraftClick={this.onSetToDraftClick}
                    showDraftButton={showDraftButton}
                    showPendingButton={showPendingButton}
                     />
                </ContainerStyled>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
        api: state.api,
        session: state.session,
        quote: state.quote,     
        alert: state.alert,
        quoteOrderCommon: state.quoteOrderCommon,
        admin: state.admin      
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        apiActions: bindActionCreators(apiActions, dispatch),
        sessionActions: bindActionCreators(sessionActions, dispatch),
        quoteActions: bindActionCreators(quoteActions, dispatch),        
        alertActions: bindActionCreators(alertActions, dispatch),
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),        
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quote));