import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import * as constants from '../../modules/constants';

export const RowStyled = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;  
    margin: 0 !important;
    
    :nth-child(even) {
        background-color: ${props => props.theme.gridRowBackgroundColor};
    }
    :nth-child(odd) {
        background-color: ${props => props.theme.gridRowAlternateBackgroundColor};
    }
    :hover {
        background-color: ${props => props.theme.gridLineHoverBackgroundColor};
        color: ${props => props.theme.gridLineHoverColor};
        opacity: ${props => props.theme.gridLineHoverOpacity};
    }
`;

export const IdColStyled = styled(Col)`
    text-align: center;
    @media (max-width:1200px) { 
        font-weight: bold; 
        &::before {
            content: "\\0023";
        }
    }
`;

export const CreateDateColStyled = styled(Col)`
    text-align: center;
    @media (max-width:1200px) {
        color: #7A43B6 !important;
        font-size: 13px !important;
    }
`;

export const NameColStyled = styled(Col)`
    text-align: center;
    @media (max-width:1200px) {
        color: ${props => props.theme.buttonColor} !important;
    }
`;

export const FirstNameColStyled = styled(Col)`
    text-align: center;
    @media (max-width:1200px) { 
        padding-right: 0px !important;
    }
`;

export const LastNameColStyled = styled(Col)`
    text-align: center;
    @media (max-width:1200px) { 
        padding-left: 6px !important;
    }
`;

export const HeaderCol = styled(Col)`
    font-weight: bold;
    text-align: center;
    cursor: pointer;
`;

export const ColStyled = styled(Col)`
    text-align:center;
`;

const DashboardGrid = React.memo(props => {   
    let optionalColumnsDisplay = 0;
    let descriptionWidth = "1";    

    if (props.showInvoicedColumn === true) optionalColumnsDisplay += 1;
    if (props.showSourceQuote === true) optionalColumnsDisplay += 1;
    if (optionalColumnsDisplay === 0) descriptionWidth = "2";    

    return (        
        <React.Fragment> 
             <Row className=".d-none .d-sm-block">
                <HeaderCol xl="1" onClick={() => props.sort('id')}>Id</HeaderCol>
                <HeaderCol xl="1" onClick={() => props.sort('createdDate')}>Created</HeaderCol>                
                <HeaderCol xl="1" onClick={() => props.sort('statusName')}>Status</HeaderCol>   
                <HeaderCol xl="1" onClick={() => props.sort('companyLocationName')}>Location</HeaderCol>
                <HeaderCol xl="1" onClick={() => props.sort('fabricatorName')}>Fabricator</HeaderCol>
                <HeaderCol xl="1" onClick={() => props.sort('companyLocationStoreNumber')}>Store #</HeaderCol>                
                {props.showSourceQuote === true &&   <HeaderCol xl="1" onClick={() => props.sort('estimatedShipDate')}>Estimated Ship</HeaderCol> }
                <HeaderCol xl="1" onClick={() => props.sort('customerName')}>Customer</HeaderCol>          

                {props.showSourceQuote !== true && 
                <HeaderCol xl={descriptionWidth} onClick={() => props.sort('name')}>Description</HeaderCol>  
                }

                {props.showSourceQuote === true && 
                <HeaderCol xl="1" onClick={() => props.sort('poNumber')}>PO Number</HeaderCol>  
                }
                {props.showInvoicedColumn === true &&      
                    <HeaderCol xl="1" onClick={() => props.sort('InvoicedYesNo')}>Invoiced</HeaderCol>                                                                          
                }
                 {props.showSourceQuote === true &&  
                    <HeaderCol xl="1" onClick={() => props.sort('sourceQuoteId')}>Source Quote</HeaderCol>                                                                          
                }
            </Row>                     
            {                                      
                props.quotes.map(quote => {                    
                    let createdDate = dateFormat(quote.createdDate, "m/d/yyyy hh:MM TT", true); 
                    let estimatedShipDate = '';
                    if (quote.estimatedShipDate)
                        estimatedShipDate = dateFormat(quote.estimatedShipDate, "m/d/yyyy hh:MM TT", true); 
                        
                    let allowDelete = quote.companyLocationId;
                    let actions = props.showInvoicedColumn === true ? constants.ORDER_GRID_ACTIONS : constants.GRID_ACTIONS;

                    if (props.isAdmin === false && quote.statusTypeId !== constants.STATUS_TYPES.Draft) {                       
                       actions = actions.filter(a => a.Value !== 'delete');                                      
                    }

                    let customerName = (quote.firstName ? quote.firstName : "") + " " + (quote.lastName ? quote.lastName : "");
                    return (                     
                        <RowStyled key={quote.id} onClick={(e) => props.onListItemClick(e, quote.id)}>                        
                            <IdColStyled xs="12" xl="1">
                                {quote.id}
                            </IdColStyled>                            
                            <CreateDateColStyled xs="12" xl="1">
                                {createdDate}
                            </CreateDateColStyled>   
                            <ColStyled xs="12" xl="1">
                                {quote.statusName}
                            </ColStyled>             
                            <ColStyled xs="12" xl="1">
                                {quote.companyLocationName}
                            </ColStyled>
                            <ColStyled xs="12" xl="1">
                                {quote.fabricatorName}
                            </ColStyled>
                            <ColStyled xs="12" xl="1">
                                {quote.companyLocationStoreNumber}
                            </ColStyled>     
                            {props.showSourceQuote === true &&                        
                            <ColStyled xs="12" xl="1">
                                {estimatedShipDate}
                            </ColStyled> 
                            }
                            <FirstNameColStyled xs="auto" xl="1">
                                {customerName}
                            </FirstNameColStyled>   
                            {props.showSourceQuote !== true &&                              
                            <NameColStyled xs="12" xl={descriptionWidth}>
                                {quote.name}
                            </NameColStyled>  
                            } 

                            {props.showSourceQuote === true &&                              
                            <NameColStyled xs="12" xl="1">
                                {quote.poNumber}
                            </NameColStyled>  
                            } 
                            {props.showInvoicedColumn === true &&   
                                <NameColStyled xs="12" xl="1">
                                    {quote.InvoicedYesNo}
                                </NameColStyled>                    
                            }
                            {props.showSourceQuote === true &&   
                                <NameColStyled xs="12" xl="1">
                                    {quote.sourceQuoteId}
                                </NameColStyled>                    
                            }
                            <NameColStyled xs="12" xl="1">
                                <SelectPrimitive placeHolder="Actions" onChange={e => props.onActionChange(e, quote.id)} onClick={e => props.stopPropagation(e)} id="gridActions" selectableValues={actions}/>
                            </NameColStyled>  
                        </RowStyled>
                    );
                })                    
            }           
        </React.Fragment>
    );
});

DashboardGrid.propTypes = {
    quotes: PropTypes.array.isRequired,    
    onListItemClick: PropTypes.func.isRequired,
    stopPropagation: PropTypes.func.isRequired,
    onActionChange: PropTypes.func.isRequired,
    showInvoicedColumn: PropTypes.bool,
    showSourceQuote: PropTypes.bool, 
    isAdmin: PropTypes.bool,
    sort: PropTypes.func.isRequired


};

export default DashboardGrid;