import React from 'react';
import Button from '../Common/Button/Button';
import PropTypes from 'prop-types';
import { Card, CardImg, CardBody, CardText, Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import styled from 'styled-components';

// border: solid 5px #f5f5f5 !important; 
export const CardStyled = styled(Card)`    
    margin-bottom:30px !important;
    height: 357px;
`;

export const CardBodyStyled = styled(CardBody)`
    padding: 0px !important;        
    text-align: center;
`;

export const ButtonStyled = styled(Button)`
    margin-left:10px !important;    

    &.btn {   
        width: 150px !important;
    }
    
`;

export const CardImgStyled = styled(CardImg)`
    height: 150px;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
    cursor: default;

`;

export const SpanStyled = styled.span`
    font-weight:bold;    
    display:inline-block;
`;

export const SpanLabelStyled = styled.span`
    font-weight:bold;    
    display:inline-block;
    margin-right:5px;
`;

export const ListGroupItemStyled = styled(ListGroupItem)`
  
`;

export const ColStyled = styled(Col)`
    
`;

export const ButtonWrapper = styled.div`    
    background-color: #f5f5f5 !important; 
    position: absolute;
    bottom: 0;
    width: 100%; 
    padding-top: 10px;
    padding-bottom: 10px;

`;

const ConfigurationList = React.memo(props => {       
    return (              
        <Container>    
            {/* <Row>
                <Col xs="12" xl="4">
                    <div class="circle" title="New Design" onClick={props.addConfiguration}></div>
                </Col>  
            </Row>                                            */}
            <Row>     

            <ColStyled xs="12" xl="4" >
                <CardStyled >                           
                    <CardBodyStyled>
                        <CardText>
                            <ListGroup flush>
                                <SpanStyled>
                                    <div className="circle" title="New Design" onClick={props.addConfiguration} />
                                </SpanStyled>                        
                            </ListGroup>
                        </CardText>                                                                
                    </CardBodyStyled>
                </CardStyled>
            </ColStyled>                                                              
            {props.configurationList.map(item => {                                        
                return(                                                                          
                    <ColStyled xs="12" xl="4" key={item.id}>
                        <CardStyled data-testid="configurationCard">
                            {item.imageUrl !== "" ? <CardImgStyled top width="100%" src={item.imageUrl} alt={item.id} /> : "" }
                            <CardBodyStyled>
                                <CardText>
                                    <ListGroup flush>
                                        {/* <ListGroupItemStyled className="text-left"><SpanStyled>ID: </SpanStyled>{item.id}</ListGroupItemStyled>
                                        <ListGroupItemStyled className="text-left"><SpanStyled>Configuration ID: </SpanStyled>{item.configurationId}</ListGroupItemStyled> */}
                                        <ListGroupItemStyled className="text-left"><SpanLabelStyled>Product Name: </SpanLabelStyled>{item.ProductName}</ListGroupItemStyled>                                        
                                        <ListGroupItemStyled className="text-left"><SpanLabelStyled>Description: </SpanLabelStyled>{item.ComponentAttributeValue}</ListGroupItemStyled>                                        
                                    </ListGroup>
                                </CardText>

                                <ButtonWrapper>
                                    <ButtonStyled 
                                        id={item.configurationId}                                                                         
                                        type="Button"
                                        onClick={(e) => props.onReconfigure(e, item.configurationId, item.headerId)}
                                        displayText="Modify" 
                                    />       
                                    <ButtonStyled                                                                        
                                        backgroundColor="#e2231a"                                                                        
                                        type="Button" 
                                        // onClick={(e) => props.onDelete(item.configurationId, item.headerId)}
                                        displayText="Request a Quote" 
                                    />    
                                </ButtonWrapper>                
                                {/* <ButtonStyled                                                                        
                                    backgroundColor="#e2231a"                                                                        
                                    type="Button" 
                                    onClick={(e) => props.onDelete(item.configurationId, item.headerId)}
                                    displayText="Delete" 
                                /> */}
                            </CardBodyStyled>
                        </CardStyled>
                    </ColStyled>
                    );                    
                })} 
            </Row>
        </Container>               
    );  
});

export default ConfigurationList;

ConfigurationList.propTypes = {
    configurationList: PropTypes.array.isRequired,
    onReconfigure: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired      
};