import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as apiActions from '../../actions/apiActions';
import * as alertActions from '../../actions/alertActions';
import { withRouter } from 'react-router-dom';
import { Container, Jumbotron } from 'reactstrap';
import Button from '../Common/Button/Button';
import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import styled from 'styled-components';
import queryString from 'query-string';

export const ButtonStyled = styled(Button)`
    display: block !important;
`;

export const JumbotronStyled = styled(Jumbotron)`
    background-color: #f5f5f5 !important;
`;

export const TextInputWithLabelStyled = styled(TextInputWithLabel)`
    input { background-color: #FFF !important; }
`;

class ResetPassword extends Component {  

    constructor(props, context) {
        super(props, context);    

        this.changePassword = this.changePassword.bind(this);
        this.passwordUpdated = this.passwordUpdated.bind(this);
        this.password2Updated = this.password2Updated.bind(this);

        this.state = {
            isLoading: true,
            password: '',
            password2: '',
            requestId: ''
        }
    }

    componentDidMount() {     
        const params = queryString.parse(this.props.location.search);

        this.setState({ isLoading: false, requestId: params.request });    
    }

    changePassword() {
        
      let passwordValid = this.validatePasswords();

      if (passwordValid === false) return;
       
       this.props.sessionActions.updatePassword(this.state.requestId, this.state.password).then(() => {
            if (this.props.session.loggedIn === true)           
                this.props.history.push("/MyConfigurations");
       });
    }

    validatePasswords() {
        let password = this.state.password;
        let password2 = this.state.password2;

        if (!password || !password2) {          
            this.props.alertActions.showFailureAlert('Please enter and re-enter password.');
            return false;
        }

        if (password !== password2) {
            this.props.alertActions.showFailureAlert('Passwords do not match');
            return false;
        }
    }

    passwordUpdated(event) {
        this.setState({ password: event.target.value });
    }

    password2Updated(event) {
        this.setState({ password2: event.target.value });
    }
  
    render() {            
        if (this.state.isLoading) 
            return (<React.Fragment></React.Fragment>);
            
            return (     
            <Container>
                <JumbotronStyled>
                    <h1>Reset Password</h1>
                    
                    <TextInputWithLabelStyled type="password" id="password" name="password" caption="Password" onBlur={(e) => this.passwordUpdated(e)} />
                    <TextInputWithLabelStyled type="password" id="password2" name="password2" caption="Re-enter Password" onBlur={(e) => this.password2Updated(e)} />
                    
                    <ButtonStyled displayText="Submit" onClick={this.changePassword} />
                </JumbotronStyled>          
            </Container>
                
                        
            );
        }
    }

function mapStateToProps(state, ownProps) {    
  return {
    session: state.session,
    api: state.api,
    alert: state.alert
  };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),
        apiActions: bindActionCreators(apiActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
    };
}    

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));