import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../actions/sessionActions';
import * as adminActions from '../../actions/adminActions';
import * as quoteOrderCommonActions from '../../actions/quoteOrderCommonActions';
import * as alertActions from '../../actions/alertActions';
import { withRouter } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Row, Col } from 'reactstrap';

import InputLabel from '../Common/InputLabel/InputLabel';
import TextInputWithLabel from '../Common/TextInputWithLabel/TextInputWithLabel';
import CheckBoxWithLabel from '../Common/CheckBoxWithLabel/CheckBoxWithLabel';
import CheckBoxPrimitive from '../Common/Primitives/CheckBoxPrimitive';
import Button from '../Common/Button/Button';
import styled from 'styled-components';
import SelectPrimitive from '../Common/Primitives/SelectPrimitive';
import Address from '../QuoteOrderCommon/AddressForm';
import * as constants from '../../modules/constants';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

export const SelectPrimitiveStyled = styled(SelectPrimitive)`
    margin-bottom: 1.5em;
`;

export const LabelStyled = styled(Label)`
    font-weight: bold;    
`;

export const SelectLabelStyled = styled(InputLabel)`
    font-weight: bold;    
`;

export const ButtonStyled = styled(Button)`
    margin-right: 1em;    
`;

export const FormGroupStyled = styled(FormGroup)`
    input {width: 100% !important; }
`;

export const PasswordResetButton = styled(Button)`
    &#passwordReset {
        width: 160px !important;   
    }
`;

export const RowStyled = styled(Row)`
margin-bottom: 25px;
`

export const CheckBoxWithLabelStyled = styled(CheckBoxWithLabel)`
    margin-bottom: 15px;
`;

export const ContainerStyled = styled(Container)`
    padding-left: 1% !important;
    padding-right: 1% !important;
`;

class ModifyCompanyLocations extends Component {  
    constructor(props, context) {
        super(props, context);   
        
        loadProgressBar();
        
        this.formValueChange = this.formValueChange.bind(this);      
        this.cancel = this.cancel.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onAddressFieldUpdate = this.onAddressFieldUpdate.bind(this);
        this.updateField = this.updateField.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        //this.onBranchChange = this.onBranchChange.bind(this);

        this.state = ({
            id: '',       
            name: '',
            quoteSubmitEmailList: '',
            orderEmailList: '',
            quoteSubmitAltEmailList: '',
            orderSubmitAltEmailList: '',
            editMode: false,
            loading: true,
            onFocusValue: '',
            allowHomeDelivery: false,
            activeCompany: '',
            allowSOSInstall: false,
            storeNumber: null,
            phoneNumber: null,
            faxNumber: null,
            fabricatorId: null,
            installerId: null,
            fabricatorLocationId: null,
            isFabricatorLocation: false,
            installerLocationId: null,
            isInstallerLocation: false,
            homeDeliveryCharge: null,
            billingAddress: '',
            billingCity: '',
            billingState: '',
            billingZip: '',
            billingCountry: '',
            billingAddress2: '',
            shippingAddress: '',
            shippingCity: '',
            shippingState: '',
            shippingZip: '',
            shippingCountry: '',
            shippingAddress2: '',
            activeCompanyTypeId: null,
            adPatchTypeId: null         
        });                
    }

    componentDidMount() {       
        if (this.props.admin.activeCompanyLocation === null) {
            this.setState({ loading: false });
            return;
        }
        
        let locations = this.props.admin.companyLocations;

        if (!locations) {
            
            let activeCompany = this.props.admin.companyList.filter(c => c.id == this.props.admin.activeCompanyId)[0];

            this.setState({ 
                activeCompany: activeCompany.company,
                activeCompanyTypeId:  activeCompany.companyTypeId, 
                isFabricatorLocation: activeCompany.companyTypeId == constants.COMPANY_TYPES.Fabricator ? true : false, 
                isInstallerLocation: activeCompany.companyTypeId == constants.COMPANY_TYPES.Installer ? true : false                     
            });                        
            return;
        }

        let activeLocation = locations.filter(location => location.id == this.props.admin.activeCompanyLocation);
        let activeCompany = this.props.admin.companyList.filter(c => c.id == this.props.admin.activeCompanyId)[0];

        this.setState({ 
            activeCompany: activeCompany.company,
            activeCompanyTypeId:  activeCompany.companyTypeId, 
            isFabricatorLocation: activeCompany.companyTypeId == constants.COMPANY_TYPES.Fabricator ? true : false, 
            isInstallerLocation: activeCompany.companyTypeId == constants.COMPANY_TYPES.Installer ? true : false                     
         });
         
        if (activeLocation.length > 0 ) {
            this.setState({ 
                name: activeLocation[0].name, 
                loading: false, editMode: true, 
                quoteSubmitEmailList: activeLocation[0].quoteSubmitEmailList, 
                orderSubmitEmailList: activeLocation[0].orderSubmitEmailList,
                allowHomeDelivery: activeLocation[0].allowHomeDelivery,
                quoteSubmitAltEmailList: activeLocation[0].quoteSubmitAltEmailList,
                orderSubmitAltEmailList: activeLocation[0].orderSubmitAltEmailList,                
                allowSOSInstall: activeLocation[0].allowSOSInstall,
                storeNumber: activeLocation[0].storeNumber,
                phoneNumber: activeLocation[0].phoneNumber,
                faxNumber: activeLocation[0].faxNumber,
                fabricatorId: activeLocation[0].fabricatorId,
                installerId: activeLocation[0].installerId,
                fabricatorLocationId: activeLocation[0].fabricatorLocationId,
                installerLocationId: activeLocation[0].installerLocationId,
                adPatchTypeId: activeLocation[0].adPatchTypeId,
                homeDeliveryCharge: activeLocation[0].homeDeliveryChargeCost               
            });

            this.props.adminActions.getCompanyLocationAddresses(activeLocation[0].id).then(() => {
                this.setState({
                    billingAddress: this.props.admin.activeCompanyLocationBillingAddress.address,
                    billingCity: this.props.admin.activeCompanyLocationBillingAddress.city,
                    billingState: this.props.admin.activeCompanyLocationBillingAddress.state,
                    billingZip: this.props.admin.activeCompanyLocationBillingAddress.zip,
                    billingCountry: this.props.admin.activeCompanyLocationBillingAddress.country,
                    billingAddress2: this.props.admin.activeCompanyLocationBillingAddress.address2,
                    shippingAddress: this.props.admin.activeCompanyLocationShippingAddress.address,
                    shippingCity: this.props.admin.activeCompanyLocationShippingAddress.city,
                    shippingState: this.props.admin.activeCompanyLocationShippingAddress.state,
                    shippingZip: this.props.admin.activeCompanyLocationShippingAddress.zip,
                    shippingCountry: this.props.admin.activeCompanyLocationShippingAddress.country,
                    shippingAddress2: this.props.admin.activeCompanyLocationShippingAddress.address2
                })
            });   
            //this.props.adminActions.getAllCompanyBranches(activeCompany);

            if (activeLocation[0].fabricatorId && activeCompany.companyTypeId !== constants.COMPANY_TYPES.Fabricator)
                this.props.adminActions.getFabricatorLocations(activeLocation[0].fabricatorId);
                                
            if (activeLocation[0].installerId && activeCompany.companyTypeId !== constants.COMPANY_TYPES.Installer)
                this.props.adminActions.getInstallerLocations(activeLocation[0].installerId);
            
        } 

        this.props.adminActions.getAllInstallers(this.props.session.manufacturer);
        this.props.adminActions.getAllFabricators(this.props.session.manufacturer);
        this.props.adminActions.getAdPatchTypes(this.props.session.company);
}

    // onBranchChange(event) {
    //     this.props.adminActions.updateCompanyBranch(this.props.admin.activeCompanyLocation, event.target.value).then(() => {
    //         this.props.adminActions.getCompanyLocations(this.state.activeCompany);
    //     });
    // }
    
    formValueChange(event) {    
        let value;
        if (event.target.type === "checkbox") {
            value = event.target.checked;
            this.onCheckBoxChange(event);
        } else  
            value = event.target.value;
        
        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.FabricatorId) {
            this.setState({ fabricatorId: value, fabricatorLocationId: null });
            this.props.adminActions.getFabricatorLocations(value);
        }

        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.AdPatchTypeId) {
            this.setState({ adPatchTypeId: value });            
        }
        
        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.InstallerId) {
            this.setState({ installerId: value, installerLocationId: null });
            this.props.adminActions.getInstallerLocations(value);
        }

        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.InstallerId)
            this.setState({ installerId: value });

        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.FabricatorLocationId)
            this.setState({ fabricatorLocationId: value });

        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.InstallerLocationId)
            this.setState({ installerLocationId: value });

        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.HomeDeliveryCharge)
            this.setState({ HomeDeliveryCharge: value });

        this.updateField(this.props.admin.activeCompanyLocation, event.target.id, value, constants.TABLES.CompanyLocations);
    }   
    
    onCheckBoxChange(event) {
        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.AllowHomeDelivery) 
            this.setState({ allowHomeDelivery: event.target.checked })

        if (event.target.id === constants.COMPANY_LOCATIONS_SQL_COLUMNS.AllowSOSInstall) 
            this.setState({ allowSOSInstall: event.target.checked })
    }

    cancel() {
        this.props.history.push({ pathname: '/CompanyLocationList' });
    }

    validate() {
        if (!this.state.name) {
            this.props.alertActions.showFailureAlert("Please enter a location name");
            return false;
        }

       return true;
    }     
    
    onInputFocus(event) {        
        this.setState({onFocusValue: event.target.value});
    }

    onAddressFieldUpdate(event, addressType) {
        if (this.state.onFocusValue === event.target.value) return;        

        this.updateAddress(event.target.id, event.target.value, addressType); 
        if (event.target.id === 'State') {
            if (addressType === constants.ADDRESS_TYPES.Billing)
                this.setState({ billingState: event.target.value });
            else 
                this.setState({ shippingState: event.target.value });        
        }
    }

    updateAddress(column, value, type) {        
        let id = type === constants.ADDRESS_TYPES.Billing ? this.props.admin.activeCompanyLocationBillingAddress.addressId : this.props.admin.activeCompanyLocationShippingAddress.addressId;

        this.props.adminActions.updateCompanyAddress(id, column, value).then(() => {
            this.reloadAddresses();
        });
    }

    updateField(id, column, value, table) {
        this.props.quoteOrderCommonActions.updateField(id, column, value, table).then(() => {
            this.reloadAddresses();            
            this.props.adminActions.getCompanyLocations(this.state.activeCompany);
             
        });
    }
    
    reloadAddresses() { 
        this.props.adminActions.getCompanyLocationAddresses(this.props.admin.activeCompanyLocation);
    }

    render() {               
        if (!this.state.loading && this.state.editMode && !this.props.admin.companyLocations && !this.props.admin.activeCompanyLocationBillingAddress) return <React.Fragment />
        
        //  let allowHomeDelivery = false;        
       // let branchId;

        if (this.state.editMode === true) {            
            let activeLocation = this.props.admin.companyLocations.filter(l => l.id === this.props.admin.activeCompanyLocation)[0];
           // allowHomeDelivery = activeLocation.allowHomeDelivery;
           
           // branchId = activeLocation.branchId;
        }

        let companyType = this.state.activeCompanyTypeId == constants.COMPANY_TYPES.Fabricator ? 'Fabricator' : this.state.activeCompanyTypeId == constants.COMPANY_TYPES.Installer ? 'Installer' : 'Store';

                                  
        return (     
        <ContainerStyled className="add-location p-3">            
            <h1 className="text-center">{companyType} Location Admin </h1>
            <Form id="location">
                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="name" caption="Name" defaultValue={this.state.name} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>

                <SelectLabelStyled isRequired={true} for="adPatchTypeId" caption="Ad Patch" />
                <SelectPrimitiveStyled id="adPatchTypeId" value={this.state.adPatchTypeId} selectableValues={this.props.admin.adPatchTypes} placeHolder="Select AD Patch" onChange={(e) => this.formValueChange(e)} />                
                
                {this.state.isFabricatorLocation === false && this.state.isInstallerLocation === false &&
                    <FormGroupStyled>  
                        <TextInputWithLabel id="storeNumber" caption="Store Number" defaultValue={this.state.storeNumber} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                                                                                                                                                                                   
                        <SelectLabelStyled isRequired={true} for="fabricatorId" caption="Fabricator" />
                        <SelectPrimitiveStyled id="fabricatorId" value={this.state.fabricatorId} selectableValues={this.props.admin.fabricatorList} placeHolder="Select a Fabricator" onChange={(e) => this.formValueChange(e)} />                

                        <SelectLabelStyled isRequired={true} for="fabricatorLocationId" caption="Fabricator Location" />
                        <SelectPrimitiveStyled id="fabricatorLocationId" value={this.state.fabricatorLocationId} selectableValues={this.props.admin.fabricatorLocations} placeHolder="Select Fabricator Location" onChange={(e) => this.formValueChange(e)} />                

                        <SelectLabelStyled isRequired={true} for="installerId" caption="Installer Listing" />
                        <SelectPrimitiveStyled id="installerId" value={this.state.installerId} selectableValues={this.props.admin.installerList} placeHolder="Select an Installer" onChange={(e) => this.formValueChange(e)} />                

                        <SelectLabelStyled isRequired={true} for="installerLocationId" caption="Installer Location" />
                        <SelectPrimitiveStyled id="installerLocationId" value={this.state.installerLocationId} selectableValues={this.props.admin.installerLocations} placeHolder="Select Installer Location" onChange={(e) => this.formValueChange(e)} />                
                    </FormGroupStyled>                                                                                                                                                          
                }

                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="phoneNumber" caption="Phone Number" defaultValue={this.state.phoneNumber} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>
                
                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="faxNumber" caption="Fax Number" defaultValue={this.state.faxNumber} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>

                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="homeDeliveryCharge" caption="Home Delivery Charge" defaultValue={this.state.homeDeliveryCharge} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>

                {this.state.isFabricatorLocation === false && this.state.isInstallerLocation === false &&
                    <React.Fragment>
                        <FormGroup>
                            <LabelStyled for="allowHomeDelivery">Allow Home Delivery?</LabelStyled>
                            <CheckBoxPrimitive id="allowHomeDelivery" checked={this.state.allowHomeDelivery} onChange={(e) => this.formValueChange(e)} required={true} autoFocus={false} />                                                                                           
                            {/* <CheckBoxWithLabelStyled id="allowHomeDelivery" labelCaption="Allow Home Delivery?" defaultChecked={this.state.allowHomeDelivery} onCheckChanged={(e) => this.formValueChange(e)} required={true} autoFocus={false} /> */}
                        </FormGroup>
                    
                        <FormGroup>
                            <LabelStyled for="allowSOSInstall">Allow SOS Install?</LabelStyled>
                            <CheckBoxPrimitive id="allowSOSInstall" checked={this.state.allowSOSInstall} onChange={(e) => this.formValueChange(e)} required={true} autoFocus={false} />                                                                                           

                            {/* <CheckBoxWithLabelStyled id="allowSOSInstall" labelCaption="Allow SOS Install?" defaultChecked={this.state.allowSOSInstall} onCheckChanged={(e) => this.formValueChange(e)} required={true} autoFocus={false} /> */}
                        </FormGroup>   
                    </React.Fragment>                                                                                                                                                    
                }               

                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="quoteSubmitEmailList" caption="SOS Quote Email List CSV" defaultValue={this.state.quoteSubmitEmailList} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>
                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="orderSubmitEmailList" caption="SOS Order Email List CSV" defaultValue={this.state.orderSubmitEmailList} onBlur={(e) => this.formValueChange(e)} required={true} />                                                                                           
                </FormGroupStyled>

                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="quoteSubmitAltEmailList" caption="SOS Install Quote Email List CSV" defaultValue={this.state.quoteSubmitAltEmailList} onBlur={(e) => this.formValueChange(e)} required={false} />                                                                                           
                </FormGroupStyled>                
                <FormGroupStyled>                                                                                                                                                          
                    <TextInputWithLabel id="orderSubmitAltEmailList" caption="SOS Install Order Email List CSV" defaultValue={this.state.orderSubmitAltEmailList} onBlur={(e) => this.formValueChange(e)} required={false} />                                                                                           
                </FormGroupStyled>                

                <RowStyled>
                    <Col>
                        <Address 
                            onInputFocus={this.onInputFocus} 
                            addressType={constants.ADDRESS_TYPES.Billing} 
                            title='Billing Address' 
                            onAddressFieldUpdate={this.onAddressFieldUpdate} 
                            state={this.state.billingState}
                            address={this.state.billingAddress}
                            city={this.state.billingCity}                            
                            address2={this.state.billingAddress2}
                            country={this.state.billingCountry}
                            zip={this.state.billingZip} 
                        />  
                    </Col>
                </RowStyled>
                <RowStyled>
                    <Col>
                        <Address 
                            onInputFocus={this.onInputFocus} 
                            addressType={constants.ADDRESS_TYPES.Shipping} 
                            title='Shipping Address' 
                            onAddressFieldUpdate={this.onAddressFieldUpdate} 
                            state={this.state.shippingState}
                            address={this.state.shippingAddress}
                            city={this.state.shippingCity}                            
                            address2={this.state.shippingAddress2}
                            country={this.state.shippingCountry}
                            zip={this.state.shippingZip} 
                        /> 
                    </Col>
                </RowStyled>                                                                                              
                                                 
                <div className="text-center">                    
                    <ButtonStyled onClick={this.cancel} backgroundColor="e2231a" displayText="Back" />
                </div>
            </Form>
        </ContainerStyled>           
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
      session: state.session,      
      quoteOrderCommon: state.quoteOrderCommon,
      alert: state.alert,
      admin: state.admin
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch),          
        quoteOrderCommonActions: bindActionCreators(quoteOrderCommonActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
    };
}   

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModifyCompanyLocations));