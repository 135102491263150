import React from 'react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import * as quoteActions from '../../actions/quoteActions';
import * as orderActions from '../../actions/orderActions';
import * as alertActions from '../../actions/alertActions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

export const FileDropArea = styled.div`
    .wrapper {       
      width: 100%;
      border: dashed 2px lightgray;
      cursor: pointer;
    }

    div {
      padding: 15px;
      text-align: center;
      color: lightgray;
      font-size: 24px !important;
      font-weight: 400;
    }
`;

class FileUploadContainer extends React.Component {
  constructor(props, context) {
    super(props, context); 
  
      this.onImageDrop = this.onImageDrop.bind(this);
    }
  
    onImageDrop(acceptedFiles) {
      const data = new FormData();
      
      for (let i = 0; i < acceptedFiles.length; i += 1) {


        const allowedTypes = ["image/png", "application/pdf", "image/jpeg", "application/msword", "image/tiff", "image/bmp"];

        if (allowedTypes.includes(acceptedFiles[i].type) === false) {           
           this.props.alertActions.showFailureAlert("Invalid file type.");
           return;
        }

        data.append('file', acceptedFiles[i]);        
      }

      data.append('company', this.props.company);
      data.append('quoteId', this.props.quoteId);
      data.append('orderId', this.props.orderId);
      data.append('attachmentType', this.props.attachmentType);
     
  
      $.ajax({
        url: '/api/v1/quoteOrderCommon/uploadAttachment',
        data,
        processData: false,
        contentType: false,
        method: 'POST',
        dataType: 'json',
        success: (response) => {
          if (response) {

            // alert('success');
          } else {
           // alert('failed');
          }

          if (this.props.orderId) {
            this.props.orderActions.getOrderById(this.props.orderId);
          } else {
            this.props.quoteActions.getQuoteById(this.props.quoteId);
          }
          
        },
        error: (jqXHR) => {
          const res = jqXHR.responseJSON;
          alert('error: ' + JSON.stringify(res));
        },
      });
    }
  
    render() {
      if (this.props.readonly === true) return (<React.Fragment />);
      return (
        <Row>
          <Col>
            <FileDropArea>            
                <Dropzone onDrop={acceptedFiles => this.onImageDrop(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="wrapper"><div>Drag and drop files here or click to select files</div></div>
                    </div>
                    </section>
                )}
                </Dropzone>
            </FileDropArea>
          </Col>
        </Row>
      );
    }
  }
 
function mapStateToProps(state, ownProps) {    
    return {
      quote: state.quote,
      order: state.order,
      alert: state.alert
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        quoteActions: bindActionCreators(quoteActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),
    };
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileUploadContainer));

FileUploadContainer.propTypes = {
  company: PropTypes.string.isRequired,
  quoteId: PropTypes.number,
  orderId: PropTypes.number, 
  readonly: PropTypes.bool 
};